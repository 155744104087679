import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

import { clone, get, set } from 'lodash';

import PlanoDiretor from 'containers/PlanoDiretor/PlanoDiretor';

import useSaveImutableState from 'custom-hooks/useSaveImutableState';

import { isDebug } from 'utils/tools';

export const PLANODIRETOR_CONTROLNAME = 'planoDiretor';

export function PlanoDiretorSubfield({
	formulario,
	onChangeHandler,
	data: dataProps,
	errosSubmissao,
	filtroAtividadesPlanoDiretor
}) {
	/** dados do plano diretor do localData */
	const planoDiretorData = get(dataProps, PLANODIRETOR_CONTROLNAME, {});

	// controla comportamento do PlanoDiretorRegimeUrbanistico
	const setIsLicencasExpressas = useSaveImutableState('isLicencasExpressas', true);
	useEffect(() => {
		setIsLicencasExpressas(true);
		return () => {
			setIsLicencasExpressas(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			const { name, value } = changes;

			set(planoDiretorData, `${name}`, clone(value));
			onChangeHandler && onChangeHandler({ name: PLANODIRETOR_CONTROLNAME, value: clone(planoDiretorData) });
		},
		[onChangeHandler, planoDiretorData]
	);

	return (
		<>
			{isDebug && <div className="debug-message">{PLANODIRETOR_CONTROLNAME}</div>}
			<PlanoDiretor
				showAtividadesPlanoDiretor={true}
				filtroAtividadesPlanoDiretor={filtroAtividadesPlanoDiretor}
				data={planoDiretorData}
				formulario={formulario}
				readOnly={false}
				onChangeHandler={(value, name) => localChangeHandler({ value, name })}
				errosSubmissao={errosSubmissao}
			/>
		</>
	);
}

PlanoDiretorSubfield.displayName = 'PlanoDiretorSubfield';
PlanoDiretorSubfield.propTypes = {
	formulario: PropTypes.object,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	errosSubmissao: PropTypes.array,
	filtroAtividadesPlanoDiretor: PropTypes.array
};
