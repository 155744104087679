import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import ReactDropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';

import Tippy from '@tippyjs/react';
import { size, get, toUpper } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import actions from '../actions';

const DocumentoUpload = ({
	formData,
	documento,
	emProcessamento,
	arquivoGerado,
	onDrop,
	removeFile,
	retiraDocumento,
	procedimentoFormatadoSei,
	usuarioDonoTask,
	emComplementacao,
	naoPodeRemoverSlot
}) => {
	const extensoesPermitidas = [
		'pdf',
		'dwg',
		'doc',
		'docx',
		'xls',
		'xlsx',
		'ppt',
		'pptx',
		'csv',
		'kmz',
		'kml',
		'msg',
		'shp',
		'zip',
		'rar',
		'mp4'
	];

	const dispatch = useDispatch();

	const retirar = payload => {
		dispatch(actions.retiraDocumento(payload));
	};

	retiraDocumento = retiraDocumento || retirar;

	return arquivoGerado ? (
		<Fragment>
			<div className="form-control" style={{ marginTop: '6px', border: '2px solid #c5c5c9', padding: '16px' }}>
				<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} />

				{emProcessamento ? (
					<i
						className="fa fa-over fa-exclamation-circle"
						style={{
							color: '#09afa7'
						}}
					>
						{procedimentoFormatadoSei ? (
							<div className="icon-tooltip">Arquivo enviado para servidor. Aguardando complementação do processo.</div>
						) : (
							<div className="icon-tooltip">Arquivo enviado para servidor. Aguardando criação do processo.</div>
						)}
					</i>
				) : documento.originalName ? (
					<i
						className="fa fa-over fa-check"
						style={{
							color: 'green'
						}}
					>
						<div className="icon-tooltip">Arquivo anexado ao processo.</div>
					</i>
				) : (
					<i
						className="fa fa-over fa-exclamation-circle"
						style={{
							color: '#09afa7'
						}}
					/>
				)}

				{documento.originalName
					? documento.originalName.length > 33
						? `${documento.originalName.substring(0, 24)}(...)${documento.originalName.substring(
								documento.originalName.length - 4,
								documento.originalName.length
						  )}`
						: documento.originalName
					: 'Arquivo rejeitado'}
				{(!formData?.procedimentoFormatadoSei || (!documento.idDocumentoSei && formData.dataComparecimento)) &&
					usuarioDonoTask &&
					removeFile && (
						<div style={{ float: 'right' }}>
							<button type="button" className="btn-link" onClick={() => removeFile(documento)}>
								<i className="fa fa-trash-o" style={{ fontSize: '18px' }} />
							</button>
						</div>
					)}
			</div>
		</Fragment>
	) : (
		<Fragment>
			{(extensoesPermitidas.includes(documento.extensao) || !documento.extensao) && (
				<Fragment>
					<ReactDropzone
						multiple={false}
						onDrop={onDrop(documento)}
						/* accept={lowerCase(documento.extensao) === 'dwg' ? '.dwg' : 'application/pdf'} */
						accept={documento.extensao ? `.${documento.extensao}` : 'application/pdf'}
						style={{ border: '2px solid #c5c5c9', padding: '16px' }}
						rejectStyle={{ border: '2px solid red' }}
						acceptStyle={{ border: '2px solid green' }}
						inputProps={{ id: `inputFile${documento.id}` }}
						className={'form-control'}
						disabled={size(procedimentoFormatadoSei) > 0 && !emComplementacao}
					>
						{props => {
							// eslint-disable-next-line react/prop-types
							const { isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles, draggedFiles } = props;
							if (procedimentoFormatadoSei && !emComplementacao) {
								return 'Processo submetido. Não é possível enviar ou alterar documentos nesse momento.';
							}
							if (isDragReject) {
								if (size(draggedFiles) > 1) {
									return 'Apenas um arquivo pode ser submetido para este campo';
								}
								return `Apenas arquivos ${documento.extensao ? `.${documento.extensao}` : 'PDF'} podem ser submetidos`;
							}
							if (isDragAccept) {
								return 'Arquivo válido';
							}
							if (!isDragActive) {
								return documento.originalName
									? `${documento.originalName} (${documento.size})`
									: `Adicione um arquivo ${documento.extensao ? `.${toUpper(documento.extensao)}` : 'PDF'}`;
							}
							return acceptedFiles.length || rejectedFiles.length
								? `Accepted ${acceptedFiles.length}, rejected ${rejectedFiles.length} files`
								: 'Try dropping some files.';
						}}
					</ReactDropzone>
					{((documento.fromSolicitante && usuarioDonoTask) ||
						(documento.filename === undefined && !documento.obrigatorio)) &&
						!naoPodeRemoverSlot && (
							<Tippy placement="top" content={'Apagar o slot opcional'}>
								<div
									style={{
										position: 'relative',
										float: 'right',
										marginTop: '-39px',
										marginRight: '18px'
									}}
								>
									<button
										type="button"
										className="btn-link"
										onClick={() =>
											retiraDocumento({ idFormData: formData.id || formData._id, idDocumento: documento.id })
										}
									>
										<i className="fa fa-minus-square-o" style={{ fontSize: '18px' }} />
									</button>
								</div>
							</Tippy>
						)}
					{get(documento, 'linkHelp') ? (
						<div className={documento?.idDocumento === 'planta-assinada-rt' ? 'link-upload-destaque' : ''}>
							<a href={documento.linkHelp} target="_blank" rel="noopener noreferrer">
								{documento.textoHelp || documento.linkHelp}
							</a>
						</div>
					) : (
						get(documento, 'textoHelp') && <span className="field-help">{documento.textoHelp}</span>
					)}
				</Fragment>
			)}
			{documento.descricaoOutroDocumento ? (
				<ErrorMessages
					type={'warning'}
					errorList={[`Novo documento requerido: ${get(documento, 'descricaoOutroDocumento', '')}`]}
				/>
			) : null}
		</Fragment>
	);
};
DocumentoUpload.displayName = 'DocumentoUpload';

DocumentoUpload.propTypes = {
	formData: PropTypes.any,
	documento: PropTypes.any,
	emProcessamento: PropTypes.bool,
	arquivoGerado: PropTypes.bool,
	onDrop: PropTypes.func,
	acceptedFiles: PropTypes.array,
	rejectedFiles: PropTypes.array,
	removeFile: PropTypes.func,
	retiraDocumento: PropTypes.func,
	procedimentoFormatadoSei: PropTypes.string,
	usuarioDonoTask: PropTypes.bool,
	emComplementacao: PropTypes.bool,
	naoPodeRemoverSlot: PropTypes.bool
};

export default DocumentoUpload;
