import React from 'react';

import PropTypes from 'prop-types';

import { divide, formatFloat } from 'utils/tools';

function ResumoIndicesConstrutivosAP({ planilha, planoDiretor, possuiImovelInventariado, isFocoModeHistory = false }) {
	const subunidades = (planoDiretor?.dadosSubunidades || []).map(sAux => sAux.subunidade?.subunidade);
	const headerColspan = 3 + (possuiImovelInventariado ? 1 : 0) + subunidades?.length || 0;
	return (
		<>
			<legend className="legenda-titulo-secundario">Quadro Resumo</legend>
			<div className="table-sticky-container">
				<table
					className={`table table-sm table-ap${isFocoModeHistory ? ' table-ap-history-view' : ''}`}
					id="table-areas"
				>
					<thead>
						<tr>
							<th rowSpan={2} className="center">
								TIPOS DE ÁREA
							</th>
							<th rowSpan={2} className="center">
								IA BÁSICO
							</th>
							<th colSpan={headerColspan} className="center">
								AQUISIÇÃO DE ÍNDICES
							</th>
							<th rowSpan={2} className="center">
								TOTAL
							</th>
						</tr>
						<tr>
							<th className="center">SC</th>
							{(subunidades || [])?.map((sAux, index) => (
								<th key={index} className="center">
									TPC LOCAL
									<br />
									subunidade {sAux}
								</th>
							))}
							<th className="center">TPC EXTERNA</th>
							<th className="center">LEILÃO SMF</th>
							{possuiImovelInventariado && <th className="center">Lei N° 12.585/2019</th>}
						</tr>
					</thead>
					<tbody>
						<tr>
							<th className="center">ADENSÁVEL</th>
							<td className="pic-resumo-valor">{formatFloat(planilha?.totalIaPermitido || 0, 2)}</td>
							<td className="pic-resumo-valor">
								{formatFloat(planilha?.soloCriadoAdensavel?.subtotalAreaCalculada || 0, 2)}
							</td>
							{subunidades.map((sAux, i) => {
								const property = `potencialConstrutivoTpcLocal_${sAux}`;
								return (
									<td key={i} className="pic-resumo-valor">
										{formatFloat(planilha?.[property]?.subtotalAreaCalculada || 0, 2)}
									</td>
								);
							})}
							<td className="pic-resumo-valor">
								{formatFloat(planilha?.potencialConstrutivoTpcRealizada?.subtotalAreaCalculada || 0, 2)}
							</td>
							<td className="pic-resumo-valor">
								{formatFloat(planilha?.potencialConstrutivoTpcLeilaoSmf?.subtotalAreaCalculada || 0, 2)}
							</td>
							{possuiImovelInventariado && (
								<td className="pic-resumo-valor">
									{formatFloat(planilha?.potencialConstrutivoImovelInventariado?.subtotalAreaCalculada || 0, 2)}
								</td>
							)}
							<td className="pic-resumo-total">{formatFloat(planilha?.totalIaPermitidaMaisAdensavel || 0, 2)}</td>
						</tr>
						<tr>
							<th className="center">NÃO ADENSÁVEL (50%)</th>
							<td className="pic-resumo-valor">{formatFloat((planilha?.totalIaPermitido || 0) / 2, 2)}</td>
							<td className="pic-resumo-valor">
								{formatFloat(divide(planilha?.soloCriadoAdensavel?.subtotalAreaCalculada, 2), 2)}
							</td>
							{subunidades.map((sAux, i) => {
								const property = `potencialConstrutivoTpcLocal_${sAux}`;
								return (
									<td key={i} className="pic-resumo-valor">
										{formatFloat(divide(planilha?.[property]?.subtotalAreaCalculada, 2), 2)}
									</td>
								);
							})}
							<td className="pic-resumo-valor">
								{formatFloat(divide(planilha?.potencialConstrutivoTpcRealizada?.subtotalAreaCalculada, 2), 2)}
							</td>
							<td className="pic-resumo-valor">
								{formatFloat(divide(planilha?.potencialConstrutivoTpcLeilaoSmf?.subtotalAreaCalculada, 2), 2)}
							</td>
							{possuiImovelInventariado && (
								<td className="pic-resumo-valor">
									{formatFloat(divide(planilha?.potencialConstrutivoImovelInventariado?.subtotalAreaCalculada, 2), 2)}
								</td>
							)}
							<td rowSpan={2} className="pic-resumo-total">
								{formatFloat(planilha?.totalIaPermitidaMaisNaoAdensavel || 0, 2)}
							</td>
						</tr>
						<tr>
							<th className="center">AQUISIÇÃO NÃO ADENSÁVEL</th>
							<td className="pic-resumo-achurado">&nbsp;</td>
							<td className="pic-resumo-valor">
								{formatFloat(planilha?.soloCriadoNaoAdensavel?.subtotalAreaCalculada || 0, 2)}
							</td>
							{subunidades.map((sAux, i) => (
								<td key={i} className="pic-resumo-achurado">
									&nbsp;
								</td>
							))}
							<td className="pic-resumo-achurado">&nbsp;</td>
							<td className="pic-resumo-achurado">&nbsp;</td>
							{possuiImovelInventariado && <td className="pic-resumo-achurado">&nbsp;</td>}
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
}
ResumoIndicesConstrutivosAP.displayName = 'ResumoIndicesConstrutivosAP';
ResumoIndicesConstrutivosAP.propTypes = {
	planilha: PropTypes.object,
	planilhaAnterior: PropTypes.object,
	planoDiretor: PropTypes.object,
	possuiImovelInventariado: PropTypes.bool,
	usuarioInterno: PropTypes.bool,
	isFocoModeHistory: PropTypes.bool
};

export default ResumoIndicesConstrutivosAP;
