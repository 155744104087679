import React, { useState, Fragment, useCallback } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';

import { getValueCutDigits } from 'utils/tools';

import { NOVA_AREA_UDRI } from './PlanilhaIndicesConstrutivos';
import PlanilhaIndicesConstrutivosArea from './PlanilhaIndicesConstrutivosArea';

function PlanilhaIndicesConstrutivosLinha({
	label,
	observacao,
	name,
	value,
	valueAnterior,
	decimals,
	onChangeHandler,
	readOnly,
	locked,
	lockedMessage,
	errors,
	usuarioInterno
}) {
	const [showObs, setShowObs] = useState(() =>
		(value?.areas || [{}]).filter(a => !(readOnly || locked) || a.informada).map(a => (size(a.obs) > 0 ? true : false))
	);

	const handleChange = (ind, property) => valorDigitado => {
		const informada = property === 'informada' ? parseFloat(valorDigitado) : value.areas[ind].informada;
		const obs = property === 'obs' ? valorDigitado : value.areas[ind].obs;
		const newAreas = (value?.areas || []).map((vAux, i) => (i === ind ? { informada, obs } : vAux));
		const subtotalAreaCalculada = newAreas.reduce((acc, area) => acc + (area.informada || 0), 0);
		const newValue = {
			...value,
			areas: newAreas,
			subtotalAreaCalculada: getValueCutDigits(subtotalAreaCalculada, 4)
		};
		onChangeHandler(newValue, name);
	};

	const removeLine = useCallback(
		ind => {
			const newAreas = value.areas.filter((o, i) => i !== ind);
			const newShowObs = showObs.filter((o, i) => i !== ind);
			const subtotalAreaCalculada = newAreas.reduce((acc, area) => acc + (area.informada || 0), 0);
			const newValue = { ...value, areas: newAreas, subtotalAreaCalculada };
			setShowObs(newShowObs);
			onChangeHandler(newValue, name);
		},
		[name, onChangeHandler, showObs, value]
	);

	return (
		<>
			{(value?.areas || [])
				.filter(a => !readOnly || a.informada)
				.map((val, ind) => {
					const valAnterior = valueAnterior?.areas
						?.filter(a => !readOnly || a.informada)
						?.find((valAnt, indAnt) => indAnt === ind);
					return (
						<Fragment key={ind}>
							<div className="flex" style={{ flexDirection: 'column' }}>
								{ind === 0 ? (
									// <div style={{ marginTop: '-11px' }}>
									<div>
										<span
											className="planilha-indices-construtivos-titulos"
											style={{ marginTop: size(label) < 30 ? '0px' : '0px' }}
										>
											{label}
										</span>
										{size(observacao) > 0 && <span className="label-observacao">{observacao}</span>}
									</div>
								) : (
									<span
										className="planilha-indices-construtivos-titulos"
										style={{ marginTop: size(label) < 30 ? '0px' : '0px' }}
									></span>
								)}
							</div>
							<div className="grupo-indice-construtivo planilha-indices-construtivos">
								<PlanilhaIndicesConstrutivosArea
									ind={ind}
									value={val?.informada}
									valueAnterior={valAnterior?.informada}
									decimals={decimals}
									onChangeHandler={handleChange(ind, 'informada')}
									readOnly={readOnly || locked}
									warning={lockedMessage}
									usuarioInterno={usuarioInterno}
									placeHolder={'0,00 m²'}
								/>
								{locked ? (
									<span>&nbsp;</span>
								) : (
									!readOnly && (
										<div className="btns-add-linha-obs">
											{ind === 0 ? ( // value.areas.length - 1
												<button
													type="button"
													className="btn-add-linha"
													onClick={() => {
														setShowObs(old => [...old, false]);
														onChangeHandler({ ...value, areas: [...value.areas, NOVA_AREA_UDRI] }, name);
													}}
												>
													<i className="fa fa-plus" />
													<span>Adicionar linha</span>
												</button>
											) : (
												<button type="button" className="btn-add-linha" onClick={() => removeLine(ind)}>
													<i className="fa fa-minus" />
													<span>Excluir linha</span>
												</button>
											)}
											<button
												type="button"
												className="btn-add-linha"
												style={{
													// color: size(val?.obs) > 0 ? 'rgb(131, 12, 12)' : '#687c80',
													cursor: 'pointer'
												}}
												onClick={() => setShowObs(old => old.map((o, i) => (i === ind ? !o : o)))}
											>
												{size(val?.obs) > 0 ? <strong>observação</strong> : <span>Observação</span>}
											</button>{' '}
										</div>
									)
								)}
								{/* segunda linha */}
								{showObs[ind] && (
									<>
										{/* <span className="planilha-indices-construtivos-titulos"></span> */}
										<div
											className="linha-obs"
											style={{
												display: 'flex',
												gap: '10px',
												alignItems: 'baseline',
												gridColumn: '1 / span 2',
												textAlign: 'right',
												// marginTop: '-10px',
												// borderBottom: '1px solid #dedede',
												paddingBottom: '5px'
											}}
										>
											{/* <b style={{ marginLeft: '10px', fontSize: '14px' }}>Observações:</b> */}
											<input
												className="form-control"
												placeholder={'Observação'}
												type="text"
												value={val?.obs || ''}
												onChange={e => handleChange(ind, 'obs')(e.target.value)}
												readOnly={readOnly || locked}
												disabled={readOnly}
											/>
											<HistoricoRespostaAnterior
												dataAtual={val?.obs}
												dataAnterior={valAnterior?.obs}
												usuarioInterno={usuarioInterno}
											/>
										</div>

										{!readOnly && <span className="planilha-indices-construtivos-titulos" />}
									</>
								)}
							</div>
						</Fragment>
					);
				})}
			{size(value?.areas.filter(a => a.informada)) > 1 && (
				<>
					<span className="planilha-indices-construtivos-titulos">
						<strong>subtotal:</strong>
					</span>
					<PlanilhaIndicesConstrutivosArea
						value={value.subtotalAreaCalculada}
						valueAnterior={valueAnterior?.subtotalAreaCalculada}
						readOnly={true}
						auto={true}
						decimals={decimals}
						usuarioInterno={usuarioInterno}
					/>
					{!readOnly && <span className="planilha-indices-construtivos-titulos">&nbsp;</span>}
				</>
			)}
			{size(errors) > 0 && (
				<div style={{ gridColumn: `1 / span ${readOnly ? '2' : '3'}` }}>
					<ErrorMessages errorList={errors} />
				</div>
			)}
		</>
	);
}
PlanilhaIndicesConstrutivosLinha.displayName = 'PlanilhaIndicesConstrutivosLinha';
PlanilhaIndicesConstrutivosLinha.propTypes = {
	label: PropTypes.string,
	observacao: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.object,
	valueAnterior: PropTypes.object,
	decimals: PropTypes.number,
	onChangeHandler: PropTypes.func,
	readOnly: PropTypes.bool,
	locked: PropTypes.bool,
	lockedMessage: PropTypes.string,
	errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
	usuarioInterno: PropTypes.bool
};

export default PlanilhaIndicesConstrutivosLinha;
