import React, { useState, useEffect, useCallback, useMemo } from 'react';

import 'components/style/PlanoDiretorAtividade.scss';

import PropTypes from 'prop-types';

import { get, replace, size, trim } from 'lodash';
import uuid from 'uuid/v4';

import Comentario from 'components/Comentario';
import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';

import { RadioField } from 'containers/Form/metadata-template/fields';

import useMutableState from 'custom-hooks/useMutableState';
import useQueryStore from 'custom-hooks/useQueryStore';

import { API_URL, PLANO_DIRETOR_API_URL } from 'environments';

import { isDebug } from 'utils/tools';

import PlanoDiretorAnexosSelecao from './PlanoDiretorAnexosSelecao';
import {
	ATIVIDADES_EVU,
	TABELA_FILTRO_INTERFERENCIA_AMBIENTAL,
	TABELA_INTERFERENCIA_AMBIENTAL,
	TABELA_PORTE,
	TABELA_RESTRICAO_IMPLANTACAO
} from './PlanoDiretorConstantes';

let i = 0;

const debugLog = (...parms) =>
	false && isDebug && console.debug('(trk-PLANO_DIRETOR_REGIME_URBANISTICO):', ++i, ...parms);

const sufixoComentario = 'Comentario';

const PlanoDiretorAtividades = ({
	data: dadosSubunidade = {},
	// eslint-disable-next-line no-unused-vars
	dataAnterior: dadosSubunidadeAnterior = {},
	readOnly,
	setDataHandler,
	showAtividadesItems,
	hideAtividadesItems,
	disabled = false,
	// eslint-disable-next-line no-unused-vars
	usuarioInterno = false,
	readOnlyVersao = null,
	onChangeComentarioHandler
}) => {
	/* CUSTOM HOOKS */
	const errors = useMutableState(['licenciamento', 'errors']);
	const atividadeAnexo52Store = useMutableState(['licenciamento', 'atividadeAnexo52']);
	const interferenciaAmbientalAnexoStore = useMutableState(['licenciamento', 'interferenciaAmbientalAnexo']);
	const implantacaoAnexo53Store = useMutableState(['licenciamento', 'implantacaoAnexo53']);
	const porteAnexo54Store = useMutableState(['licenciamento', 'porteAnexo54']);
	const isLicencasExpressas = useMutableState(['licenciamento', 'isLicencasExpressas']);

	const { data: dadosRI, changeUrl: changeUrlRI } = useQueryStore(null, 'codigoRI');
	const { data: dadosPorte, changeUrl: changeUrlPorte } = useQueryStore(null, 'codigoPorte');

	/* ESTADOS */
	const [dadosAtividade, setDadosAtividade] = useState({});
	const [codigoRI, setCodigoRI] = useState(null);
	const [codigoPorte, setCodigoPorte] = useState(null);
	const [internalErrors, setInternalErrors] = useState({});
	const [temComentario, setTemComentario] = useState({});

	const setTemComentarioAux = valores => {
		const { name, value } = valores || {};
		if (temComentario[name] !== value) {
			setTemComentario(oldState => {
				const newState = { ...oldState };
				newState[name] = value;
				return newState;
			});
		}
	};

	// #region FUNÇÕES ACESSÓRIAS
	// useMemo... executada no render... alterou a atividade já tem um novo valor recuperado da store
	const selectAtividadeFromStore = useMemo(() => {
		const atividadeProcurada = (atividadeAnexo52Store || []).find(
			item => item?.item === dadosAtividade.atividadeAnexo52?.item
		);
		return atividadeProcurada;
	}, [atividadeAnexo52Store, dadosAtividade]);

	// useCallback... recria a função sempre que alterar os inputs mas só a executa quando chamada
	const selectIaFromStore = useCallback(
		codigo =>
			(interferenciaAmbientalAnexoStore || []).find(item => item?.codigo?.trim().replace(/\.$/, '') === codigo?.trim()),
		[interferenciaAmbientalAnexoStore]
	);
	const selectRiFromStore = useCallback(
		codigo => (implantacaoAnexo53Store || []).find(item => item.codigo === codigo),
		[implantacaoAnexo53Store]
	);
	const selectPorteFromStore = useCallback(
		codigo => (porteAnexo54Store || []).find(item => item.codigo === codigo),
		[porteAnexo54Store]
	);

	const codigoIA = useMemo(
		() =>
			TABELA_INTERFERENCIA_AMBIENTAL.find(item => dadosAtividade?.atividadeAnexo52?.item.startsWith(item.descricao))
				?.codigo,
		[dadosAtividade]
	);

	const filtraInterferenciaAmbientalPorAtividadeAnexo52 = useCallback(
		interferenciaAmbientalList => {
			let codigos = (interferenciaAmbientalList || []).map(ia => ia.codigo);
			if (size(dadosAtividade.atividadeAnexo52) > 0) {
				const inicio = dadosAtividade.atividadeAnexo52.item.substring(0, 4);
				codigos = TABELA_FILTRO_INTERFERENCIA_AMBIENTAL.find(item => item.inicios.includes(inicio))?.codigos || codigos;
			}
			let codigosInterferenciaAmbientalToInt = [];
			codigos.forEach(c => {
				codigosInterferenciaAmbientalToInt.push(parseInt(c));
			});
			return codigosInterferenciaAmbientalToInt;
		},
		[dadosAtividade.atividadeAnexo52]
	);
	const filtroInterferenciAmbiental = useCallback(
		(lista, termo) => item =>
			JSON.stringify(item)
				.toLowerCase()
				.indexOf((termo || '').toLowerCase()) > -1 &&
			filtraInterferenciaAmbientalPorAtividadeAnexo52(lista).includes(parseInt(item.codigo)),
		[filtraInterferenciaAmbientalPorAtividadeAnexo52]
	);

	const permiteAtividade = useCallback(
		(lista, termo) => item => {
			const objectIds = (dadosSubunidade.dadosAtividades || []).map(i => i.atividadeAnexo52?.objectid);
			let incluir = true;
			let isToHide = false;
			if (hideAtividadesItems && Array.isArray(hideAtividadesItems)) {
				isToHide = hideAtividadesItems.includes(trim(item.item));
			}
			if (showAtividadesItems && Array.isArray(showAtividadesItems)) {
				incluir = showAtividadesItems.includes(trim(item.item));
			}

			const naoUsou = !objectIds.includes(item.objectid);
			const entra =
				incluir &&
				naoUsou &&
				!isToHide &&
				JSON.stringify(item)
					.toLowerCase()
					.indexOf((termo || '').toLowerCase()) > -1;
			return entra;
		},
		[dadosSubunidade.dadosAtividades, hideAtividadesItems, showAtividadesItems]
	);
	// #endregion

	// #region atualiza codigoRI e codigoPorte só quando se seleciona uma nova atividade
	const planoDiretorRestricaoUrl = useMemo(
		() =>
			`${PLANO_DIRETOR_API_URL}/restricao/${parseFloat(dadosSubunidade.gaRegimeUrbanisticoAnexo?.codigo)}/${
				selectAtividadeFromStore?.codigo
			}`,
		[dadosSubunidade.gaRegimeUrbanisticoAnexo, selectAtividadeFromStore]
	);

	const planoDiretorPorteUrl = useMemo(
		() =>
			`${PLANO_DIRETOR_API_URL}/porte/${parseFloat(dadosSubunidade.gaRegimeUrbanisticoAnexo?.codigo)}/${
				selectAtividadeFromStore?.codigo
			}`,
		[dadosSubunidade.gaRegimeUrbanisticoAnexo, selectAtividadeFromStore]
	);

	useEffect(() => {
		if (selectAtividadeFromStore?.codigo && dadosSubunidade.gaRegimeUrbanisticoAnexo?.codigo) {
			changeUrlRI(planoDiretorRestricaoUrl);
			changeUrlPorte(planoDiretorPorteUrl);
		}
	}, [
		selectAtividadeFromStore,
		dadosSubunidade.gaRegimeUrbanisticoAnexo,
		changeUrlRI,
		changeUrlPorte,
		planoDiretorRestricaoUrl,
		planoDiretorPorteUrl
	]);

	useEffect(() => {
		if (dadosRI) {
			const codigo = TABELA_RESTRICAO_IMPLANTACAO.find(item => item.descricao === dadosRI)?.codigo;
			if (codigo) {
				setCodigoRI(codigo);
			}
		}
		/* É preciso manter codigoRI e dadosAtividade.atividadeAnexo52 no array de dependências */
	}, [dadosRI, codigoRI, dadosAtividade.atividadeAnexo52]);

	useEffect(() => {
		if (dadosPorte) {
			const regex = /\W/gi;
			const codigo = TABELA_PORTE.find(item =>
				item.descricoes?.find(descricao => replace(descricao, regex, '') === replace(dadosPorte, regex, ''))
			)?.codigo;
			if (codigo) {
				setCodigoPorte(codigo);
			}
		}
		/* É preciso manter codigoPorte e dadosAtividade.atividadeAnexo52 no array de dependências */
	}, [dadosPorte, codigoPorte, dadosAtividade.atividadeAnexo52]);
	// #endregion

	// useEffect para atualização de Interferências Ambientais, Restrição de Implantação e Porte, pela Atividade selecionada
	useEffect(() => {
		if (size(dadosAtividade.atividadeAnexo52) === 0) {
			const ia = !!dadosAtividade.interferenciaAmbientalAnexo;
			const ri = !!dadosAtividade.implantacaoAnexo53;
			const porte = !!dadosAtividade.porteAnexo54;
			if (ia || ri || porte) {
				setDadosAtividade({});
			}
			setCodigoPorte(null);
			setCodigoRI(null);
		} else {
			const ia = codigoIA ? selectIaFromStore(codigoIA) : null;
			const ri = codigoRI ? selectRiFromStore(codigoRI) : null;
			const porte = codigoPorte ? selectPorteFromStore(codigoPorte) : null;

			const novaAtividade = {};
			if (ia) {
				novaAtividade.interferenciaAmbientalAnexo = ia;
				novaAtividade.iaAutoSelected = true;
			}
			if (ri) {
				novaAtividade.implantacaoAnexo53 = ri;
				novaAtividade.riAutoSelected = true;
			}
			if (porte) {
				novaAtividade.porteAnexo54 = porte;
				novaAtividade.porteAutoSelected = true;
			}
			if (size(novaAtividade) > 0) {
				setDadosAtividade(old => ({ ...old, ...novaAtividade }));
				setInternalErrors({});
			}
		}
	}, [
		dadosAtividade.atividadeAnexo52,
		dadosAtividade.implantacaoAnexo53,
		dadosAtividade.interferenciaAmbientalAnexo,
		dadosAtividade.porteAnexo54,
		codigoIA,
		codigoPorte,
		codigoRI,
		selectIaFromStore,
		selectPorteFromStore,
		selectRiFromStore
	]);

	const pushAtividade = () => {
		let errors = {};
		if (!dadosAtividade.atividadeAnexo52) {
			errors.adicionar = (errors.adicionar || []).concat(['Selecione a Atividade (Anexo 5.2)']);
		}
		if (!dadosAtividade.interferenciaAmbientalAnexo) {
			errors.adicionar = (errors.adicionar || []).concat(['Selecione a Interferência Ambiental']);
		}
		if (!dadosAtividade.implantacaoAnexo53) {
			errors.adicionar = (errors.adicionar || []).concat(['Selecione a Implantação (Anexo 5.3)']);
		}
		if (!dadosAtividade.porteAnexo54) {
			errors.adicionar = (errors.adicionar || []).concat(['Selecione o Porte (Anexo 5.4)']);
		}
		if (
			(isIndustriasInterferenciaAmbiental || isRestricaoEvu) &&
			!dadosAtividade.estudoViabilidadeUrbanisticaAprovado
		) {
			errors.adicionar = (errors.adicionar || []).concat([
				'Informe se a atividade possui Estudo de Viabilidade Urbanística aprovado e válido'
			]);
		}
		if (
			(isIndustriasInterferenciaAmbiental || isRestricaoEvu) &&
			dadosAtividade.estudoViabilidadeUrbanisticaAprovado === 'nao'
		) {
			errors.adicionar = (errors.adicionar || []).concat([
				'Devido a atividade NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá solicitar Estudo Viabilidade Urbanística (EVU) no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).'
			]);
		}
		if (size(errors) > 0) {
			console.debug('errors', errors);
			setInternalErrors(errors);
		} else {
			setInternalErrors({});
			const newValue = [...(dadosSubunidade.dadosAtividades || []), { id: uuid(), ...dadosAtividade }];
			debugLog('<pushAtividade>', 'dadosAtividades', newValue);
			setDataHandler(newValue, 'dadosAtividades');
			setDadosAtividade({});
		}
	};

	const pullAtividade = id => {
		debugLog('pullAtividade', id);
		return setDataHandler(
			(dadosSubunidade.dadosAtividades || []).filter(su => su.id !== id),
			'dadosAtividades'
		);
	};

	const setLocalDataHandler = (value, name) => {
		debugLog('<setLocalDataHandler>', name, value);
		return setDadosAtividade(old => ({ ...old, [name]: value }));
	};

	// Indústrias - Interferência Ambiental
	const isIndustriasInterferenciaAmbiental = useMemo(
		() => ATIVIDADES_EVU.includes(get(dadosAtividade, 'atividadeAnexo52.item', '').trim()),
		[dadosAtividade]
	);

	const isRestricaoEvu = useMemo(
		() =>
			TABELA_RESTRICAO_IMPLANTACAO.filter(item => /estudo/i.test(item.descricao) || item.forceRestricaoEVU)
				.map(item => item.codigo)
				.includes(get(dadosAtividade, 'implantacaoAnexo53.codigo', '').trim()),
		[dadosAtividade]
	);

	const restamAtividades = useMemo(() => {
		const filtro = permiteAtividade([], '');
		const items = (atividadeAnexo52Store || []).filter(filtro);
		const restamItems = size(items) > 0;
		return restamItems || !atividadeAnexo52Store;
	}, [atividadeAnexo52Store, permiteAtividade]);

	const nenhumaAtividade =
		size(dadosSubunidade?.dadosAtividades) === 0 && size(dadosSubunidadeAnterior?.dadosAtividades) === 0;

	const AtividadeHtml = (ativ, indice, isRemovida = false) => {
		const idAnterior = dadosSubunidadeAnterior?.dadosAtividades?.find(ativAnt => ativAnt.id === ativ.id)?.id;
		return (
			<div
				key={indice}
				className={`dados-atividades-subunidades pda-ativ ${
					usuarioInterno ? (isRemovida ? 'pda-ativ-removida' : !idAnterior ? 'pda-ativ-adicionada' : '') : ''
				}`}
			>
				<div style={{ display: 'flex', padding: '10px' }}>
					<div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gap: '5px 18px' }}>
						<span>Atividade:</span>
						<span>
							{ativ.atividadeAnexo52?.item} {ativ.atividadeAnexo52?.atv}
						</span>
						<span style={{ whiteSpace: 'nowrap' }}>Interferência Ambiental:</span>
						<span>
							{ativ.interferenciaAmbientalAnexo?.codigo}. {ativ.interferenciaAmbientalAnexo?.descricao}
						</span>
						<span>Implantação:</span>
						<span>
							{ativ.implantacaoAnexo53?.codigo}. {ativ.implantacaoAnexo53?.descricao}
						</span>
						<span>Porte:</span>
						<span>
							{ativ.porteAnexo54?.codigo}. {ativ.porteAnexo54?.descricao}
						</span>
					</div>
				</div>
				<HistoricoRespostaAnterior
					dataAnterior={isRemovida ? '0' : idAnterior}
					dataAtual={isRemovida ? '1' : ativ.id}
					usuarioInterno={usuarioInterno}
					msgAlt={isRemovida ? 'Esta atividade foi removida' : !idAnterior ? 'Esta atividade foi adicionada' : null}
				/>
				{!readOnly && !disabled && (
					<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
						<span
							onClick={() => pullAtividade(ativ.id)}
							style={{
								color: 'rgb(204, 62, 62)',
								fontSize: '20px',
								cursor: 'pointer'
							}}
						>
							<i className="fa fa-trash" />
						</span>
					</div>
				)}
			</div>
		);
	};
	AtividadeHtml.displayName = 'AtividadeHtml';

	return (
		<>
			{dadosSubunidade ? (
				<>
					<div className="plano-diretor">
						<div className="super-grupo">
							<div className="grupoBpmTasks">
								<div
									className={`grupo${nenhumaAtividade ? ' spaced-list' : ''}${
										temComentario[`dadosAtividadesSubunidades${sufixoComentario}`]
											? ' resposta-com-comentario pin-comentario'
											: usuarioInterno
											? ' resposta-com-comentario'
											: ''
									}`}
								>
									<div style={{ flex: '1' }}>
										<h3 style={isLicencasExpressas ? { backgroundColor: 'transparent', marginLeft: '5px' } : {}}>
											{disabled || readOnly ? 'Atividades' : 'Selecione a(s) atividade(s) do projeto para a subunidade'}
										</h3>
									</div>
									<>
										{nenhumaAtividade && <div style={{ margin: '16px' }}>Nenhuma atividade selecionada </div>}
										{(dadosSubunidade?.dadosAtividades || []).map((ativ, indice) => AtividadeHtml(ativ, indice))}
										{usuarioInterno &&
											(dadosSubunidadeAnterior?.dadosAtividades || []).map((ativAntigaAux, indiceAtivAntiga) => (
												<div key={indiceAtivAntiga}>
													{!(dadosSubunidade?.dadosAtividades || []).find(
														ativAtualAux => ativAntigaAux?.id === ativAtualAux?.id
													) && <>{AtividadeHtml(ativAntigaAux, indiceAtivAntiga, true)}</>}
												</div>
											))}
										{restamAtividades && (
											<>
												{!disabled && (
													<>
														<PlanoDiretorAnexosSelecao
															data={dadosAtividade}
															property="atividadeAnexo52"
															multiple={false}
															label="Atividades (Anexo 5.2)"
															url={`${PLANO_DIRETOR_API_URL}/anexo52`}
															containerClass="form-group col-md-6"
															detailCodigo="item"
															detailDescricao="atv"
															readOnly={readOnly}
															setDataHandler={setLocalDataHandler}
															filter={permiteAtividade}
														/>
														<PlanoDiretorAnexosSelecao
															data={dadosAtividade}
															property="interferenciaAmbientalAnexo"
															multiple={false}
															label="Interferência Ambiental (Anexo)"
															url={`${API_URL}/collections/anexointerferenciaambiental/`}
															containerClass="form-group col-md-6"
															readOnly={readOnly || !dadosAtividade.atividadeAnexo52}
															tagged={dadosAtividade?.iaAutoSelected}
															setDataHandler={setLocalDataHandler}
															filter={filtroInterferenciAmbiental}
														/>
														<PlanoDiretorAnexosSelecao
															data={dadosAtividade}
															multiple={false}
															property="implantacaoAnexo53"
															label="Implantação (Anexo 5.3)"
															url={`${API_URL}/collections/anexo53implantacao/`}
															containerClass="form-group col-md-6"
															readOnly={readOnly || !dadosAtividade.atividadeAnexo52}
															tagged={dadosAtividade?.riAutoSelected}
															setDataHandler={setLocalDataHandler}
														/>
														<PlanoDiretorAnexosSelecao
															data={dadosAtividade}
															multiple={false}
															property="porteAnexo54"
															label="Porte (Anexo 5.4)"
															url={`${API_URL}/collections/anexo54porte/`}
															containerClass="form-group col-md-6"
															readOnly={readOnly || !dadosAtividade.atividadeAnexo52}
															tagged={dadosAtividade?.porteAutoSelected}
															setDataHandler={setLocalDataHandler}
														/>
													</>
												)}
												{(isIndustriasInterferenciaAmbiental || isRestricaoEvu) && (
													<>
														<div className="form-group col-md-12">
															<RadioField
																name="estudoViabilidadeUrbanisticaAprovado"
																label="A atividade possui Estudo de Viabilidade Urbanística aprovado e válido?"
																value={dadosAtividade?.estudoViabilidadeUrbanisticaAprovado}
																options={[
																	{ codigo: 'sim', descricao: 'Sim' },
																	{ codigo: 'nao', descricao: 'Não' }
																]}
																onChangeHandler={({ name, value }) => setLocalDataHandler(value, name)}
																required={true}
															/>
															{dadosAtividade?.estudoViabilidadeUrbanisticaAprovado === 'nao' && (
																<ErrorMessages
																	errorList={[
																		// 'Devido a necessidade de Estudo de Viabilidade Urbanística prévio, esta atividade não se enquadra na modalidade de Licenciamento Expresso'
																		'Devido a atividade NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá solicitar Estudo Viabilidade Urbanística (EVU) no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).'
																	]}
																/>
															)}
														</div>
													</>
												)}
												{internalErrors?.adicionar && <ErrorMessages errorList={internalErrors.adicionar} />}
												{errors?.[`dadosAtividades-${dadosSubunidade.id}`] && (
													<ErrorMessages errorList={errors[`dadosAtividades-${dadosSubunidade.id}`]} />
												)}
												{!readOnly && !disabled && (
													<div className="toolbar" style={{ padding: '0px 16px' }}>
														<button type="button" className="btn-primary" onClick={() => pushAtividade()}>
															Adicionar Atividade
														</button>
													</div>
												)}
											</>
										)}
									</>

									<Comentario
										campoComentario={`dadosAtividadesSubunidades${sufixoComentario}`}
										valueComentario={get(dadosSubunidade, `dadosAtividadesSubunidades${sufixoComentario}`)}
										onChangeComentarioHandler={onChangeComentarioHandler}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={!!readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`dadosAtividadesSubunidades${sufixoComentario}`]}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<h2>Aguardando dados...</h2>
			)}
		</>
	);
};
PlanoDiretorAtividades.displayName = 'Plano Diretor Atividades';

PlanoDiretorAtividades.propTypes = {
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	readOnly: PropTypes.bool,
	setDataHandler: PropTypes.func,
	showAtividadesItems: PropTypes.array,
	hideAtividadesItems: PropTypes.array,
	disabled: PropTypes.bool,
	usuarioInterno: PropTypes.bool,
	readOnlyVersao: PropTypes.any,
	onChangeComentarioHandler: PropTypes.func
};

export default PlanoDiretorAtividades;
