import {
	TODOS_ENQUADRAMENTOS,
	UAP_ENQUADRAMENTOS_ENUM,
	ENQUADRAMENTOS_SEM_AUTONOMA
} from './TabelaAreasAP/constantsAP';

const {
	MULTIFAMILIAR,
	UNIFAMILIAR_NAO_RESIDENCIAL,
	NAO_RESIDENCIAL,
	MULTIFAMILIAR_NAO_RESIDENCIAL,
	UNIFAMILIAR_AUTONOMA,
	HABITACAO_COLETIVA
} = UAP_ENQUADRAMENTOS_ENUM;

/**
 * METADATA do formulário de licenciamento expresso
 * ajuda principalmente a validar os campos obrigatórios
 * de forma rápida
 */
export const METAFORMAP = {
	nomeLocal: {
		name: 'nomeLocal'
	},

	INFOS_PROJETO: {
		// Campos de Projeto em comum
		areasPlanilha: {
			name: 'areasPlanilha',
			required: true,
			label: 'Selecione os tipos de áreas do seu projeto para preenchimento da planilha',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		numeroPavimentos: {
			name: 'numeroPavimentos',
			required: true,
			label: 'Número de pavimentos',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		numeroDormitorios: {
			name: 'numeroDormitorios',
			required: true,
			label: 'Número de dormitórios',
			tipoform: UNIFAMILIAR_AUTONOMA
		},
		recuoJardimIsento: {
			name: 'recuoJardimIsento',
			required: true,
			label: 'Conforme PDDUA, há isenção do Recuo de Jardim?',
			tipoform: TODOS_ENQUADRAMENTOS,
			subcampos: ['recuoJardimIsentoObservacoes']
		},
		recuoJardimIsentoObservacoes: {
			name: 'recuoJardimIsentoObservacoes',
			required: false,
			label: 'Informe a dimensão do(s) recuo(s) de jardim.',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		projetoPossuiEvuValido: {
			name: 'projetoPossuiEvuValido',
			required: true,
			label: 'O projeto possui Estudo de Viabilidade Urbanística aprovado e válido?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		alturaMaximaAeroportoAerodromo: {
			name: 'alturaMaximaAeroportoAerodromo',
			required: true,
			label: 'Altura máxima pela guia Aeroporto (DECEA) informada na DMWEB (m)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		alturaTotalProjeto: {
			name: 'alturaTotalProjeto',
			required: true,
			label: 'Altura do elemento mais alto do projeto (m) (Observar Portaria nº 957/GC3-09/07/15, Cap. VII)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		// Campos específicos Unifamiliar 1 ou 2 economias
		alpIsenta: {
			name: 'alpIsenta',
			required: true,
			label: 'Conforme PDDUA, há isenção da Área Livre Permeável?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		projetoPossuiBaseCorpo: {
			name: 'projetoPossuiBaseCorpo',
			required: false,
			label: 'O projeto possui base e corpo?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		numeroEconomiasResidencial: {
			name: 'numeroEconomiasResidencial',
			required: false,
			label: 'Número de Economias - Residencial',
			tipoform: [MULTIFAMILIAR, UNIFAMILIAR_NAO_RESIDENCIAL]
		},
		numeroEconomiasNaoResidencial: {
			name: 'numeroEconomiasNaoResidencial',
			required: false,
			label: 'Número de Economias - Não Residencial',
			tipoform: [NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL]
		},
		trintaPorcentoIa: {
			name: 'trintaPorcentoIa',
			required: false,
			label: 'O projeto utilizará acréscimo de 30% de índice de aproveitamento conforme Art. 107, §5º do PDDUA?',
			tipoform: NAO_RESIDENCIAL
		},
		projetoImpactoUrbano: {
			name: 'projetoImpactoUrbano',
			required: true,
			label:
				'A(s) atividade(s) não residencial(ais), conforme Anexo 5.2 do PDDUA, referem-se a Projetos Especiais de Impacto Urbano de 1° ou 2º grau, listadas no Anexo 11 do PDDUA?',
			tipoform: [NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL, MULTIFAMILIAR_NAO_RESIDENCIAL],
			subcampos: ['projetoViabilidaUrbanistico']
		},
		projetoViabilidaUrbanistico: {
			name: 'projetoViabilidaUrbanistico',
			required: true,
			label:
				'O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido referente a atividade conforme Art. 159 do PDDUA?',
			tipoform: [NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL, MULTIFAMILIAR_NAO_RESIDENCIAL]
		},
		aquisicaoIndicesConstrutivos: {
			name: 'aquisicaoIndicesConstrutivos',
			required: true,
			label: 'O projeto contempla a aquisição de índices construtivos?',
			tipoform: [NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL, MULTIFAMILIAR_NAO_RESIDENCIAL, MULTIFAMILIAR]
		},
		atividadeSaude: {
			name: 'atividadeSaude',
			required: false,
			label:
				'A(s) atividade(s) do projeto enquadra-se como Ambulatório, Posto de Atendimento Médico ou Clínica/Policlínica?',
			tipoform: [NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL, MULTIFAMILIAR_NAO_RESIDENCIAL],
			subcampos: ['atividadeSaudePossuiVagaAmbulancia']
		},
		atividadeSaudePossuiVagaAmbulancia: {
			name: 'atividadeSaudePossuiVagaAmbulancia',
			required: false,
			label:
				'O projeto atende ao mínimo de 1 vaga especial no interior do lote para Embarque/Desembarque com dimensões para ambulância?',
			tipoform: [NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL, MULTIFAMILIAR_NAO_RESIDENCIAL]
		}
	},

	// Quotas: apenas Unifamiliar Autônoma (dentro de Infos Dispositivos de Controle)
	// Na Unifamiliar Autônoma, quotaALP abre dependendo de restricaoALPdoCondominio
	INFOS_QUOTAS: {
		quotaIndiceAproveitamento: {
			name: 'quotaIndiceAproveitamento',
			required: true,
			label: 'Índice de Aproveitamento - IA',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		},
		quotaTaxaOcupacao: {
			name: 'quotaTaxaOcupacao',
			required: true,
			label: 'Taxa de Ocupação - TO (m²)',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		},
		quotaALP: {
			name: 'quotaALP',
			required: true,
			label: 'Área Livre Permeável - ALP (m²)',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		}
	},

	// Infos Alturas Máximas: apenas para Unifamiliar Autônoma (dentro de Infos Dispositivos de Controle)
	INFOS_ALTURAS_MAXIMAS: {
		alturaMaximaRegimeUrbanistico: {
			name: 'alturaMaximaRegimeUrbanistico',
			required: true,
			label: 'Altura máxima definida pelo Regime Urbanístico (m)',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		},
		alturaMaximaAeroportoAerodromo: {
			name: 'alturaMaximaAeroportoAerodromo',
			required: true,
			label: 'Altura máxima pela guia Aeroporto (DECEA) informada na DMWEB (m)',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		}
	},

	// Infos ambientais: comuns para todos os forms
	INFOS_AMBIENTAIS: {
		reusoAgua: {
			name: 'reusoAgua',
			required: false,
			// eslint-disable-next-line quotes
			label: "Reuso d'água?",
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		energiaEletricaAlternativa: {
			name: 'energiaEletricaAlternativa',
			required: false,
			label: 'Energia elétrica alternativa?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		telhadoVerde: {
			name: 'telhadoVerde',
			required: false,
			label: 'Telhado Verde?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		tecnologiasConstrutivasSustentaveis: {
			name: 'tecnologiasConstrutivasSustentaveis',
			required: false,
			label: 'Tecnologias Construtivas e Procedimentos Sustentáveis?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		aquecimentoSolarAgua: {
			name: 'aquecimentoSolarAgua',
			required: false,
			label: 'Água',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		aquecimentoSolarAmbiente: {
			name: 'aquecimentoSolarAmbiente',
			required: false,
			label: 'Ambiente',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		}
		/* Removido, OP #59436
			intervencaoAmbiental: {
			name: 'intervencaoAmbiental',
			required: true,
			label: 'Intervenção Ambiental (Poda, remoção, compensação, etc)?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		areaLivrePermeavel: {
			name: 'areaLivrePermeavel',
			required: true,
			label: 'ALP - Área Livre Permeável?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		} */
	},

	// Infos Terreno: todos exceto Unifamiliar Autônoma (condomínio)
	INFOS_TERRENO: {
		areaTotalMatriculaLote: {
			name: 'areaTotalMatriculaLote',
			required: true,
			label: 'Área total da(s) matrícula(s) do(s) lote(s) (m²)',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		possuiAreaDeMenorPoligono: {
			name: 'possuiAreaDeMenorPoligono',
			required: true,
			label: 'O terreno possui área de menor polígono?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: [
				'areaMenorPoligonoLote',
				'areaMenorPoligonoLoteMais5',
				'areaAtingidaMenorPoligonal',
				'areaRemanescenteMenorPoligonal5'
			]
		},
		areaMenorPoligonoLote: {
			name: 'areaMenorPoligonoLote',
			required: true,
			label: 'Área do menor polígono do lote (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaMenorPoligonoLoteMais5: {
			name: 'areaMenorPoligonoLoteMais5',
			required: true,
			label: 'Área do menor polígono do lote +5% (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		possuiAreaAtingida: {
			name: 'possuiAreaAtingida',
			required: true,
			label: 'O terreno possui área atingida?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: [
				'areaAtingidaMatricula',
				'areaRemanescenteMatricula',
				'areaAtingidaMenorPoligonal',
				'areaRemanescenteMenorPoligonal5'
			]
		},
		areaMatricula3000: {
			name: 'areaMatricula3000',
			required: true,
			label: 'O terreno possui matrícula acima de 3.000,00 m²?',
			tipoform: [MULTIFAMILIAR, MULTIFAMILIAR_NAO_RESIDENCIAL, HABITACAO_COLETIVA]
		},
		areaAtingidaMatricula: {
			name: 'areaAtingidaMatricula',
			required: true,
			label: 'Área atingida da matrícula (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaRemanescenteMatricula: {
			name: 'areaRemanescenteMatricula',
			required: true,
			label: 'Área remanescente da matrícula (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaAtingidaMenorPoligonal: {
			name: 'areaAtingidaMenorPoligonal',
			required: true,
			label: 'Área atingida da menor poligonal (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaRemanescenteMenorPoligonal5: {
			name: 'areaRemanescenteMenorPoligonal5',
			required: true,
			label: 'Área remanescente do menor polígono +5% (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaAplicacaoRU: {
			name: 'areaAplicacaoRU',
			required: true,
			label: 'Área para aplicação do Regime Urbanístico (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		}
	}
};
