import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { get, size, trim, isNil } from 'lodash';

import Confirm from 'components/Confirm';
import ErrorMessages from 'components/ErrorMessages';
import RegiaoBadge from 'components/RegiaoBadge';

import { EXPEDIENTES_API_URL } from 'environments';

import './expedienteEnderecosFieldBox.scss';
import { accessApi, sendApi } from 'utils/injectApi';
import { permiteInput, normalizedAddress, montaUrlExpedientes, isDebug } from 'utils/tools';
import { format } from 'utils/tools';

import CdlListFieldBox from './cdlListFieldBox';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';
const PAGE_EXPEDIENTE_NUMERO = 'expediente-numero';
const PAGE_EXPEDIENTE_BUSCA = 'expediente-busca';
const PAGE_ENDERECO = 'endereco';
const HOMEPAGE = 'homepage';

/* eslint-disable react-hooks/exhaustive-deps */
const ExpedienteEnderecosFieldBox = props => {
	const {
		title,
		label,
		required = [false, false],
		placeHolder,
		name,
		pesquisarPorEndereco,
		alertaCadastroRequired = false,
		atributoEnderecos,
		readOnly,
		disabled,
		consultaPublicaExpedientes = true,
		onChangeHandler,
		history,
		obterDadosProjetosLicencasExpediente = false
	} = props;
	let { localAttrsAux, value } = props;

	const [isFinalized, setIsFinalized] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const finalizeExpediente = () => {
		setIsFinalized(true);
		setIsEditing(false);
	};

	const editExpediente = () => {
		setIsEditing(true);
		setIsFinalized(false);
	};

	const popup = useRef();

	const [expediente, setExpediente] = useState(null);
	const [isPopupActive, setIsPopupActive] = useState(false);
	const [timer, setTimer] = useState(null);
	const [loading, setLoading] = useState(false);
	const [expedientes, setExpedientes] = useState(null);
	const [query, setQuery] = useState('');
	const [valid, setValid] = useState(true);
	const [warnings, setWarnings] = useState(null);
	const [page, setPage] = useState(PAGE_EXPEDIENTE_NUMERO);
	const [confirm, setConfirm] = useState(false);

	useEffect(() => {
		if (value.numero && getString(value.areaPrivativa)) {
			if (value.numero !== get(expediente, 'numero') || value.areaPrivativa !== get(expediente, 'areaPrivativa')) {
				setLoading(true);
				setTimeout(async () => {
					await buscaExpediente(value.numero, getString(value.areaPrivativa)).then(() => setLoading(false));
				}, 0);
			}
		} else if (localAttrsAux && size(localAttrsAux.value) > 0 && page !== PAGE_EXPEDIENTE_NUMERO) {
			setPage(PAGE_ENDERECO);
		}
	}, [value]);

	const setNumeroExpediente = e => {
		let { value: input } = e.target;
		input = (input || '').replace(/[^0-9]/g, '');
		input = (input || '').substring(0, 12);
		if (permiteInput(input, 'number', 0, 12)) {
			onChangeHandler({
				name,
				value: { numero: input, areaPrivativa: value.areaPrivativa }
			});
			onChangeHandler({ name: 'errors', value: { [name]: [] } });
			if (size(input) === 12) {
				setLoading(true);
				setTimeout(async () => {
					await buscaExpediente(input, value.areaPrivativa).then(() => setLoading(false));
				}, 0);
			} else if (input) {
				setValid(false);
			} else {
				setValid(true);
			}
		}
	};

	const setAreaPrivativa = e => {
		let { value: input } = e.target;
		input = (input || '').replace(/[^0-9]/g, '');
		input = (input || '').substring(0, 5);
		if (permiteInput(input, 'number', 0, 5)) {
			onChangeHandler({
				name,
				value: { numero: value.numero, areaPrivativa: input }
			});
			if (timer) {
				clearTimeout(timer);
			}
			if (size(value.numero) === 12) {
				setLoading(true);
				setTimer(
					setTimeout(async () => {
						await buscaExpediente(value.numero, input).then(() => setLoading(false));
					}, 500)
				);
			}
		}
	};

	const formataEnderecoCdlList = async enderecos => {
		let enderecoCdlList = {};
		if (size(atributoEnderecos) > 0) {
			const cdls = enderecos.map(e => ({
				id: e.id,
				codLogradouro: e.codigoLogradouro,
				numero: e.numero,
				nomeLogradouro: e.nomeLogradouro,
				nomeBairro: e.bairro,
				cep: e.cep,
				enderecoFormatadoCurto: e.enderecoFormatadoCurto
			}));

			const { data } = await sendApi({ url: 'consulta-regiao-por-endereco', payload: cdls });
			if (data && !data.erro) {
				data.forEach(({ regiao, codLogradouro, numero }) => {
					if (regiao) {
						const cdl = cdls.find(cdl => cdl.codLogradouro === codLogradouro && cdl.numero === numero);
						if (cdl) {
							cdl.regiao = regiao;
						}
					}
				});
			}

			enderecoCdlList = {
				name: atributoEnderecos,
				value: cdls
			};
		}
		return enderecoCdlList;
	};

	const buscaExpediente = async (eu, area) => {
		try {
			const response = await accessApi(
				`${EXPEDIENTES_API_URL}/expediente-unico-enderecos?numExpediente=${eu}&areaPrivativa=${area || '0'}`,
				true
			);

			// se precisa obter dados de Projetos de Edificação e Licenças do Expediente para verificações
			if (obterDadosProjetosLicencasExpediente) {
				const idExpediente = get(response.data, '_id') || get(response.data, 'id');
				const responseExpedienteCompleto = await accessApi(`${EXPEDIENTES_API_URL}/eu/${idExpediente}`, true);
				// Atualiza valores de projetosEdificacao e licencas no Expediente
				response.data.projetosEdificacao = get(responseExpedienteCompleto, 'data.projetosEdificacao');
				response.data.licencas = get(responseExpedienteCompleto, 'data.licencas');
			}

			const expUnico = response.data;
			setExpediente(expUnico);

			// atualiza lista de enderecos ao alterar o expediente
			if (expUnico && expUnico.enderecos) {
				let arrayExpLogErrors = [];
				arrayExpLogErrors.push({ name, value: expUnico });
				const cdls = await formataEnderecoCdlList(expUnico.enderecos);
				if (cdls && cdls.value) {
					setExpediente(prevState => ({
						...prevState,
						enderecos: cdls.value
					}));
				}
				arrayExpLogErrors.push(cdls);
				arrayExpLogErrors.push({
					name: 'errors',
					value: { [name]: validate(expUnico, required, label, title) }
				});
				onChangeHandler(arrayExpLogErrors);
			} else {
				onChangeHandler({ name, value: expUnico });
				onChangeHandler({
					name: 'errors',
					value: { [name]: validate(expUnico, required, label, title) }
				});
			}

			setPage(PAGE_EXPEDIENTE_NUMERO);
			setValid(true);
		} catch (e) {
			setExpediente(null);
			if (get(e, 'response.status') === 404) {
				onChangeHandler({
					name: 'errors',
					value: { [name]: ['Expediente não encontrado'] }
				});
				setValid(false);
			} else {
				console.error('erro no servidor', e);
				history.push('/serverUnavailable');
			}
		}
	};

	const pesquisarExpedientes = async endereco => {
		let numero = null;
		let q = null;
		const texto = (endereco || '').replace(/[.,;:/?{}[\]]/g, ' ');
		let parts = texto.split(/[\s*]/);
		const numericas = parts.filter(p => isFinite(parseInt(p, 10)));
		if (size(numericas) > 0) {
			numero = numericas[numericas.length - 1];
			parts = texto.split(numero)[0].split(/[\s*]/);
			const selecionadas = parts.filter(p => p !== numero && size(trim(p)) > 0);
			q = selecionadas.join(' ');
		}
		if (size(q) > 2 && numero) {
			setWarnings(null);
			try {
				setLoading(true);
				setExpedientes(null);

				let response = null;
				response = await accessApi(
					`${EXPEDIENTES_API_URL}/consulta-expedientes-por-endereco?q=${normalizedAddress(q)}&numero=${numero}`,
					true
				);

				// se precisa obter dados de Projetos de Edificação e Licenças do Expediente para verificações
				if (obterDadosProjetosLicencasExpediente && Array.isArray(response.data)) {
					let idExpediente = null;
					let responseExpedienteCompleto = null;

					for (var i = 0; i < response.data.length; i++) {
						idExpediente = get(response.data[i], '_id') || get(response.data[i], 'id');
						responseExpedienteCompleto = await accessApi(`${EXPEDIENTES_API_URL}/eu/${idExpediente}`, true);
						// Atualiza valores de projetosEdificacao e licencas no Expediente
						response.data[i].projetosEdificacao = get(responseExpedienteCompleto, 'data.projetosEdificacao');
						response.data[i].licencas = get(responseExpedienteCompleto, 'data.licencas');
					}
				}

				const expedientesAux = response.data;
				setLoading(false);
				setExpedientes(expedientesAux);
				if (expedientesAux !== null && size(expedientesAux) > 0) {
					showPopupHandler();
				}
			} catch (e) {
				setLoading(false);
				console.error('erro no servidor', e);
				history.push('/serverUnavailable');
			}
		} else {
			if (size(endereco) > 0) {
				setWarnings(['Você deve informar um endereço completo, como em Ipiranga, 1200']);
			}
		}
	};

	const montaLinkExpedientes = id => {
		let url = EXPEDIENTES_API_URL;
		url = url.replace('-api', '');
		url = url.replace('api', '');
		url = url.replace(/http:\/\/localhost:.*/, 'https://expedientes-des.procempa.com.br/');
		const saida = `${url}${consultaPublicaExpedientes ? 'consultapublica/' : ''}${id}`;
		return saida;
	};

	const handleLinkClick = e => {
		e.stopPropagation(); // Isso impede que o evento de clique se propague para outros elementos.
	};

	const usarExpediente = ({ _id, numero, areaPrivativa, enderecos }) => {
		//monta codigo = expediente + areaPrivativa (com 5 digitos)
		let area = `00000${areaPrivativa}`;
		area = area.substring(area.length - 5);
		const codigo = numero + area;

		const objects = [{ name, value: { _id, numero, areaPrivativa, codigo } }];

		if (size(atributoEnderecos) > 0) {
			objects.push({
				name: atributoEnderecos,
				value: enderecos.map(e => ({
					id: e.id,
					codLogradouro: e.codigoLogradouro,
					numero: e.numero,
					nomeLogradouro: e.nomeLogradouro,
					nomeBairro: e.nomeBairro,
					cep: e.cep,
					enderecoFormatadoCurto: e.enderecoFormatadoCurto
				}))
			});
		}

		objects.push({ name: 'errors', value: { [name]: [] } });
		// rodar buscaExpediente diretamente evita que o onChangeHandler seja chamado duas vezes
		// seja disparado duas vezes no mesmo render, evitando problemas de contexto no callback enviado.
		// onChangeHandler(objects);
		buscaExpediente(numero, areaPrivativa);
		setPage(PAGE_EXPEDIENTE_NUMERO);
		hidePopupHandler();
	};

	const showPopupHandler = () => {
		if (value) {
			pesquisarExpedientes('');
		} else {
			setQuery('');
		}
		setIsPopupActive(true);
	};

	const hidePopupHandler = () => {
		setExpedientes(null);
		setQuery('');
		setIsPopupActive(false);
	};

	const clearPageExpediente = (goHome = false) => {
		setNumeroExpediente({ target: { value: '' } });
		onChangeHandler([
			{ name: 'expediente', value: [] },
			{ name: 'enderecoCdlList', value: [] },
			{ name: 'errors', value: { enderecoCdlList: [] } }
		]);
		delete value.numero;
		delete value.enderecos;
		delete value._id;
		delete value.codigo;
		delete value.areaPrivativa;

		localAttrsAux.readOnly = false;
		localAttrsAux.cdlListFieldAux.readOnly = false;
		localAttrsAux.onChangeHandler([
			{ name: localAttrsAux.name, value: [] },
			{ name: 'errors', value: { enderecoCdlList: [] } }
		]);
		if (goHome) {
			setPage(HOMEPAGE);
		}
	};

	const closeAddEndereco = () => {
		// reset expedientes
		setNumeroExpediente({ target: { value: '' } });
		value = [];
		let errorsValidate = [];
		localAttrsAux.readOnly = false;
		localAttrsAux.cdlListFieldAux.readOnly = false;

		if (CdlListFieldBox.validate) {
			errorsValidate = CdlListFieldBox.validate([], true, 'endereço');
		}
		localAttrsAux.onChangeHandler([
			{ name: localAttrsAux.name, value: [] },
			{ name: 'errors', value: { enderecoCdlList: errorsValidate } }
		]);
		setPage(PAGE_EXPEDIENTE_BUSCA);
	};

	const handleNewAddress = () => {
		setExpedientes(null);
		setWarnings(null);
		setPage(PAGE_ENDERECO);
	};

	const closePageEnderecoAction = () => {
		// reset expedientes
		setNumeroExpediente({ target: { value: '' } });
		value = [];
		let errorsValidate = [];
		localAttrsAux.readOnly = false;
		localAttrsAux.cdlListFieldAux.readOnly = false;

		// reset endereço cdlListField
		if (CdlListFieldBox.validate) {
			errorsValidate = CdlListFieldBox.validate([], true, 'endereço');
		}
		localAttrsAux.onChangeHandler([
			{ name: localAttrsAux.name, value: [] },
			{ name: 'errors', value: { enderecoCdlList: errorsValidate } }
		]);
		setPage(HOMEPAGE);
	};

	const trataEnter = e => {
		if (e.key === 'Enter') {
			pesquisarExpedientes(query);
		} else {
			if (e.key === 'Escape') {
				setQuery('');
				hidePopupHandler();
			}
		}
		return true;
	};

	return (
		<>
			<div className="control-label-aux">
				{page === HOMEPAGE ? (
					<>
						Selecionar expediente único<span className="required">*</span>
					</>
				) : null}
			</div>

			{confirm && (
				<Confirm
					msg="Tem certeza que deseja fechar? Os endereços serão descartados!"
					onConfirm={() => {
						closePageEnderecoAction();
						setConfirm(false);
					}}
					onReject={() => setConfirm(false)}
					loader={false}
				/>
			)}
			{isPopupActive && (
				<div className="modal-wrapper modal-exp-search">
					<div className="modal modal-lg" ref={popup}>
						<div className="header-group">
							<h1> Pesquisando expedientes </h1>
							<i className="fa fa-times" onClick={() => hidePopupHandler()}></i>
						</div>
						<label className="control-label" htmlFor="root_nome">
							Resultado da pesquisa: <span className="exp-query-sample">{query}</span>
							<span className="exp-query-info">clique para selecionar</span>
						</label>

						{size(expedientes) > 0 && (
							<div className="col-md-12 container-eu-modal">
								{expedientes
									.filter(e => !!e)
									.map((e, i) => (
										<div key={e._id} className="eu-modal" onClick={() => usarExpediente(e)}>
											<div className="d-flex justify-content-between">
												<button type="button" className={'btn btn-link'}>
													{i + 1} - {format(e.numero, '000.000000.00.0')} - {format(e.areaPrivativa, '00000')}{' '}
													<em>(escolher este)</em>
												</button>
												<a
													href={montaUrlExpedientes(e._id)}
													target="_blank"
													rel="noopener noreferrer"
													className="btn-expediente"
													onClick={handleLinkClick}
												>
													<i className="fa fa-external-link"></i> consultar
												</a>
											</div>
											<ul>
												{e.enderecos.map(end => (
													<li key={end.id}>
														{get(end, 'enderecoFormatadoCurto')}
														{isDebug ? (
															<span className="debug-message">{` (codLogradouro: ${end.codigoLogradouro})`}</span>
														) : (
															''
														)}
													</li>
												))}
											</ul>
										</div>
									))}
							</div>
						)}
					</div>
				</div>
			)}
			<div
				className={`expediente-enderecos-fieldbox ${
					[PAGE_EXPEDIENTE_NUMERO, PAGE_EXPEDIENTE_BUSCA].includes(page)
						? 'exp-selected'
						: page === PAGE_ENDERECO
						? 'address-selected'
						: ''
				}`}
			>
				{/* EXPEDIENTE - LEFT PANEL */}

				<div className={`expediente-unico-field ${loading ? 'skeleton' : ''}`}>
					{/* EXPEDIENTE - NÚMERO */}
					{page === PAGE_EXPEDIENTE_NUMERO && (
						<div className="expediente-unico-numero">
							<div className="expediente-unico-remove">
								<button
									type="button"
									className="btn btn-default"
									onClick={() => {
										clearPageExpediente(true);
									}}
								></button>
							</div>
							<div className="form-group col-md-4">
								<label className="control-label">
									{label[0]}
									{required[0] && <span className="required">*</span>}
								</label>
								<input
									className={
										!valid
											? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
											: `${CLASS_NAME_FORM_CONTROL}`
									}
									type="text"
									placeholder={placeHolder[0]}
									name="numero"
									value={value.numero && valid ? format(value.numero, '000.000000.00.0') : value.numero || ''}
									onChange={setNumeroExpediente}
									readOnly={readOnly || (isFinalized && !isEditing)}
									disabled={disabled}
								/>
								{loading && <i className={'fa fa-refresh fa-spin refresh-field'} />}
								{(readOnly || disabled) && value._id && (
									<div className="element-with-link">
										<a href={montaLinkExpedientes(value._id)} target="_blank" rel="noopener noreferrer">
											<i style={{ marginLeft: '8px' }} className="fa fa-external-link" />
										</a>
									</div>
								)}
							</div>
							<div className="form-group col-md-8">
								<label className="control-label">
									{label[1]}
									{required[1] && <span className="required">*</span>}
								</label>
								<input
									className={`exp-area-privativa ${
										!valid
											? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
											: `${CLASS_NAME_FORM_CONTROL}`
									}`}
									type="text"
									placeholder={placeHolder[1]}
									name="areaPrivativa"
									value={getString(value.areaPrivativa)}
									onChange={setAreaPrivativa}
									readOnly={readOnly || (isFinalized && !isEditing)}
									disabled={disabled}
								/>
							</div>
							{!isFinalized && (
								<div className="col-md-12">
									<button
										type="button"
										className={'btn btn-link'}
										onClick={
											loading
												? null
												: () => {
														clearPageExpediente();
														setPage(PAGE_EXPEDIENTE_BUSCA);
												  }
										}
										disabled={loading}
									>
										<>
											<i className="fa fa-search" aria-hidden="true"></i>
										</>
										pesquisar por <span>endereço</span> de E.U
									</button>
								</div>
							)}
						</div>
					)}

					{/* EXPEDIENTE - BUSCA */}
					{page === PAGE_EXPEDIENTE_BUSCA && (
						<div className="expediente-unico-busca">
							<div className="expediente-unico-remove">
								<button
									type="button"
									className="btn btn-default"
									onClick={() => {
										clearPageExpediente(true);
									}}
								></button>
							</div>
							<div className="form-group col-md-9 exp-busca-wrapper">
								<label className="control-label">
									Pesquisar por endereço de Expediente Único: <span className="required">*</span>
								</label>
								<input
									className={`exp-busca-input ${CLASS_NAME_FORM_CONTROL}`}
									type="text"
									value={query || ''}
									onChange={e => setQuery(e.target.value)}
									onKeyDown={trataEnter}
									placeholder="Informe um endereço, como em Av. Julio de Castilhos, 505"
								/>
								{loading && (
									<i
										className={'fa fa-refresh fa-spin'}
										style={{
											marginTop: '-27px',
											position: 'relative',
											float: 'right',
											marginRight: '15px'
										}}
									/>
								)}
							</div>
							<div className="form-group col-md-3">
								<button type="button" className="btn btn-primary" onClick={() => pesquisarExpedientes(query)}>
									Pesquisar
								</button>
							</div>
							<div className="col-md-12">
								{warnings && <ErrorMessages errorList={warnings} />}
								{expedientes !== null && size(expedientes) === 0 ? (
									<>
										<div className="expedientes-warning-busca">
											<i className="fa fa-alert" aria-hidden="true"></i>
											Não há expedientes com este endereço!
										</div>
										<br />
										<button
											type="button"
											className="btn btn-link"
											onClick={loading ? null : handleNewAddress}
											disabled={loading}
										>
											<i className="fa fa-plus" aria-hidden="true"></i>É necessário incluir novo endereço
										</button>
									</>
								) : (
									<>
										<button
											type="button"
											className={'btn btn-link'}
											onClick={loading ? null : () => setPage(PAGE_EXPEDIENTE_NUMERO)}
											disabled={loading}
										>
											<>
												<i className="fa fa-search" aria-hidden="true"></i>
											</>
											pesquisar por <span>número</span> de E.U.
										</button>
									</>
								)}
							</div>
						</div>
					)}

					{/* EXPEDIENTE - HOMEPAGE */}
					{page === HOMEPAGE && (
						<>
							<div className="fieldbox-title">
								O endereço <span>possui</span> Expediente Único:
							</div>
							{!(readOnly || disabled) && pesquisarPorEndereco && (
								<div
									className={`endereco-btn-wrapper ${
										page === PAGE_EXPEDIENTE_NUMERO ? 'col-md-10 text-left reposition-btn' : 'col-md-12'
									}`}
								>
									<div className="btn-wrapper-link">
										<button
											type="button"
											className={'btn btn-link'}
											onClick={loading ? null : () => setPage(PAGE_EXPEDIENTE_NUMERO)}
											disabled={loading}
										>
											<>
												<i className="fa fa-search" aria-hidden="true"></i>
											</>
											{' pesquisar por número'}
										</button>
									</div>
									<div className="btn-wrapper-link  btn-link-reposition">
										<button
											type="button"
											className={'btn btn-link'}
											onClick={loading ? null : () => setPage(PAGE_EXPEDIENTE_BUSCA)}
											// onClick={loading ? null : isPopupActive ? hidePopupHandler : showPopupHandler}
											disabled={loading}
										>
											<>
												<i className="fa fa-search" aria-hidden="true"></i>
											</>
											{' pesquisar por endereço'}
										</button>
									</div>
								</div>
							)}
						</>
					)}

					{/* EXPEDIENTE - LISTA ENDEREÇOS */}
					{value.numero &&
						valid &&
						expediente?.enderecos &&
						[PAGE_EXPEDIENTE_NUMERO, PAGE_EXPEDIENTE_BUSCA].includes(page) && (
							<>
								<div className="endereco-list-field col-md-12">
									<div>Endereço(s) da solicitação</div>
									<ul className="list-group list-group-flush">
										{expediente.enderecos.map(end => (
											<li
												key={end.id}
												className="list-group-item menor d-flex justify-content-between align-items-center"
											>
												{get(end, 'enderecoFormatadoCurto')}
												{isDebug ? (
													<span className="debug-message">{` (codLogradouro: ${end.codigoLogradouro})`}</span>
												) : (
													''
												)}
												<RegiaoBadge regiao={end.regiao} />
											</li>
										))}
									</ul>
									<br />
									{!isFinalized && !readOnly && (
										<>
											<br />
											<button
												type="button"
												className="btn-primary"
												onClick={finalizeExpediente}
												disabled={loading || !expediente}
											>
												Concluir
											</button>
										</>
									)}
									{isFinalized && !isEditing && !readOnly && (
										<button type="button" className="btn btn-primary" onClick={editExpediente}>
											Editar
										</button>
									)}
								</div>
							</>
						)}
					{/* EXPEDIENTE - ALERTAS */}
					{alertaCadastroRequired && (
						<div className="alert alert-secondary">
							Caso seja necessária a criação de Expediente Único, solicitar ao Escritório de Licenciamento, através do
							e-mail:{' '}
							<a href="escritoriolicenciamento@portoalegre.rs.gov.br">escritoriolicenciamento@portoalegre.rs.gov.br</a>
						</div>
					)}
					{expediente?.anulado && (
						<ErrorMessages
							type="warning"
							errorList={[
								{
									message: 'O Expediente Único informado possui atualmente o status de ANULADO.',
									type: 'warning'
								},
								{
									message: 'O requerimento pode ser continuado, mas certifique-se que o Expediente Único está correto.',
									type: 'warning'
								}
							]}
						/>
					)}
				</div>

				{/* ENDEREÇO */}

				<div
					className={`cdllist-field ${page === PAGE_ENDERECO ? 'cdllist-field-selected' : ''} ${
						loading ? 'skeleton' : ''
					}`}
				>
					{page === PAGE_ENDERECO ? (
						<>
							<div className="col-md-12 cdl-outter-wrapper">
								<CdlListFieldBox {...localAttrsAux} />
							</div>
							<div className="expediente-unico-remove">
								<button
									type="button"
									className="btn btn-default"
									onClick={() => {
										closeAddEndereco();
									}}
								>
									<i className="fa fa-times"></i>
								</button>
							</div>
						</>
					) : (
						<>
							<div className="fieldbox-divider">
								<span>ou</span>
							</div>
							<div className="fieldbox-title">
								O endereço <span>não possui</span> Expediente Único:
							</div>
							<div className={`endereco-btn-wrapper ${page === PAGE_ENDERECO ? 'col-md-10' : 'col-md-12'}`}>
								<button
									type="button"
									className={'btn btn-link'}
									onClick={() => setPage(PAGE_ENDERECO)}
									disabled={loading}
								>
									<i className="fa fa-plus" aria-hidden="true"></i>incluir novo endereço
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

ExpedienteEnderecosFieldBox.displayName = 'ExpedienteEnderecosFieldBox';

ExpedienteEnderecosFieldBox.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.arrayOf(PropTypes.bool),
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.object,
	pesquisarPorEndereco: PropTypes.bool,
	alertaCadastroRequired: PropTypes.bool,
	atributoEnderecos: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	consultaPublicaExpedientes: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	history: PropTypes.any,
	obterDadosProjetosLicencasExpediente: PropTypes.bool,
	localAttrsAux: PropTypes.object
};

export default withRouter(ExpedienteEnderecosFieldBox);

const getString = valor => (isNil(valor) ? '' : `${valor}`);

export const validate = (
	expediente,
	required,
	label = ['Número Expediente', 'Área Privativa'],
	title = 'Expediente'
) => {
	const { _id, numero, areaPrivativa } = expediente || {};
	const area = getString(areaPrivativa);
	const errors = [];
	if (numero) {
		if (!numero.startsWith('002') || numero.length !== 12) {
			errors.push(`${label[0]} inválido`);
		}
		if (size(area) === 0) {
			errors.push(`Informando ${label[0]}, ${label[1]} deve ser informada`);
		}
		if (size(errors) === 0) {
			if (!_id) {
				errors.push(`${title} não encontrado`);
			}
		}
	} else {
		if (required[0]) {
			errors.push(`${label[0]} deve ser informado`);
		}
		if (required[1] && size(area) === 0) {
			errors.push(`${label[1]} deve ser informada`);
		}
	}
	return errors;
};
