import { useEffect, useRef } from 'react';

import Decimal from 'decimal.js';
import Immutable, { fromJS, isImmutable, List, Map } from 'immutable';
import {
	capitalize,
	findIndex,
	get,
	includes,
	isNil,
	join,
	padStart,
	repeat,
	replace,
	size,
	startsWith,
	trim,
	trimStart
} from 'lodash';
import moment from 'moment';
import { buffers, END, eventChannel } from 'redux-saga';

import {
	EMAIL_REGEX,
	REGEX_ADDRESS,
	FORMATO_CODIGO_EU,
	REGEX_TELEFONE,
	FORMATO_CODIGO_EU_SHORT,
	SITUACOES_AP,
	SITUACOES_VS,
	SITUACOES_LE,
	SITUACOES_ET,
	SITUACOES_CE,
	SITUACOES_RC,
	SITUACOES_LI,
	SITUACOES_BL,
	SITUACOES_CS,
	SITUACOES_PE,
	TELAS_QUE_ENVIAM_PARA_OUTRAS_SECRETARIAS,
	SITUACOES_GE,
	SITUACOES_DI,
	SITUACOES_CH,
	SITUACOES_LO,
	SITUACOES_CV,
	SITUACOES_AT,
	SITUACOES_GA,
	SITUACOES_LT
} from 'utils/constants';

import { store } from 'index';

import { ANALISTAS, PROCESSOS_FORMAT } from './enums';
import { accessApi } from './injectApi';
import { DIACRITICS, DISCARD_WORDS } from './words';

const stringify = o => {
	let cache = [];
	return JSON.stringify(
		o,
		(key, value) => {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value) !== -1) {
					return;
				}
				cache.push(value);
			}
			return value;
		},
		2
	);
};

/*
	Formata um número com uma máscara.
	Exemplo format('2263924006', '000.000000.00.0') => 002.263924.00.6
*/
const format = (numero = '', mascara = '') => {
	const exp = /-|\.|\/|\(|\)|@|%|\$|,| /g;
	const max = mascara.replace(exp, '').length;
	const numeroCompleto = padStart(trimStart((numero || '').toString().replace(exp, ''), '0'), max, '0');
	let size = mascara.length;
	let formatado = '';
	let j = 0;
	for (let i = 0; i < size; i++) {
		if (mascara.charAt(i).toString().match(exp)) {
			formatado += mascara.charAt(i);
			size++;
		} else {
			formatado += numeroCompleto.charAt(j);
			j++;
		}
	}
	return formatado;
};

/*
	Formata um número com uma máscara para horario no formato 00:00.
*/
const formataHorario = (numero = '', mascara = '') => {
	const exp = /-|:|\/|\(|\)|@|%|\$|,| /g;
	const max = mascara.replace(exp, '').length;
	const numeroCompleto = padStart(trimStart(numero.toString().replace(exp, ''), '0'), max, '0');
	let size = mascara.length;
	let formatado = '';
	let j = 0;
	for (let i = 0; i < size; i++) {
		if (mascara.charAt(i).toString().match(exp)) {
			formatado += mascara.charAt(i);
			size++;
		} else {
			formatado += numeroCompleto.charAt(j);
			j++;
		}
	}
	return formatado.substring(0, 5);
};

const titleCase = texto => {
	if (!texto) return texto;
	if (typeof texto !== 'string') return texto;

	const palavras = texto.split(' ');

	const palavrasCorrigidas = palavras.map((w, i) => {
		if ((size(w) > 2 || i === 0) && !includes(DISCARD_WORDS, w.toLowerCase())) {
			return capitalize(w);
		} else {
			return w.toLowerCase();
		}
	});

	const saida = palavrasCorrigidas.join(' ');

	return saida;
};

const removeSpaces = texto => {
	let text = trataDigitacaoComEspacos(texto);
	if (text && text.length > 0) {
		text = replace(text, /(\s{2,})/g, ' ');
		text = trim(text);
	}
	return text;
};

const trataDigitacaoComEspacos = texto => {
	let saida = texto;
	const qtdBrancos = saida.split('').reduce((acc, c) => (acc += c === ' ' ? 1 : 0), 0);
	const perc = (qtdBrancos / saida.length) * 100;
	// se mais de 40% dos carteres são espaços
	if (perc >= 40) {
		//remove cada espaço que segue-se a um caracter
		saida = saida.replace(/(\S)\s/g, '$1');
	}
	return saida;
};

const calculaDigitoMod11 = (dado, numDig = 1, limiteMultiplicador = 9, x10 = true) => {
	dado = dado.toString();
	let multiplicador, sum, digito;

	if (!x10) numDig = 1;

	for (let numeroDigito = 1; numeroDigito <= numDig; numeroDigito++) {
		sum = 0;
		multiplicador = 2;
		for (let i = dado.length - 1; i >= 0; i--) {
			sum += multiplicador * parseInt(dado.charAt(i));
			if (++multiplicador > limiteMultiplicador) multiplicador = 2;
		}
		if (x10) {
			digito = ((sum * 10) % 11) % 10;
		} else {
			digito = sum % 11;
			if (digito === 10) digito = 'X';
		}
		dado += digito;
	}
	return dado.substr(dado.length - numDig, numDig);
};

const sortCadastros = (arr = [], property = 'codigo', ordem = 'crescente') => {
	let arrJS = (Map.isMap(arr) ? arr.toJS() : arr) || [];

	let aux = ordem === 'crescente' ? -1 : 1;

	let saida = [];
	if (size(arrJS) > 0) {
		arrJS.forEach(i => saida.push({ ...i }));
	}

	if (saida && saida.length > 1) {
		saida.sort((r1, r2) => {
			if (parseInt(r1[property]) === r1[property] && parseInt(r2[property]) === r2[property]) {
				return parseInt(r1[property]) < parseInt(r2[property]) ? aux : aux * -1;
			}
			if (typeof r1[property] === 'string' && typeof r2[property] === 'string') {
				return r1[property].trim().toLowerCase() < r2[property].trim().toLowerCase() ? aux : aux * -1;
			}
			return r1[property] < r2[property] ? aux : aux * -1;
		});
	}
	return saida;
};

const permiteInput = (value, type, decs, size = 9) => {
	let ok = true;
	if (includes(['process', 'sei', 'processo'], type)) {
		const regex = new RegExp(`^$|^[0-9\\.\\-\\/]{1,${size}}$`);
		ok = regex.test(value);
	} else if (includes(['validnumber'], type)) {
		const regexStr = `^$|^[1-9]{1}[0-9]{0,${size - 1}}$`;
		const regex = new RegExp(regexStr);
		ok = regex.test(value);
	} else if (includes(['number', 'integer', 'int', 'long'], type)) {
		const regexStr = `^$|^[0-9]{1,${size}}$`;
		const regex = new RegExp(regexStr);
		ok = regex.test(value);
	} else if (includes(['float', 'currency', 'area'], type)) {
		const regex = new RegExp(`^$|^[1-9]{1}\\d{0,${size - 1}}(,\\d{0,${decs}})?$`);
		ok = regex.test(value);
	} else if (includes(['text', 'string'], type)) {
		const regex = new RegExp(`^$|^\\S{1}.{0,${size - 1}}$`);
		ok = regex.test(value);
	} else if (includes(['varname'], type)) {
		const regex = new RegExp(`^$|^[a-z]{1}[A-Za-z0-9]{0,${size - 1}}$`);
		ok = regex.test(value);
	}
	return ok;
};

/* Formata numeros de telefone no formato (00) 00000-0000 ou (00) 0000-0000 */
const formataTelefone = v => {
	var texto = v;

	texto = texto.replace(/[^\d]/g, '');

	if (texto.length > 0) {
		texto = `(${texto}`;

		if (texto.length > 3) {
			texto = [texto.slice(0, 3), ') ', texto.slice(3)].join('');
		}
		if (texto.length > 12) {
			if (texto.length > 13) texto = [texto.slice(0, 10), '-', texto.slice(10)].join('');
			else texto = [texto.slice(0, 9), '-', texto.slice(9)].join('');
		}
		if (texto.length > 15) texto = texto.substr(0, 15);
	}
	return texto;
};

/* Formata valores numericos para cep (99999-999) */
const cep = v => {
	v = v.replace(/\D/g, '').substring(0, 8);

	if (v.length <= 8) {
		//Coloca um hifen entre o quinto e o sexto dígitos
		v = v.replace(/(\d{5})(\d)/, '$1-$2');
	}

	return v;
};

/* Formata valores numericos para CPF (ate 11 digitos) ou para CNPJ (ate 14 digitos) */
const cpfCnpj = v => {
	//Remove tudo o que não é dígito
	v = v.replace(/\D/g, '');

	if (v.length < 14) {
		//CPF

		//Coloca um ponto entre o terceiro e o quarto dígitos
		v = v.replace(/(\d{3})(\d)/, '$1.$2');

		//Coloca um ponto entre o terceiro e o quarto dígitos
		//de novo (para o segundo bloco de números)
		v = v.replace(/(\d{3})(\d)/, '$1.$2');

		//Coloca um hífen entre o terceiro e o quarto dígitos
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	} else {
		//CNPJ

		//Coloca ponto entre o segundo e o terceiro dígitos
		v = v.replace(/^(\d{2})(\d)/, '$1.$2');

		//Coloca ponto entre o quinto e o sexto dígitos
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

		//Coloca uma barra entre o oitavo e o nono dígitos
		v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');

		//Coloca um hífen depois do bloco de quatro dígitos
		v = v.replace(/(\d{4})(\d)/, '$1-$2');
	}
	if (v.length > 18) v = v.substr(0, 18);

	return v;
};

const isCPF = cpf => {
	if (!cpf) return false;
	let sum;
	let resto;
	sum = 0;
	let strCPF = replace(cpf, /(\.|_|-)/g, '');
	if (strCPF === '00000000000' || strCPF.length !== 11) return false;

	for (let i = 1; i <= 9; i++) {
		sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
	}

	resto = (sum * 10) % 11;

	if (resto === 10 || resto === 11) {
		resto = 0;
	}
	if (resto !== parseInt(strCPF.substring(9, 10))) {
		return false;
	}
	sum = 0;
	for (let i = 1; i <= 10; i++) {
		sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
	}
	resto = (sum * 10) % 11;

	if (resto === 10 || resto === 11) resto = 0;
	if (resto !== parseInt(strCPF.substring(10, 11))) return false;
	return true;
};

const isCNPJ = cnpj => {
	if (!cnpj) return false;
	if (cnpj) {
		cnpj = cnpj.replace(/[^\d]+/g, '');

		if (cnpj.length !== 14) {
			return false;
		}

		// Elimina CNPJs invalidos conhecidos
		if (
			cnpj === '00000000000000' ||
			cnpj === '11111111111111' ||
			cnpj === '22222222222222' ||
			cnpj === '33333333333333' ||
			cnpj === '44444444444444' ||
			cnpj === '55555555555555' ||
			cnpj === '66666666666666' ||
			cnpj === '77777777777777' ||
			cnpj === '88888888888888' ||
			cnpj === '99999999999999'
		)
			return false;

		// Valida DVs
		let tamanho = cnpj.length - 2;
		let numeros = cnpj.substring(0, tamanho);
		let digitos = cnpj.substring(tamanho);
		let sum = 0;
		let pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			sum += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
		if (`${resultado}` !== `${digitos.charAt(0)}`) {
			return false;
		}

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		sum = 0;
		pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			sum += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
		if (`${resultado}` !== `${digitos.charAt(1)}`) {
			return false;
		}
	}

	return true;
};

const isEmail = e => EMAIL_REGEX.test(e);
export const isTelefone = e => REGEX_TELEFONE.test(e);

/* Formata valores numericos para expediente único (até 12 digitos) */
const expedienteUnico = v => {
	//Remove tudo o que não é dígito
	v = v.replace(/\D/g, '');

	//Número de Expediente Único

	//Coloca ponto entre o terceiro e o quarto dígitos
	v = v.replace(/^(\d{3})(\d)/, '$1.$2');

	//Coloca ponto entre o nono e o décimo dígitos
	v = v.replace(/^(\d{3})\.(\d{6})(\d)/, '$1.$2.$3');

	//Coloca ponto entre o décimo primeiro e o décimo segundo dígitos
	v = v.replace(/^(\d{3})\.(\d{6})\.(\d{2})(\d)/, '$1.$2.$3.$4');

	return v;
};

const isNumeroExpediente = value => {
	const string = (value || '').replace(/[^0-9]/g, '');
	if (string.length !== 12) {
		return false;
	}
	// TO DO : validação do número do Expediente Único pelo dígito verificador
	return true;
};

const isAreaPrivativaExpediente = value => {
	const string = (value || '').replace(/[^0-9]/g, '');
	if (string.length !== 5) {
		return false;
	}
	return true;
};

const isDam = value => {
	const string = (value || '').replace(/[^0-9]/g, '');
	if (string.length !== 16) {
		return false;
	}
	const ano = parseInt(string.substring(0, 4), 10);
	const tipo = parseInt(string.substring(4, 6), 10);
	// const numero = parseInt(string.substring(6, 12), 10);
	const parcela = parseInt(string.substring(12, 15), 10);
	const digito = parseInt(string.substring(15, 16), 10);

	if (ano < 1900 || ano > new Date().getFullYear()) {
		return false;
	}
	if ([87].indexOf(tipo) === -1) {
		return false;
	}
	if (parcela !== 0) {
		return false;
	}
	const entrada = string.substring(0, 15);
	const digCalculado = parseInt(calculaDigitoMod11(entrada), 10);
	if (digito !== digCalculado) {
		return false;
	}
	return true;
};

// eslint-disable-next-line
const mergeTest = () => {
	const show = (name, map) => {
		console.debug(name, JSON.stringify(map.toJS()));
		return map;
	};
	// eslint-disable-next-line
	let state = Immutable.fromJS({
		etapa: {
			ocorrencia: 'aprovação',
			novoObj: null
		}
	});
	const payload1 = { data: '2018-03-20' };

	const payload2 = { user: { nome: 'Alex' } };

	const payload3 = { user: { nome: 'Paulo' } };

	state = show('mergeDeepIn', state.mergeDeepIn(['etapa', 'novoObj'], payload1));
	state = show(
		'add to array',
		state.updateIn(['etapa', 'users'], users => [...(users || []), payload2])
	);
	state = show(
		'add to array',
		state.updateIn(['etapa', 'users'], users => [...(users || []), payload3])
	);
	console.debug(state);
};
// mergeTest();

const virgulaPonto = string =>
	typeof string === 'string'
		? string.replace(/,/g, '.')
		: Array.isArray(string)
		? string.map(s => virgulaPonto(s))
		: string;
const pontoVirgula = string =>
	typeof string === 'string'
		? string.replace(/\./g, ',')
		: Array.isArray(string)
		? string.map(s => pontoVirgula(s))
		: string;

const eliminaSeparadorMilhar = str => {
	if (size(str) > 0) {
		const posVirgula = str.lastIndexOf(',');
		const posPonto = str.lastIndexOf('.');
		if (posVirgula > posPonto && posPonto > -1) {
			str = str.replace(/\./g, '');
		}
	}
	return str;
};

const convertToDecimal = (obj, properties) => {
	obj &&
		properties.map(p => {
			const stringSemSeparadorMilhar = eliminaSeparadorMilhar(obj[p]);
			const stringNumber = virgulaPonto(stringSemSeparadorMilhar) || '0';
			const decimal = new Decimal(stringNumber);
			return (obj[p] = decimal);
		});
};

const convertToNumber = (obj, properties) =>
	properties.map(p => {
		if (obj && obj[p]) {
			convertToDecimal(obj, [p]);
			obj[p] = obj[p].toNumber();
		} else {
			obj[p] = null;
		}
		return obj[p];
	});

const formatFloats = (obj, properties, decimals, keepNull) =>
	properties.map(p => (obj[p] = formatFloat(obj[p], decimals, keepNull)));

const formatFloat = (valor, decimals = 2, keepNull) => {
	if (Decimal.isDecimal(valor)) {
		valor = valor.toFixed(decimals);
	}

	if (!(isNil(valor) && keepNull)) {
		valor = pontoVirgula((valor || 0).toString());

		if (valor.indexOf(',') === -1) {
			valor += ',';
		}

		const parts = valor.split(',');
		if (startsWith(parts[0], '0')) {
			parts[0] = trimStart(parts[0], '0');
		}

		if (parts[0].length === 0) {
			parts[0] = '0';
		}

		if (parts[1].length !== decimals) {
			if (parts[1].length > decimals) {
				parts[1] = parts[1].substring(0, decimals);
			} else {
				const qtd = decimals - parts[1].length;
				parts[1] += repeat('0', qtd);
			}
		}

		let parteInteira = '';
		let conta = 0;
		for (let i = parts[0].length - 1; i >= 0; i--) {
			conta++;
			parteInteira = `${parts[0][i]}${parteInteira}`;
			if (conta % 3 === 0 && i > 0) {
				parteInteira = `.${parteInteira}`;
			}
		}

		valor = `${parteInteira},${parts[1]}`;
	}
	return valor;
};

const mergePropertyIntoState = (state, path, property, type, decs, tamanho) => {
	const key = Object.keys(property)[0];
	const value = Object.values(property)[0];

	state = stateWithoutError(state, key);
	let property2 = property;
	if (key.indexOf('.') > -1) {
		const parts = key.split('.');
		parts.reduce((acc, part, index) => (index < parts.length - 1 ? acc.push(part) : acc), path);
		const propName = key.substring(key.lastIndexOf('.') + 1);
		property2 = { [propName]: value };
	}

	if (permiteInput(value, type, decs, tamanho)) {
		let obj = state.getIn(path);
		if (obj) {
			state = state.mergeDeepIn(path, property2);
		} else {
			state = state.setIn(path, property2);
		}
	}

	return state;
};

const selectInArray = (array, id, property = 'id') => {
	const arrayJS = List.isList(array) ? array.toJS() : array;
	const index = findIndex(arrayJS, item => item[property] === id);
	return index > -1 ? arrayJS[index] : null;
};

const stateWithoutError = (state, property) => {
	const errors = fromJS(state.get('errors'));
	const errorsJS = errors && isImmutable(errors) ? errors.toJS() : errors;
	if (errorsJS) {
		const partes = property.split('.');
		let novo = partes.reduce((acc, p, i) => (i === partes.length - 1 ? acc : acc[p]), errorsJS);
		if (novo) {
			delete novo[partes[partes.length - 1]];
		}
		if (size(errorsJS) > 0) {
			state = state.set('errors', fromJS(errorsJS));
		} else {
			state = state.delete('errors');
		}
	}
	return state;
};

const normalizedAddress = str => (str || '').replace(REGEX_ADDRESS, '');

const clearObject = obj => {
	let newObj = {};
	for (var p in obj) {
		newObj[p] = obj[p] ? obj[p] : undefined;
	}
	return newObj;
};

const formataProcessos = (text, format) => {
	let saida = typeof text === 'number' ? text.toString() : text;
	if (saida) {
		switch (format) {
			case PROCESSOS_FORMAT.EXPEDIENTE:
				if (saida.length === 10) {
					if (saida.startsWith('2')) {
						saida = padStart(saida, 12, '0');
					}
				}
				if (saida.length !== 12 && saida.length !== 17) {
					console.debug('saida não tem 12 nem 17: ', saida);
				} else if (!saida.startsWith('002')) {
					console.debug('saida não inicia com 002: ', saida);
				} else {
					if (saida.length === 12) {
						saida = saida.replace(/(\d{3})(\d{6})(\d{2})(\d)/, '$1.$2.$3.$4');
					} else {
						saida = saida.replace(/(\d{3})(\d{6})(\d{2})(\d)(\d{5})/, '$1.$2.$3.$4.$5');
					}
				}
				break;

			case PROCESSOS_FORMAT.EXPEDIENTE_AREA:
				if (saida.length > 17 || saida.length < 10) {
					//eslint-disable-next-line no-console
				} else {
					if (saida.startsWith('2')) {
						saida = `00${saida}`;
					}
					if (!saida.startsWith('002')) {
						//eslint-disable-next-line no-console
					}
					const eu = saida.substring(0, 12);
					let ap = padStart(saida.substring(12), 5, '0');
					saida = eu + ap;
					saida = text.replace(/(\d{3})(\d{6})(\d{2})(\d)(\d*)/, '$1.$2.$3.$4.$5');
				}
				break;

			case PROCESSOS_FORMAT.SEI:
				break;

			default:
				break;
		}
	}
	return saida;
};

const createUploadFileChannel = (endpoint, payload) => {
	const {
		idFormulario,
		idFormData,
		id,
		idDocumento,
		documento,
		file,
		files,
		token,
		ordem,
		obrigatorio,
		tituloDocumento,
		complementadaEm,
		owner,
		fromSolicitante,
		extensao,
		docGroup,
		condicionante,
		isTramitarDurante
	} = payload;
	try {
		return eventChannel(emitter => {
			const xhr = new XMLHttpRequest();

			const onProgress = e => {
				if (e.lengthComputable) {
					const progress = e.loaded / e.total;
					emitter({ progress });
				}
			};
			const onFailure = e => {
				emitter({ err: new Error(`Upload failed at ${e}`) });
				emitter(END);
			};
			xhr.upload.addEventListener('progress', onProgress);
			xhr.upload.addEventListener('error', onFailure);
			xhr.upload.addEventListener('abort', onFailure);
			xhr.onreadystatechange = () => {
				const { readyState, status, responseText } = xhr;
				if (readyState === 4) {
					if (status === 200) {
						emitter({ success: true, responseText });
						emitter(END);
					} else {
						onFailure(null);
					}
				}
			};
			let body = new FormData();
			body.set('idFormulario', `${idFormulario}`);
			body.set('idFormData', `${idFormData}`);
			body.set('id', `${id || documento?.id}`);
			body.set('idDocumento', `${idDocumento || documento?.idDocumento}`);
			body.set('ordem', `${ordem || documento?.ordem}`);
			body.set('obrigatorio', `${obrigatorio || documento?.obrigatorio}`);
			body.set('tituloDocumento', `${tituloDocumento || documento?.tituloDocumento}`);
			body.set('extensao', `${extensao || documento?.extensao}`);
			if (condicionante) {
				body.set('condicionante', `${condicionante}`);
			}
			if (isTramitarDurante) {
				body.set('isTramitarDurante', `${isTramitarDurante}`);
			}
			if (docGroup) {
				body.set('docGroup', docGroup);
			}
			if (documento?.versao) {
				body.set('versao', `${documento.versao}`);
			}
			if (documento?.tituloDocumentoAdmin) {
				body.set('tituloDocumentoAdmin', `${documento.tituloDocumentoAdmin}`);
			}
			if (documento?.descricaoDocumentoAdmin) {
				body.set('descricaoDocumentoAdmin', `${documento.descricaoDocumentoAdmin}`);
			}
			if (documento?.textoHelp) {
				body.set('textoHelp', `${documento.textoHelp}`);
			}
			if (documento?.linkHelp) {
				body.set('linkHelp', `${documento.linkHelp}`);
			}
			if (documento?.invisible) {
				body.set('invisible', `${documento.invisible}`);
			}
			if (documento?.descricaoOutroDocumento) {
				body.set('descricaoOutroDocumento', `${documento.descricaoOutroDocumento}`);
			}
			if (complementadaEm) {
				body.set('complementadaEm', `${complementadaEm}`);
				if (owner) {
					body.set('owner', `${owner}`);
				}
			}
			if (fromSolicitante) {
				body.set('fromSolicitante', `${fromSolicitante}`);
			}
			if (file) {
				body.set('file', file);
			}
			if (files) {
				files.forEach((f, i) => {
					body.set(`file${i}`, f);
				});
			}
			// body.set('filename', dadosImaged.filename);
			// body.set('indices', JSON.stringify(dadosImaged.indices));
			xhr.open('POST', endpoint, true);
			xhr.setRequestHeader('Authorization', `Bearer ${token}`);
			xhr.send(body);
			return () => {
				xhr.upload.removeEventListener('progress', onProgress);
				xhr.upload.removeEventListener('error', onFailure);
				xhr.upload.removeEventListener('abort', onFailure);
				xhr.onreadystatechange = null;
				xhr.abort();
			};
		}, buffers.sliding(2));
	} catch (err) {
		console.error('err: ', err);
	}
};

const getUpdatedToken = () =>
	store.kc
		? new Promise(resolve => {
				store.kc
					.updateToken(5)
					.then(() => {
						resolve(store.kc.token);
					})
					.catch(() => store.kc.login());
		  })
		: Promise.reject('Sem token ainda');

const isAnalista = email => ANALISTAS.indexOf(email) > -1;

const montaURL = sistema => {
	let url = null;
	let environment = null;
	const hostname = window.location.hostname;

	if (isLocalhost) {
		environment = '-des';
	} else {
		const app = hostname.replace('.procempa.com.br', '');
		environment = app.endsWith('-des') ? '-des' : app.endsWith('-hom') ? '-hom' : '';
	}
	if (sistema.toLowerCase() === 'sei' && environment === '-des') {
		// SEI nao possui ambiente de desenvolvimento
		environment = '-hom';
	}

	url = `https://${sistema}${environment}.procempa.com.br`;

	return url;
};

const ellipsis = (nome, size = 50, ellipsis = '(...)') => {
	if ((nome || '').length > size) {
		const ind = nome.endsWith('.') ? -1 : nome.lastIndexOf('.');
		let nomeAux = ind === -1 ? nome : nome.substring(0, ind);
		const extAux = ind === -1 ? '' : nome.substring(ind);
		const oQueExcede = nome.length + ellipsis.length - size;
		nomeAux = `${nomeAux.substring(0, nomeAux.length - oQueExcede)}${ellipsis}`;
		return `${nomeAux}${extAux}`;
	} else {
		return nome;
	}
};

const formataNomeDocumento = nome => {
	if ((nome || '').length > 33) {
		return `${nome.substring(0, 24)}(...)${nome.substring(nome.length - 4, nome.length)}`;
	} else {
		return nome;
	}
};

const ellipsisMultiRow = (text, rowSize = 35, rows = 3, ellipsis = '...') => {
	if ((text || '').length <= rowSize) {
		return text;
	}
	const rowSizeMax = rowSize - ellipsis.length;
	let output = '';
	const palavras = text.split(' ');
	let row = '';
	let rowsAux = 0;
	let cortou = false;
	palavras.forEach(p => {
		if (rowsAux < rows) {
			row = `${row}${row.length === 0 ? '' : ' '}${p}`;

			if (row.length === rowSize) {
				output = `${output}\n${row}`;
				row = '';
				rowsAux++;
			} else if (row.length > rowSize) {
				row = row.substring(0, rowSizeMax).trim() + ellipsis;
				cortou = true;
				output = `${output}\n${row}`;
				row = '';
				rowsAux++;
			}
		}
	});

	if (row.length > 0) {
		if (rowsAux < rows) {
			output = `${output}\n${row}`;
		} else {
			cortou = true;
		}
	}

	if (!output.endsWith(ellipsis) && cortou) {
		output = output.substring(0, output.length - ellipsis.length) + ellipsis;
	}

	return output;
};
/* teste da função acima
let text = `Possimussociisduisrepellatsemperlectusmassamattisex
mollis. At est sapien esse? Magnam ipsa vel quaerat fusce
impeditleotinciduntaddicta, voluptatemconguebeataetaciti
portaauguedictatortorquicuraeconvallisquisquamullamcorper
etiam integer? Nobis tristique ornare sequi nostrum. Laoreet hendrerit
commodo mus, magna sapien elit consequuntur euismod lobortis orci lectus?
Mattis saepe debitis rhoncus, lacus aliquet eros, alias magni diamlorem
doloremque voluptatem? Numquam quis, sollicitudin fuga, neque fringilla
montes pulvinar, habitant recusandae, non ipsum accumsan dolore eiusmod
possimus accumsan vestibulum urna ducimus. Officia nemo. Molestie fringilla.
Penatibus platea viverra ultricies luctus morbi, risus, vulputate, turpis
venenatis adipisicing? Vulputate, illum, pulvinar in vero, posuere laudantium!
Ornare modi? Ipsa accusantium dicta dolorum ac quo facere quasi? Integer porro
molestias, mi ullam, dictum! Irure veniam, facere earum! Rem at repudiandae dis
occaecat sapiente, fugiat culpa harum itaque ullamcorper eget torquent alias?
Laboris urna ut mauris reprehenderit habitant.
`;
text = text.replace(/\n/g, ' ');

const elipsado = ellipsis2(text, 40, 5);
console.log('\n\n\n---------------\n', elipsado, size(elipsado), '\n---------------\n\n\n');
*/
const CASAS_DECIMAIS_AREAS = 2;

const numericValue = original => {
	let numericValue = original;
	if (!isNil(original)) {
		const valueWithPoint = numericValue.replace(/[,]/, '.').replace(/[A-Z a-z]/, '');
		numericValue = size(valueWithPoint) > 0 ? Number(valueWithPoint) : null;
	}
	return numericValue;
};

const stringValue = original => {
	let stringValue = `${original || ''}`.replace(/\./, ',');
	const index = stringValue.indexOf(',');
	if (index > -1 && stringValue.length - index + 1 > 4) {
		stringValue = stringValue.substring(0, index + CASAS_DECIMAIS_AREAS + 1);
	}
	return stringValue;
};

/* formatação de número decimal */
const formatTypedNumber = typedNumber => {
	let formatando = null;

	// digitando 0
	if (typedNumber === '0' || typedNumber === '00') {
		formatando = '0';
	} else if (typedNumber === '0,') {
		formatando = '0,';
	} else if (typedNumber === '0,0') {
		formatando = '0,0';
	} else if (typedNumber === '0,00') {
		formatando = '0,00';
	}
	// permite que sejam digitados ',' e '0' após vírgula
	else if (typedNumber.charAt(typedNumber.length - 1) === ',') {
		formatando = `${stringValue(numericValue(typedNumber))},`;
	} else if (typedNumber.substr(typedNumber.length - 2, typedNumber.length) === ',0') {
		formatando = `${stringValue(numericValue(typedNumber))},0`;
	} else if (typedNumber.substr(typedNumber.length - 3, typedNumber.length) === ',00') {
		formatando = `${stringValue(numericValue(typedNumber))},00`;
	} else {
		formatando = stringValue(numericValue(typedNumber));
	}

	return formatando;
};

const ignoreDiacritics = term => {
	const saida = term
		? term
				.split('')
				.map(c => (DIACRITICS[c] ? DIACRITICS[c] : c))
				.join('')
		: term;
	return saida;
};

const removeDiacritics = term => {
	const saida = term
		? term
				.split('')
				.map(c => Object.keys(DIACRITICS).reduce((acc, key) => (DIACRITICS[key].includes(c) ? key : acc), c))
				.join('')
		: term;
	return saida;
};

/**
 * obtem o um moment a partir do valor informado
 * ou o dia de hoje se nao informado nada
 *
 * @param {*} value valor para transformar em moment ou undefined
 * @returns {moment.Moment} moment date
 */
const getMomentDate = value => {
	if (value) {
		if (value instanceof moment) {
			return value;
		} else if (isFinite(value)) {
			return moment(parseInt(value, 10));
		} else if (value instanceof Date) {
			return moment(value);
		}
	}
	return moment().startOf('day');
};

const { hostname, port } = window.location || document.location;

const isLocalhost = Boolean(
	/^(localhost|192\.168\..*|10\..*)$/.test(hostname) ||
		// [::1] is the IPv6 localhost address.
		hostname === '[::1]' ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
const isDes = Boolean(hostname.match(/.*-des\.procempa\.com\.br/));
const isHom = Boolean(hostname.match(/.*-hom\.procempa\.com\.br/));
const isPro = Boolean(hostname.match(/.*\.procempa\.com\.br/) && !isDes && !isHom);

const getEnvironment = () => (isDes || isLocalhost ? 'des' : isHom ? 'hom' : isPro ? 'pro' : 'error');

let debug = isLocalhost || isDes || isHom;

if (debug && localStorage) {
	if (localStorage.getItem('debug') === null) {
		localStorage.setItem('debug', 'false');
		debug = false;
	} else if (localStorage.getItem('debug') === 'false') {
		debug = false;
	}
}
const isDebug = debug;

const isAdmin =
	(/^(localhost|192\.168\..*|10\..*)$/.test(hostname) && /1?3002/.test(port)) ||
	/licenciamento-admin(-des|-hom)?\.procempa\.com\.br/.test(hostname);
const isPortal =
	(/^(localhost|192\.168\..*|10\..*)$/.test(hostname) && /1?3001/.test(port)) ||
	/licenciamento(-des|-hom)?\.procempa\.com\.br/.test(hostname);

const obtemDescricaoOcorrencia = ocorrencia => {
	let str = '';
	str = `${ocorrencia.tipo ? ocorrencia.tipo : ''} - ${ocorrencia.subtipo ? ocorrencia.subtipo : ''} - ${
		ocorrencia.descricao ? ocorrencia.descricao : ''
	}`;
	str = str.replace(/ {2,10}/g, ' ');
	str = str.replace(/( -){2,10}/g, ' -');
	str = str.startsWith(' - ') ? str.substring(3) : str;
	str = str.endsWith(' - ') ? str.substring(0, str.length - 3) : str;

	return str;
};

const formatCodigoEU = (eu, isShort) =>
	size(eu) > 0 ? (isShort ? format(eu, FORMATO_CODIGO_EU_SHORT) : format(eu, FORMATO_CODIGO_EU)) : eu;

const useIsMounted = () => {
	const isMountedRef = useRef(true);

	useEffect(
		() => () => {
			isMountedRef.current = false;
		},
		[]
	);

	return isMountedRef;
};

function deepEqual(element1, element2, ignoreDiferentKeys) {
	if ((element1 === null && element2 !== null) || (element1 !== null && element2 === null)) {
		return false;
	}

	if ((element1 === undefined && element2 !== undefined) || (element1 !== undefined && element2 === undefined)) {
		return false;
	}

	if ((Array.isArray(element1) && !Array.isArray(element2)) || (!Array.isArray(element1) && Array.isArray(element2))) {
		return false;
	}

	if (element1 === element2) {
		// it's just the same object. No need to compare.
		return true;
	}

	if (isPrimitive(element1) && isPrimitive(element2)) {
		// compare primitives
		const iguais = element1 === element2;
		return iguais;
	}

	if (!ignoreDiferentKeys && size(element1) !== size(element2)) {
		return false;
	}

	if (Array.isArray(element1) && Array.isArray(element2)) {
		if (size(element1) !== size(element2)) {
			return false;
		}
		if (size(element1) === 0 && size(element2) === 0) {
			return true;
		}

		let equal = true;

		for (let index = 0; index < element1.length; index++) {
			const e1 = element1[index];
			const e2 = element2[index];
			const novoEqual = deepEqual(e1, e2, ignoreDiferentKeys);
			equal = equal && novoEqual;
		}

		return equal;
	}

	// compare objects with same number of keys
	for (let key in element1) {
		if (!ignoreDiferentKeys) {
			if (!(key in element2)) {
				return false;
			}
		}

		if (!deepEqual(element1[key], element2[key])) {
			return false;
		}
	}

	return true;
}

//check if value is primitive
function isPrimitive(obj) {
	return obj !== Object(obj);
}

function defineNomeSecretaria(taskName) {
	let nomeSecretaria = '';
	let siglaSecretaria = '';

	if (taskName) {
		const siglaTask = taskName.substring(0, 2);

		/* eslint-disable-next-line default-case */
		switch (siglaTask) {
			case 'AP':
				nomeSecretaria = 'Escritório de Licenciamento (DGEL-SMAMUS)';
				siglaSecretaria = 'DGEL-SMAMUS';
				break;
			case 'DP':
				nomeSecretaria = 'Divisão de Projetos Viários (DPV-SMIM)';
				siglaSecretaria = 'DPV-SMIM';
				break;
			case 'PH':
				nomeSecretaria = 'Equipe do Patrimônio Histórico e Cultural (EPAHC-SMC)';
				siglaSecretaria = 'EPAHC-SMC';
				break;
			case 'ER':
				nomeSecretaria = 'Equipe de Regularização Fundiária (ERF-DGEL)';
				siglaSecretaria = 'ERF-DGEL';
				break;
			case 'EA':
				nomeSecretaria = 'Equipe de Alinhamento Predial (EAP-SMAMS)';
				siglaSecretaria = 'EAP-SMAMS';
				break;
			case 'CP':
				nomeSecretaria = 'Coordenação de Mercado (DMAE)';
				siglaSecretaria = 'DMAE';
				break;
			case 'ES':
				nomeSecretaria = 'Unidade Atualização Informações Urbanísticas (UAIU-SMAMUS)';
				siglaSecretaria = 'UAIU-SMAMUS';
				break;
			case 'EP':
				nomeSecretaria = 'Diretoria de Mobilidade Urbana (DMU-SMMU)';
				siglaSecretaria = 'DMU-SMMU';
				break;
			case 'DM':
				nomeSecretaria = 'Departamento Municipal de Água e Esgoto (DMAE)';
				siglaSecretaria = 'DMAE';
				break;
			case 'UD':
				nomeSecretaria = 'Coordenação de Desapropriação e Reserva de Índices (CDRI-SMAMUS)';
				siglaSecretaria = 'UDRI-DGEL';
				break;
			case 'EV':
				nomeSecretaria = 'Equipe de Viabilidade de Edificações (EVE-DGEL)';
				siglaSecretaria = 'EVE-DGEL';
				break;
			case 'VS':
				nomeSecretaria = 'Unidade de Vistoria Predial (UVP-SMAMUS)';
				siglaSecretaria = 'Vistoria';
				break;
			case 'EU':
				nomeSecretaria = 'Coordenação de Desapropriação e Reserva de Índices (ERDU-SMAMUS)';
				siglaSecretaria = 'ERDU/SMAMUS';
				break;
			case 'UA':
				nomeSecretaria = 'Unidade de Aprovação de Projetos (UAP-SMAMUS)';
				siglaSecretaria = 'UAP/SMAMUS';
				break;
			case 'PV':
				nomeSecretaria = 'Projetos Viários (DPOV/SMOI)';
				siglaSecretaria = 'DPOV/SMOI';
				break;
			case 'QP':
				nomeSecretaria = 'Questões Pluviais (GPLA-DMAE)';
				siglaSecretaria = 'DMAE';
				break;
			case 'PG':
				nomeSecretaria = 'Termo de Compromisso (PUMA/PGM)';
				siglaSecretaria = 'PUMA/PGM';
				break;
			case 'EC':
				nomeSecretaria = 'Obras de Urbanização (EACEU/SMAMUS)';
				siglaSecretaria = 'EACEU/SMAMUS';
				break;
			case 'LE':
				nomeSecretaria = 'Escritório de Licenciamento (DGEL-SMAMUS)';
				siglaSecretaria = 'DGEL-SMAMUS';
				break;
			case 'ET':
				nomeSecretaria = 'Escritório de Licenciamento (DGEL-SMAMUS)';
				siglaSecretaria = 'DGEL-SMAMUS';
				break;
			case 'CA':
				nomeSecretaria = 'Comissão de Análise e Aprovação da Demanda Habitacional Prioritária (CAADHAP)';
				siglaSecretaria = 'CAADHAP';
				break;
			case 'RC':
				nomeSecretaria = 'Unidade de Vistoria Predial (UVP-SMAMUS)';
				siglaSecretaria = 'Vistoria';
				break;
			case 'LI':
				nomeSecretaria = 'Setor de Licenças (UAP/CE/DEL/SMAMUS)';
				siglaSecretaria = 'SL-DEL';
				break;
			case 'BL':
				nomeSecretaria = 'Coordenação de Desapropriação e Reserva de Índices (CDRI-SMAMUS)';
				siglaSecretaria = 'CDRI-SMAMUS';
				break;
			case 'CE':
				nomeSecretaria = 'Escritório de Licenciamento-SMAMUS';
				siglaSecretaria = 'SMAMUS';
				break;
			case 'CI':
				nomeSecretaria = 'Escritório de Licenciamento-SMAMUS';
				siglaSecretaria = 'SMAMUS';
				break;
			case 'CS':
				nomeSecretaria = 'Diretoria de Projetos e Políticas de Sustentabilidade';
				siglaSecretaria = 'DPPS';
				break;
			case 'PE':
				nomeSecretaria = 'Equipe de Projetos Especiais';
				siglaSecretaria = 'EPE-DPU';
				break;
			case 'GE':
				nomeSecretaria = 'Equipe de Georreferenciamento';
				siglaSecretaria = 'SMAMUS';
				break;
			case 'LO':
				nomeSecretaria = 'Equipe de Licenciamento Ambiental';
				siglaSecretaria = 'SMAMUS';
				break;
			case 'EI':
				nomeSecretaria = 'Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura (EAPIS-SMAMUS)';
				siglaSecretaria = 'EAPIS-SMAMUS';
				break;
			case 'CV':
				nomeSecretaria = 'Coordenação de Eventos (CEVEN)';
				siglaSecretaria = 'CEVEN-SMDET';
				break;
			case 'LT':
				nomeSecretaria = 'Setor de Licenças (ULE/CE/DEL/SMAMUS)';
				siglaSecretaria = 'SL-DEL';
				break;
		}

		if (taskName === 'SM0610' || taskName === 'SM0710') {
			nomeSecretaria = 'Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura (EAPIS-SMAMUS)';
			siglaSecretaria = 'EAPIS-SMAMUS';
		} else if (['SM0310', 'SM0410', 'SM0460'].includes(taskName)) {
			nomeSecretaria = 'Equipe de Uso e Ocupação do Solo (EUOS-SMAMUS)';
			siglaSecretaria = 'EUOS-SMAMUS';
		}
	}
	return { nomeSecretaria, siglaSecretaria };
}

/**
 * soma dois numeros convertendo antes para float e retorna uma
 * string representando esse numero com os decimais informados (pode ser zero)
 *
 * @param {*} n1 number or string
 * @param {*} n2
 * @param {number} decimais default 2
 * @returns
 */
function soma(n1, n2, { decimais = 2 } = {}) {
	let ad1 = parseFloat(n1);
	let ad2 = parseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 0 : ad2;
	return (ad1 + ad2).toFixed(decimais);
}

/**
 * multiplica dois numeros convertendo antes para float e retorna uma
 * string representando esse numero com os decimais informados (pode ser zero)
 *
 * @param {*} n1 number or string
 * @param {*} n2
 * @param {number} decimais default 2
 * @returns
 */
function normalizarParseFloat(numero) {
	if (typeof numero === 'string') {
		if (numero.includes('.') && numero.includes(',')) {
			// Verifica qual separador está mais à direita
			if (numero.lastIndexOf(',') > numero.lastIndexOf('.')) {
				// ',' é o separador decimal; remove '.' (separador de milhares)
				numero = numero.replace(/\./g, '').replace(',', '.');
			} else {
				// '.' é o separador decimal; remove ',' (separador de milhares)
				numero = numero.replace(/,/g, '');
			}
		} else if (numero.includes(',')) {
			// Apenas ',' presente; é o separador decimal
			numero = numero.replace(',', '.');
		}
		// Converte para número
		return parseFloat(numero);
	} else {
		// Não é um número
		return parseFloat(numero);
	}
}
function multiplica(n1, n2, { decimais = 2 } = {}) {
	let ad1 = normalizarParseFloat(n1);
	let ad2 = normalizarParseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 0 : ad2;
	return (ad1 * ad2).toFixed(decimais);
}

/**
 * divide dois numeros convertendo antes para float e retorna uma
 * string representando esse numero com os decimais informados (pode ser zero)
 *
 * @param {*} n1 number or string
 * @param {*} n2
 * @param {number} decimais default 2
 * @returns
 */
function divide(n1, n2, { decimais = 2 } = {}) {
	let ad1 = normalizarParseFloat(n1);
	let ad2 = normalizarParseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 1 : ad2 > 0 ? ad2 : 1;
	return (ad1 / ad2).toFixed(decimais);
}

/**
 * faz a diferenca (-) dois numeros convertendo antes para float e retorna uma
 * string representando esse numero com os decimais informados (pode ser zero)
 *
 * @param {*} n1 number or string
 * @param {*} n2
 * @param {number} decimais default 2
 * @returns
 */
function diferenca(n1, n2, { decimais = 2, minimunZero = false } = {}) {
	let ad1 = normalizarParseFloat(n1);
	let ad2 = normalizarParseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 0 : ad2;
	const dif = ad1 - ad2;
	if (minimunZero && dif < 0) {
		return '0';
	}
	return dif.toFixed(decimais);
}

/**
 * testa se dois numeros sao iguais
 * @param {number|string} n1 numero 1
 * @param {number|string} n2 numero 2
 * @returns {boolean}
 */
function iguais(n1, n2) {
	return diferenca(n1, n2, { decimais: 0 }) === '0';
}

/**
 * testa se está num intervalo
 * @param {number|string} valor
 * @param {number|string} li limite inferior
 * @param {number|string} ls limite superior
 * @param {boolean} ouIgual se inclui os limites
 * @returns {boolean}
 */
function entre(valor, li, ls, { ouIgual = false }) {
	const valorF = normalizarParseFloat(valor);
	const liF = normalizarParseFloat(li);
	const lsF = normalizarParseFloat(ls);

	if (ouIgual) {
		return valorF >= liF && valorF <= lsF;
	}

	return valorF > liF && valorF < lsF;
}

function filterList(list, search) {
	let term = removeDiacritics(search);
	let properties = Array.isArray(list) ? list : [list];

	return properties.filter(l => {
		const json = JSON.stringify(l);
		return removeDiacritics(json).toLowerCase().indexOf(term) > -1;
	});
}

/**
 * testa se o primeiro numero eh maior do que o segundo
 * @param {*} n1
 * @param {*} n2
 * @param {*} n3 se maior ou igual
 * @returns
 */
function maiorQue(n1, n2, { ouIgual } = {}) {
	let ad1 = parseFloat(n1);
	let ad2 = parseFloat(n2);

	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	ad2 = Number.isNaN(ad2) ? 0 : ad2;
	return ouIgual ? ad1 >= ad2 : ad1 > ad2;
}

function parseNumero(n1, decimais = 0) {
	let ad1 = parseFloat(n1);
	ad1 = Number.isNaN(ad1) ? 0 : ad1;
	return +ad1.toFixed(decimais);
}

function trocaPontoPorVirgula(texto) {
	return texto && texto.replace && texto.replace('.', ',');
}

function compareStates(state1, state2, idProperties = ['id', '_id']) {
	if (!Array.isArray(idProperties)) {
		idProperties = [idProperties];
	}
	// são diferentes se um é null/undefined e o outro não
	if ((isNil(state1) && !isNil(state2)) || (!isNil(state1) && isNil(state2))) return false;
	// são iguais se ambos são null ou undefined
	if (isNil(state1) && isNil(state2)) return true;
	// são iguais se === retornar true
	if (state1 === state2) return true;
	// são iguais se são primitivos e === retornar true
	if (isPrimitive(state1) && isPrimitive(state2)) return state1 === state2;
	// se um dos dois forem Immutables, rexecuta a function usando os respectivos valores mutáveis
	if (isImmutable(state1) || isImmutable(state2)) {
		return compareStates(state1.toJS?.() || state1, state2.toJS?.() || state2, idProperties);
	}

	// restaram arrays ou objetos
	let equals = size(state1) === size(state2);
	if (equals) {
		if (Array.isArray(state1) && Array.isArray(state2)) {
			equals = state1.reduce((acc, item, index) => acc && compareStates(item, state2[index], idProperties), true);
		} else if (typeof state1 === 'object') {
			equals = idProperties.reduce(
				(acc, idProperty) => acc || get(state1, idProperty) === get(state2, idProperties),
				false
			);
		} else {
			equals = false;
		}
	}
	return equals;
}

const termoNotificacaoEmpty = text => {
	const test = '<p></p>\n';
	return size(trim(text)) === 0 ? true : text === test;
};

const obtemPerguntaRespostaExtraInfo = ({
	formulario,
	fieldName = 'comparecimento',
	taskName,
	taskNameComplementacao
}) => {
	let fieldPergunta = null;
	let fieldResposta = null;

	const bpmTasks = (get(formulario, 'formData.bpmTasks') || [])
		.filter(task => task.taskName === taskName)
		.sort((t1, t2) => (t1.data < t2.data ? 1 : -1)); // ordena as bpmTasks para pegar a mais recente do taskName recebido
	if (size(bpmTasks) > 0) {
		const bpmTask = bpmTasks[0];
		const pergunta = get(bpmTask, `taskData.${fieldName}`);
		const dadosComplementacao = get(formulario, 'formData.data.dadosComplementacao') || {};
		Object.keys(dadosComplementacao)
			.sort((t1, t2) => (t1 > t2 ? 1 : -1)) // ordena as respostas para que os fields que permaneçam ao final do looping sejam os mais recentes
			.forEach(timestamp => {
				if (dadosComplementacao[timestamp][taskNameComplementacao]) {
					const extraInfo = dadosComplementacao[timestamp][taskNameComplementacao].extraInfo;
					const info = (extraInfo || []).find(info => info.taskName === taskName && info.pergunta === pergunta);
					const index = (extraInfo || []).findIndex(info => info.taskName === taskName && info.pergunta === pergunta);
					if (info) {
						fieldPergunta = `formData.data.dadosComplementacao.${timestamp}.${taskNameComplementacao}.extraInfo.${index}.pergunta`;
						fieldResposta = `formData.data.dadosComplementacao.${timestamp}.${taskNameComplementacao}.extraInfo.${index}.resposta`;
					}
				}
			});
	}

	let pergunta = fieldPergunta ? get(formulario, fieldPergunta) : null;
	let resposta = fieldResposta ? get(formulario, fieldResposta) : null;

	return [pergunta, resposta];
};

//check if value is primitive
export const getSituacaoFromEnums = (taskname, enumeradores) => {
	if (!enumeradores) {
		enumeradores = [
			SITUACOES_AP,
			SITUACOES_BL,
			SITUACOES_CE,
			SITUACOES_CS,
			SITUACOES_ET,
			SITUACOES_LE,
			SITUACOES_LI,
			SITUACOES_RC,
			SITUACOES_VS,
			SITUACOES_PE,
			SITUACOES_GE,
			SITUACOES_DI,
			SITUACOES_CH,
			SITUACOES_LO,
			SITUACOES_CV,
			SITUACOES_AT,
			SITUACOES_GA,
			SITUACOES_LT
		];
	}
	let situacao = null;
	enumeradores.forEach(enumerador => {
		situacao = situacao || enumerador.value(taskname);
	});
	return situacao;
};

export const calculaNumeroLinhas = (texto, qtdMinimoLinhas = 3, tamanhoLinha = 80) => {
	const calculado = ((texto || '').match(/\n/g) || []).length + 1;
	const linhas = (texto || '').split('\n');
	const qtd = linhas.reduce((acc, linha) => {
		if (linha.length > 80) {
			return acc + Math.trunc(linha.length / tamanhoLinha);
		}
		return acc;
	}, calculado);
	return qtdMinimoLinhas > qtd ? qtdMinimoLinhas : qtd;
};

export const joinPlus = (stringArray, separator = ', ', lastSeparator = ' e ') => {
	let output = join(stringArray || [], separator);
	const lastIndex = output.lastIndexOf(separator);
	if (lastIndex > -1) {
		output = output.substring(0, lastIndex) + lastSeparator + output.substring(lastIndex + separator.length);
	}
	return output;
};

const OPTION_SIM = { codigo: 'sim', descricao: 'Sim' };
const OPTION_NAO = { codigo: 'nao', descricao: 'Não', secretarias: [] };
const OPTION_NAO_APLICA = { codigo: 'naoSeAplica', descricao: 'Não se aplica', secretarias: [] };
const DEFAULT_OPTIONS = [OPTION_SIM, OPTION_NAO, OPTION_NAO_APLICA];

function extractLabels(metadata, labels = {}) {
	Object.keys(metadata || {}).forEach(key => {
		const prop = metadata[key];
		if (typeof prop === 'string') {
			if (!['label', 'type', 'titulo'].includes(key)) {
				labels[key] = prop;
			}
		} else {
			// debugLog('KEY', key, prop)
			if (prop && prop.titulo) {
				labels[key] = prop.titulo;
			} else if (prop && prop.label) {
				labels[key] = prop.label;
			}
			if (prop && prop.subfields) {
				(prop.options || DEFAULT_OPTIONS).forEach(option => {
					if (prop.subfields[option.codigo]) {
						labels = extractLabels(prop.subfields[option.codigo], labels);
					}
				});
			} else {
				labels = extractLabels(prop, labels);
			}
		}
	});
	return labels;
}

const obtainUrlPortal = (part1, part2) => {
	const { origin } = document.location;
	let server = null;
	if (origin.indexOf('localhost') > -1) {
		server = 'http://localhost:3001';
	} else {
		server = origin.replace('licenciamento-admin', 'licenciamento');
	}
	const url = `${server}/${part1}/${part2}`;
	return url;
};

const obtainUrlAdmin = (part1, part2) => {
	const { origin } = document.location;
	let server = null;
	if (origin.indexOf('localhost') > -1) {
		server = 'http://localhost:3002';
	} else {
		server = origin.replace('licenciamento', 'licenciamento-admin');
	}
	const url = `${server}/${part1}/${part2}`;
	return url;
};

const obtainLink = (part1, part2) => {
	const { origin } = document.location;
	const url = `${origin}/${part1}/${part2}`;
	return url;
};

function getValueCutDigits(valor, digitals = 2) {
	let subtot = valor || 0;
	subtot = subtot.toFixed(digitals + 6);
	subtot = subtot.substring(0, subtot.length - 6);
	subtot = parseFloat(subtot);
	return subtot;
}

const getUserName = (email = '') => {
	const idx = email.indexOf('@');
	return email.substring(0, idx);
};

const montaUrlExpedientes = idExpediente => {
	const protocolo = window.location.protocol;
	const hostnameLic = window.location.hostname;
	const hostname = isLocalhost
		? 'expedientes-des.procempa.com.br'
		: hostnameLic.replace('licenciamento', 'expedientes');
	const url = `${protocolo}//${hostname}/consultapublica/${idExpediente}`;
	return url;
};

const formataNumeroAlvara = numeroAlvara => {
	if (numeroAlvara?.length >= 20) {
		return `${numeroAlvara.substring(0, 12)}/${numeroAlvara.substring(12, 20)}`;
	}
	return numeroAlvara;
};

const removeFromArray = (array, callback, all = false) => {
	let saida = [];
	let encerrar = false;

	for (let index = 0; index < array.length; index++) {
		const item = array[index];
		if (encerrar) {
			continue;
		}
		// submete o item ao callback que testa para exclusão
		const deveExcluir = callback(item);

		// se não for para excluir, adiciona ao array de retorno
		if (!deveExcluir) {
			saida.push(item);
			if (!all) {
				encerrar = true;
			}
		}
	}

	return saida;
};

const calculaSecretariasCondicionantes = (tiposCondicionantes, metaProps = {}, defaultOptions = []) => {
	const avaliaCondicionante = (tiponame, condicionanteName, condicionante, secretarias, saida = {}) => {
		if (!saida[tiponame]) {
			saida[tiponame] = {};
		}
		const tipo = saida[tiponame];
		const secretariasCondicionante = condicionante.secretarias || secretarias;
		if (typeof condicionante === 'string') {
			secretariasCondicionante.forEach(secname => {
				if (!tipo[secname]) {
					tipo[secname] = {};
				}
				const sec = tipo[secname];
				if (!sec[condicionanteName]) {
					sec[condicionanteName] = ['sim'];
				}
			});
		} else {
			const options = condicionante.options || defaultOptions;
			options
				.map(option => option.codigo) // gera array com options
				.forEach(optionname => {
					const option = options.find(o => o.codigo === optionname);
					const secretariasOption = option.secretarias || secretariasCondicionante;

					let ignoreSubfields = true;

					if (get(condicionante, `subfields.${optionname}`)) {
						Object.keys(condicionante.subfields[optionname])
							.filter(key => !metaProps.includes(key))
							.forEach(subfieldname => {
								const subfield = condicionante.subfields[optionname][subfieldname];
								if (typeof subfield === 'string' || !subfield.type || subfield.type === 'radio') {
									ignoreSubfields = false;
								}
							});
					} else {
						ignoreSubfields = false;
					}

					if (!ignoreSubfields && get(condicionante, `subfields.${optionname}`)) {
						Object.keys(condicionante.subfields[optionname])
							.filter(key => !metaProps.includes(key))
							.forEach(subfieldname => {
								const subfield = condicionante.subfields[optionname][subfieldname];
								avaliaCondicionante(tiponame, subfieldname, subfield, secretariasOption, saida);
							});
						// avaliar subfield
					} else {
						if (optionname !== 'nao' || size(get(option, 'secretarias')) > 0) {
							secretariasOption.forEach(secname => {
								if (!tipo[secname]) {
									tipo[secname] = {};
								}
								const sec = tipo[secname];
								if (!sec[condicionanteName]) {
									sec[condicionanteName] = [];
								}
								sec[condicionanteName].push(optionname);
							});
						}
					}
				});
		}
	};

	let saida = {};

	Object.keys(tiposCondicionantes).forEach(tiponame => {
		const supergrupos = tiposCondicionantes[tiponame];
		supergrupos.forEach(supergrupo => {
			Object.keys(supergrupo)
				.filter(key => !metaProps.includes(key))
				.forEach(gruponame => {
					// 'analiseDmae'
					const grupo = supergrupo[gruponame];
					const secretariasGrupo = grupo.secretarias || [];
					Object.keys(grupo)
						.filter(key => !metaProps.includes(key))
						.forEach(condicionanteName => {
							const condicionante = grupo[condicionanteName];
							avaliaCondicionante(tiponame, condicionanteName, condicionante, secretariasGrupo, saida);
						});
				});
		});
	});

	return saida;
};

const getLabels = (metadata, metaProps, labels = {}) => {
	Object.keys(metadata)
		.filter(key => !metaProps.includes(key))
		.forEach(key => {
			if (isNil(metadata[key]) || typeof metadata[key] === 'string') {
				labels[key] = metadata[key];
			} else {
				if (metadata[key].label) {
					labels[key] = metadata[key].label;
				}
				getLabels(metadata[key], metaProps, labels);
				if (metadata[key].subfields) {
					Object.keys(metadata[key].subfields || []).forEach(option => {
						getLabels(metadata[key].subfields[option], metaProps, labels);
					});
				}
			}
		});
	return labels;
};

const getOptionsLabels = (metadata, metaProps, labels = {}) => {
	Object.keys(metadata)
		.filter(key => !metaProps.includes(key))
		.forEach(key => {
			if (!isNil(metadata[key]) && typeof metadata[key] !== 'string') {
				if (metadata[key]?.options?.length > 0) {
					metadata[key].options.forEach(option => {
						if (option.codigo !== OPTION_NAO && option.codigo !== OPTION_SIM && option.codigo !== OPTION_NAO_APLICA) {
							labels[option.codigo] = option.descricao || option.codigo;
						}
					});
				}
				getOptionsLabels(metadata[key], metaProps, labels);
				if (metadata[key].subfields) {
					Object.keys(metadata[key].subfields || []).forEach(option => {
						getOptionsLabels(metadata[key].subfields[option], metaProps, labels);
					});
				}
			}
		});
	return labels;
};

const defineTarefaSolicitante = formulario => {
	const bpmTasks = get(formulario, 'formData.bpmTasks');
	let taskOrigem = {};
	if (bpmTasks) {
		for (let i = bpmTasks.length - 1; i >= 0; i--) {
			if (TELAS_QUE_ENVIAM_PARA_OUTRAS_SECRETARIAS.includes(bpmTasks[i].taskName)) {
				taskOrigem = bpmTasks[i];
				break;
			}
		}
	}
	return taskOrigem;
};

function joinBr(array, separator, lastSeparator) {
	if (!Array.isArray(array)) {
		array = [];
	}
	if (!separator) {
		separator = ', ';
	}
	if (!lastSeparator) {
		lastSeparator = ' e ';
	}
	let saida = array.join(separator);

	const index = saida.lastIndexOf(separator);

	if (index > -1) {
		saida = saida.substring(0, index) + lastSeparator + saida.substring(index + separator.length);
	}

	return saida;
}

const formatNumber = (number, decs = 0) => {
	if (typeof number === 'string') {
		if (decs === 0) {
			number = number.replace(/\.,/g, '');
		} else {
			number = number.replace(/\./g, '');
		}
		number = number.replace(/,/g, '.');
		number = parseFloat(number);
	}
	const saida = number.toLocaleString('pt-br', {
		style: 'decimal',
		minimumIntegerDigits: 1,
		useGrouping: true,
		minimumFractionDigits: decs,
		maximumFractionDigits: decs
	});
	return saida;
};

const verificaEnquadramentoDefinicao = (tiposDefinicoes, definicao) => {
	const newLocal = tiposDefinicoes.reduce((ac, elem) => {
		const regex = new RegExp(`\\b${elem}\\b`);
		return ac || regex.test(definicao);
	}, false);
	return newLocal;
};

export {
	getUserName,
	formataNumeroAlvara,
	termoNotificacaoEmpty,
	compareStates,
	trocaPontoPorVirgula,
	parseNumero,
	filterList,
	maiorQue,
	soma,
	multiplica,
	divide,
	diferenca,
	iguais,
	entre,
	stringify,
	format,
	titleCase,
	removeSpaces,
	calculaDigitoMod11,
	sortCadastros,
	permiteInput,
	convertToNumber,
	convertToDecimal,
	virgulaPonto,
	pontoVirgula,
	formatFloat,
	formatFloats,
	formataTelefone,
	cpfCnpj,
	cep,
	expedienteUnico,
	isCPF,
	isCNPJ,
	isEmail,
	isDam,
	isNumeroExpediente,
	isAreaPrivativaExpediente,
	mergePropertyIntoState,
	selectInArray,
	stateWithoutError,
	normalizedAddress,
	clearObject,
	formataProcessos,
	createUploadFileChannel,
	montaURL,
	stringValue,
	numericValue,
	formatTypedNumber,
	useIsMounted,
	formataNomeDocumento,
	ellipsis,
	ellipsisMultiRow,
	getUpdatedToken,
	isAnalista,
	getEnvironment,
	obtemPerguntaRespostaExtraInfo,
	formataHorario,
	ignoreDiacritics,
	removeDiacritics,
	getMomentDate,
	obtemDescricaoOcorrencia,
	formatCodigoEU,
	deepEqual,
	defineNomeSecretaria,
	isPrimitive,
	extractLabels,
	obtainUrlPortal,
	obtainUrlAdmin,
	obtainLink,
	getValueCutDigits,
	montaUrlExpedientes,
	removeFromArray,
	calculaSecretariasCondicionantes,
	getLabels,
	getOptionsLabels,
	defineTarefaSolicitante,
	joinBr,
	formatNumber,
	verificaEnquadramentoDefinicao,
	isLocalhost,
	isDes,
	isDes as isDev,
	isHom,
	isPro,
	isPro as isProd,
	isDebug,
	isAdmin,
	isPortal
};

const constants = require('./constants.js');
const andamentosKeys = Reflect.ownKeys(constants).filter(k => typeof k === 'string' && k.startsWith('ANDAMENTOS_'));
const TODOS_ANDAMENTOS = andamentosKeys.reduce((acc, key) => ({ ...acc, ...constants[key] }), {});
export function getAndamentoGlobal(taskname, property = 'concluida') {
	const andamento = (TODOS_ANDAMENTOS[taskname] || {})[property];
	if (!andamento) {
		console.error(
			`ATENÇÃO DEV: Faltou o mapeamento da tarefa ${taskname} ou do andamento ${property} no arquivo /src/utils/constants.js`
		);
	}
	return andamento || `Andamento ${property} não encontrado para task ${taskname}`;
}

export async function verificaFeiraDeRua(data) {
	const tipoEvento = data.tipoEvento;
	const areaOcupadaPublica = data.areaOcupadaPublica;
	const viaPublicaParquePraca = ['via', 'parque'].reduce(
		(acc, current) => acc || (areaOcupadaPublica || []).includes(current),
		false
	);
	const localEvento = data.localEvento;
	const datasEvento = data.datasEvento;

	if (tipoEvento !== 'feiras' || !viaPublicaParquePraca) {
		return new Promise(resolve => resolve(0));
	}

	const codLogradouro = localEvento?.codLogradouro;
	let numero = localEvento?.numero;

	const dataInicioEvento = datasEvento?.[0].datas?.[0];
	const dataFimEvento = datasEvento?.[0].datas?.[1];

	let dias = null;

	if ((areaOcupadaPublica || []).includes('via')) {
		dias = 14 - 1; // se evento num domingo, pode ter novo evento no segundo sábado subsequente
	} else if ((areaOcupadaPublica || []).includes('parque')) {
		dias = 6 - 1; // se evento num domingo, pode ter novo evento no sábado subsequente
	}

	numero = '0'; // ignora numeração para verificar todo o logradouro
	const url =
		'/api/verifica-evento-feira-rua/' +
		`?codLogradouro=${codLogradouro}` +
		`&numero=${numero}` +
		`&dataInicial=${dataInicioEvento}&dataFinal=${dataFimEvento}` +
		`&dias=${dias}`;

	return accessApi(url, true).then(retorno => retorno?.data?.eventos || 0);
}

/**
 * obtém a última versão do "documentoDados" ou do "documento", de acordo com parametros e a extensao solicitada (arrazoado-evu, boletim-vistoria, planilha-unifamiliar, etc...)
 * @param {*} formData objeto dos dados do formulário
 * @param {string} extensao extensao solicitada (arrazoado-evu, boletim-vistoria, planilha-unifamiliar, etc...)
 * @param {boolean} obterDoc se deseja obter apenas o "documento" em sua última versão, default: FALSE
 * @param {boolean} obterDocDadosOriginal se deseja obter o documentoDados do documento de versão anterior à última, default: TRUE
 * @param {boolean} versao se deseja obter de acordo com uma versão específica de documento, caso não exista, retorna a última versão. default: NULL
 * @returns documento ou dados do documento
 */
export const obtemDadosDocumento = (
	formData,
	extensao,
	obterDoc = false,
	obterDocDadosOriginal = true,
	versao = null,
	removerComentarios = false
) => {
	const { documentos, documentosDados } = formData || {};
	let docDadosAux = null;
	const documento = (documentos || []).reduce((acc, doc) => {
		if (doc.extensao === extensao) {
			if (acc) {
				// obter uma versão específica, ou por padrão, obter a última versão
				return (versao && versao === doc.versao) || (!versao && doc.versao > acc.versao) ? doc : acc;
			} else {
				return doc;
			}
		}
		return acc;
	}, null);
	const docDados = (documentosDados || []).find(
		dd => dd?.id === documento?.id || (obterDocDadosOriginal && dd?.id === documento?.original)
	);
	if (removerComentarios) {
		const removeComments = obj => {
			for (let key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					if (typeof obj[key] === 'object') {
						removeComments(obj[key]);
					} else if (key.endsWith('Comentario')) {
						delete obj[key];
					}
				}
			}
			return obj;
		};
		docDadosAux = removeComments(docDados);
	}
	return obterDoc ? documento : docDadosAux ? docDadosAux : docDados;
};
