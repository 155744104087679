import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import gql from 'graphql-tag';
import { isNil, size, trim } from 'lodash';
import uuid from 'uuid/v4';

import { REGEX_NUMBERS_ONLY } from 'utils/constants';
import apolloClient from 'utils/graphql';
import { accessApi } from 'utils/injectApi';
import { normalizedAddress, permiteInput } from 'utils/tools';

import Selecao from '../components/Selecao';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

/* eslint-disable react-hooks/exhaustive-deps */
const CdlField = ({
	title,
	label = ['Logradouro', 'Número'],
	noLabel = false,
	required: requiredProps,
	placeHolder = ['Nome do logradouro', 'Número do imóvel'],
	name,
	value,
	readOnly,
	disabled,
	errorList,
	onChangeHandler,
	setCdlFieldLoading,
	clearData,
	setClearData
}) => {
	const [loading, setLoading] = useState(false);
	const [timer, setTimer] = useState(null);
	const [timer2, setTimer2] = useState(null);
	const [logradourosPesquisados, setLogradourosPesquisados] = useState([]);
	const [logradouro, setLogradouro] = useState(null);
	const [numero, setNumero] = useState(null);
	const [validar, setValidar] = useState(false);
	const [formTouched, setFormTouched] = useState(false);
	const searchTermRef = useRef();
	const numeroRef = useRef();

	const requiredLog = Array.isArray(requiredProps) ? requiredProps[0] : requiredProps;
	const requiredNum = Array.isArray(requiredProps) ? requiredProps[1] : requiredProps;

	useEffect(
		() => () => {
			if (timer) {
				clearTimeout(timer);
			}
			if (timer2) {
				clearTimeout(timer2);
			}
		},
		[]
	);

	useEffect(() => {
		if (setCdlFieldLoading) {
			setCdlFieldLoading(loading);
		}
	}, [loading]);

	useEffect(() => {
		if (clearData) {
			setLogradouro(null);
			sendRetorno(null);
			setNumero(null);
			setClearData(false);
		}
	}, [clearData]);

	useEffect(() => {
		if (value) {
			setValidar(true);
			if (!logradouro) {
				setLogradouro(value.nomeLogradouro);
			}
			if (!numero) {
				setNumero(value.numero);
			}
		}
	}, [value]);

	useEffect(() => {
		if (timer) {
			clearTimeout(timer);
		}
		setTimer(
			setTimeout(async () => {
				const enderecosCdl = await buscaEnderecosCdl(logradouro);
				setLogradourosPesquisados(enderecosCdl);
			}, 500)
		);
	}, [logradouro]);

	useEffect(() => {
		if (timer2) {
			clearTimeout(timer2);
		}
		setTimer2(
			setTimeout(async () => {
				if (!isNil(numero)) {
					const enderecoCdl = await obtemEndereco(value, numero);
					sendRetorno(enderecoCdl);
				}
			}, 300)
		);
	}, [numero]);

	const setCDLHandler = cdl => async () => {
		setLogradourosPesquisados([]);
		const enderecoCdl = await obtemEndereco(cdl, numero);
		sendRetorno(enderecoCdl);
		if (numeroRef && numeroRef.current) {
			numeroRef.current.focus();
		}
	};

	const unsetCDLHandler = () => async () => {
		setLogradouro(null);
		sendRetorno(null);
		setTimeout(() => {
			if (searchTermRef && searchTermRef.current) {
				searchTermRef.current.focus();
			}
		}, 300);
		if (logradouro) {
			const enderecosCdl = await buscaEnderecosCdl(logradouro);
			setLogradourosPesquisados(enderecosCdl);
		}
	};

	const setNumeroHandler = e => {
		const numAux = e.target.value;
		if (permiteInput(numAux, 'int')) {
			setNumero(numAux);
			setFormTouched(true);
		}
	};

	const sendRetorno = cdl => {
		(formTouched || !cdl) &&
			onChangeHandler([
				{ name, value: cdl },
				{
					name: 'errors',
					value: { [name]: validate(logradouro, numero, cdl, requiredProps, label, title) }
				}
			]);
	};

	/**
	 * busca todos os enderecos conforme for sendo digitado para procura
	 * @param {*} logradouro
	 * @returns
	 */
	const buscaEnderecosCdl = async logradouro => {
		let saida = [];
		if (!value || (value && value.nomeLogradouro !== logradouro)) {
			try {
				const queryCDL = gql`
					query CDL($term: String) {
						list: CdlEnderecoList(term: $term) {
							id
							nomeLogradouro
							enderecoFormatadoCurto
						}
					}
				`;
				if (size(logradouro) > 2) {
					setLoading(true);
					const {
						data: { list }
					} = await apolloClient.query({
						query: queryCDL,
						variables: { term: logradouro },
						fetchPolicy: 'network-only'
					});
					saida = list;
					setLoading(false);
				}
			} catch (e) {
				setLoading(false);
				console.error('erro no servidor', e);
				// yield put(push('/serverUnavailable'));
			}
		}
		return saida;
	};

	/**
	 * obtem o endereco final correto
	 * @param {*} cdl
	 * @param {*} numero
	 * @returns
	 */
	const obtemEndereco = async (cdl, numero) => {
		let saida = null;
		setLoading(true);
		if (size(cdl) > 0) {
			if (cdl.nomeLogradouro !== logradouro || `${cdl.numero}` !== `${numero}`) {
				try {
					const queryCDL = gql`
						query byId($id: String!) {
							cdlLido: CdlEnderecoById(id: $id) {
								id
								nomeLogradouro
								nomeHistorico
								codigoBairro
								nomeBairro
								lado
								nomeBairroHistorico
								numero
								cep
								enderecoFormatado
								enderecoFormatadoCurto
							}
						}
					`;
					const id = { codLogradouro: cdl.codLogradouro || cdl.id, numero };
					const query = {
						query: queryCDL,
						variables: { id: JSON.stringify(id) },
						fetchPolicy: 'network-only'
					};
					const {
						data: { cdlLido }
					} = await apolloClient.query(query);
					saida = { ...cdlLido, codLogradouro: cdlLido.id, id: uuid() };
				} catch (e) {
					console.error('erro no servidor', e);
				}
			} else {
				saida = cdl;
			}
		}
		if (saida && saida.codLogradouro && saida.numero) {
			try {
				const { data } = await accessApi(`/consulta-regiao-por-endereco/${saida.codLogradouro}/${saida.numero}`);
				if (data.regiao) {
					saida.regiao = data.regiao;
				}
			} catch (error) {
				console.error('cdlField erro inesperado ao consultar regiao cdl: ', error);
			}
		}
		setLoading(false);
		return saida;
	};

	return (
		<div className="cdl-field">
			<div className="form-group col-md-8 cdl-field-logradouro">
				{!noLabel && (
					<label className="control-label">
						{label[0]}
						{requiredLog && <span className="required">*</span>}
					</label>
				)}
				<Selecao
					searchTermRef={searchTermRef}
					className={
						size(errorList) > 0 ||
						(validar && size(validate(logradouro, numero, value, requiredProps, label, title)) > 0)
							? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
							: `${CLASS_NAME_FORM_CONTROL}`
					}
					multiple={false}
					selected={value}
					label={''}
					detailInnerClassName={'inner-list-item inner-list-item-input'}
					detailCodigo={''}
					detailDescricao={'nomeLogradouro'}
					autoShowList={false}
					searchTerm={logradouro}
					searchList={logradourosPesquisados}
					searchTermMinLength={3}
					errorList={false}
					onChangeSearchTerm={e => {
						setFormTouched(true);
						setLogradouro(e.target.value);
					}}
					onBlurSearchTerm={() => false}
					onSelectItem={setCDLHandler}
					onUnselect={unsetCDLHandler}
					noResetList={true}
					loading={loading}
					required={requiredLog}
					placeholder={placeHolder[0]}
					readOnly={disabled || readOnly}
				/>
			</div>
			<div className="form-group col-md-4 cdl-field-numero">
				{!noLabel && (
					<label className="control-label">
						{label[1]}
						{requiredNum && <span className="required">*</span>}
					</label>
				)}
				<div className="input-with-icon">
					<input
						ref={numeroRef}
						className={
							size(errorList) > 0 ||
							(validar && size(validate(logradouro, numero, value, requiredProps, label, title)) > 0)
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						placeholder={placeHolder[1]}
						name={name}
						value={numero || ''}
						onChange={setNumeroHandler}
						readOnly={readOnly}
						disabled={disabled || loading}
					/>
					{loading && <i className="fa fa-refresh fa-spin" />}
				</div>
			</div>
		</div>
	);
};

CdlField.displayName = 'CdlField';

CdlField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	noLabel: PropTypes.bool,
	required: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.bool)]),
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.object,
	containerClass: PropTypes.string,
	pesquisarExpedientes: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	forTemplate: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func,
	history: PropTypes.any,
	setCdlFieldLoading: PropTypes.func,
	clearData: PropTypes.bool,
	setClearData: PropTypes.func
};

export default withRouter(CdlField);

const isValidLogradouro = logradouro => size(logradouro) === 0 || size(trim(normalizedAddress(logradouro))) > 0;
const isValidNumero = numero => size(numero) === 0 || REGEX_NUMBERS_ONLY.test(numero);

export const validate = (
	logradouro = '',
	numero,
	cdl,
	required,
	label = ['Logradouro', 'Número'],
	title = 'Endereço'
) => {
	const numeroAsString = `${numero ? numero : ''}`;
	const errors = [];
	if (!isValidLogradouro(logradouro)) {
		errors.push(`${label[0]} inválido. Ex: Av. Ipiranga`);
	}
	if (!isValidNumero(numeroAsString)) {
		errors.push(`${label[1]} inválido. Ex: 1200`);
	}
	if (required === true) {
		if (size(logradouro) === 0) {
			errors.push(`${label[0]} deve ser informado`);
		}
		if (size(numeroAsString) === 0 || numeroAsString === '0') {
			errors.push(`${label[1]} deve ser informado`);
		}
		if (size(cdl) === 0) {
			errors.push(`Você deve pesquisar e selecionar um ${title}`);
		}
	} else if (required && Array.isArray(required)) {
		if (required[0] && size(logradouro) === 0) {
			errors.push(`${label[0]} deve ser informado`);
		}
		if (required[1] && (size(numeroAsString) === 0 || numeroAsString === '0')) {
			errors.push(`${label[1]} deve ser informado`);
		}
	}
	return errors;
};
