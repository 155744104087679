import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { get, size, trim, isNil } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { EXPEDIENTES_API_URL } from 'environments';

import { accessApi, sendApi } from 'utils/injectApi';
import { permiteInput, normalizedAddress, montaUrlExpedientes, isDebug } from 'utils/tools';
import { format } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

/* eslint-disable react-hooks/exhaustive-deps */
const ExpedienteEnderecosField = ({
	title,
	label,
	required = [false, false],
	placeHolder,
	name,
	value,
	pesquisarPorEndereco,
	alertaCadastroRequired = false,
	atributoEnderecos,
	readOnly,
	disabled,
	consultaPublicaExpedientes = true,
	onChangeHandler,
	history,
	obterDadosProjetosLicencasExpediente = false,
	formetrnahora = false
}) => {
	const popup = useRef();

	const [expediente, setExpediente] = useState(null);
	const [showPopup, setShowPopup] = useState(false);
	const [timer, setTimer] = useState(null);
	const [loading, setLoading] = useState(false);
	const [expedientes, setExpedientes] = useState(null);
	const [query, setQuery] = useState('');
	const [valid, setValid] = useState(true);
	const [warnings, setWarnings] = useState(null);

	useEffect(() => {
		if (value.numero && getString(value.areaPrivativa)) {
			if (value.numero !== get(expediente, 'numero') || value.areaPrivativa !== get(expediente, 'areaPrivativa')) {
				setTimeout(async () => {
					await buscaExpediente(value.numero, getString(value.areaPrivativa));
				}, 0);
			}
		}
	}, [value]);

	const setNumeroExpediente = e => {
		let { value: input } = e.target;
		input = (input || '').replace(/[^0-9]/g, '');
		input = (input || '').substring(0, 12);
		if (permiteInput(input, 'number', 0, 12)) {
			onChangeHandler({
				name,
				value: { numero: input, areaPrivativa: value.areaPrivativa }
			});
			onChangeHandler({ name: 'errors', value: { [name]: [] } });
			if (size(input) === 12) {
				setTimeout(async () => {
					setLoading(true);
					await buscaExpediente(input, value.areaPrivativa);
					setLoading(false);
				}, 0);
			} else if (input) {
				setValid(false);
			} else {
				setValid(true);
			}
		}
	};

	const setAreaPrivativa = e => {
		let { value: input } = e.target;
		input = (input || '').replace(/[^0-9]/g, '');
		input = (input || '').substring(0, 5);
		if (permiteInput(input, 'number', 0, 5)) {
			onChangeHandler({
				name,
				value: { numero: value.numero, areaPrivativa: input }
			});
			if (timer) {
				clearTimeout(timer);
			}
			if (size(value.numero) === 12) {
				setTimer(
					setTimeout(async () => {
						setLoading(true);
						await buscaExpediente(value.numero, input);
						setLoading(false);
					}, 500)
				);
			}
		}
	};

	const formataEnderecoCdlList = async enderecos => {
		let enderecoCdlList = {};
		if (size(atributoEnderecos) > 0) {
			const cdls = enderecos.map(e => ({
				id: e.id,
				codLogradouro: e.codigoLogradouro,
				numero: e.numero,
				nomeLogradouro: e.nomeLogradouro,
				nomeBairro: e.bairro,
				cep: e.cep,
				enderecoFormatadoCurto: e.enderecoFormatadoCurto
			}));

			const { data } = await sendApi({ url: 'consulta-regiao-por-endereco', payload: cdls });
			if (data && !data.erro) {
				data.forEach(({ regiao, codLogradouro, numero }) => {
					if (regiao) {
						const cdl = cdls.find(cdl => cdl.codLogradouro === codLogradouro && cdl.numero === numero);
						if (cdl) {
							cdl.regiao = regiao;
						}
					}
				});
			}

			enderecoCdlList = {
				name: atributoEnderecos,
				value: cdls
			};
		}
		return enderecoCdlList;
	};

	const buscaExpediente = async (eu, area) => {
		try {
			const response = await accessApi(
				`${EXPEDIENTES_API_URL}/expediente-unico-enderecos?numExpediente=${eu}&areaPrivativa=${area || '0'}`,
				true
			);

			// se precisa obter dados de Projetos de Edificação e Licenças do Expediente para verificações
			if (obterDadosProjetosLicencasExpediente) {
				const idExpediente = get(response.data, '_id') || get(response.data, 'id');
				const responseExpedienteCompleto = await accessApi(`${EXPEDIENTES_API_URL}/eu/${idExpediente}`, true);
				// Atualiza valores de projetosEdificacao e licencas no Expediente
				response.data.projetosEdificacao = get(responseExpedienteCompleto, 'data.projetosEdificacao');
				response.data.licencas = get(responseExpedienteCompleto, 'data.licencas');
			}

			const expUnico = response.data;
			setExpediente(expUnico);

			// atualiza lista de enderecos ao alterar o expediente
			if (expUnico && expUnico.enderecos) {
				let arrayExpLogErrors = [];
				arrayExpLogErrors.push({ name, value: expUnico });
				const cdls = await formataEnderecoCdlList(expUnico.enderecos);
				arrayExpLogErrors.push(cdls);
				arrayExpLogErrors.push({
					name: 'errors',
					value: { [name]: validate(expUnico, required, label, title) }
				});
				onChangeHandler(arrayExpLogErrors);
			} else {
				onChangeHandler({ name, value: expUnico });
				onChangeHandler({
					name: 'errors',
					value: { [name]: validate(expUnico, required, label, title) }
				});
			}

			setValid(true);
		} catch (e) {
			setExpediente(null);
			if (get(e, 'response.status') === 404) {
				onChangeHandler({
					name: 'errors',
					value: { [name]: ['404 - Expediente não encontrado'] }
				});
				setValid(false);
			} else {
				console.error('erro no servidor', e);
				history.push('/serverUnavailable');
			}
		}
	};

	const pesquisarExpedientes = async endereco => {
		let numero = null;
		let q = null;
		const texto = (endereco || '').replace(/[.,;:/?{}[\]]/g, ' ');
		let parts = texto.split(/[\s*]/);
		const numericas = parts.filter(p => isFinite(parseInt(p, 10)));
		if (size(numericas) > 0) {
			numero = numericas[numericas.length - 1];
			parts = texto.split(numero)[0].split(/[\s*]/);
			const selecionadas = parts.filter(p => p !== numero && size(trim(p)) > 0);
			q = selecionadas.join(' ');
		}
		if (size(q) > 2 && numero) {
			setWarnings(null);
			try {
				setLoading(true);
				setExpedientes(null);

				let response = null;
				response = await accessApi(
					`${EXPEDIENTES_API_URL}/consulta-expedientes-por-endereco?q=${normalizedAddress(q)}&numero=${numero}`,
					true
				);

				// se precisa obter dados de Projetos de Edificação e Licenças do Expediente para verificações
				if (obterDadosProjetosLicencasExpediente && Array.isArray(response.data)) {
					let idExpediente = null;
					let responseExpedienteCompleto = null;

					for (var i = 0; i < response.data.length; i++) {
						idExpediente = get(response.data[i], '_id') || get(response.data[i], 'id');
						responseExpedienteCompleto = await accessApi(`${EXPEDIENTES_API_URL}/eu/${idExpediente}`, true);
						// Atualiza valores de projetosEdificacao e licencas no Expediente
						response.data[i].projetosEdificacao = get(responseExpedienteCompleto, 'data.projetosEdificacao');
						response.data[i].licencas = get(responseExpedienteCompleto, 'data.licencas');
					}
				}

				const expedientesAux = response.data;
				setLoading(false);
				setExpedientes(expedientesAux);
			} catch (e) {
				setLoading(false);
				console.error('erro no servidor', e);
				history.push('/serverUnavailable');
			}
		} else {
			if (size(endereco) > 0) {
				setWarnings(['Você deve informar um endereço completo, como em Ipiranga, 1200']);
			}
		}
	};

	const montaLinkExpedientes = id => {
		let url = EXPEDIENTES_API_URL;
		url = url.replace('-api', '');
		url = url.replace('api', '');
		url = url.replace(/http:\/\/localhost:.*/, 'https://expedientes-des.procempa.com.br/');
		const saida = `${url}${consultaPublicaExpedientes ? 'consultapublica/' : ''}${id}`;
		return saida;
	};

	const usarExpediente = ({ _id, numero, areaPrivativa, enderecos }) => {
		//monta codigo = expediente + areaPrivativa (com 5 digitos)
		let area = `00000${areaPrivativa}`;
		area = area.substring(area.length - 5);
		const codigo = numero + area;

		const objects = [{ name, value: { _id, numero, areaPrivativa, codigo } }];

		if (size(atributoEnderecos) > 0) {
			objects.push({
				name: atributoEnderecos,
				value: enderecos.map(e => ({
					id: e.id,
					codLogradouro: e.codigoLogradouro,
					numero: e.numero,
					nomeLogradouro: e.nomeLogradouro,
					nomeBairro: e.nomeBairro,
					cep: e.cep,
					enderecoFormatadoCurto: e.enderecoFormatadoCurto
				}))
			});
		}

		objects.push({ name: 'errors', value: { [name]: [] } });
		// rodar buscaExpediente diretamente evita que o onChangeHandler seja chamado duas vezes
		// seja disparado duas vezes no mesmo render, evitando problemas de contexto no callback enviado.
		// onChangeHandler(objects);
		buscaExpediente(numero, areaPrivativa);
		hidePopupHandler();
	};

	const showPopupHandler = () => {
		if (value) {
			setQuery('');
			pesquisarExpedientes('');
		} else {
			setQuery('');
		}
		setShowPopup(true);
	};

	const hidePopupHandler = () => {
		setExpedientes(null);
		setQuery('');
		setShowPopup(false);
	};

	const trataEnter = e => {
		if (e.key === 'Enter') {
			pesquisarExpedientes(query);
		} else {
			if (e.key === 'Escape') {
				setQuery('');
				hidePopupHandler();
			}
		}
		return true;
	};

	useEffect(() => {
		if (showPopup) {
			const div = popup.current;
			if (div) {
				const input = div.querySelectorAll('input');
				input[0].focus();
			}
		}
	}, [showPopup]);

	return (
		<>
			{showPopup && (
				<div className="modal-wrapper">
					<div className="modal modal-lg" ref={popup}>
						<div className="header-group">
							<h1> Pesquisar expedientes </h1>
							<i className="fa fa-times" onClick={() => hidePopupHandler()}></i>
						</div>
						<label className="control-label" htmlFor="root_nome">
							Pesquisar Expediente Único por endereço
						</label>
						<div
							className="input-group"
							style={{
								display: 'grid',
								gridTemplateColumns: '5fr 1fr',
								gridColumnGap: '10px'
							}}
						>
							<div>
								<input
									className={CLASS_NAME_FORM_CONTROL}
									type="text"
									value={query || ''}
									onChange={e => setQuery(e.target.value)}
									onKeyDown={trataEnter}
									placeholder="Informe um endereço, como em Av. Julio de Castilhos, 505"
								/>
								{loading && (
									<i
										className={'fa fa-refresh fa-spin'}
										style={{
											marginTop: '-27px',
											position: 'relative',
											float: 'right',
											marginRight: '15px'
										}}
									/>
								)}
							</div>
							<div style={{ marginTop: '3px' }}>
								<button type="button" className="btn btn-primary" onClick={() => pesquisarExpedientes(query)}>
									Pesquisar
								</button>
							</div>
						</div>
						{warnings && <ErrorMessages errorList={warnings} />}
						{expedientes !== null && size(expedientes) === 0 && (
							<div className="col-md-12">Nenhum expediente com o endereço pesquisado</div>
						)}
						{size(expedientes) > 0 && (
							<div className="col-md-12 container-eu-modal">
								{expedientes
									.filter(e => !!e)
									.map((e, i) => (
										<div key={e._id} className="eu-modal">
											<div className="d-flex justify-content-between">
												<button type="button" className={'btn btn-link'} onClick={() => usarExpediente(e)}>
													{i + 1} - {format(e.numero, '000.000000.00.0')} - {format(e.areaPrivativa, '00000')}{' '}
													<em>(escolher este)</em>
												</button>
												<a
													href={montaUrlExpedientes(e._id)}
													target="_blank"
													rel="noopener noreferrer"
													className="btn-expediente"
												>
													<i className="fa fa-external-link"></i> consultar
												</a>
											</div>
											<ul>
												{e.enderecos.map(end => (
													<li key={end.id}>
														{get(end, 'enderecoFormatadoCurto')}
														{isDebug ? (
															<span className="debug-message">{` (codLogradouro: ${end.codigoLogradouro})`}</span>
														) : (
															''
														)}
													</li>
												))}
											</ul>
										</div>
									))}
							</div>
						)}
					</div>
				</div>
			)}
			<div className="expediente-unico-field">
				<div className="form-group col-md-6">
					<label className="control-label">
						{label[0]}
						{required[0] && <span className="required">*</span>}
					</label>
					<input
						className={
							!valid ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						placeholder={placeHolder[0]}
						name="numero"
						value={value.numero && valid ? format(value.numero, '000.000000.00.0') : value.numero || ''}
						onChange={setNumeroExpediente}
						readOnly={readOnly}
						disabled={disabled}
					/>
					{loading && <i className={'fa fa-refresh fa-spin refresh-field'} />}
					{(readOnly || disabled) && value._id && (
						<div className="element-with-link">
							<a href={montaLinkExpedientes(value._id)} target="_blank" rel="noopener noreferrer">
								<i style={{ marginLeft: '8px' }} className="fa fa-external-link" />
							</a>
						</div>
					)}
				</div>
				<div className="form-group col-md-6">
					<label className="control-label">
						{label[1]}
						{required[1] && <span className="required">*</span>}
					</label>
					<input
						className={
							!valid ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						placeholder={placeHolder[1]}
						name="areaPrivativa"
						value={getString(value.areaPrivativa)}
						onChange={setAreaPrivativa}
						readOnly={readOnly}
						disabled={disabled}
					/>
				</div>
				{!(readOnly || disabled) && pesquisarPorEndereco && (
					<div className="col-md-12">
						<button type="button" className={'btn btn-link'} onClick={showPopup ? hidePopupHandler : showPopupHandler}>
							{showPopup ? (
								<i className="fa fa-ban" aria-hidden="true"></i>
							) : (
								<i className="fa fa-search" aria-hidden="true"></i>
							)}
							{showPopup ? ' cancelar pesquisa' : ' pesquisar por endereço'}
						</button>
					</div>
				)}
				{alertaCadastroRequired && (
					<div className="alert alert-secondary">
						Caso seja necessária a criação de Expediente Único, solicitar ao Escritório de Licenciamento, através do
						e-mail:{' '}
						{formetrnahora ? ( //ETR NA HORA OU LICENÇA TOLDO
							<>
								<a href="setor.licencas@portoalegre.rs.gov.br">setor.licencas@portoalegre.rs.gov.br</a>
								<br />
								<p>
									Anexar no e-mail a matrícula do imóvel e a Inscrição Municipal (IPTU) para verificação do endereço.
								</p>
							</>
						) : (
							<a href="escritoriolicenciamento@portoalegre.rs.gov.br">escritoriolicenciamento@portoalegre.rs.gov.br</a>
						)}
					</div>
				)}
				{expediente?.anulado && (
					<ErrorMessages
						type="warning"
						errorList={[
							{
								message: 'O Expediente Único informado possui atualmente o status de ANULADO.',
								type: 'warning'
							},
							{
								message: 'O requerimento pode ser continuado, mas certifique-se que o Expediente Único está correto.',
								type: 'warning'
							}
						]}
					/>
				)}
			</div>
		</>
	);
};

ExpedienteEnderecosField.displayName = 'ExpedienteEnderecosField';

ExpedienteEnderecosField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.arrayOf(PropTypes.bool),
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.object,
	pesquisarPorEndereco: PropTypes.bool,
	alertaCadastroRequired: PropTypes.bool,
	atributoEnderecos: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	consultaPublicaExpedientes: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	history: PropTypes.any,
	obterDadosProjetosLicencasExpediente: PropTypes.bool,
	formetrnahora: PropTypes.bool
};

export default withRouter(ExpedienteEnderecosField);

const getString = valor => (isNil(valor) ? '' : `${valor}`);

export const validate = (
	expediente,
	required,
	label = ['Número Expediente', 'Área Privativa'],
	title = 'Expediente'
) => {
	const { _id, numero, areaPrivativa } = expediente || {};
	const area = getString(areaPrivativa);
	const errors = [];
	if (numero) {
		if (!numero.startsWith('002') || numero.length !== 12) {
			errors.push(`${label[0]} inválido`);
		}
		if (size(area) === 0) {
			errors.push(`Informando ${label[0]}, ${label[1]} deve ser informada`);
		}
		if (size(errors) === 0) {
			if (!_id) {
				errors.push(`${title} não encontrado`);
			}
		}
	} else {
		if (required[0]) {
			errors.push(`${label[0]} deve ser informado`);
		}
		if (required[1] && size(area) === 0) {
			errors.push(`${label[1]} deve ser informada`);
		}
	}
	return errors;
};
