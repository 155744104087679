import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { List } from 'immutable';
import { size, isArray, get } from 'lodash';
import uuid from 'uuid/v1';

import ErrorMessages from 'components/ErrorMessages';

import ListItem from './ListItem';
import 'tippy.js/dist/tippy.css'; // optional

const Selecao = ({
	className,
	readOnly,
	multiple,
	selected,
	label,
	detailInnerClassName,
	detailCodigo,
	detailDescricao,
	detailData,
	detailIcon = null,
	detailModifier,
	searchTerm,
	searchList,
	errorList,
	warning,
	required,
	searchTermRef,
	onUnselect,
	onChangeSearchTerm = () => false,
	onBlurSearchTerm = () => false,
	onSelectItem,
	searchTermMinLength = 2,
	descriptionSubstringLength = 26,
	autoShowList,
	noResetList = false,
	loading = false,
	placeholder,
	showHyphen = true,
	readOnlyStyle
}) => {
	const [showList, setShowList] = useState(false);
	const wrapperInnerRef = useRef(null);
	const wrapperOuterRef = useRef(null);

	const selectedJS = List.isList(selected) ? selected.toJS() : selected;

	const selectedList = selectedJS ? (isArray(selectedJS) ? selectedJS : [selectedJS]) : selectedJS;

	const hasSelected = size(selectedList) > 0;

	let searchSelected = -1;
	let prev;

	const handleKeyDown = event => {
		// let sl = Map.isMap(searchList) ? searchList.toJS() : searchList;
		let sl = searchList;

		if (!noResetList && !showList && event.key === 'ArrowDown') {
			//empty search
			setShowList(true);
			onChangeSearchTerm(event);
		}

		if (sl && sl.length > 0) {
			if (event.key === 'Enter') {
				searchSelected = searchSelected === -1 ? 0 : searchSelected;
				//select
				document.querySelectorAll('.results-item')[searchSelected].click();
			} else if (event.key === 'ArrowUp') {
				//focus previous
				prev = searchSelected;
				searchSelected = searchSelected === 0 ? sl.length - 1 : searchSelected - 1;
				fakeFocus(searchSelected, prev);
			} else if (event.key === 'ArrowDown') {
				//focus next
				prev = searchSelected;
				searchSelected = searchSelected === sl.length - 1 ? 0 : searchSelected + 1;
				fakeFocus(searchSelected, prev);
			} else if (event.key === 'Tab') {
				//close list
				setShowList(false);
			}
		}
	};
	const fakeFocus = (searchSelected, prev) => {
		const list = document.querySelectorAll('.results-item');

		if (list && prev > -1 && list[prev]) {
			list[prev].classList.remove('fake-focus');
		}
		if (list && searchSelected > -1 && list[searchSelected]) {
			list[searchSelected].classList.add('fake-focus');
			list[searchSelected].scrollIntoView(false);
		}
	};

	const handleOnChange = event => {
		onChangeSearchTerm(event);
		const mostra = autoShowList || size(event.target.value) >= searchTermMinLength;
		setShowList(mostra);
	};

	const handleOnFocus = event => {
		if (size(searchTerm) >= searchTermMinLength || autoShowList) {
			setShowList(true);
			onChangeSearchTerm(event);
		}
		if (!autoShowList) {
			onBlurSearchTerm();
		}
	};

	const handleArrowBtn = event => {
		event.target.parentNode.getElementsByTagName('input')[0].focus();
		setShowList(true);
		onChangeSearchTerm({ target: { value: '' } });
	};

	/**
	 * Hook que fecha o componente de seleção ao clicar fora dele
	 */
	const useOutsideAlerter = (refInner, refOuter) => {
		useEffect(() => {
			// Fecha componente de seleção ao clicar fora da área dos REFS indicados
			const handleClickOutside = event => {
				if (refOuter.current && !refOuter.current.contains(event.target)) {
					if (refInner.current && !refInner.current.contains(event.target)) {
						setShowList(false);
					}
				}
			};
			// Bind do event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind do event listener no clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [refInner, refOuter]);
	};
	useOutsideAlerter(wrapperInnerRef, wrapperOuterRef);

	const propDescricao = obj =>
		(typeof detailDescricao === 'string'
			? detailDescricao
			: Array.isArray(detailDescricao)
			? detailDescricao.reduce((acc, prop) => acc || (get(obj, prop) ? prop : null), null)
			: null) || 'descricao';

	const descricao = get(selected, propDescricao(selected));

	return (
		<>
			<div className="input-group-lic">
				{hasSelected && (
					<>
						{multiple ? (
							selectedList.map(selected => {
								const descSelected = get(selected, propDescricao(selected));
								const descricao =
									descriptionSubstringLength &&
									descriptionSubstringLength > 3 &&
									size(descSelected) > descriptionSubstringLength
										? `${descSelected.substring(0, descriptionSubstringLength - 3)}...`
										: descSelected;
								return (
									<div className={`title-with-button${readOnly ? ' read-only' : ''}`} key={selected?.id || uuid()}>
										<ListItem
											innerClassName={detailInnerClassName}
											codigo={get(selected, detailCodigo)}
											descricao={descricao}
											title={descSelected}
											data={detailData ? get(selected, detailData) : null}
											icon={
												detailIcon ? (get(selected, 'tipo') === 'formulario-especifico' ? 'wpforms' : detailIcon) : null
											}
											iconStyle={
												detailIcon
													? get(selected, 'tipo') === 'formulario-especifico'
														? { marginRight: '8px' }
														: {}
													: null
											}
											iconTooltipText={
												detailIcon
													? get(selected, 'tipo') === 'formulario-especifico'
														? 'Formulário  Específico'
														: null
													: null
											}
											modifier={detailModifier}
											showHyphen={showHyphen}
										/>
										{!readOnly && (
											<Tippy
												key={`tltp-${selected?.id || uuid()}`}
												content={multiple ? 'excluir item' : 'modificar item'}
												placement="right"
												animation="fade"
												duration={200}
											>
												<button
													type="button"
													onClick={onUnselect(selected)}
													aria-label={multiple ? 'excluir item' : 'modificar item'}
												>
													<i className={`fa fa-${multiple ? 'trash' : 'exchange'}`} aria-hidden="true" />
												</button>
											</Tippy>
										)}
									</div>
								);
							})
						) : (
							<div className="input-with-icon">
								<Tippy content={descricao} placement="top" animation="fade" duration={200}>
									<input
										type="text"
										readOnly={true}
										className={detailInnerClassName}
										required={required}
										value={
											descriptionSubstringLength &&
											descriptionSubstringLength > 3 &&
											size(descricao) > descriptionSubstringLength
												? `${descricao.substring(0, descriptionSubstringLength - 3)}...`
												: descricao || ''
										}
									/>
								</Tippy>
								{!readOnly && (
									<Tippy
										key={`tltp-${uuid()}`}
										content={multiple ? 'excluir item' : 'modificar item'}
										placement="right"
										animation="fade"
										duration={200}
									>
										<i
											aria-label={multiple ? 'excluir item' : 'modificar item'}
											onClick={onUnselect(selected)}
											className={`fa fa-${multiple ? 'trash' : 'exchange'}`}
											aria-hidden="true"
											role="button"
										/>
									</Tippy>
								)}
							</div>
						)}
						{!multiple && <ErrorMessages errorList={errorList} />}
					</>
				)}
				{(multiple || !hasSelected) && (
					<>
						<div className="floating-list-wrapper">
							<div className="input-with-icon" ref={wrapperOuterRef}>
								<input
									ref={searchTermRef}
									className={className}
									label={label}
									required={required}
									readOnly={!!readOnly}
									type="text"
									value={searchTerm || ''}
									onKeyDown={handleKeyDown}
									// onChange={onChangeSearchTerm}
									onChange={readOnly ? () => undefined : event => handleOnChange(event)}
									// onBlur={readOnly ? () => undefined : event => handleOnBlur(event ? event : onBlurSearchTerm)}
									onFocus={readOnly ? () => undefined : event => handleOnFocus(event)}
									// onFocus={onBlurSearchTerm}
									placeholder={placeholder}
									style={readOnlyStyle || {}}
								/>
								{!noResetList && !readOnly && !loading && (
									<i className="fa fa-angle-down" onClick={event => handleArrowBtn(event)} />
								)}
								{loading && <i className="fa fa-refresh fa-spin" />}
							</div>
							{!readOnly && showList && (
								<div className="input-floating-list" ref={wrapperInnerRef}>
									<p className="results-count">
										{size(searchList) === 0
											? loading
												? 'Pesquisando...'
												: 'Nenhum item encontrado'
											: size(searchList) === 1
											? 'Apenas um item na lista'
											: `Selecione um dos ${size(searchList)} itens`}
									</p>
									{size(searchList) > 0 && (
										<ul className="results-list">
											{searchList.map((item, index) => (
												<li
													key={index}
													className="results-item"
													onClick={e => {
														e.stopPropagation();
														const innerCallback = onSelectItem(item);
														if (innerCallback && typeof innerCallback === 'function') {
															innerCallback();
														}
														setShowList(false);
													}}
												>
													<ListItem
														innerClassName={detailInnerClassName}
														codigo={get(item, detailCodigo)}
														descricao={get(item, propDescricao(item))}
														data={detailData ? get(item, detailData) : null}
														icon={
															detailIcon
																? get(item, 'tipo') === 'formulario-especifico'
																	? 'wpforms'
																	: detailIcon
																: null
														}
														iconStyle={
															detailIcon
																? get(item, 'tipo') === 'formulario-especifico'
																	? { marginRight: '8px' }
																	: {}
																: null
														}
														iconTooltipText={
															detailIcon
																? get(item, 'tipo') === 'formulario-especifico'
																	? 'Formulário  Específico'
																	: null
																: null
														}
														modifier={detailModifier}
														showHyphen={showHyphen}
													/>
												</li>
											))}
										</ul>
									)}
								</div>
							)}
						</div>
						<ErrorMessages errorList={errorList} />
						{warning && <ErrorMessages errorList={['Nenhum item foi selecionado']} warning />}
					</>
				)}
			</div>
		</>
	);
};
Selecao.displayName = 'Selecao';

Selecao.propTypes = {
	className: PropTypes.string,
	readOnly: PropTypes.bool,
	multiple: PropTypes.bool,
	selected: PropTypes.any,
	label: PropTypes.string,
	required: PropTypes.bool,
	detailInnerClassName: PropTypes.string,
	detailCodigo: PropTypes.string,
	detailDescricao: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	detailData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	detailIcon: PropTypes.string,
	detailModifier: PropTypes.func,
	searchTerm: PropTypes.string,
	searchList: PropTypes.arrayOf(PropTypes.object),
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	warning: PropTypes.bool,
	searchTermRef: PropTypes.any,
	onUnselect: PropTypes.func,
	onChangeSearchTerm: PropTypes.func,
	onBlurSearchTerm: PropTypes.func,
	onSelectItem: PropTypes.func,
	searchTermMinLength: PropTypes.number,
	descriptionSubstringLength: PropTypes.number,
	autoShowList: PropTypes.bool,
	noResetList: PropTypes.bool,
	loading: PropTypes.bool,
	placeholder: PropTypes.string,
	showHyphen: PropTypes.bool,
	readOnlyStyle: PropTypes.object
};

export default Selecao;
