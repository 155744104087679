import React, { useEffect, useState } from 'react';
import './style/HistoricoRespostaAnterior.scss';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { size } from 'lodash';

const HistoricoRespostaAnterior = ({
	dataAnterior,
	dataAtual,
	usuarioInterno = false,
	msgAlt = null,
	compacto = false
}) => {
	const [respostaAnterior, setRespostaAnterior] = useState(null);
	const [respostaAtual, setRespostaAtual] = useState(null);

	const ajustarValores = respostaAux => {
		if (Object.prototype.hasOwnProperty.call(respostaAux, 'data')) {
			return respostaAux.data;
		} else if (Object.prototype.hasOwnProperty.call(respostaAux, 'value')) {
			return respostaAux.value;
		} else if (typeof respostaAux === 'object' && !Array.isArray(respostaAux) && respostaAux != null) {
			return Object.values(respostaAux)
				.map(tp => tp['descricao'])
				.toString();
		} else {
			return respostaAux === 'nao' ? 'Não' : respostaAux === 'sim' ? 'Sim' : respostaAux;
		}
	};

	useEffect(() => {
		if (!respostaAnterior && dataAnterior != null) {
			setRespostaAnterior(ajustarValores(dataAnterior).toString());
		}
	}, [dataAnterior, respostaAnterior]);

	useEffect(() => {
		if (!respostaAtual && dataAtual != null) {
			setRespostaAtual(ajustarValores(dataAtual).toString());
		}
	}, [dataAtual, respostaAtual]);

	return usuarioInterno ? (
		<>
			{size(respostaAnterior) > 0 && respostaAnterior !== respostaAtual ? (
				<div className={`warning-message hra-wrapper${compacto ? ' warning-compacto' : ''}`}>
					<Tippy
						placement="top"
						content={
							<div className="warning-tooltip">
								Este valor foi alterado pelo requerente na última complementação
								{compacto ? (
									<>
										<br />
										<br />
										Anterior: <strong>{msgAlt ? msgAlt : respostaAnterior}</strong>
									</>
								) : (
									''
								)}
							</div>
						}
					>
						<i className="fa fa-exclamation-triangle" title="Esta resposta foi alterada pelo requerente."></i>
					</Tippy>
					&nbsp;
					{!compacto && <strong>{msgAlt ? msgAlt : respostaAnterior}</strong>}
				</div>
			) : !respostaAnterior && size(respostaAtual) > 0 ? (
				<div className={`info-message hra-wrapper${compacto ? ' warning-compacto' : ''}`}>
					<Tippy
						placement="top"
						content={
							<div className="warning-tooltip">
								Este valor foi adicionado pelo requerente ou automaticamente pelo sistema na última complementação
								{compacto ? (
									<>
										<br />
										<br />
										Anterior: <strong>{msgAlt ? msgAlt : 'novo'}</strong>
									</>
								) : (
									''
								)}
							</div>
						}
					>
						<i className="fa fa-exclamation-circle" title="Esta resposta foi adicionada pelo requerente."></i>
					</Tippy>
					&nbsp;
					{!compacto && <strong>{msgAlt ? msgAlt : 'novo'}</strong>}
				</div>
			) : null}
		</>
	) : null;
};
HistoricoRespostaAnterior.displayName = 'HistoricoRespostaAnterior';

HistoricoRespostaAnterior.propTypes = {
	dataAnterior: PropTypes.any,
	dataAtual: PropTypes.any,
	usuarioInterno: PropTypes.bool,
	msgAlt: PropTypes.string,
	compacto: PropTypes.bool
};

export default HistoricoRespostaAnterior;
