import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { get, unset } from 'lodash';
import moment from 'moment';

import { disabledPast } from 'components/Calendario';

import { getMomentDate, isDebug } from 'utils/tools';

import CurrencyFieldAverbacao from '../fields/currencyFieldAverbacao';
import Calendario from './components/Calendario';
import CalendarioRange from './components/CalendarioRange';
import FieldList from './components/FieldList';
import { validate as validateFieldList } from './components/FieldList';
import TimePickerField from './components/TimePicker';
import {
	CdlField,
	CdlListField,
	CheckboxField,
	CpfCnpjField,
	EmailField,
	ExpedienteEnderecosField,
	ExpedienteEnderecosFieldBox,
	MapField,
	NumberField,
	NumeroDamField,
	PhoneField,
	ProjetosExpedienteField,
	RadioField,
	ResponsavelTecnicoField,
	ResponsavelTecnicoNovoField,
	SelectField,
	TextAreaField,
	TextField,
	UFSelectField,
	TypeAheadField,
	VistoriasExpedienteField,
	SelecaoField
} from './fields';
import { validate as validateCpfCnpj } from './fields/cpfCnpjField';
import { validate as validateEmail } from './fields/emailField';
import { validate as validateExpediente } from './fields/expedienteEnderecosField';
import { validate as validateDam } from './fields/numeroDamField';
import { validate as validatePhone } from './fields/phoneField';
import { validate as validateProjetosExpediente } from './fields/projetosExpediente';
import { validate as validateRT } from './fields/responsavelTecnicoField';
import { validate as validateRTNovo } from './fields/responsavelTecnicoNovoField';

const debug = false && isDebug;
export function TemplateField(props) {
	const {
		controlName,
		attributes,
		onErrorHandler,
		onChangeHandler,
		isInvalid,
		consultaPublicaExpedientes,
		obterDadosProjetosLicencasExpediente,
		allErrors,
		cdlListFieldAux
	} = props;

	// change handler
	const changeInput = useCallback(
		e => {
			if (Array.isArray(e)) {
				const [valid, invalid] = e;
				onErrorHandler(invalid.value);
				onChangeHandler({ key: valid.name, value: valid.value });
			} else if (typeof e === 'object') {
				const { name, value } = e;
				if (name === 'errors') {
					onErrorHandler(e.value);
				} else {
					onChangeHandler({ key: name, value });
				}
			} else {
				console.error(`Tipo de retorno do Field não suportado. Tipo: ${typeof e}; Valor: `, JSON.stringify(e));
			}
		},
		[onChangeHandler, onErrorHandler]
	);
	// change especifico para dates
	const changeDate = useCallback(
		e => {
			let timestamp;
			if (e && e instanceof moment) {
				timestamp = e.valueOf();
			} else if (e && e instanceof Date) {
				timestamp = e.getTime();
			} else if (e && Array.isArray(e)) {
				if (e[0] && e[1] && e.every(v => v instanceof moment)) {
					timestamp = [e[0].valueOf(), e[1].valueOf()];
				}
			} else {
				timestamp = e;
			}
			onChangeHandler({ key: controlName, value: timestamp });
		},
		[onChangeHandler, controlName]
	);
	// change especifico para expedientes
	const changeExpedientes = useCallback(
		e => {
			if (Array.isArray(e)) {
				if (e.length <= 2) {
					//mantem o comportamento padrao
					changeInput(e);
				} else {
					// [expediente, logradouros, erros]
					let demais = [];
					for (let i = 0; i < e.length; i++) {
						const item = e[i];
						if (item.name === 'errors') {
							onErrorHandler(item.value);
						} else {
							demais.push({ key: item.name, value: item.value });
						}
					}
					onChangeHandler(demais);
				}
			} else {
				changeInput(e);
			}
		},
		[onChangeHandler, onErrorHandler, changeInput]
	);

	// manipular os atributos do field
	let localAttrs = {
		...attributes,
		forTemplate: true,
		isInvalid,
		onChangeHandler: changeInput,
		name: controlName,
		value: getValueFromField(attributes) || '',
		templateErrors: get(allErrors, controlName)
	};
	let localAttrsAux = {
		...cdlListFieldAux,
		forTemplate: true,
		hideTitle: true,
		smallTitle: true,
		value: getValueFromField(cdlListFieldAux) || '',
		cdlListFieldAux,
		onChangeHandler: changeInput,
		isInvalid,
		consultaPublicaExpedientes,
		obterDadosProjetosLicencasExpediente,
		allErrors
	};

	switch (localAttrs.type) {
		case 'infoField':
		case 'info':
			unset(attributes, 'required');
			return (
				<>
					<div className="alert alert-info" role="alert">
						<span
							dangerouslySetInnerHTML={{
								__html: localAttrs.info
							}}
						></span>
						{debug && <span className="debug-message">{controlName}</span>}
					</div>
				</>
			);
		case 'timeField':
		case 'time':
			return (
				<>
					<label className="control-label">
						{localAttrs.label}
						{localAttrs.required && <span className="required">*</span>}
					</label>
					<TimePickerField {...localAttrs} onChange={changeDate} inputClass={'form-control'}></TimePickerField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'dateField':
		case 'date':
			localAttrs = { ...localAttrs, value: getMomentDate(localAttrs.value) };
			return (
				<>
					<label className="control-label">
						{localAttrs.label}
						{localAttrs.required && <span className="required">*</span>}
					</label>
					<Calendario {...localAttrs} onChange={changeDate} inputClass={'form-control'} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'dateRangeField':
		case 'dateRange':
			if (localAttrs.value && Array.isArray(localAttrs.value)) {
				localAttrs.value = localAttrs.value.map(getMomentDate);
			} else {
				localAttrs.value = [];
			}
			if (localAttrs.disabledPast && !localAttrs.disableIf) {
				delete localAttrs.disabledPast;
				localAttrs.disabledDate = disabledPast;
			}
			return (
				<>
					<label className="control-label">
						{localAttrs.label}
						{localAttrs.required && <span className="required">*</span>}
					</label>
					<CalendarioRange {...localAttrs} onChange={changeDate} inputClass={'form-control'} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'textField':
		case 'text':
			return (
				<>
					<TextField {...localAttrs}></TextField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'phoneField':
		case 'phone':
			attributes.validate = ({ value, required, label }) => validatePhone(value, required, label);
			return (
				<>
					<PhoneField {...localAttrs}></PhoneField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'cpfCnpjField':
		case 'cpfCnpj':
			attributes.validate = ({ value, label, required, cpf, cnpj }) =>
				validateCpfCnpj(value, required, label, cpf, cnpj);
			return (
				<>
					<CpfCnpjField {...localAttrs}></CpfCnpjField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'emailField':
		case 'email':
			attributes.validate = ({ value, required, label }) => validateEmail(value, required, label);
			return (
				<>
					<EmailField {...localAttrs}></EmailField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'cdlField':
		case 'cdl':
			localAttrs = { ...localAttrs, value: localAttrs.value || undefined };
			return (
				<>
					<CdlField {...localAttrs}></CdlField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'cdlListField':
		case 'cdlList':
			localAttrs = { ...localAttrs, value: localAttrs.value || undefined };
			return (
				<>
					{cdlListFieldAux ? null : <CdlListField {...localAttrs} />}
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'responsavelTecnicoField':
		case 'responsavelTecnico':
			attributes.validate = ({ value, required, label, title }) => validateRT(value, required, label, title);
			localAttrs = { ...localAttrs, value: localAttrs.value || {} };
			return (
				<>
					<ResponsavelTecnicoField {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'responsavelTecnicoNovoField':
		case 'responsavelTecnicoNovo':
			attributes.validate = ({ value, required, label, title }) => validateRTNovo(value, required, label, title);
			localAttrs = { ...localAttrs, value: localAttrs.value || {} };
			return (
				<>
					<ResponsavelTecnicoNovoField {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'numeroDamField':
		case 'numeroDam':
			attributes.validate = ({ value, required, label }) => validateDam(value, required, label);
			return (
				<>
					<NumeroDamField {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'expedienteEnderecosFieldBox':
		case 'expedienteEnderecosField':
		case 'expedienteEnderecos':
			localAttrsAux = { ...localAttrsAux, name: localAttrsAux.name, value: localAttrsAux.value || undefined };
			attributes.validate = ({ value, required = [false, false], label, title }) =>
				validateExpediente(value, required, label, title);
			localAttrs = {
				...localAttrs,
				value: localAttrs.value || {},
				consultaPublicaExpedientes,
				obterDadosProjetosLicencasExpediente,
				onChangeHandler: changeExpedientes,
				localAttrsCdlListField: CdlListField
			};

			return (
				<>
					{localAttrs.type === 'expedienteEnderecosFieldBox' ? (
						<ExpedienteEnderecosFieldBox {...localAttrs} localAttrsAux={localAttrsAux} />
					) : (
						<ExpedienteEnderecosField {...localAttrs} />
					)}
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'textAreaField':
		case 'textArea':
			return (
				<>
					<TextAreaField {...localAttrs}></TextAreaField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'checkBoxField':
		case 'checkBox':
			localAttrs = { ...localAttrs, value: localAttrs.value || false };
			return (
				<>
					<CheckboxField {...localAttrs}></CheckboxField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'radioField':
		case 'radio':
			localAttrs = { ...localAttrs };
			return (
				<>
					<RadioField {...localAttrs}></RadioField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'numberField':
		case 'number':
			localAttrs = { ...localAttrs, value: localAttrs.value || {} };
			return (
				<>
					<NumberField {...localAttrs}></NumberField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'fieldList':
			attributes.validate = formfield => validateFieldList(formfield);
			return (
				<>
					<FieldList {...props}></FieldList>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'selectField':
		case 'select':
			return (
				<>
					<SelectField {...localAttrs}></SelectField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'selecaoField':
		case 'selecao':
			return (
				<>
					<SelecaoField {...localAttrs}></SelecaoField>
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'dataField':
		case 'data':
			return (
				<>
					<input hidden={localAttrs.hidden} name={controlName} readOnly defaultValue={localAttrs.value} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'ufField':
		case 'uf':
			return (
				<>
					<UFSelectField {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'typeahead':
			return (
				<>
					<TypeAheadField {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'vistoriasExpediente':
			return (
				<>
					<VistoriasExpedienteField {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'projetosExpediente':
			attributes.validate = ({ value, label, required, ultimo }) =>
				validateProjetosExpediente(value, label, required, ultimo);
			return (
				<>
					<ProjetosExpedienteField {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'mapField':
		case 'map':
			return (
				<>
					<MapField {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		case 'currencyFieldAverbacao':
			return (
				<>
					<CurrencyFieldAverbacao {...localAttrs} />
					{debug && <span className="debug-message">{controlName}</span>}
				</>
			);
		default:
			return (
				<>
					<span>
						Invalid input type <strong>{localAttrs.type}</strong>
					</span>
				</>
			);
	}
}

TemplateField.displayName = 'TemplateField';
TemplateField.propTypes = {
	controlName: PropTypes.string,
	attributes: PropTypes.object,
	onErrorHandler: PropTypes.func,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool,
	consultaPublicaExpedientes: PropTypes.bool,
	obterDadosProjetosLicencasExpediente: PropTypes.bool,
	allErrors: PropTypes.object,
	cdlListFieldAux: PropTypes.object
};
export default TemplateField;

function getValueFromField(attribs) {
	if (attribs == null) return undefined;
	if (attribs.value == null) return undefined;

	const value = attribs.value;
	if (Array.isArray(value)) return value.length > 0 ? value : undefined;
	if (typeof value === 'object') return Object.keys(value).length > 0 ? value : undefined;

	return `${value}`.trim().length > 0 ? value : undefined;
}
