export const TABELA_RESTRICAO_IMPLANTACAO = [
	{ codigo: '1', descricao: 'ATIVIDADES COM IMPLANTAÇÃO PERMITIDA.' },
	{ codigo: '2', descricao: 'ATIVIDADES COM IMPLANTAÇÃO PROIBIDA.', forceRestricaoEVU: true },
	{
		codigo: '06',
		descricao:
			'PARA ESTE GRUPAMENTO DE ATIVIDADE DEVERÁ CONSULTAR A LC 630/2009 - OPERAÇÃO URBANA CONSORCIADA LOMBA DO PINHEIRO PARA VERIFICAR A POSSIBILIDADE DE IMPLANTAÇÃO DA ATIVIDADE (PERMITIDA/PROIBIDA/COM RESTRIÇÕES).'
	},
	{
		codigo: '07',
		descricao:
			'PARA ESTA ATIVIDADE VERIFICAR O ANEXO 5.9 DO PDDUA OU A POSSIBILIDADE DE IMPLANTAÇÃO DA ATIVIDADE MEDIANTE CONSULTA À EQUIPE DE PROJETOS ESPECIAIS I (EPE I) - PORTAL DE LICENCIAMENTO (https://licenciamento.procempa.com.br/).'
	},
	{
		codigo: '08',
		descricao:
			'"A ATIVIDADE FUNERÁRIA TEM IMPLANTAÇÃO PROIBIDA; A ATIVIDADES BAR, CAFÉ, LANCHERIA E RESTAURANTE SÃO PERMITIDAS, COM HORÁRIO DE FUNCIONAMENTO RESTRITO - ATÉ ÀS 22H E AS DEMAIS ATIVIDADES TÊM IMPLANTAÇÃO PERMITIDA."'
	},
	{
		codigo: '09',
		descricao:
			'AS ATIVIDADES MOTEL, SAUNA, DUCHA E TERMA TÊM IMPLANTAÇÃO PROIBIDA E AS DEMAIS ATIVIDADES TÊM IMPLANTAÇÃO PERMITIDA.'
	},
	{
		codigo: '10',
		descricao:
			'AS ATIVIDADES TRANSPORTADORA E EMPRESA DE MUDANÇA TÊM IMPLANTAÇÃO PROIBIDA E AS DEMAIS ATIVIDADES TÊM IMPLANTAÇÃO PERMITIDA.'
	},
	{ codigo: '11', descricao: 'POSSIBILIDADE DE IMPLANTAÇÃO ATRAVÉS DE EVU - ESTUDO DE VIABILIDADE URBANÍSTICA.' },
	{
		codigo: '12',
		descricao:
			'CLASSIFICAÇÃO DA ATIVIDADE MEDIANTE CONSULTA À EQUIPE DE PROJETOS ESPECIAIS I (EPE I) - PORTAL DE LICENCIAMENTO (https://licenciamento.procempa.com.br/).'
	},
	{
		codigo: '13',
		descricao: 'IMPLANTAÇÃO ANALISADA CASO A CASO, MEDIANTE EVU (ESTUDO DE VIABILIDADE URBANÍSTICA) - CAUGE.'
	},
	{
		codigo: '14',
		descricao:
			'PARA ESTE GRUPAMENTO DE ATIVIDADE O REGIME URBANÍSTICO É DEFINIDO MEDIANTE EVU (ESTUDO DE VIABILIDADE URBANÍSTICA), ATRAVÉS DA ANÁLISE DA PROPOSTA, CONFORME ART. 73 E ART. 94 DO PDDUA.'
	},
	{
		codigo: '15',
		descricao:
			'PARA ESTE GRUPAMENTO DE ATIVIDADE DEVERÁ CONSULTAR À EQUIPE DE GESTÃO DE INFORMAÇÃO (SMAMUS/DAV/CPO) PARA VERIFICAR A POSSIBILIDADE DE IMPLANTAÇÃO DA ATIVIDADE (PERMITIDA/PROIBIDA/COM RESTRIÇÕES) ATRAVÉS DO E-MAIL - egi.smams@portoalegre.rs.gov.br.'
	},
	{
		codigo: '16',
		descricao:
			'PARA ESTE GRUPAMENTO DE ATIVIDADE, CASO NÃO POSSUA DEFINIÇÃO DO REGIME URBANÍSTICO ATRAVÉS DE LEGISLAÇÃO ESPECÍFICA, A PROPOSTA SERÁ ANALISADA MEDIANTE EVU (ESTUDO DE VIABILIDADE URBANÍSTICA), CONFORME ART. 73 E ART. 94 DO PDDUA.'
	},
	{
		codigo: '17',
		descricao:
			'PARA ESTE GRUPAMENTO DE ATIVIDADE, LOCALIZADO NA ÁREA DE OCUPAÇÃO RAREFEITA, DEVERÁ CONSULTAR O ANEXO 5.5 DO PDDUA PARA VERIFICAR A POSSIBILIDADE DE IMPLANTAÇÃO DA ATIVIDADE (PERMITIDA/PROIBIDA/COM RESTRIÇÕES).'
	},
	{
		codigo: '18',
		descricao:
			'PARA ESTE G.A. DEVERÁ CONSULTAR LEGIS. ESPECIF. INFORMADA NA DMI PARA VERIF. A POSSIB. DE IMPL. DA ATIV. CASO NÃO POSSUA DEFINIÇÃO DO REG. URB., OU TENHA MATR. DO IMÓVEL EMITIDA ANTERIORMENTE A DEFINIÇÃO DA AEIS, CONSULTAR A ERF-PORTAL DE LICENCIAMENTO.'
	}
];

export const TABELA_PORTE = [
	{ codigo: '01', descricoes: ['SEM LIMITE'] },
	{ codigo: '02', descricoes: ['PROIBIDO'] },
	{ codigo: '03', descricoes: ['200 m2', '200 m²'] },
	{ codigo: '04', descricoes: ['300 m2', '300 m²'] },
	{ codigo: '05', descricoes: ['400 m2', '400 m²'] },
	{ codigo: '06', descricoes: ['500 m2', '500 m²'] },
	{ codigo: '07', descricoes: ['1000 m2', '1000 m²'] },
	{ codigo: '08', descricoes: ['1500 m2', '1500 m²', '1500'] },
	{ codigo: '09', descricoes: ['2000 m2', '2000 m²'] },
	{ codigo: '10', descricoes: ['5000 m2', '5000 m²'] },
	{
		codigo: '12',
		descricoes: [
			'Oficinas: 500 m² Demais atividades: SEM LIMITE',
			'Oficinas: 500 m² - Demais atividades: SEM LIMITE',
			'Oficinas: 500 m² -  Demais atividades: SEM LIMITE'
		]
	},
	{
		codigo: '13',
		descricoes: [
			'Oficinas: 200 m² Demais atividades: SEM LIMITE',
			'Oficinas: 200 m² Demais atividades: 1.500 m²',
			'Oficinas: 200 m² -  Demais atividades: 1.500 m²'
		]
	},
	{ codigo: '14', descricoes: ['REQUER EVU'] },
	{ codigo: '15', descricoes: ['VERIFICAR'] },
	{
		codigo: '16',
		descricoes: [
			'3.2.31 assistência técnica de equipamentos domésticos, 3.2.32 borracharia; 3.2.37 estofaria; 3.2.39 lavagem e lubrificação; 3.2.40 manutenção e reparação máquinas: 500 m² Demais atividades: 1.500 m²'
		]
	},
	{ codigo: '17', descricoes: ['Ver Lei Complementar 462/01 e alterações posteriores'] },
	{ codigo: '18', descricoes: ['PROIBIDO (Atividade residencial possibilitada através de Projeto Especial)'] }
];

export const TABELA_INTERFERENCIA_AMBIENTAL = [
	{ codigo: '01', descricao: '1' },
	{ codigo: '02', descricao: '2.1.1' },
	{ codigo: '03', descricao: '2.1.2' },
	{ codigo: '04', descricao: '2.1.3' },
	{ codigo: '13', descricao: '2.2.1' },
	{ codigo: '05', descricao: '2.2.2' },
	{ codigo: '06', descricao: '2.2.3' },
	{ codigo: '07', descricao: '3.1' },
	{ codigo: '08', descricao: '3.2' },
	{ codigo: '09', descricao: '3.3' },
	{ codigo: '10', descricao: '3.4' },
	{ codigo: '11', descricao: '4.1' },
	{ codigo: '12', descricao: '4.2' },
	{ codigo: '16', descricao: '5' }
];

export const TABELA_FILTRO_INTERFERENCIA_AMBIENTAL = [
	{ inicios: ['1.1.', '1.2.', '1.3.', '1.4.'], codigos: ['1'] },
	{ inicios: ['2.1.'], codigos: ['2', '3', '4'] },
	{ inicios: ['2.2.'], codigos: ['5', '6'] },
	{ inicios: ['3.1.'], codigos: ['7'] },
	{ inicios: ['3.2.'], codigos: ['8'] },
	{ inicios: ['3.3.'], codigos: ['9'] },
	{ inicios: ['3.4.'], codigos: ['10'] },
	{ inicios: ['4.1.'], codigos: ['11'] },
	{ inicios: ['4.2.'], codigos: ['12'] },
	{ inicios: ['5.'], codigos: ['16'] }
];

export const ATIVIDADES_EVU = [
	'4.1.2.',
	'4.2.',
	'5.',
	'5.1.',
	'5.2.',
	'5.3.',
	'5.4.',
	'5.5.',
	'5.6.',
	'5.7.',
	'5.8.',
	'5.9.',
	'5.10.',
	'5.11.',
	'5.12.',
	'5.13.',
	'5.14.',
	'5.15.',
	'5.16.'
];
