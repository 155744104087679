import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import Loader from 'components/Loader';

import { TIPO_FORMULARIO_ETR_NA_HORA, TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO } from 'containers/Form/Detalhe';

import { accessApi } from 'utils/injectApi';

import TemplateForm from '../metadata-template/TemplateForm';

export function AbaDamForm({
	formulario,
	readOnly,
	formData,
	showNext = true,
	onSubmit = () => false,
	onChange = () => false
}) {
	const showDamNoRequerimento = get(formulario, 'mostrarDamRequerimento', false) === true;

	const [loading, setLoading] = useState(true);
	const [formFields, setFormFields] = useState(null);
	const [data, setData] = useState(formData?.data);
	const [errorList, setErrorsList] = useState();
	const [type, setType] = useState(get(formulario, 'idTipoFormulario', ''));

	useEffect(() => {
		const tp = get(formulario, 'idTipoFormulario', '');
		setType(tp);
	}, [formulario]);

	useEffect(() => {
		async function carregaMetadata() {
			setLoading(true);
			try {
				const url = `collections/metadata/${type}`;
				const retorno = (await accessApi(url))?.data;
				const meta = retorno || {};
				// const fields = get(retorno, '0.fieldsets', get(retorno, '0.fields', {}));
				const flds = meta.fieldsets || meta.fields || {};
				const fsets = Array.isArray(flds) ? flds : [{ fields: flds }];
				const apenasDam = {};

				for (const fset of fsets) {
					const e = Object.entries(fset.fields).filter(([k, v]) => {
						if (['numeroDamField', 'numeroDam'].includes(v?.type)) return true;
						if ('valorDam' === k || v.usage === 'dam_value') return true;
						return false;
					});
					if (e && e.length) {
						const ndam = e[0];
						const vdam = e[1];
						if (ndam) {
							apenasDam[ndam[0]] = ndam[1];
						}
						if (vdam) {
							apenasDam[vdam[0]] = vdam[1];
						}
						break;
					}
				}
				let form = size(apenasDam) ? [{ fields: apenasDam }] : undefined;
				setFormFields(form);
			} catch (e) {
				setFormFields({});
				console.error(`Erro no acesso aos metadados da ${type}: `, e);
			} finally {
				setLoading(false);
			}
		}

		carregaMetadata();
	}, [type]);

	useEffect(() => {
		if (formData) {
			setData(formData?.data);
		}
	}, [formData]);

	const changeHandler = obj => {
		if (Array.isArray(obj)) {
			const newData = Object.assign({}, data);
			let modified = false;
			obj.forEach(o => {
				modified = true;
				newData[o.key] = o.value;
			});
			if (modified) {
				setData(newData);
				onChange(newData);
			}
		} else {
			// recebe como parametro um objeto assim: { fieldSetName, key, value };
			const { key, value } = obj;
			const newData = Object.assign({}, data, { [key]: value });
			setData(newData);
			onChange(newData);
		}
	};

	const submitHandler = result => {
		const [ok, errorList, customErrors] = result;
		if (readOnly) {
			setErrorsList(null);
			onSubmit();
		} else if (ok) {
			setErrorsList(null);
			onSubmit(data);
		} else if (size(errorList) === 0 && customErrors?.numeroDam) {
			setErrorsList(errorList);
		} else {
			setErrorsList(size(customErrors) > 0 ? customErrors : errorList);
		}
	};

	const getCustomValidates = useCallback(() => {
		if (type === TIPO_FORMULARIO_ETR_NA_HORA || type === TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO) {
			return fields => {
				const nok = false;
				let value = get(fields, ['valorDam', 'value']);
				value = get(value, 'value');
				if (parseFloat(value) < 3000) {
					return [nok, ['Deve ser paga a taxa DAM correspondente a 800 UFMs'], 'valorDam'];
				}
				return undefined;
			};
		}
		return undefined;
	}, [type]);

	const readonly = readOnly === true;
	const disable = readOnly === true;

	if (showDamNoRequerimento) {
		return <h3>DAM deve ser preenchida no requerimento solicitado.</h3>;
	}

	return (
		<>
			{loading ? (
				<Loader msg={`Carregando formulário DAM de ${type}`} />
			) : (
				<>
					{!formFields && <h3>Esse requerimento não necessita de DAM.</h3>}
					<TemplateForm
						fieldsets={formFields}
						onChangeHandler={changeHandler}
						onSubmit={submitHandler}
						validateForm={getCustomValidates()}
						formData={data}
						readOnly={readonly}
						disabled={disable}
						submitLabel={showNext ? 'Avançar' : 'Salvar'}
						customErrors={errorList}
					/>
				</>
			)}
		</>
	);
}
export default AbaDamForm;

AbaDamForm.displayName = 'AbaDamForm';
AbaDamForm.propTypes = {
	formulario: PropTypes.object,
	readOnly: PropTypes.bool,
	formData: PropTypes.object,
	showNext: PropTypes.bool,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func
};
