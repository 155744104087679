/**
 *
 * Este arquivo deve ser igual nos projetos
 * licenciamento (portal) e licenciamento-admin
 *
 * Havendo necessidade de constantes específicas, usar
 * o arquivo enums.js
 *
 */

import { join } from 'lodash';

import { ADDRESS_IGNORED_WORDS } from './words';

const joinedStopWords = join(ADDRESS_IGNORED_WORDS, '|');
const regexAddressPattern = `\\b(?:${joinedStopWords}\\b\\s*)`;
export const REGEX_ADDRESS = new RegExp(regexAddressPattern, 'ig');
export const REGEX_NUMBERS_ONLY = new RegExp('^[0-9]*$');

export const TIPO_PROPRIETARIO_OUTROS = 'Proprietário (OUTROS)';
// Retirada do campo de Outra Especificação
/* export const TIPO_REQUERENTE_ESPECIFICACAO_OUTRA = 'outra'; */
export const TIPO_REQUERENTE_TIPO_OUTRO = 'Outro';
export const TIPO_AUTORIZACAO_PRAZO = 'Prorrogação de prazo até';
export const TIPO_AUTORIZACAO_OUTROS = 'Outros';

// Valida numeros de telefone no formato (00) 00000-0000 ou (00) 0000-0000
export const REGEX_TELEFONE = new RegExp('^\\([1-9]{2}\\) [2-9][0-9]{3,4}\\-[0-9]{4}$');

export const EMAIL_REGEX = new RegExp(
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const DISALLOW_SPACES_START_END_DOUBLE_REGEX = new RegExp(/^\S((?!.* {2}).*\S)?$/);

export const FORMATO_CODIGO_EU = '000.000000.00.0.00000';
export const FORMATO_CODIGO_EU_SHORT = '000.000000.00.0';

export const TELAS_QUE_ENVIAM_PARA_OUTRAS_SECRETARIAS = [
	'AP1200',
	'AP1510',
	'EV0100',
	'EV0250',
	'VS0600',
	'VS0800',
	'LI0300',
	'PE0500'
];

export const SITUACOES_SM = {
	situacoes: {
		EM_RECEBIMENTO_DISTRIBUICAO_SMAMS: 'SM0100',
		EM_ANALISE_DISTRIBUICAO_EUOS: 'SM0200',
		EM_CONSULTA_CHEFIA_EUOS: 'SM0250',
		EM_CONSULTA_CHEFIA_EAPIS: 'SM0260',
		EM_ANALISE_MEIO_BIOTICO_EUOS: 'SM0300',
		EM_COMPLEMENTACAO_SM310: 'SM0310',
		EM_CONSULTA_MEIO_BIOTICO_CHEFIA_EUOS: 'SM0320',
		EM_ANALISE_MEIO_FISICO_EUOS: 'SM0400',
		EM_COMPLEMENTACAO_SM410: 'SM0410',
		EM_CONSULTA_MEIO_FISICO_CHEFIA_EUOS: 'SM0420',
		EM_ANALISE_FAUNA_EUOS: 'SM0450',
		EM_COMPLEMENTACAO_SM460: 'SM0460',
		EM_CONSULTA_FAUNA_CHEFIA_EUOS: 'SM0470',
		EM_ANALISE_DISTRIBUICAO_EAPIS: 'SM0500',
		EM_ANALISE_MEIO_BIOTICO_EAPIS: 'SM0600',
		EM_COMPLEMENTACAO_SM610: 'SM0610',
		EM_CONSULTA_MEIO_BIOTICO_CHEFIA_EAPIS: 'SM0620',
		EM_ANALISE_MEIO_FISICO_EAPIS: 'SM0700',
		EM_COMPLEMENTACAO_SM710: 'SM0710',
		EM_CONSULTA_MEIO_FISICO_CHEFIA_EAPIS: 'SM0720',
		EM_CONSULTA_CLA: 'SM0820',
		EM_CONSULTA_DIRECAO_LICENCIAMENTO_AMBIENTAL: 'SM0850'
	},

	properties: {
		SM0100: { key: 'SM0100', value: 'Recebimento e distribuição do processo (SM0100)' },
		SM0200: { key: 'SM0200', value: 'Análise e distribuição do processo - EUOS (SM0200)' },
		SM0250: { key: 'SM0250', value: 'Consulta à chefia EUOS (SM0250)' },
		SM0260: { key: 'SM0260', value: 'Consulta à chefia EAPIS (SM0260)' },
		SM0300: { key: 'SM0300', value: 'Análise vegetação - EUOS (SM0300)' },
		SM0310: { key: 'SM0310', value: 'Em complementação (SM0310)' },
		SM0320: { key: 'SM0320', value: 'Consulta vegetação à chefia EUOS (SM0320)' },
		SM0400: { key: 'SM0400', value: 'Análise meio físico - EUOS (SM0400)' },
		SM0410: { key: 'SM0410', value: 'Em complementação (SM0410)' },
		SM0420: { key: 'SM0420', value: 'Consulta Meio físico à chefia EUOS (SM0420)' },
		SM0450: { key: 'SM0450', value: 'Análise da fauna - EUOS (SM0450)' },
		SM0460: { key: 'SM0460', value: 'Em complementação (SM0460)' },
		SM0470: { key: 'SM0470', value: 'Consulta Fauna à chefia EUOS (SM0470)' },
		SM0500: {
			key: 'SM0500',
			value: 'Distribuição Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0500)'
		},
		SM0600: { key: 'SM0600', value: 'Análise vegetação - EAPIS (SM0600)' },
		SM0610: { key: 'SM0610', value: 'Em complementação (SM0610)' },
		SM0620: { key: 'SM0620', value: 'Consulta vegetação à chefia EAPIS (SM0620)' },
		SM0700: { key: 'SM0700', value: 'Análise meio físico - EAPIS (SM0700)' },
		SM0710: { key: 'SM0710', value: 'Em complementação (SM0710)' },
		SM0720: { key: 'SM0720', value: 'Consulta Meio físico à chefia EAPIS (SM0720)' },
		SM0820: { key: 'SM0820', value: 'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)' },
		SM0850: { key: 'SM0850', value: 'Consulta à Direção do Licenciamento Ambiental (SM0850)' }
	}
};

const getAndamentosSM = destino => ({
	SM0100: {
		consultaDirecao:
			'Distribuição Coordenação de Licenciamento Ambiental - CLA (SM0100) concluída, encaminhado para Consulta Direção do Licenciamento Ambiental (SM0850)',
		naoNecessitaAnalise: `Distribuição Coordenação de Licenciamento Ambiental - CLA (SM0100) concluída, encaminhado para ${destino}`,
		euos: 'Distribuição Coordenação de Licenciamento Ambiental - CLA (SM0100) concluída, encaminhado para Análise e distribuição EUOS (SM0200)',
		eapis:
			'Distribuição Coordenação de Licenciamento Ambiental - CLA (SM0100) concluída, encaminhado para Análise e distribuição EAPIS (SM0500)'
	},
	SM0200: {
		consultaCla:
			'Distribuição Equipe de Uso e Ocupação do Solo - EUOS (SM0200) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)',
		retornaChefiaCla: 'Processo retornado para Distribuição Coordenação de Licenciamento Ambiental - CLA (SM0100)',
		concluida: 'Distribuição Equipe de Uso e Ocupação do Solo - EUOS (SM0200) concluída, encaminhado para '
		// os seguintes trechos são adicionados de acordo com a seleção na tela (separados por virgula)
		// 'Análise meio físico EUOS (SM0400)'
		// 'Análise meio biótico EUOS (SM0300)'
		// 'Análise da fauna EUOS (SM0450)'
	},
	SM0300: {
		concluida: `Análise vegetação EUOS (SM0300) concluída, encaminhado para Consolidação de pareceres EUOS e retorno para a ${destino}`,
		consultaChefeIlha:
			'Análise vegetação EUOS (SM0300) aguardando, encaminhado para Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS',
		// os seguintes trechos são adicionados à consultaChefeIlha de acordo com a versão do fluxo
		// (AP0320) - para versões anteriores à 1.4
		// (AP0250) - para versões a partir da 1.4
		devolverChefeIlha:
			'Análise vegetação EUOS (SM0300) concluída, devolvido à Distribuição Equipe de Uso e Ocupação do Solo - EUOS (SM0200)',
		complementar:
			'Análise vegetação EUOS (SM0300) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico vegetação EUOS'
	},
	SM0400: {
		consultaChefeIlha:
			'Análise meio físico EUOS (SM0400) aguardando, encaminhado para Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS',
		// os seguintes trechos são adicionados à consultaChefeIlha de acordo com a versão do fluxo
		// (AP0420) - para versões anteriores à 1.4
		// (AP0250) - para versões a partir da 1.4
		devolverChefeIlha:
			'Análise  meio físico EUOS (SM0400) concluída, devolvido à Distribuição Equipe de Uso e Ocupação do Solo - EUOS (SM0200)',
		complementar:
			'Análise meio físico EUOS (SM0400) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico meio físico EUOS',
		concluida: `Análise meio físico EUOS (SM0400) concluída, encaminhado para Consolidação de pareceres EUOS e retorno para a ${destino}`
	},
	SM0450: {
		consultaChefeIlha:
			'Análise fauna EUOS (SM0450) aguardando, encaminhado para Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS',
		// os seguintes trechos são adicionados à consultaChefeIlha de acordo com a versão do fluxo
		// (AP0470) - para versões anteriores à 1.4
		// (AP0250) - para versões a partir da 1.4
		devolverChefeIlha:
			'Análise fauna EUOS (SM0450) concluída, devolvido à Distribuição Equipe de Uso e Ocupação do Solo - EUOS (SM0200)',
		complementar:
			'Análise fauna EUOS (SM0450) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico fauna EUOS',
		concluida: `Análise fauna EUOS (SM0450) concluída, encaminhado para Consolidação de pareceres EUOS e retorno para a ${destino}`
	},
	SM0500: {
		consultaCla:
			'Distribuição Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0500) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)',
		retornaChefiaCla: 'Processo retornado para Distribuição Coordenação de Licenciamento Ambiental - CLA (SM0100)',
		fisico:
			'Distribuição Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0500) concluída, encaminhado para Análise meio físico EAPIS (SM0700)',
		biotico:
			'Distribuição Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS(SM0500) concluída, encaminhado para Análise vegetação EAPIS (SM0600)',
		ambos:
			'Distribuição Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0500) concluída, encaminhado para Análise vegetação e físico (SM0600 e SM0700)'
	},
	SM0600: {
		concluida: `Análise vegetação EAPIS (SM0600) concluída, encaminhado para Consolidação de pareceres EAPIS e retorno para a ${destino}`,
		devolverChefeIlha:
			'Análise vegetação EAPIS (SM0600) concluída, devolvido à Distribuição Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0500)',
		consultaChefeIlha:
			'Análise vegetação EAPIS (SM0600) aguardando, encaminhada para Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS',
		// os seguintes trechos são adicionados à consultaChefeIlha de acordo com a versão do fluxo
		// (AP0620) - para versões anteriores à 1.4
		// (AP0260) - para versões a partir da 1.4
		complementar:
			'Análise vegetação EAPIS (SM0600) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico vegetação EAPIS'
	},
	SM0700: {
		consultaChefeIlha:
			'Consulta encaminhada para Chefia EAPIS (SM0720) aguardando, encaminhado para Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS',
		// os seguintes trechos são adicionados à consultaChefeIlha de acordo com a versão do fluxo
		// (AP0720) - para versões anteriores à 1.4
		// (AP0260) - para versões a partir da 1.4
		complementar:
			'Análise meio físico EAPIS (SM0700) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico meio físico EAPIS',
		devolverChefeIlha:
			'Análise meio físico EAPIS (SM0700) concluída, devolvido à Distribuição Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0500)',
		concluida: `Análise meio físico EAPIS (SM0700) aguardando, encaminhado para Consolidação de pareceres EAPIS e retorno para a ${destino}`
	},
	SM0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico vegetação EUOS (SM0310) concluída, encaminhado para Análise vegetação EUOS (SM0300)'
	},
	SM0410: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico meio físico EUOS (SM0410) concluída, encaminhado para Análise meio físico EUOS (SM0400)'
	},
	SM0460: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico meio físico EUOS (SM0460) concluída, encaminhado para Análise da fauna EUOS (SM0450)'
	},
	SM0610: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico vegetação EAPIS(SM0610) concluída, encaminhado para Análise vegetação EAPIS (SM0600)'
	},
	SM0710: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico meio físico EAPIS (SM0710) concluída, encaminhado para Análise meio físico EAPIS (SM0700)'
	},
	SM0250: {
		concluida: 'Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0250) concluída, encaminhado para ',
		consultaCla:
			'Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0250) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)'
	},
	SM0260: {
		concluida:
			'Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0260) concluída, encaminhado para ',
		consultaCla:
			'Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0260) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)'
	},
	SM0320: {
		concluida:
			'Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0320) concluída, encaminhado para Análise vegetação EUOS (SM0300)',
		consultaCla:
			'Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0320) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)'
	},
	SM0420: {
		concluida:
			'Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0420) concluída, encaminhado para Análise meio físico EUOS (SM0400)',
		consultaCla:
			'Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0420) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)'
	},
	SM0470: {
		concluida:
			'Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0470) concluída, encaminhado para Análise da fauna EUOS (SM0450)',
		consultaCla:
			'Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0470) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)'
	},
	SM0620: {
		concluida:
			'Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0620) concluída, encaminhado para Análise vegetação EAPIS (SM0600)',
		consultaCla:
			'Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0620) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)'
	},
	SM0720: {
		concluida:
			'Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0720) concluída, encaminhado para Análise meio físico EAPIS (SM0700)',
		consultaCla:
			'Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0720) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)'
	},
	SM0820: {
		consultaDirecao:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) aguardando, encaminhado para Consulta à Direção do Licenciamento Ambiental (SM0850)',
		concluidaSM0200:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Distribuição Equipe de Uso e Ocupação do Solo - EUOS (SM0200)',
		concluidaSM0250:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0250)',
		concluidaSM0260:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0260)',
		concluidaSM0320:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0320)',
		concluidaSM0420:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0420)',
		concluidaSM0470:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Consulta à chefia Equipe de Uso e Ocupação do Solo - EUOS (SM0470)',
		concluidaSM0500:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Distribuição Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0500)',
		concluidaSM0620:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0620)',
		concluidaSM0720:
			'Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820) concluída, encaminhado para Consulta à chefia Equipe de Atividades Primárias, Industriais, Serviços e Infraestrutura - EAPIS (SM0720)'
	},
	SM0850: {
		concluidaSM0100:
			'Consulta à Direção do Licenciamento Ambiental (SM0850) concluída, encaminhado para Distribuição Coordenação de Licenciamento Ambiental - CLA (SM0100)',
		concluidaSM0820:
			'Consulta à Direção do Licenciamento Ambiental (SM0850) concluída, encaminhado para Consulta à Coordenação de Licenciamento Ambiental - CLA (SM0820)'
	}
});

export const SITUACOES_EV = {
	situacoes: {
		EM_COMPLEMENTACAO_EV0115: 'EV0115',
		EM_COMPLEMENTACAO_EV0165: 'EV0165',
		EM_COMPLEMENTACAO_EV0180: 'EV0180',
		EM_COMPLEMENTACAO_EV0280: 'EV0280',
		EM_COMPLEMENTACAO_EV0515: 'EV0515',
		EM_COMPLEMENTACAO_EV0525: 'EV0525',
		EM_COMPLEMENTACAO_EV0535: 'EV0535',
		EM_COMPLEMENTACAO_EV0545: 'EV0545',
		EM_COMPLEMENTACAO_EV0555: 'EV0555',
		EM_COMPLEMENTACAO_EV0565: 'EV0565',
		EM_ANALISE_CONDICIONANTES_EVU_EV0100: 'EV0100',
		EM_CONSULTA_UEU_SMAMUS_EV0110: 'EV0110',
		EM_CONSULTA_CPU_SMAMS_EV0120: 'EV0120',
		EM_CONSULTA_CMDUA_SMAMS_EV0130: 'EV0130',
		EM_CONSULTA_CEAF_DGDUS_SMAMS_EV0140: 'EV0140',
		EM_CONSULTA_ETR_UAP_EV0150: 'EV0150',
		EM_CONSULTA_ETR_UAP_EV0155: 'EV0155',
		EM_CONSULTA_TRIAGEM_UAP_EV0160: 'EV0160',
		EM_CONSULTA_CCCE_EV0191: 'EV0191',
		EM_CONSULTA_GRPDDUA_EV0192: 'EV0192',
		EM_CONSULTA_CAUGE_EV0193: 'EV0193',
		EM_CONSULTA_ARQUIVO_MUNICIPAL_EV0194: 'EV0194',
		EM_ANALISE_PROCESSO_EVE: 'EV0200',
		EM_REANALISE_CONDICIONANTES_EVU_EV0250: 'EV0250',
		EM_CONSULTA_CCCE_EV0291: 'EV0291',
		EM_CONSULTA_GRPDDUA_EV0292: 'EV0292',
		EM_CONSULTA_CAUGE_EV0293: 'EV0293',
		EM_CONSULTA_ARQUIVO_MUNICIPAL_EV0294: 'EV0294',
		EM_REVISAO_PARECER_EVU_EV0350: 'EV0350',
		EM_ELABORACAO_PAUTA_CEVEA: 'EV0400',
		EM_DISPONIBILIZACAO_PROC_MEMBROS_COMISSAO: 'EV0500',
		EM_VERIFICACAO_REPRESENTANTE_EPACH_EV0510: 'EV0510',
		EM_VERIFICACAO_REPRESENTANTE_UDRI_EV0520: 'EV0520',
		EM_VERIFICACAO_REPRESENTANTE_EUOS_SMAMS_EV0530: 'EV0530',
		EM_VERIFICACAO_REPRESENTANTE_EAPIS_SMAMS_EV0540: 'EV0540',
		EM_VERIFICACAO_REPRESENTANTE_EPO_SMIMS_EV0550: 'EV0550',
		EM_VERIFICACAO_REPRESENTANTE_EPTC_EV0560: 'EV0560',
		EM_FINALIZACAO_PARECER_E_ASSINATURAS: 'EV0600',
		EM_LOCALIZACAO_EU_EV0800: 'EV0800',
		EM_DIGITALIZACAO_EV0900: 'EV0900'
	},

	properties: {
		EV0100: {
			key: 'EV0100',
			value: 'Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
		},
		EV0110: { key: 'EV0110', value: 'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (EV0110)' },
		EV0115: { key: 'EV0115', value: 'Em complementação (EV0115)' },
		EV0120: { key: 'EV0120', value: 'Consulta à Coordenação de Planejamento Urbano/SMAMUS (EV0120)' },
		EV0130: { key: 'EV0130', value: 'Consulta ao Conselho Municipal de Desenvolvimento Urbano e Ambiental (EV0130)' },
		EV0140: { key: 'EV0140', value: 'Consulta à Unidade de Fiscalização Ambiental/CEAF/DGDUS/SMAMUS (EV0140)' },
		EV0150: { key: 'EV0150', value: 'Consulta à Equipe de Triagem da Unidade de Aprovação de Projetos (EV0150)' },
		EV0155: { key: 'EV0155', value: 'Consulta à Equipe de Triagem da Unidade de Aprovação de Projetos (EV0155)' },
		EV0160: { key: 'EV0160', value: 'Consulta à Equipe de Triagem da Unidade de Aprovação de Projetos (EV0160)' },
		EV0165: { key: 'EV0165', value: 'Em Complementação (EV0165)' },
		EV0180: { key: 'EV0180', value: 'Em complementação para chefia EVE (EV0180)' },
		EV0191: { key: 'EV0191', value: 'Consulta à Comissão Consultiva do Código de Edificações (CCCE) (EV0191)' },
		EV0192: { key: 'EV0192', value: 'Consulta ao Grupo de Regulamentação do Plano Diretor (GRPDDUA) (EV0192)' },
		EV0193: { key: 'EV0193', value: 'Consulta à CAUGE (EV0193)' },
		EV0194: { key: 'EV0194', value: 'Consulta ao Arquivo Municipal (EV0194)' },
		EV0200: { key: 'EV0200', value: 'Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)' },
		EV0250: {
			key: 'EV0250',
			value: 'Reanálise de condicionantes do Estudo de Viabilidade Urbanística de 1º Grau (EV0250)'
		},
		EV0280: { key: 'EV0280', value: 'Em complementação para revisor EVE (EV0280)' },
		EV0291: { key: 'EV0291', value: 'Consulta à Comissão Consultiva do Código de Edificações (CCCE) (EV0291)' },
		EV0292: { key: 'EV0292', value: 'Consulta ao Grupo de Regulamentação do Plano Diretor (GRPDDUA) (EV0292)' },
		EV0293: { key: 'EV0293', value: 'Consulta à CAUGE (EV0293)' },
		EV0294: { key: 'EV0294', value: 'Consulta ao Arquivo Municipal (EV0294)' },
		EV0350: { key: 'EV0350', value: 'Revisão do parecer do Estudo de Viabilidade Urbanística de 1º Grau (EV0350)' },
		EV0400: { key: 'EV0400', value: 'Elaboração de pauta da CEVEA (EV0400)' },
		EV0500: { key: 'EV0500', value: 'Disponibilização de acesso ao processo da CEVEA (EV0500)' },
		EV0510: { key: 'EV0510', value: 'Análise EVU 1º Grau representante Patrimônio Histórico e Cultural (EV0510)' },
		EV0515: { key: 'EV0515', value: 'Em complementação para representante Patrimônio Histórico e Cultural (EV0515)' },
		EV0520: { key: 'EV0520', value: 'Análise EVU 1º Grau representante Análise da Ocupação e Uso do Solo (EV0520)' },
		EV0525: { key: 'EV0525', value: 'Em complementação para representante Análise da Ocupação e Uso do Solo (EV0525)' },
		EV0530: { key: 'EV0530', value: 'Análise EVU 1º Grau representante Meio Ambiente e Sustentabilidade (EV0530)' },
		EV0535: { key: 'EV0535', value: 'Em complementação para representante Meio Ambiente e Sustentabilidade  (EV0535)' },
		EV0540: { key: 'EV0540', value: 'Análise EVU 1º Grau representante Aprovação e Licenciamento (EV0540)' },
		EV0545: { key: 'EV0545', value: 'Em complementação para representante Aprovação e Licenciamento (EV0545)' },
		EV0550: {
			key: 'EV0550',
			value: 'Análise EVU 1º Grau representante Análise do Licenciamento e Alvará de atividades na CEVEA (EV0550)'
		},
		EV0555: {
			key: 'EV0555',
			value: 'Em complementação para representante Análise do Licenciamento e Alvará de atividades na CEVEA (EV0555)'
		},
		EV0560: {
			key: 'EV0560',
			value: 'Análise EVU 1º Grau representante Infra-estrutura e Mobilidade Urbana na CEVEA (EV0560)'
		},
		EV0565: {
			key: 'EV0565',
			value: 'Em complementação para representante Infra-estrutura e Mobilidade Urbana na CEVEA (EV0565)'
		},
		EV0600: { key: 'EV0600', value: 'Finalização de parecer e assinaturas represententes CEVEA (EV0600)' },
		EV0800: { key: 'EV0800', value: 'Localização do Expediente Único no Arquivo (EV0800)' },
		EV0900: { key: 'EV0900', value: 'Digitalização do Expediente Único (EV0900)' }
	}
};

const getAndamentosEV = destino => ({
	EV0100: {
		concluida:
			'Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)',
		necessitaAnaliseEvu: `Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100) concluída, encaminhado para a ${destino}`,
		indeferir: `Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100) concluída, encaminhado para a ${destino}`,
		paralelas:
			'Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100) concluída, encaminhado para Análise de outras secretarias',
		aguardaOutras:
			'Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100) aguardando, encaminhado para Análise de outras secretarias',
		condicionantes:
			'Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100) aguardando, encaminhado para Análise de outras secretarias',
		complementar:
			'Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EV0110: {
		concluida:
			'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (EV0110) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)',
		complementar:
			'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (EV0110) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EV0115: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0115) concluída, encaminhado para Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (EV0110)'
	},
	EV0120: {
		concluida:
			'Consulta à Coordenação de Planejamento Urbano/SMAMUS (EV0120) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0130: {
		concluida:
			'Consulta ao Conselho Municipal de Desenvolvimento Urbano e Ambiental (EV0130) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0140: {
		concluida:
			'Consulta à Unidade de Fiscalização Ambiental/CEAF/DGDUS/SMAMUS (EV0140) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0150: {
		concluida:
			'Consulta à Equipe de Triagem da Unidade de Aprovação de Projetos (EV0150) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0155: {
		concluida:
			'Consulta à Equipe de Triagem da Unidade de Aprovação de Projetos (EV0155) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0160: {
		concluida:
			'Consulta à Equipe de Triagem da Unidade de Aprovação de Projetos (EV0160) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)',
		complementar:
			'Consulta à Equipe de Triagem da Unidade de Aprovação de Projetos (EV0160) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EV0165: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0165) concluída, encaminhado para Consulta à Equipe de Triagem da Unidade de Aprovação de Projetos (EV0160)'
	},
	EV0180: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0180) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0191: {
		concluida:
			'Consulta à Comissão Consultiva do Código de Edificações (CCCE) (EV0191) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0192: {
		concluida:
			'Consulta ao Grupo de Regulamentação do Plano Diretor (GRPDDUA) (EV0192) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0193: {
		concluida:
			'Consulta à CAUGE (EV0193) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0194: {
		concluida:
			'Consulta ao Arquivo Municipal (EV0194) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)'
	},
	EV0200: {
		concluida:
			'Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200) concluída, encaminhado para Revisão do parecer do Estudo de Viabilidade Urbanística de 1º Grau (EV0350)',
		complementar:
			'Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		reanaliseCondicionantes:
			'Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200) aguardando, encaminhado para Reanálise de condicionantes do Estudo de Viabilidade Urbanística de 1º Grau (EV0250)',
		retornarChefia:
			'Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200) concluída, encaminhado para Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)',
		paralelas:
			'Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200) aguardando, encaminhado para Consultas externas',
		redigitalizar:
			'Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200) aguardando, encaminhado para Localização do Expediente Único no Arquivo (EV0800)'
	},
	EV0250: {
		concluida:
			'Reanálise de condicionantes do Estudo de Viabilidade Urbanística de 1º Grau (EV0250) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)',
		condicionantes:
			'Reanálise de condicionantes do Estudo de Viabilidade Urbanística de 1º Grau (EV0250) concluída, encaminhado para Análise de outras secretarias'
	},
	EV0280: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0280) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0291: {
		concluida:
			'Consulta à Comissão Consultiva do Código de Edificações (CCCE) (EV0291), encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0292: {
		concluida:
			'Consulta ao Grupo de Regulamentação do Plano Diretor (GRPDDUA) (EV0292), encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0293: {
		concluida:
			'Consulta à CAUGE (EV0293), encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0294: {
		concluida:
			'Consulta ao Arquivo Municipal (EV0194), encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0350: {
		concluida:
			'Revisão do parecer do Estudo de Viabilidade Urbanística de 1º Grau (EV0350) concluída, encaminhado para Elaboração de pauta da CEVEA (EV0400)',
		retornarChefia:
			'Revisão do parecer do Estudo de Viabilidade Urbanística de 1º Grau (EV0350) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0400: {
		concluida:
			'Elaboração de pauta da CEVEA (EV0400) concluída, encaminhado para Disponibilização de acesso ao processo da CEVEA (EV0500)'
	},
	EV0500: {
		concluida:
			'Disponibilização de acesso ao processo da CEVEA (EV0500) concluída, encaminhado para Finalização do parecer e assinaturas (EV0600)',
		analiseComissao:
			'Disponibilização de acesso ao processo da CEVEA (EV0500) concluída, encaminhado para Análise dos representantes da CEVEA',
		retornarChefia:
			'Disponibilização de acesso ao processo da CEVEA (EV0500) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0510: {
		concluida:
			'Análise EVU 1º Grau representante Patrimônio Histórico e Cultural (EV0510) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0515: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0515) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0520: {
		concluida:
			'Análise EVU 1º Grau representante Análise da Ocupação e Uso do Solo (EV0520) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0525: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0525) concluída, encaminhado para Análise EVU 1º Grau representante Análise da Ocupação e Uso do Solo (EV0520)'
	},
	EV0530: {
		concluida:
			'Análise EVU 1º Grau representante Meio Ambiente e Sustentabilidade (EV0530) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0535: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0535) concluída, encaminhado para Análise EVU 1º Grau representante Meio Ambiente e Sustentabilidade (EV0530)'
	},
	EV0540: {
		concluida:
			'Análise EVU 1º Grau representante Aprovação e Licenciamento (EV0540) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0545: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0545) concluída, encaminhado para Análise EVU 1º Grau representante Aprovação e Licenciamento (EV0540)'
	},
	EV0550: {
		concluida:
			'Análise EVU 1º Grau representante Análise do Licenciamento e Alvará de atividades na CEVEA (EV0550) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0555: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0555) concluída, encaminhado para Análise EVU 1º Grau representante Análise do Licenciamento e Alvará de atividades na CEVEA (EV0550)'
	},
	EV0560: {
		concluida:
			'Análise EVU 1º Grau representante Infra-estrutura e Mobilidade Urbana na CEVEA  (EV0560) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	},
	EV0565: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EV0565) concluída, encaminhado para Análise EVU 1º Grau representante Infra-estrutura e Mobilidade Urbana na CEVEA  (EV0560)'
	},
	EV0600: {
		concluida: `Finalização de parecer e assinaturas represententes CEVEA (EV0600) concluída, encaminhado para a ${destino}`
	},
	EV0800: {
		concluida:
			'Localização do Expediente Único no Arquivo (EV0800) concluída, encaminhado para Digitalização do Expediente Único (EV0900)',
		euEncontrado:
			'Localização do Expediente Único no Arquivo (EV0800) concluída, processo não localizado e processo B criado. Encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200))'
	},
	EV0900: {
		concluida:
			'Digitalização do Expediente Único (EV0900) concluída, encaminhado para Análise do Estudo de Viabilidade Urbanística de 1º Grau (EV0200)'
	}
});

const SITUACOES_FLUXOS_PADRAO = {
	situacoes: {},
	properties: {
		CP0100: { key: 'CP0100', value: 'Distribuição Coordenação de Mercado - DMAE (CP0100)' },
		CP0200: { key: 'CP0200', value: 'Validação de pareceres Coordenação de Mercado - DMAE (CP0200)' },
		CP0110: { key: 'CP0110', value: 'Análise técnica Coordenação de Mercado - DMAE (CP0110)' },
		CP0120: { key: 'CP0120', value: 'Análise técnica Coordenação de Mercado - DMAE (CP0120)' },
		CP0130: { key: 'CP0130', value: 'Análise técnica Coordenação de Mercado - DMAE (CP0130)' },
		CP0300: {
			key: 'CP0300',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Coordenação de Mercado - DMAE (CP0300)'
		},
		CP0310: {
			key: 'CP0310',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Coordenação de Mercado - DMAE (CP0310)'
		},
		CP0320: {
			key: 'CP0320',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Coordenação de Mercado - DMAE (CP0320)'
		},
		DM0100: { key: 'DM0100', value: 'Distribuição Departamento Municipal de Água e Esgoto (DM0100)' },
		DM0200: { key: 'DM0200', value: 'Validação de pareceres Departamento Municipal de Água e Esgoto (DM0200)' },
		DM0110: { key: 'DM0110', value: 'Análise técnica Departamento Municipal de Água e Esgoto (DM0110)' },
		DM0120: { key: 'DM0120', value: 'Análise técnica Departamento Municipal de Água e Esgoto (DM0120)' },
		DM0130: { key: 'DM0130', value: 'Análise técnica Departamento Municipal de Água e Esgoto (DM0130)' },
		DM0300: {
			key: 'DM0300',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Departamento Municipal de Água e Esgoto (DM0300)'
		},
		DM0310: {
			key: 'DM0310',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Departamento Municipal de Água e Esgoto (DM0310)'
		},
		DM0320: {
			key: 'DM0320',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Departamento Municipal de Água e Esgoto (DM0320)'
		},
		DP0100: { key: 'DP0100', value: 'Distribuição Projetos Viários (DP0100)' },
		DP0200: { key: 'DP0200', value: 'Validação de pareceres Projetos Viários (DP0200)' },
		DP0110: { key: 'DP0110', value: 'Análise técnica Projetos Viários (DP0110)' },
		DP0120: { key: 'DP0120', value: 'Análise técnica Projetos Viários (DP0120)' },
		DP0130: { key: 'DP0130', value: 'Análise técnica Projetos Viários (DP0130)' },
		DP0300: {
			key: 'DP0300',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico Projetos Viários (DP0300)'
		},
		DP0310: {
			key: 'DP0310',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico Projetos Viários (DP0310)'
		},
		DP0320: {
			key: 'DP0320',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico Projetos Viários (DP0320)'
		},
		EA0100: { key: 'EA0100', value: 'Distribuição Alinhamento Predial (EA0100)' },
		EA0200: { key: 'EA0200', value: 'Validação de pareceres Alinhamento Predial (EA0200)' },
		EA0110: { key: 'EA0110', value: 'Análise técnica Alinhamento Predial (EA0110)' },
		EA0120: { key: 'EA0120', value: 'Análise técnica Alinhamento Predial (EA0120)' },
		EA0130: { key: 'EA0130', value: 'Análise técnica Alinhamento Predial (EA0130)' },
		EA0300: {
			key: 'EA0300',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico Alinhamento Predial (EA0300)'
		},
		EA0310: {
			key: 'EA0310',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico Alinhamento Predial (EA0310)'
		},
		EA0320: {
			key: 'EA0320',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico Alinhamento Predial (EA0320)'
		},
		ES0100: { key: 'ES0100', value: 'Distribuição Informações Urbanísticas (ES0100)' },
		ES0200: { key: 'ES0200', value: 'Validação de pareceres Informações Urbanísticas (ES0200)' },
		ES0110: { key: 'ES0110', value: 'Análise técnica Informações Urbanísticas (ES0110)' },
		ES0120: { key: 'ES0120', value: 'Análise técnica Informações Urbanísticas (ES0120)' },
		ES0130: { key: 'ES0130', value: 'Análise técnica Informações Urbanísticas (ES0130)' },
		ES0300: {
			key: 'ES0300',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Informações Urbanísticas (ES0300)'
		},
		ES0310: {
			key: 'ES0310',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Informações Urbanísticas (ES0310)'
		},
		ES0320: {
			key: 'ES0320',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Informações Urbanísticas (ES0320)'
		},
		PH0100: { key: 'PH0100', value: 'Distribuição Patrimônio Histórico e Cultural (PH0100)' },
		PH0200: { key: 'PH0200', value: 'Validação de pareceres Patrimônio Histórico e Cultural (PH0200)' },
		PH0110: { key: 'PH0110', value: 'Análise técnica Patrimônio Histórico e Cultural (PH0110)' },
		PH0120: { key: 'PH0120', value: 'Análise técnica Patrimônio Histórico e Cultural (PH0120)' },
		PH0130: { key: 'PH0130', value: 'Análise técnica Patrimônio Histórico e Cultural (PH0130)' },
		PH0300: {
			key: 'PH0300',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Patrimônio Histórico e Cultural (PH0300)'
		},
		PH0310: {
			key: 'PH0310',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Patrimônio Histórico e Cultural (PH0310)'
		},
		PH0320: {
			key: 'PH0320',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Patrimônio Histórico e Cultural (PH0320)'
		},
		EP0100: { key: 'EP0100', value: 'Distribuição SMMU (EP0100)' },
		EP0200: { key: 'EP0200', value: 'Validação de pareceres SMMU (EP0200)' },
		EP0110: { key: 'EP0110', value: 'Análise técnica SMMU (EP0110)' },
		EP0120: { key: 'EP0120', value: 'Análise técnica SMMU (EP0120)' },
		EP0130: { key: 'EP0130', value: 'Análise técnica SMMU (EP0130)' },
		EP0300: {
			key: 'EP0300',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico SMMU (EP0300)'
		},
		EP0310: {
			key: 'EP0310',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico SMMU (EP0310)'
		},
		EP0320: {
			key: 'EP0320',
			value: 'Em complementação de documentos e/ou informações pelo Responsável Técnico SMMU (EP0320)'
		},
		ER0100: { key: 'ER0100', value: 'Distribuição Regularização Fundiária (ER0100)' },
		ER0200: { key: 'ER0200', value: 'Validação de pareceres Regularização Fundiária (ER0200)' },
		ER0110: { key: 'ER0110', value: 'Análise técnica Regularização Fundiária (ER0110)' },
		ER0120: { key: 'ER0120', value: 'Análise técnica Regularização Fundiária (ER0120)' },
		ER0130: { key: 'ER0130', value: 'Análise técnica Regularização Fundiária (ER0130)' },
		ER0300: {
			key: 'ER0300',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Regularização Fundiária (ER0300)'
		},
		ER0310: {
			key: 'ER0310',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Regularização Fundiária (ER0310)'
		},
		ER0320: {
			key: 'ER0320',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Regularização Fundiária (ER0320)'
		}
	}
};

const getAndamentosFluxosPadrao = destino => ({
	PARECER_DESFAVORAVEL: 'parecerDesfavoravel',
	CONCLUIDA: 'concluida',
	VISTO_CHEFIA: 'vistoChefia',
	COMPLEMENTAR: 'complementar',

	CP0100: {
		concluida:
			'Distribuição Coordenação de Mercado - DMAE (CP0100) concluída, encaminhado para Análise(s) técnica Coordenação de Mercado - DMAE'
	},
	CP0200: {
		concluida: `Validação de pareceres Coordenação de Mercado - DMAE (CP0200) concluída, encaminhado para a ${destino}`,
		parecerDesfavoravel:
			'Validação de pareceres Coordenação de Mercado - DMAE (CP0200) concluída, encaminhado para Distribuição Coordenação de Mercado - DMAE (CP0100)'
	},
	CP0110: {
		concluida: `Análise técnica Coordenação de Mercado - DMAE (CP0110) concluída, encaminhado para Consolidação de pareceres Coordenação de Mercado - DMAE e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Coordenação de Mercado - DMAE (CP0110) concluída, encaminhado para Validação de pareceres Coordenação de Mercado - DMAE (CP0200)',
		complementar:
			'Análise técnica Coordenação de Mercado - DMAE (CP0110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	CP0120: {
		concluida: `Análise técnica Coordenação de Mercado - DMAE (CP0120) concluída, encaminhado para Consolidação de pareceres Coordenação de Mercado - DMAE e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Coordenação de Mercado - DMAE (CP0120) concluída, encaminhado para Validação de pareceres Coordenação de Mercado - DMAE (CP0200)',
		complementar:
			'Análise técnica Coordenação de Mercado - DMAE (CP0120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	CP0130: {
		concluida: `Análise técnica Coordenação de Mercado - DMAE (CP0130) concluída, encaminhado para Consolidação de pareceres Coordenação de Mercado - DMAE e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Coordenação de Mercado - DMAE (CP0130) concluída, encaminhado para Validação de pareceres Coordenação de Mercado - DMAE (CP0200)',
		complementar:
			'Análise técnica Coordenação de Mercado - DMAE (CP0130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	CP0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (CP0300) concluída, encaminhado para Análise técnica Coordenação de Mercado - DMAE (CP0110)'
	},
	CP0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (CP0310) concluída, encaminhado para Análise técnica Coordenação de Mercado - DMAE (CP0120)'
	},
	CP0320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (CP0320) concluída, encaminhado para Análise técnica Coordenação de Mercado - DMAE (CP0130)'
	},

	DM0100: {
		concluida:
			'Distribuição Departamento Municipal de Água e Esgoto (DM0100) concluída, encaminhado para Análise(s) técnica Departamento Municipal de Água e Esgoto'
	},
	DM0200: {
		concluida: `Validação de pareceres Departamento Municipal de Água e Esgoto (DM0200) concluída, encaminhado para a ${destino}`,
		parecerDesfavoravel:
			'Validação de pareceres Departamento Municipal de Água e Esgoto (DM0200) concluída, encaminhado para Distribuição Departamento Municipal de Água e Esgoto (DM0100)'
	},
	DM0110: {
		concluida: `Análise técnica Departamento Municipal de Água e Esgoto (DM0110) concluída, encaminhado para Consolidação de pareceres Departamento Municipal de Água e Esgoto e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM0110) concluída, encaminhado para Validação de pareceres Departamento Municipal de Água e Esgoto (DM0200)',
		complementar:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM0110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DM0120: {
		concluida: `Análise técnica Departamento Municipal de Água e Esgoto (DM0120) concluída, encaminhado para Consolidação de pareceres Departamento Municipal de Água e Esgoto e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM0120) concluída, encaminhado para Validação de pareceres Departamento Municipal de Água e Esgoto (DM0200)',
		complementar:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM0120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DM0130: {
		concluida: `Análise técnica Departamento Municipal de Água e Esgoto (DM0130) concluída, encaminhado para Consolidação de pareceres Departamento Municipal de Água e Esgoto e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM0130) concluída, encaminhado para Validação de pareceres Departamento Municipal de Água e Esgoto (DM0200)',
		complementar:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM0130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DM0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DM0300) concluída, encaminhado para Análise técnica Departamento Municipal de Água e Esgoto (DM0110)'
	},
	DM0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DM0310) concluída, encaminhado para Análise técnica Departamento Municipal de Água e Esgoto (DM0120)'
	},
	DM0320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DM0320) concluída, encaminhado para Análise técnica Departamento Municipal de Água e Esgoto (DM0130)'
	},

	DP0100: {
		concluida: 'Distribuição Projetos Viários (DP0100) concluída, encaminhado para Análise(s) técnica Projetos Viários'
	},
	DP0200: {
		concluida: `Validação de pareceres Projetos Viários (DP0200) concluída, encaminhado para a ${destino}`,
		parecerDesfavoravel:
			'Validação de pareceres Projetos Viários (DP0200) concluída, encaminhado para Distribuição Projetos Viários (DP0100)'
	},
	DP0110: {
		concluida: `Análise técnica Projetos Viários (DP0110) concluída, encaminhado para Consolidação de pareceres Projetos Viários e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Projetos Viários (DP0110) concluída, encaminhado para Validação de pareceres Projetos Viários (DP0200)',
		complementar:
			'Análise técnica Projetos Viários (DP0110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DP0120: {
		concluida: `Análise técnica Projetos Viários (DP0120) concluída, encaminhado para Consolidação de pareceres Projetos Viários e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Projetos Viários (DP0120) concluída, encaminhado para Validação de pareceres Projetos Viários (DP0200)',
		complementar:
			'Análise técnica Projetos Viários (DP0120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DP0130: {
		concluida: `Análise técnica Projetos Viários (DP0130) concluída, encaminhado para Consolidação de pareceres Projetos Viários e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Projetos Viários (DP0130) concluída, encaminhado para Validação de pareceres Projetos Viários (DP0200)',
		complementar:
			'Análise técnica Projetos Viários (DP0130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DP0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DP0300) concluída, encaminhado para Análise técnica Projetos Viários (DP0110)'
	},
	DP0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DP0310) concluída, encaminhado para Análise técnica Projetos Viários (DP0120)'
	},
	DP0320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DP0320) concluída, encaminhado para Análise técnica Projetos Viários (DP0130)'
	},

	EA0100: {
		concluida:
			'Distribuição Alinhamento Predial (EA0100) concluída, encaminhado para Análise(s) técnica Alinhamento Predial'
	},
	EA0200: {
		concluida: `Validação de pareceres Alinhamento Predial (EA0200) concluída, encaminhado para a ${destino}`,
		parecerDesfavoravel:
			'Validação de pareceres Alinhamento Predial (EA0200) concluída, encaminhado para Distribuição Alinhamento Predial (EA0100)'
	},
	EA0110: {
		concluida: `Análise técnica Alinhamento Predial (EA0110) concluída, encaminhado para Consolidação de pareceres Alinhamento Predial e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Alinhamento Predial (EA0110) concluída, encaminhado para Validação de pareceres Alinhamento Predial (EA0200)',
		complementar:
			'Análise técnica Alinhamento Predial (EA0110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EA0120: {
		concluida: `Análise técnica Alinhamento Predial (EA0120) concluída, encaminhado para Consolidação de pareceres Alinhamento Predial e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Alinhamento Predial (EA0120) concluída, encaminhado para Validação de pareceres Alinhamento Predial (EA0200)',
		complementar:
			'Análise técnica Alinhamento Predial (EA0120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EA0130: {
		concluida: `Análise técnica Alinhamento Predial (EA0130) concluída, encaminhado para Consolidação de pareceres Alinhamento Predial e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Alinhamento Predial (EA0130) concluída, encaminhado para Validação de pareceres Alinhamento Predial (EA0200)',
		complementar:
			'Análise técnica Alinhamento Predial (EA0130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EA0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EA0300) concluída, encaminhado para Análise técnica Alinhamento Predial (EA0110)'
	},
	EA0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EA0310) concluída, encaminhado para Análise técnica Alinhamento Predial (EA0120)'
	},
	EA0320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EA0320) concluída, encaminhado para Análise técnica Alinhamento Predial (EA0130)'
	},

	ES0100: {
		concluida:
			'Distribuição Informações Urbanísticas (ES0100) concluída, encaminhado para Análise(s) técnica Informações Urbanísticas'
	},
	ES0200: {
		concluida: `Validação de pareceres Informações Urbanísticas (ES0200) concluída, encaminhado para a ${destino}`,
		parecerDesfavoravel:
			'Validação de pareceres Informações Urbanísticas (ES0200) concluída, encaminhado para Distribuição Informações Urbanísticas (ES0100)'
	},
	ES0110: {
		concluida: `Análise técnica Informações Urbanísticas (ES0110) concluída, encaminhado para Consolidação de pareceres Informações Urbanísticas e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Informações Urbanísticas (ES0110) concluída, encaminhado para Validação de pareceres Informações Urbanísticas (ES0200)',
		complementar:
			'Análise técnica Informações Urbanísticas (ES0110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	ES0120: {
		concluida: `Análise técnica Informações Urbanísticas (ES0120) concluída, encaminhado para Consolidação de pareceres Informações Urbanísticas e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Informações Urbanísticas (ES0120) concluída, encaminhado para Validação de pareceres Informações Urbanísticas (ES0200)',
		complementar:
			'Análise técnica Informações Urbanísticas (ES0120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	ES0130: {
		concluida: `Análise técnica Informações Urbanísticas (ES0130) concluída, encaminhado para Consolidação de pareceres Informações Urbanísticas e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Informações Urbanísticas (ES0130) concluída, encaminhado para Validação de pareceres Informações Urbanísticas (ES0200)',
		complementar:
			'Análise técnica Informações Urbanísticas (ES0130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	ES0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (ES0300) concluída, encaminhado para Análise técnica Informações Urbanísticas (ES0110)'
	},
	ES0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (ES0310) concluída, encaminhado para Análise técnica Informações Urbanísticas (ES0120)'
	},
	ES0320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (ES0320) concluída, encaminhado para Análise técnica Informações Urbanísticas (ES0130)'
	},

	PH0100: {
		concluida:
			'Distribuição Patrimônio Histórico e Cultural (PH0100) concluída, encaminhado para Análise(s) técnica Patrimônio Histórico e Cultural'
	},
	PH0200: {
		concluida: `Validação de pareceres Patrimônio Histórico e Cultural (PH0200) concluída, encaminhado para a ${destino}`,
		parecerDesfavoravel:
			'Validação de pareceres Patrimônio Histórico e Cultural (PH0200) concluída, encaminhado para Distribuição Patrimônio Histórico e Cultural (PH0100)'
	},
	PH0110: {
		concluida: `Análise técnica Patrimônio Histórico e Cultural (PH0110) concluída, encaminhado para Consolidação de pareceres Patrimônio Histórico e Cultural e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Patrimônio Histórico e Cultural (PH0110) concluída, encaminhado para Validação de pareceres Patrimônio Histórico e Cultural (PH0200)',
		complementar:
			'Análise técnica Patrimônio Histórico e Cultural (PH0110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PH0120: {
		concluida: `Análise técnica Patrimônio Histórico e Cultural (PH0120) concluída, encaminhado para Consolidação de pareceres Patrimônio Histórico e Cultural e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Patrimônio Histórico e Cultural (PH0120) concluída, encaminhado para Validação de pareceres Patrimônio Histórico e Cultural (PH0200)',
		complementar:
			'Análise técnica Patrimônio Histórico e Cultural (PH0120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PH0130: {
		concluida: `Análise técnica Patrimônio Histórico e Cultural (PH0130) concluída, encaminhado para Consolidação de pareceres Patrimônio Histórico e Cultural e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Patrimônio Histórico e Cultural (PH0130) concluída, encaminhado para Validação de pareceres Patrimônio Histórico e Cultural (PH0200)',
		complementar:
			'Análise técnica Patrimônio Histórico e Cultural (PH0130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PH0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PH0300) concluída, encaminhado para Análise técnica Patrimônio Histórico e Cultural (PH0110)'
	},
	PH0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PH0310) concluída, encaminhado para Análise técnica Patrimônio Histórico e Cultural (PH0120)'
	},
	PH0320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PH0320) concluída, encaminhado para Análise técnica Patrimônio Histórico e Cultural (PH0130)'
	},

	EP0100: {
		concluida: 'Distribuição SMMU (EP0100) concluída, encaminhado para Análise(s) técnica SMMU'
	},
	EP0200: {
		concluida: `Validação de pareceres SMMU (EP0200) concluída, encaminhado para a ${destino}`,
		parecerDesfavoravel: 'Validação de pareceres SMMU (EP0200) concluída, encaminhado para Distribuição SMMU (EP0100)'
	},
	EP0110: {
		concluida: `Análise técnica SMMU (EP0110) concluída, encaminhado para Consolidação de pareceres SMMU e retorno para a ${destino}`,
		vistoChefia: 'Análise técnica SMMU (EP0110) concluída, encaminhado para Validação de pareceres SMMU (EP0200)',
		complementar:
			'Análise técnica SMMU (EP0110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EP0120: {
		concluida: `Análise técnica SMMU (EP0120) concluída, encaminhado para Consolidação de pareceres SMMU e retorno para a ${destino}`,
		vistoChefia: 'Análise técnica SMMU (EP0120) concluída, encaminhado para Validação de pareceres SMMU (EP0200)',
		complementar:
			'Análise técnica SMMU (EP0120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EP0130: {
		concluida: `Análise técnica SMMU (EP0130) concluída, encaminhado para Consolidação de pareceres SMMU e retorno para a ${destino}`,
		vistoChefia: 'Análise técnica SMMU (EP0130) concluída, encaminhado para Validação de pareceres SMMU (EP0200)',
		complementar:
			'Análise técnica SMMU (EP0130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	EP0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EP0300) concluída, encaminhado para Análise técnica SMMU (EP0110)'
	},
	EP0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EP0310) concluída, encaminhado para Análise técnica SMMU (EP0120)'
	},
	EP0320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EP0320) concluída, encaminhado para Análise técnica SMMU (EP0130)'
	},

	ER0100: {
		concluida:
			'Distribuição Regularização Fundiária (ER0100) concluída, encaminhado para Análise(s) técnica Regularização Fundiária'
	},
	ER0200: {
		concluida: `Validação de pareceres Regularização Fundiária (ER0200) concluída, encaminhado para a ${destino}`,
		parecerDesfavoravel:
			'Validação de pareceres Regularização Fundiária (ER0200) concluída, encaminhado para Distribuição Regularização Fundiária (ER0100)'
	},
	ER0110: {
		concluida: `Análise técnica Regularização Fundiária (ER0110) concluída, encaminhado para Consolidação de pareceres Regularização Fundiária e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Regularização Fundiária (ER0110) concluída, encaminhado para Validação de pareceres Regularização Fundiária (ER0200)',
		complementar:
			'Análise técnica Regularização Fundiária (ER0110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	ER0120: {
		concluida: `Análise técnica Regularização Fundiária (ER0120) concluída, encaminhado para Consolidação de pareceres Regularização Fundiária e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Regularização Fundiária (ER0120) concluída, encaminhado para Validação de pareceres Regularização Fundiária (ER0200)',
		complementar:
			'Análise técnica Regularização Fundiária (ER0120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	ER0130: {
		concluida: `Análise técnica Regularização Fundiária (ER0130) concluída, encaminhado para Consolidação de pareceres Regularização Fundiária e retorno para a ${destino}`,
		vistoChefia:
			'Análise técnica Regularização Fundiária (ER0130) concluída, encaminhado para Validação de pareceres Regularização Fundiária (ER0200)',
		complementar:
			'Análise técnica Regularização Fundiária (ER0130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	ER0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (ER0300) concluída, encaminhado para Análise técnica Regularização Fundiária (ER0110)'
	},
	ER0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (ER0310) concluída, encaminhado para Análise técnica Regularização Fundiária (ER0120)'
	},
	ER0320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (ER0320) concluída, encaminhado para Análise técnica Regularização Fundiária (ER0130)'
	}
});

export const SITUACOES_AP = {
	EM_PRE_TRIAGEM: 'AP0100',
	EM_VERIFICA_EU: 'AP0200',
	EM_COMPLEMENTACAO: 'AP0300',
	EM_GERAR_NOVO_EU: 'AP0400',
	EM_GERAR_ETAPA: 'AP0410',
	EM_DISTRIBUICAO_TRIAGEM: 'AP0400',
	EM_TRIAGEM: 'AP0500',
	EM_CONSULTA_EDI_SMAMS: 'AP0600',
	EM_CONSULTA_ATENDIMENTO: 'AP0610',
	EM_CONSULTA_CAUGE: 'AP0620',
	EM_CONSULTA_EPE1: 'AP0630',
	EM_CONSULTA_CHEFIA_TRIAGEM: 'AP0640',
	EM_CONSULTA_COORD_EDIF: 'AP0650',
	EM_CONSULTA_DIRECAO_EL: 'AP0660',
	EM_CONSULTA_UEU_SMAMUS_AP0670: 'AP0670',
	EM_CONSULTA_UEU_SMAMUS_AP0680: 'AP0680',
	EM_CONSULTA_AJ: 'AP0700',
	LOCALIZA_EU: 'AP0800',
	EM_DIGITALIZACAO: 'AP0900',
	EM_HOMOLOG_INDEFERIMENTO: 'AP0905',
	EM_INDEFERIR: 'AP1000',
	EM_ANALISE_PRE_DISTRIBUICAO: 'AP1100',
	EM_ANALISE_PRE_DISTRIBUICAO_AJUSTES: 'AP1101',
	EM_DISTRIBUICAO_CHEFE_ILHA: 'AP1200',
	EM_CONSULTA_CEVEA_AP1209: 'AP1209',
	EM_CONSULTA_AJ_AP1210: 'AP1210',
	EM_CONSULTA_AJ_AP1215: 'AP1215',
	EM_CONSULTA_EPS_AP1220: 'AP1220',
	EM_CONSULTA_EDI_SMAMS_AP1230: 'AP1230',
	EM_CONSULTA_CHEFE_ILHA_AP1240: 'AP1240',
	EM_CONSULTA_CCCE_AP1250: 'AP1250',
	EM_CONSULTA_CCCE_AP1255: 'AP1255',
	EM_CONSULTA_GRPDDUA_AP1260: 'AP1260',
	EM_CONSULTA_DPPS_AP1265: 'AP1265',
	EM_CONSULTA_CAUGE_AP1270: 'AP1270',
	EM_CONSULTA_CAADHAP_AP1280: 'AP1280',
	EM_CONSULTA_CEFH_AP1281: 'AP1281',
	EM_CONSULTA_COORD_EDIF_AP1290: 'AP1290',
	EM_CONSULTA_DIRECAO_EL_AP1299: 'AP1299',
	EM_COMPLEMENTACAO_AP1300: 'AP1300',
	EM_COMPLEMENTACAO_AP1320: 'AP1320',
	EM_COMPLEMENTACAO_AP1370: 'AP1370',
	EM_COMPLEMENTACAO_AP1380: 'AP1380',
	EM_COMPLEMENTACAO_AP1381: 'AP1381',
	EM_LOCALIZACAO_EU_AP1430: 'AP1430',
	EM_DIGITALIZACAO_AP1460: 'AP1460',
	EM_ANALISE_PROJETO_ARQUITETONICO: 'AP1500',
	EM_REANALISE_CONDICIONANTES: 'AP1510',
	EM_COMPLEMENTACAO_AP1515: 'AP1515',
	EM_CONDICIONANTES_APROVACAO: 'AP1600',
	EM_ANALISE_PARECER_FINAL_CHEFIA: 'AP1700',
	EM_COMPLEMENTACAO_AP1710: 'AP1710',
	EM_DISTRIBUICAO_UDRI_AP1750: 'AP1750',
	EM_REVISAO_COMPRA_INDICES: 'AP1800',
	EM_COMPLEMENTACAO_AP1810: 'AP1810',
	EM_CONSULTA_ASSISTENTE_UDRI_AP1820: 'AP1820',
	EM_CORRECAO_GERACAO_AUTOMATICA_BLOQUETOS_UDRI_AP1822: 'AP1822',
	EM_COMPLEMENTACAO_AP1825: 'AP1825',
	EM_AGUARDA_TPC: 'AP1850',
	EM_VALIDACAO_INDEFERIMENTO: 'AP1890',
	EM_COMPILACAO_DOCUMENTACAO_AP1900: 'AP1900',
	EM_COMPLEMENTACAO_AP1910: 'AP1910',
	EM_REVISAO_TAXAS_DAM: 'AP1920',
	EM_ASSINATURA_REVISOR_AP1950: 'AP1950',
	EM_VALIDACAO_DEFERIMENTO: 'AP1990',
	EM_ENCAMINHAMENTO_ANALISE_UDRI: 'UD0100',
	EM_ANALISE_UDRI: 'UD0200',
	EM_CONSULTA_ASSESSORIA_JURIDICA_UDRI: 'UD0300',
	EM_CONSULTA_PGM_UDRI: 'UD0400',
	EM_CONSULTA_CHEFIA_UDRI: 'UD0600',
	EM_CONSULTA_DIRECAO_UDRI: 'UD0700',
	EM_COMPLEMENTACAO_UDRI: 'UD0500',
	DM0110_EM_ANALISE_PROCESSO_TECNICO: 'DM0110',
	DM0120_EM_ANALISE_PROCESSO_TECNICO: 'DM0120',
	DM0130_EM_ANALISE_PROCESSO_TECNICO: 'DM0130',
	...SITUACOES_SM.situacoes,
	...SITUACOES_EV.situacoes,

	properties: {
		AP0100: { key: 'AP0100', value: 'Recebimento do requerimento (AP0100)' },
		AP0200: { key: 'AP0200', value: 'Registro de etapa (AP0200)' },
		AP0300: { key: 'AP0300', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (AP0300)' },
		AP0370: { key: 'AP0370', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (AP0370)' },
		AP0380: { key: 'AP0380', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (AP0380)' },
		AP0400: { key: 'AP0400', value: 'Distribuição para Validação do Expediente Único e Documentos (AP0400)' },
		AP0500: { key: 'AP0500', value: 'Validação do Expediente Único e Documentos (AP0500)' },
		AP0600: { key: 'AP0600', value: 'Consulta UAIU-SMAMUS (AP0600)' },
		AP0610: { key: 'AP0610', value: 'Consulta Atendimento (AP0610)' },
		AP0620: { key: 'AP0620', value: 'Consulta CAUGE (AP0620)' },
		AP0630: { key: 'AP0630', value: 'Consulta EPE-1 (AP0630)' },
		AP0640: { key: 'AP0640', value: 'Consulta Chefia Triagem (AP0640)' },
		AP0650: { key: 'AP0650', value: 'Consulta Coordenação de Edificações (AP0650)' },
		AP0660: { key: 'AP0660', value: 'Consulta Direção do Escritório de Licenciamento (AP0660)' },
		AP0670: { key: 'AP0670', value: 'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP0670)' },
		AP0680: { key: 'AP0680', value: 'Consulta à Unidade de Projetos Urbanos - UPU/SMAMUS (AP0680)' },
		AP0700: { key: 'AP0700', value: 'Consulta Assessoria Jurídica (AP0700)' },
		AP0800: { key: 'AP0800', value: 'Localização do Expediente Único no Arquivo (AP0800)' },
		AP0900: { key: 'AP0900', value: 'Digitalização do Expediente Único (AP0900)' },
		AP0905: { key: 'AP0905', value: 'Homologação de indeferimento (AP0905)' },
		AP1000: { key: 'AP1000', value: 'Indeferimento do processo (AP1000)' },
		AP1100: { key: 'AP1100', value: 'Pré-distribuição da Unidade de Aprovação de Projetos (AP1100)' },
		AP1101: { key: 'AP1101', value: 'Pré-distribuição de Ajustes de Projetos (AP1101)' },
		AP1200: { key: 'AP1200', value: 'Análise de condicionantes (AP1200)' },
		AP1205: { key: 'AP1205', value: 'Consulta Chefe da Unidade de Aprovação de Projetos (AP1205)' },
		AP1209: { key: 'AP1209', value: 'Consulta à CEVEA (AP1209)' },
		AP1210: { key: 'AP1210', value: 'Consulta Assessoria Jurídica (AP1210)' },
		AP1215: { key: 'AP1215', value: 'Consulta Assessoria Jurídica (AP1215)' },
		AP1220: { key: 'AP1220', value: 'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP1220)' },
		AP1230: { key: 'AP1230', value: 'Consulta Enquadramento LEI 960/22 4º Distrito (AP1230)' },
		AP1240: { key: 'AP1240', value: 'Consulta Chefe de Ilha (AP1240)' },
		AP1250: { key: 'AP1250', value: 'Consulta CCCE (AP1250)' },
		AP1255: { key: 'AP1255', value: 'Consulta CCCE (AP1255)' },
		AP1260: { key: 'AP1260', value: 'Consulta GRPDDUA (AP1260)' },
		AP1265: { key: 'AP1265', value: 'Consulta DPPS (AP1265)' },
		AP1270: { key: 'AP1270', value: 'Consulta CAUGE (AP1270)' },
		AP1280: { key: 'AP1280', value: 'Consulta CAADHAP (AP1280)' },
		AP1281: { key: 'AP1281', value: 'Consulta CEFH (AP1281)' },
		AP1290: { key: 'AP1290', value: 'Consulta Coordenador de Edificações (AP1290)' },
		AP1299: { key: 'AP1299', value: 'Consulta Direção EL (AP1299)' },
		AP1300: { key: 'AP1300', value: 'Em complementação (AP1300)' },
		AP1309: { key: 'AP1309', value: 'Em complementação (AP1309)' },
		AP1320: { key: 'AP1320', value: 'Em complementação (AP1320)' },
		AP1330: { key: 'AP1330', value: 'Em complementação (AP1330)' },
		AP1370: { key: 'AP1370', value: 'Em complementação (AP1370)' },
		AP1380: { key: 'AP1380', value: 'Em complementação (AP1380)' },
		AP1381: { key: 'AP1381', value: 'Em complementação (AP1381)' },
		AP1430: { key: 'AP1430', value: 'Localização do Expediente Único no Arquivo (AP1430)' },
		AP1460: { key: 'AP1460', value: 'Digitalização do Expediente Único (AP1460)' },
		AP1500: { key: 'AP1500', value: 'Análise de projeto arquitetônico (AP1500)' },
		AP1510: { key: 'AP1510', value: 'Reanálise de condicionantes (AP1510)' },
		AP1515: { key: 'AP1515', value: 'Em complementação (AP1515)' },
		AP1600: { key: 'AP1600', value: 'Análise de condicionantes de aprovação (AP1600)' },
		AP1700: { key: 'AP1700', value: 'Análise do parecer final pela chefia (AP1700)' },
		AP1710: { key: 'AP1710', value: 'Em complementação (AP1710)' },
		AP1750: { key: 'AP1750', value: 'Distribuição CDRI (AP1750)' },
		AP1800: { key: 'AP1800', value: 'Revisão de compra de índices (AP1800)' },
		AP1820: { key: 'AP1820', value: 'Consulta à Assistente CDRI (AP1820)' },
		AP1822: { key: 'AP1822', value: 'Corrige geração de boletos automáticos CDRI (AP1822)' },
		AP1810: { key: 'AP1810', value: 'Em complementação (AP1810)' },
		AP1825: { key: 'AP1825', value: 'Em complementação (AP1825)' },
		AP1850: { key: 'AP1850', value: 'Aguardando TPC (AP1850)' },
		AP1890: { key: 'AP1890', value: 'Validação do indeferimento (AP1890)' },
		AP1900: { key: 'AP1900', value: 'Consolidar DOC final e rever taxas (AP1900)' },
		AP1910: { key: 'AP1910', value: 'Em complementação (AP1910)' },
		AP1920: { key: 'AP1920', value: 'Revisão de taxas da DAM (AP1920)' },
		AP1950: { key: 'AP1950', value: 'Assinatura do revisor (AP1950)' },
		AP1990: { key: 'AP1990', value: 'Validação do deferimento (AP1990)' },
		UD0100: { key: 'UD0100', value: 'Encaminhamento para análise de viabilidade (UD0100)' },
		UD0200: { key: 'UD0200', value: 'Análise de viabilidade e realização de parecer (UD0200)' },
		UD0300: { key: 'UD0300', value: 'Consulta Assessoria Jurídica - CDRI' },
		UD0400: { key: 'UD0400', value: 'Consulta PGM - CDRI' },
		UD0600: { key: 'UD0600', value: 'Consulta Chefia - CDRI' },
		UD0700: { key: 'UD0700', value: 'Consulta Direção do Escritório de Licenciamento - CDRI' },
		UD0500: { key: 'UD0500', value: 'Em complementação (UD0500)' },
		...SITUACOES_SM.properties,
		...SITUACOES_EV.properties,
		...SITUACOES_FLUXOS_PADRAO.properties
	},
	value: s => (SITUACOES_AP.properties[s] || {}).value
};

export const ANDAMENTOS_AP = {
	CONCLUIDA: 'concluida',
	ANALISE_EVU: 'analiseEVU',
	COMPLEMENTAR: 'complementar',
	CONSULTA_ASSESSORIA: 'consultaAssessoria',
	CONSULTA_EDI: 'consultaEDI',
	CONSULTA_ATENDIMENTO: 'consultaAtendimento',
	CONSULTA_CAUGE: 'consultaCauge',
	CONSULTA_EPE1: 'consultaEpe1',
	CONSULTA_CHEFIA_TRIAGEM: 'consultaChefiaTriagem',
	CONSULTA_COORD_EDIF: 'consultaCoordEdif',
	CONSULTA_UEU_SMAMUS: 'consultaUeuSmamus',
	CONSULTA_UPU_SMAMUS: 'consultaUpuSmamus',
	DIGITALIZADO: 'digitalizado',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	EU_INVALIDO: 'euInvalido',
	CRIACAO_EU: 'criacaoEu',
	ATUALIZACAO_EU: 'atualizacaoEu',
	INDEFERIR_EU: 'indeferirEU',
	INDEFERIR: 'indeferir',
	PROC_ENCONTRADO: 'procEncontrado',
	TIPO_EU: 'tipoEU',
	TRAMITANDO_EU: 'tramitandoEU',
	BLOQUEADA_DMI_4_DISTRITO: 'bloqueadaDmi4Distrito',
	COMPRAR_INDICES: 'compraIndices',
	CONDICIONANTES: 'condicionantes',
	CONDICOES_DISTRIBUICAO: 'condicoesDistribuicao',
	CONSULTA_JURIDICA: 'consultaJuridica',
	DE_ACORDO_PARECER: 'deAcordoParecer',
	DUVIDAS_PARECER: 'duvidaParecer',
	EVU: 'evu',
	INDICES_CONSTRUTIVOS: 'indicesConstrutivos',
	INDICES_CONSTRUTIVOS13: 'indicesConstrutivos13',
	MAIS_QUE_3000: 'maisQue3000',
	PARECER_FAVORAVEL: 'parecerFavoravel',
	VISTO_CHEFIA: 'vistoChefia',
	PARALELAS: 'paralelas',
	PARECER_DESFAVORAVEL: 'parecerDesfavoravel',
	DISCORDA_REVISOR: 'discordaRevisor',
	CONSULTA_PGM: 'consultaPgm',
	EM_REANALISE_CONDICIONANTES: 'reanaliseCondicionantes',
	CONSULTAR_COORD_EDIF: 'consultaCoordenador',
	CONSULTAR_CHEFE_UAP: 'consultaChefeUap',
	CONSULTAR_DIRECAO: 'consultarDirecao',
	CONSULTA_CHEFIA_UDRI: 'consultaChefiaUdri',
	AGUARDA_TPC: 'aguardaTpc',

	// CONSTANTES SMXXXX - Devem ser iguais em ANDAMENTOS_AP e ANDAMENTOS_VS
	CONSULTA_CHEFE_ILHA: 'consultaChefeIlha',
	DEVOLVER_CHEFE_ILHA: 'devolverChefeIlha',
	ANALISE_DISTRIBUICAO_EAPIS: 'eapis',
	ANALISE_DISTRIBUICAO_EUOS: 'euos',
	ANALISE_MEIO_FISICO: 'fisico',
	ANALISE_MEIO_BIOTICO: 'biotico',
	ANALISE_AMBOS_MEIOS: 'ambos',
	RETORNAR_CHEFIA_CLA: 'retornaChefiaCla',
	CONSULTA_CLA: 'consultaCla',
	CONSULTA_DIRECAO: 'consultaDirecao',
	RETORNAR_CHEFIA: 'retornarChefia',
	ANALISE_COMISSAO: 'analiseComissao',
	RETORNO_REVISOR_UAP: 'retornoRevisorUAP',
	RETORNO_DISTRIBUICAO_UDRI: 'retornoDistribuicaoUdri',
	CONSULTAR_ASSISTENTE_UDRI: 'consultarAssistenteUdri',
	PULAR_COMPLEMENTACAO: 'pularComplementacao',
	RETORNO_REVISAO_TAXA: 'retornoRevisaoTaxa',
	RETORNO_ASSINATURAS: 'retornoAssinaturas',
	RETORNO_REVISOR: 'retornoRevisor',
	RETORNO_RECEBIMENTO: 'retornoRecebimento',

	PROC_NAO_ENCONTRADO_FINAL: 'procNaoEncontradoFinal',
	PROC_NAO_DIGITALIZADO_FINAL: 'naoDigitalizadoFinal',

	AP0100: {
		concluida: 'Recebimento do requerimento (AP0100) concluído, encaminhado para Registro de etapa (AP0200)',
		documentosInvalidos: 'Recebimento do requerimento (AP0100) concluído, etapa rejeitada e concluída.'
	},
	AP0200: {
		//	ambos iguais, a pedido do Andre. #52217
		concluida:
			'Registro de etapa (AP0200) concluído, encaminhado para Distribuição para Validação do Expediente Único e Documentos (AP0400)',
		criacaoEu:
			'Registro de etapa (AP0200) concluído, encaminhado para criação de expediente único e Distribuição para Validação do Expediente Único e Documentos (AP0400)',
		atualizacaoEu:
			'Registro de etapa (AP0200) concluído, encaminhado para atualização de expediente único e Distribuição para Validação do Expediente Único e Documentos (AP0400)',
		retornoRecebimento: 'Registro de etapa (AP0200) concluído, retornado para Recebimento do requerimento (AP0100)'
	},
	AP0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP0300) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)'
	},
	AP0370: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP0370) concluída, encaminhado para Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP0670)'
	},
	AP0380: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP0380) concluída, encaminhado para Consulta à Unidade de Projetos Urbanos - UPU/SMAMUS (AP0680)'
	},
	AP0400: {
		concluida:
			'Distribuição para Validação do Expediente Único e Documentos (AP0400) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)',
		consultaUeuSmamus:
			'Distribuição para Validação do Expediente Único e Documentos (AP0400) concluída, encaminhado para Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP0670)',
		consultaUpuSmamus:
			'Distribuição para Validação do Expediente Único e Documentos (AP0400) concluída, encaminhado para Consulta à Unidade de Projetos Urbanos - UPU/SMAMUS (AP0680)'
	},
	AP0500: {
		concluida:
			'Validação do Expediente Único e Documentos (AP0500) concluída, encaminhado para Pré-distribuição da Unidade de Aprovação de Projetos (AP1100)',
		consultaEDI:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Consulta à Equipe de Divulgação da Informação/SMAMUS (AP0600)',
		consultaAssessoria:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Consulta à Assessoria Jurídica (AP0700)',
		consultaAtendimento:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Consulta ao Atendimento (AP0610)',
		consultaCauge:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Consulta a CAUGE (AP0620)',
		consultaEpe1:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Consulta a EPE-1 (AP0630)',
		consultaChefiaTriagem:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Consulta a Chefia da Triagem (AP0640)',
		consultaUeuSmamus:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP0670)',
		consultaUpuSmamus:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Consulta à Unidade de Projetos Urbanos - UPU/SMAMUS (AP0680)',
		complementar:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		tipoEU:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Localizar pasta do Expediente Único (AP0800) para posterior encaminhamento para Digitalização do Expediente Único (AP0900)',
		existeEU:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Criação de Expediente Único (AP0510)',
		euInvalido:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, encaminhado para Transferência de etapa (AP0520)',
		tramitandoEU:
			'Validação do Expediente Único e Documentos (AP0500) aguardando, processo aguardando tramitação de outra etapa do Expediente Único',
		indeferirEU:
			'Validação do Expediente Único e Documentos (AP0500) concluída, encaminhado para Homologação da Validação do Expediente Único e Documentos (AP0905)',
		analiseEVU:
			'Validação do Expediente Único e Documentos (AP0500) concluída, encaminhado para Análise de Estudo de Viabilidade Urbanística (EVU)',
		retornarChefia:
			'Validação do Expediente Único e Documentos (AP0500) concluída, encaminhado para Distribuição para Validação do Expediente Único e Documentos (AP0400)'
	},
	AP0600: {
		concluida:
			'Consulta a Equipe de Divulgação da Informação/SMAMUS (AP0600) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)'
	},
	AP0610: {
		concluida:
			'Consulta ao Atendimento (AP0610) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)'
	},
	AP0620: {
		concluida:
			'Consulta a CAUGE (AP0620) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)'
	},
	AP0630: {
		concluida:
			'Consulta a EPE-1 (AP0630) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)'
	},
	AP0640: {
		concluida:
			'Consulta a Chefia da Triagem (AP0640) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)',
		consultaCoordenador:
			'Consulta a Chefia da Triagem (AP0640) concluída, encaminhado para Consulta à Coordenação de Edificações (AP0650)'
	},
	AP0650: {
		concluida:
			'Consulta a Coordenação de Edificações (AP0650) concluída, retornando para Consulta à Chefia da Triagem (AP0640)',
		consultarDirecao:
			'Consulta a Coordenação de Edificações (AP0650) concluída, encaminhado para Consulta à Direção do Escritório de Licenciamento (AP0660)'
	},
	AP0660: {
		concluida:
			'Consulta à Direção do Escritório de Licenciamento (AP0660) concluida, retornando para Consulta a Coordenação de Edificações (AP0650)'
	},
	AP0670: {
		concluidaAP0400:
			'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP0670) concluida, retornando para Distribuição para Validação do Expediente Único e Documentos (AP0400)',
		concluidaAP0500:
			'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP0670) concluida, retornando para Validação do Expediente Único e Documentos (AP0500)',
		complementar:
			'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP0670) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	AP0680: {
		concluidaAP0400:
			'Consulta à Unidade de Projetos Urbanos - UPU/SMAMUS (AP0680) concluida, retornando para Distribuição para Validação do Expediente Único e Documentos (AP0400)',
		concluidaAP0500:
			'Consulta à Unidade de Projetos Urbanos - UPU/SMAMUS (AP0680) concluida, retornando para Validação do Expediente Único e Documentos (AP0500)',
		complementar:
			'Consulta à Unidade de Projetos Urbanos - UPU/SMAMUS (AP0680) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	AP0700: {
		concluida:
			'Consulta a Assessoria Jurídica (AP0700) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)'
	},
	AP0800: {
		concluida:
			'Localização do Expediente Único no Arquivo (AP0800) concluída, encaminhado para Digitalização do Expediente Único (AP0900)',
		procEncontrado:
			'Localização do Expediente Único no Arquivo (AP0800) concluída, processo não localizado e processo B criado. Encaminhado para Validação do Expedientes Único e Documentos (AP0500)'
	},
	AP0900: {
		concluida:
			'Digitalização do Expediente Único (AP0900) concluído, encaminhado para Validação do Expediente Único e Documentos (AP0500)',
		digitalizado:
			'Digitalização do Expediente Único (AP0900) não concluído, encaminhado para Validação do Expediente Único e Documentos (AP0500)'
	},
	AP0905: {
		concluida:
			'Homologação da Validação do Expediente Único e Documentos (AP0905) concluída, processo indeferido e etapa encerrada',
		indeferirEU:
			'Homologação da Validação do Expediente Único e Documentos (AP0905) concluída, encaminhado para Validação do Expediente Único e Documentos (AP0500)'
	},
	AP1000: {
		concluida: 'Indeferimento do processo (AP1000) concluído e etapa encerrada.'
	},
	AP1100: {
		concluida:
			'Pré-distribuição da Unidade de Aprovação de Projetos (AP1100) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)',
		caadhap:
			'Pré-distribuição da Unidade de Aprovação de Projetos (AP1100) concluída, encaminhado para CAADHAP (AP1280).',
		cefh: 'Pré-distribuição da Unidade de Aprovação de Projetos (AP1100) concluída, encaminhado para CEFH (AP1281).'
	},
	AP1101: {
		concluida:
			'Pré-distribuição de Ajustes de Projetos (AP1101) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)',
		caadhap: 'Pré-distribuição de Ajustes de Projetos (AP1101) concluída, encaminhado para CAADHAP (AP1280).',
		cefh: 'Pré-distribuição de Ajustes de Projetos (AP1101) concluída, encaminhado para CEFH (AP1281).'
	},
	AP1200: {
		concluida:
			'Análise de condicionantes e distribuição (AP1200) concluída, encaminhado Análise do projeto arquitetônico (AP1500)',
		paralelas: 'Análise de condicionantes e distribuição (AP1200) aguardando, encaminhado para Consultas externas',
		complementar:
			'Análise de condicionantes e distribuição (AP1200) aguardando, encaminhado para Complementação de informações pelo Responsável Técnico',
		retornoDistribuicao:
			'Análise de condicionantes e distribuição (AP1200) concluída, encaminhado para Pré-distribuição da Unidade de Aprovação de Projetos (AP1100)',
		retornoDistribuicaoAjustes:
			'Análise de condicionantes e distribuição (AP1200) concluída, encaminhado para Pré-distribuição de Ajustes de Projetos (AP1101)',
		digitalizacao:
			'Análise de condicionantes e distribuição (AP1200) aguardando, encaminhado para Localizar pasta do Expediente Único (AP1430) para posterior encaminhamento para Digitalização do Expediente Único (AP1460)',
		condicoesDistribuicao:
			'Análise de condicionantes e distribuição (AP1200) concluída, encaminhado para Homologação do parecer (AP1890)',
		evu: 'Análise de condicionantes e distribuição (AP1200) aguardando, encaminhado para Análise de Estudo de Viabilidade Urbanística (EVU) de 1º Grau',
		condicionantes:
			'Análise de condicionantes e distribuição (AP1200) aguardando, encaminhado para análise de outros setores'
	},
	AP1205: {
		concluida:
			'Consulta Chefe da Unidade de Aprovação de Projetos (AP1205) concluída, processo retornado ao Chefe de Ilha (AP1240)'
	},
	AP1209: {
		concluida:
			'Consulta à CEVEA (AP1209) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)',
		complementar:
			'Consulta à CEVEA (AP1209) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	AP1210: {
		concluida:
			'Consulta à Assessoria Jurídica concluída (AP1210) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)'
	},
	AP1215: {
		concluida:
			'Consulta à Assessoria Jurídica concluída (AP1215) concluída, encaminhado para Análise do projeto arquitetônico (AP1500)'
	},
	AP1220: {
		concluida:
			'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP1220) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)',
		complementar:
			'Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP1220) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	AP1230: {
		concluida:
			'Consulta Enquadramento LEI 960/22 4º Distrito (AP1230) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)',
		complementar:
			'Consulta Enquadramento LEI 960/22 4º Distrito (AP1230) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	AP1240: {
		consultaCoordenador:
			'Consulta ao Chefe de Ilha (AP1240) concluída, encaminhada para consulta ao Coordenador de Edificações (AP1290)',
		consultaChefeUap:
			'Consulta ao Chefe de Ilha (AP1240) concluída, encaminhada para consulta Chefe da Unidade de Aprovação de Projetos (AP1205)',
		concluidaAP1500:
			'Consulta ao Chefe de Ilha (AP1240) concluída, encaminhado para Análise do projeto arquitetônico (AP1500)',
		concluidaAP1950:
			'Consulta ao Chefe de Ilha (AP1240) concluída, encaminhado para Revisão final e assinatura do projeto arquitetônico (AP1950)'
	},
	AP1250: {
		concluida:
			'Consulta à Comissão Consultiva do Código de Edificações (CCCE) (AP1250) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)'
	},
	AP1255: {
		concluida:
			'Consulta à Comissão Consultiva do Código de Edificações (CCCE) (AP1255) concluída, encaminhado para Análise do projeto arquitetônico (AP1500)'
	},
	AP1260: {
		concluida:
			'Consulta ao Grupo de Regulamentação do Plano Diretor (GRPDDUA) (AP1260) concluída, encaminhado para Análise do projeto arquitetônico (AP1500)'
	},
	AP1265: {
		concluida:
			'Consulta a Diretoria de Projetos e Políticas de Sustentabilidade (DPPS) (AP1265) concluída, encaminhado para Análise do projeto arquitetônico (AP1500)'
	},
	AP1270: {
		complementar:
			'Consulta à CAUGE (AP1270) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		concluida: 'Consulta à CAUGE (AP1270) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)'
	},
	AP1280: {
		complementar:
			'Consulta à CAADHAP (AP1280) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		concluida:
			'Consulta à CAADHAP (AP1280) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)'
	},
	AP1281: {
		complementar:
			'Consulta à CEFH (AP1281) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		concluida: 'Consulta à CEFH (AP1281) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)'
	},
	AP1290: {
		concluidaAP1200:
			'Consulta ao Coordenador de Edificações (AP1290) concluída, processo retornado para Análise de condicionantes e distribuição (AP1200)',
		concluidaAP1240:
			'Consulta ao Coordenador de Edificações (AP1290) concluída, processo retornado ao Chefe de Ilha (AP1240)',
		concluidaAP1920:
			'Consulta ao Coordenador de Edificações (AP1290) concluída, processo retornado para Conferência da Taxa (AP1920)',
		consultarDirecao:
			'Consulta ao Coordenador de Edificações (AP1290) concluída, encaminhada para Consulta à Direção do EL (AP1299)'
	},
	AP1299: {
		concluida:
			'Consulta à Direção do EL (AP1299) concluída, encaminhado para Consulta ao Coordenador de Edificações (AP1290)'
	},
	AP1300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1300) concluída, encaminhado para Análise de condicionantes e distribuição (AP1200)'
	},
	AP1309: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1309) concluída, encaminhado para CEVEA (AP1209)'
	},
	AP1320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1320) concluída, encaminhado para Consulta à Unidade de Estudos Urbanos - UEU/SMAMUS (AP1220)'
	},
	AP1330: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1330) concluída, encaminhado para Consulta Enquadramento LEI 960/22 4º Distrito (AP1230)'
	},
	AP1370: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1370) concluída, encaminhado para Consulta à CAUGE (AP1270)'
	},
	AP1380: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1380) concluída, encaminhado para Consulta à CAADHAP (AP1280)'
	},
	AP1381: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1381) concluída, encaminhado para Consulta à CEFH (AP1281)'
	},
	AP1430: {
		concluida:
			'Localização do Expediente Único no Arquivo (AP1430) concluída, encaminhado para Digitalização do Expediente Único (AP1460)',
		procNaoEncontrado1200:
			'Localização do Expediente Único no Arquivo (AP1430) concluída, processo não localizado e processo B criado. Encaminhado para Análise de condicionantes e distribuição (AP1200)',
		procNaoEncontrado1500:
			'Localização do Expediente Único no Arquivo (AP1430) concluída, processo não localizado e processo B criado. Encaminhado para Análise do projeto arquitetônico (AP1500)',
		procNaoEncontradoFinal:
			'Localização do Expediente Único no Arquivo (AP1430) concluída, processo não localizado e processo B criado, encaminhado para encerramento de fluxo'
	},
	AP1460: {
		concluida1200:
			'Digitalização do Expediente Único (AP1460) concluído, encaminhado para Análise de condicionantes e distribuição (AP1200)',
		naoDigitalizado1200:
			'Digitalização do Expediente Único (AP1460) não concluído, encaminhado para Análise de condicionantes e distribuição (AP1200)',
		concluida1500:
			'Digitalização do Expediente Único (AP1460) concluído, encaminhado para Análise do projeto arquitetônico (AP1500)',
		naoDigitalizado1500:
			'Digitalização do Expediente Único (AP1460) não concluído, encaminhado para Análise do projeto arquitetônico (AP1500)',
		concluidaFinal: 'Digitalização do Expediente Único (AP1460) concluído, encaminhado para encerramento de fluxo',
		naoDigitalizadoFinal:
			'Digitalização do Expediente Único (AP1460) não concluído, encaminhado para encerramento de fluxo'
	},
	AP1500: {
		concluida:
			'Análise do projeto arquitetônico (AP1500) concluída, encaminhado para Inclusão de condicionantes do projeto arquitetônico (AP1600)',
		duvidaParecer:
			'Análise do projeto arquitetônico (AP1500) aguardando, encaminhado para Análise de outras secretarias',
		complementar:
			'Análise do projeto arquitetônico (AP1500) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		vistoChefia:
			'Análise do projeto arquitetônico (AP1500) concluída, encaminhado para Visto do Chefe de Ilha (AP1700)',
		consultaChefeIlha:
			'Análise do projeto arquitetônico (AP1500) aguardando, encaminhado para Consulta ao Chefe de Ilha (AP1240)',
		reanaliseCondicionantes:
			'Análise do projeto arquitetônico (AP1500) aguardando, encaminhado para Reanálise de condicionantes (AP1510)',
		parecerDesfavoravel:
			'Análise do projeto arquitetônico (AP1500) concluída, encaminhado para Homologação do parecer (AP1890)'
	},
	AP1510: {
		concluida:
			'Reanálise de condicionantes (AP1510) concluída, encaminhado para Análise do projeto arquitetônico (AP1500)',
		evu: 'Reanálise de condicionantes (AP1510) concluída, encaminhado para Análise de Estudo de Viabilidade Urbanística (EVU)',
		condicionantes: 'Reanálise de condicionantes (AP1510) concluída, encaminhado para análise de outros setores'
	},
	AP1515: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1515) concluída, encaminhado para Análise do projeto arquitetônico (AP1500)'
	},
	AP1600: {
		concluida:
			'Análise de condicionantes de aprovação (AP1600) concluída, encaminhado para Consolidação projeto arquitetônico para assinatura e conferência da taxa (AP1900)',
		vistoChefia:
			'Análise de condicionantes de aprovação (AP1600) concluída, encaminhado para Visto do Chefe de Ilha (AP1700)',
		parecerFavoravel:
			'Análise de condicionantes de aprovação (AP1600) concluída, encaminhado para Homologação do parecer (AP1890)',
		indicesConstrutivos:
			'Análise de condicionantes de aprovação (AP1600) concluída, encaminhado para Verificação de aquisição de índice construtivo (AP1800)',
		indicesConstrutivos13:
			'Análise de condicionantes de aprovação (AP1600) concluída, encaminhado para Distribuição CDRI (AP1750)'
	},
	AP1700: {
		parecerDesfavoravel:
			'Análise do parecer final pela chefia (AP1700) concluída, encaminhado para Homologação do parecer (AP1890)',
		indicesConstrutivos:
			'Análise do parecer final pela chefia (AP1700) concluída, encaminhado para Efetivação de aquisição de índice construtivo (AP1800)',
		indicesConstrutivos13:
			'Análise do parecer final pela chefia (AP1700) concluída, encaminhado para Distribuição CDRI (AP1750)',
		complementar:
			'Análise do parecer final pela chefia (AP1700) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		discordaRevisor:
			'Análise do parecer final pela chefia (AP1700) concluída, encaminhado para Análise do projeto arquitetônico (AP1500)',
		concluida:
			'Análise do parecer final pela chefia (AP1700) concluída, encaminhado para Consolidação projeto arquitetônico para assinatura e conferência da taxa (AP1900)'
	},
	AP1710: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1710) concluído, encaminhado para Análise do parecer final pela chefia (AP1700)'
	},
	AP1750: {
		concluida:
			'Distribuição CDRI (AP1750) concluída, encaminhado para Efetivação de aquisição de índice construtivo (AP1800)',
		retornoRevisorUap: 'Distribuição CDRI (AP1750) concluída, retorna para Análise do projeto arquitetônico (AP1500)'
	},
	AP1800: {
		aguardaTpc:
			'Efetivação de aquisição de índice construtivo (AP1800) aguardando, encaminhado para Aguardando TPC (AP1850)',
		parecerDesfavoravel:
			'Efetivação de aquisição de índice construtivo (AP1800) concluída, encaminhado para Homologação do parecer (AP1890)',
		consultarAssistenteUdri:
			'Efetivação de aquisição de índice construtivo (AP1800) concluída, encaminhado para Consulta à Assistente CDRI (AP1820)',
		complementar:
			'Efetivação de aquisição de índice construtivo (AP1800) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		concluida:
			'Efetivação de aquisição de índice construtivo (AP1800) concluída, encaminhado para Consolidação projeto arquitetônico para assinatura e conferência da taxa (AP1900)',
		retornoRevisorUap:
			'Efetivação de aquisição de índice construtivo (AP1800) concluída, retorna para Análise do projeto arquitetônico (AP1500)',
		retornoDistribuicaoUdri:
			'Efetivação de aquisição de índice construtivo (AP1800) concluída, retorna para Distribuição CDRI (AP1750)'
	},
	AP1810: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1810) concluída, retornado para Efetivação de aquisição de índice construtivo (AP1800)'
	},
	AP1820: {
		complementar:
			'Consulta à Assistente CDRI (AP1820) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		concluida:
			'Consulta à Assistente CDRI (AP1820) concluída, encaminhado para Efetivação de aquisição de índice construtivo (AP1800)'
	},
	AP1822: {
		concluida:
			'Corrige geração de boletos automáticos CDRI (AP1822) concluída, encaminhado para deferimento no Expedientes e etapa encerrada'
	},
	AP1825: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (AP1825) concluída, encaminhado para Consulta à Assistente CDRI (AP1820)'
	},
	AP1850: {
		concluida:
			'Aguardando TPC (AP1850) concluída, encaminhado para Efetivação de aquisição de índice construtivo (AP1800)'
	},
	AP1890: {
		concluida: 'Homologação do parecer (AP1890) concluída, encaminhado para Indeferimento no Expedientes',
		concordaIndeferimento:
			'Homologação do parecer (AP1890) concluída, etapa não homologada, retorna para Análise do projeto arquitetônico (AP1500)'
	},
	AP1900: {
		concluida:
			'Consolidação projeto arquitetônico para assinatura e conferência da taxa (AP1900) concluída, encaminhado para Assinatura do projeto arquitetônico e, caso necessário, para complementação de taxa pelo Responsável Técnico (AP1910)',
		retornoRevisorUAP:
			'Consolidação projeto arquitetônico para assinatura e conferência da taxa (AP1900) concluída, retorna para Análise do projeto arquitetônico (AP1500)',
		pularComplementacao:
			'Consolidação projeto arquitetônico para assinatura e conferência da taxa (AP1900) concluída, encaminhado para Conferência da Taxa (AP1920)'
	},
	AP1910: {
		concluida:
			'Assinatura do projeto arquitetônico e, caso necessário, para complementação de taxa pelo Responsável Técnico (AP1910) concluída, encaminhado para Revisão final e assinatura do projeto arquitetônico (AP1950)',
		taxaComplementar:
			'Assinatura do projeto arquitetônico e, caso necessário, para complementação de taxa pelo Responsável Técnico (AP1910) concluída, encaminhado para Retorno da complementação da Taxa (AP1920)'
	},
	AP1920: {
		concluida:
			'Retorno da complementação da Taxa (AP1920) concluída, encaminhado para Revisão final e assinatura do projeto arquitetônico (AP1950)',
		consultaCoordenador:
			'Conferência da Taxa (AP1920) concluída, encaminhada para consulta ao Coordenador de Edificações (AP1290)',
		parecerFavoravel:
			'Conferência da Taxa (AP1920) concluída, encaminhado para Consolidação projeto arquitetônico para assinatura e conferência da taxa (AP1900)'
	},
	AP1950: {
		concluida:
			'Revisão final e assinatura do projeto arquitetônico (AP1950) concluída, encaminhado para deferimento no Expedientes e etapa encerrada',
		parecerFavoravel:
			'Revisão final e assinatura do projeto arquitetônico (AP1950) concluída, retorna para Análise do projeto arquitetônico (AP1500)',
		vistoChefia:
			'Revisão final e assinatura do projeto arquitetônico (AP1950) concluída, encaminhado para Homologação do deferimento (AP1990)',
		retornoRevisaoTaxa:
			'Revisão final e assinatura do projeto arquitetônico (AP1950) concluída, retorna para Conferência da Taxa (AP1920)',
		consultaChefeIlha:
			'Revisão final e assinatura do projeto arquitetônico (AP1950) aguardando, encaminhado para Consulta ao Chefe de Ilha (AP1240)'
	},
	AP1990: {
		concluida:
			'Homologação do deferimento (AP1990) concluída, encaminhado para deferimento no Expedientes e etapa encerrada',
		retornoAssinaturas:
			'Homologação do deferimento (AP1990) concluída, retorna para Revisão final e assinatura do projeto arquitetônico (AP1950)',
		retornoRevisor:
			'Homologação do deferimento (AP1990) concluída, retorna para Análise do projeto arquitetônico (AP1500)'
	},

	UD0100: {
		concluida:
			'Distribuição da análise de viabilidade de índice construtivo (UD0100) concluída, encaminhado para Análise de viabilidade de índice construtivo (UD0200)'
	},
	UD0200: {
		consultaAssessoria:
			'Análise de viabilidade de índice construtivo (UD0200) aguardando, encaminhado para Consulta à Assessoria Jurídica (UD0300)',
		consultaPgm:
			'Análise de viabilidade de índice construtivo (UD0200) aguardando, encaminhado para Consulta à Procuradoria-Geral do Município (UD0400)',
		complementar:
			'Análise de viabilidade de índice construtivo (UD0200) aguardando, encaminhado para Complementação de documentos e/ou informações da viabilidade de índice construtivo pelo Responsável Técnico (UD0500)',
		consultaChefiaUdri:
			'Análise de viabilidade de índice construtivo (UD0200) aguardando, encaminhado para Consulta a Chefia UDRI (UD0600)',
		concluida:
			'Análise de viabilidade de índice construtivo (UD0200) concluído, encaminhado para a Unidade de Aprovação de Projetos'
	},
	UD0300: {
		concluida:
			'Consulta à Assessoria Jurídica (UD0300) concluído, encaminhado para Análise de viabilidade de índice construtivo (UD0200)'
	},
	UD0400: {
		concluida:
			'Consulta à Procuradoria-Geral do Município (UD0400) concluído, encaminhado para Análise de viabilidade de índice construtivo (UD0200)'
	},
	UD0500: {
		concluida:
			'Complementação de documentos e/ou informações da viabilidade de índice construtivo pelo Responsável Técnico (UD0500) concluída, encaminhado para Análise de viabilidade de índice construtivo (UD0200)'
	},
	UD0600: {
		consultarDirecao:
			'Consulta à Chefia da CDRI (UD0600) concluída, encaminhado para Consulta à Direção do Escritório de Licenciamento (UD0700)',
		concluida:
			'Consulta à Chefia da CDRI (UD0600) concluída, encaminhado para Análise de viabilidade de índice construtivo (UD0200)'
	},
	UD0700: {
		concluida:
			'Consulta à Direção do Escritório de Licenciamento (UD0700) concluída, processo retornado para Consulta à Chefia da CDRI (UD0600)'
	},
	...getAndamentosSM('Unidade de Aprovação de Projetos'),
	...getAndamentosEV('Unidade de Aprovação de Projetos'),
	...getAndamentosFluxosPadrao('Unidade de Aprovação de Projetos'),

	getAndamento: (taskname, andamento = 'concluida') => {
		const taskDef = ANDAMENTOS_AP[taskname] || {};
		return taskDef[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`;
	}
};

export const SITUACOES_VS = {
	VS0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'VS0100',
	VS0200_EM_VERIFICACAO_EXPEDIENTE_UNICO: 'VS0200',
	VS0300_LOCALIZAR_PASTA_EU: 'VS0300',
	VS0400_DIGITALIZAR_EU: 'VS0400',
	VS0500_DISTRIBUIR_PARA_TRIAGEM: 'VS0500',
	VS0510_EM_CONSULTA_CHEFE_VISTORIA: 'VS0510',
	VS0600_TRIAGEM_VISTORIA: 'VS0600',
	VS0610_EM_CONSULTA_CHEFE_VISTORIA: 'VS0610',
	VS0675_EM_DISTRIBUICAO_EQUIPE_CARTAS: 'VS0675',
	VS0680_EM_CONF_REG_CARTA_HABITACAO: 'VS0680',
	VS0685_EM_CONSULTA_REGISTROS_UAP: 'VS0685',
	VS0688_EM_CONSULTA_CHEFE_VISTORIA: 'VS0688',
	VS0690_HOMOLOGACAO_INDEFERIMENTO: 'VS0690',
	VS0695_EM_CONSULTA_CHEFE_VISTORIA: 'VS0695',
	VS0696_EM_CONSULTA_ESCRITORIO_LICENCIAMENTO: 'VS0696',
	VS0800_HOMOLOGAR_CARTA_HABITACAO: 'VS0800',
	EU1100_EM_CONSULTA_ERDU_SMAMUS: 'EU1100',
	EC1100_EM_CONSULTA_EACEU_SMAMUS: 'EC1100',
	EC1300_EM_COMPLEMENTACAO: 'EC1300',
	EP1100_EM_CONSULTA_EAP_ETPC: 'EP1100',
	EP1300_EM_COMPLEMENTACAO: 'EP1300',
	ES1100_EM_CONSULTA_EDI_SMAMUS: 'ES1100',
	UA1100_EM_CONSULTA_UAP_SMAMUS: 'UA1100',
	CA1100_EM_CONSULTA_CAADHAP: 'CA1100',
	PH1100_EM_CONSULTA_EPAHC: 'PH1100',
	DM1000_DMAE_DISTRIBUIR_PROCESSO: 'DM1000',
	DM1100_EM_ANALISE_PROCESSO_TECNICO: 'DM1100',
	DM1200_EM_ANALISE_PROCESSO_TECNICO: 'DM1200',
	DM1300_EM_ANALISE_PROCESSO_TECNICO: 'DM1300',
	DM2000_DMAE_VALIDACAO_PARECERES: 'DM2000',
	DM3000_EM_COMPLEMENTACAO: 'DM3000',
	PV1000_DPOV_SMOI_DISTRIBUIR_PROCESSO: 'PV1000',
	PV1100_EM_ANALISE_PROCESSO_TECNICO: 'PV1100',
	PV1200_EM_ANALISE_PROCESSO_TECNICO: 'PV1200',
	PV1300_EM_ANALISE_PROCESSO_TECNICO: 'PV1300',
	PV2000_DPOV_SMOI_VALIDACAO_PARECERES: 'PV2000',
	QP1000_DISTRIBUIR_PROCESSO: 'QP1000',
	QP1100_EM_ANALISE_PROCESSO_TECNICO: 'QP1100',
	QP1200_EM_ANALISE_PROCESSO_TECNICO: 'QP1200',
	QP1300_EM_ANALISE_PROCESSO_TECNICO: 'QP1300',
	QP2000_VALIDACAO_PARECERES: 'QP2000',
	PG0100_EM_DISTRIBUICAO: 'PG0100',
	PG0200_EM_ANALISE_TERMOS: 'PG0200',
	PG0300_EM_COMPLEMENTACAO: 'PG0300',
	PG0210_EM_CONSULTA_PROCURADORIA_SETORIAL: 'PG0210',
	PG0400_EM_AGUARDANDO_RESPOSTAS: 'PG0400',
	PG0500_EM_DISTRIBUICAO: 'PG0500',
	PG0600_EM_ANALISE_PROCURADORIA_SETORIAL: 'PG0600',
	PG0700_EM_AGUARDANDO_ANALISE_JURIFICA: 'PG0700',
	PG0800_EM_CONSULTA_PROCURADORIA_ADJUNTA: 'PG0800',
	EA1000_EM_RECEBIMENTO_ANALISE_PROC: 'EA1000',
	EA1100_EM_REALIZACAO_DO_LEVANTAMENTO: 'EA1100',
	EA1200_EM_VERIF_GRAVAME_ELAB_PARECER: 'EA1200',
	EA1300_EM_ANALISE_DOC_ELABORACAO_CROQUI: 'EA1300',

	...SITUACOES_SM.situacoes,

	properties: {
		VS0100: { key: 'VS0100', value: 'Recebimento do requerimento (VS0100)' },
		VS0200: { key: 'VS0200', value: 'Registro da etapa (VS0200)' },
		VS0300: { key: 'VS0300', value: 'Localização da pasta do Expediente Único (VS0300)' },
		VS0400: { key: 'VS0400', value: 'Digitalização do Expediente Único (VS0400)' },
		VS0500: { key: 'VS0500', value: 'Distribuição da Análise Documental e Condicionantes (VS0500)' },
		VS0510: { key: 'VS0510', value: 'Consulta à Chefia Unidade de Vistoria Predial (VS0510)' },
		VS0600: { key: 'VS0600', value: 'Análise Documental e Condicionantes (VS0600)' },
		VS0610: { key: 'VS0610', value: 'Consulta à Chefia Unidade de Vistoria Predial (VS0610)' },
		VS0620: { key: 'VS0620', value: 'Consulta ao Setor de Numeração (VS0620)' },
		VS0625: { key: 'VS0625', value: 'Em complementação (VS0625)' },
		VS0630: { key: 'VS0630', value: 'Consulta à SMF (VS0630)' },
		VS0640: { key: 'VS0640', value: 'Consulta ao GEO (VS0640)' },
		VS0650: { key: 'VS0650', value: 'Em complementação (VS0650)' },
		VS0675: { key: 'VS0675', value: 'Distribuição da Equipe de Cartas (VS0675)' },
		VS0680: { key: 'VS0680', value: 'Conferência e registro da Carta de Habitação (VS0680)' },
		VS0685: { key: 'VS0685', value: 'Consulta à Unidade de Aprovação de Projetos (VS0685)' },
		VS0687: { key: 'VS0687', value: 'Consulta à Chefia da Unidade de Licenciamento Expresso (VS0687)' },
		VS0688: { key: 'VS0688', value: 'Consulta à Chefia Unidade de Vistoria Predial (VS0688)' },
		VS0690: { key: 'VS0690', value: 'Homologação da Análise Documental e Condicionantes (VS0690)' },
		VS0695: { key: 'VS0695', value: 'Consulta à Chefia Unidade de Vistoria Predial (VS0695)' },
		VS0696: { key: 'VS0696', value: 'Consulta Direção do Escritório de Licenciamento (VS0696)' },
		VS0700: { key: 'VS0700', value: 'Consulta Coordenação de Edificações (VS0700)' },
		VS0750: { key: 'VS0750', value: 'Consulta Direção do Escritório de Licenciamento (VS0750)' },
		VS0800: { key: 'VS0800', value: 'Homologação da Carta de Habitação (VS0800)' },
		VS0850: { key: 'VS0850', value: 'Em complementação (VS0850)' },
		VS0900: { key: 'VS0900', value: 'Consulta Assessoria Jurídica (VS0900)' },
		EU1100: { key: 'EU1100', value: 'Consulta à Índices construtivos (EU1100)' },
		EU1300: { key: 'EU1300', value: 'Em complementação (EU1300)' },
		EC1100: { key: 'EC1100', value: 'Consulta à Urbanização e/ou Obras de Logradouro (EC1100)' },
		EC1300: { key: 'EC1300', value: 'Em complementação (EC1300)' },
		EP1100: { key: 'EP1100', value: 'Consulta à Diretrizes SMMU (EP1100)' },
		EP1300: { key: 'EP1100', value: 'Em complementação (EP1300)' },
		ES1100: { key: 'ES1100', value: 'Consulta ao Cadastramento de Logradouro (ES1100)' },
		ES1300: { key: 'ES1300', value: 'Em complementação (ES1300)' },
		UA1100: { key: 'UA1100', value: 'Consulta à Unidade de Aprovação de Projetos (UA1100)' },
		UA1300: { key: 'UA1300', value: 'Em complementação (UA1300)' },
		CA1100: { key: 'CA1100', value: 'Consulta à CAADHAP (CA1100)' },
		CA1300: { key: 'CA1300', value: 'Em complementação (CA1300)' },
		PH1100: { key: 'PH1100', value: 'Consulta ao Patrimônio Histórico e Cultural (PH1100)' },
		PH1300: { key: 'PH1300', value: 'Em complementação (PH1300)' },
		DM1000: { key: 'DM1000', value: 'Distribuição Departamento Municipal de Água e Esgoto (DM1000)' },
		DM2000: { key: 'DM2000', value: 'Validação de pareceres Departamento Municipal de Água e Esgoto (DM2000)' },
		DM1100: { key: 'DM1100', value: 'Análise técnica Departamento Municipal de Água e Esgoto (DM1100)' },
		DM1200: { key: 'DM1200', value: 'Análise técnica Departamento Municipal de Água e Esgoto (DM1200)' },
		DM1300: { key: 'DM1300', value: 'Análise técnica Departamento Municipal de Água e Esgoto (DM1300)' },
		DM3100: { key: 'DM3100', value: 'Em complementação (DM3100)' },
		DM3200: { key: 'DM3200', value: 'Em complementação (DM3200)' },
		PV1000: { key: 'PV1000', value: 'Distribuição Projetos Viários (PV1000)' },
		PV2000: { key: 'PV2000', value: 'Validação de pareceres Projetos Viários (PV2000)' },
		PV1100: { key: 'PV1100', value: 'Análise técnica Projetos Viários (PV1100)' },
		PV1200: { key: 'PV1200', value: 'Análise técnica Projetos Viários (PV1200)' },
		PV1300: { key: 'PV1300', value: 'Análise técnica Projetos Viários (PV1300)' },
		PV3000: { key: 'PV3000', value: 'Em complementação (PV3000)' },
		PV3100: { key: 'PV3100', value: 'Em complementação (PV3100)' },
		PV3200: { key: 'PV3200', value: 'Em complementação (PV3200)' },
		QP1000: { key: 'QP1000', value: 'Distribuição Questões Pluviais (QP1000)' },
		QP2000: { key: 'QP2000', value: 'Validação de pareceres Questões Pluviais (QP2000)' },
		QP1100: { key: 'QP1100', value: 'Análise técnica Questões Pluviais (QP1100)' },
		QP1200: { key: 'QP1200', value: 'Análise técnica Questões Pluviais (QP1200)' },
		QP1300: { key: 'QP1300', value: 'Análise técnica Questões Pluviais (QP1300)' },
		QP3000: { key: 'QP3000', value: 'Em complementação (QP3000)' },
		QP3100: { key: 'QP3100', value: 'Em complementação (QP3100)' },
		QP3200: { key: 'QP3200', value: 'Em complementação (QP3200)' },
		PG0100: { key: 'PG0100', value: 'Distribuição Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0100)' },
		PG0200: {
			key: 'PG0200',
			value: 'Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200)'
		},
		PG0300: { key: 'PG0300', value: 'Em complementação (PG0300)' },
		PG0310: { key: 'PG0310', value: 'Em complementação (PG0310)' },
		PG0210: { key: 'PG0210', value: 'Consulta à Procuradoria Setorial/PGM (PG0210)' },
		PG0400: { key: 'PG0400', value: 'Aguardando Respostas Termo de Compromisso (PG0400)' },
		PG0500: { key: 'PG0500', value: 'Distribuição Procuradoria Setorial/PGM (PG0500)' },
		PG0600: { key: 'PG0600', value: 'Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600)' },
		PG0700: { key: 'PG0700', value: 'Aguardando Análise Jurídica (PG0700)' },
		PG0800: { key: 'PG0800', value: 'Consulta à Procuradoria Adjunta (PG0800)' },
		EA1000: { key: 'EA1000', value: 'Recebimento e Análise do Alinhamento Predial (EA1000)' },
		EA1100: { key: 'EA1100', value: 'Realização do Levantamento Topográfico (EA1100)' },
		EA1200: { key: 'EA1200', value: 'Verificação do Gravame e Elaboração do Parecer (EA1200)' },
		EA1300: { key: 'EA1300', value: 'Análise da Documentação e Elaboração do Croqui (EA1300)' },
		AJ1000: { key: 'AJ1000', value: 'Consulta à Assessoria Jurídica (AJ1000)' },
		DM3000: { key: 'DM3000', value: 'Em complementação (DM3000)' },
		...SITUACOES_SM.properties
	},
	value: s => (SITUACOES_VS.properties[s] || {}).value
};

export const ANDAMENTOS_VS = {
	CONCLUIDA: 'concluida',
	CONCLUIDAV10: 'concluidav10',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	EU_INVALIDO: 'euInvalido',
	EXISTE_EU: 'existeEU',
	TIPO_EU: 'tipoEU',
	TRAMITANDO_EU: 'tramitandoEU',
	DIGITALIZAR: 'digitalizar',
	RETORNAR_PARA_ATENDIMENTO: 'retornoAtendimento',
	DUVIDAS_ETAPA: 'duvidasEtapa',
	REDISTRIBUICAO: 'redistribuicao',
	REDIGITALIZACAO: 'redigitalizacao',
	COMPLEMENTAR: 'complementar',
	CONSULTA_CHEFE_VISTORIA: 'consultaChefeVistoria',
	ANALISE_OUTRAS_SECRETARIAS: 'analiseOutrasSecretarias',
	INDEFERIR_EU: 'indeferirEU',
	RETORNAR_TRIAGEM: 'retornarTriagem',
	RETORNAR_EQUIPE_CARTAS: 'retornarEquipeCartas',
	RETORNO_VERIFICACAO_DOCS: 'retornoVerificacaoDocs',
	VISTO_CHEFIA: 'vistoChefia',
	PARECER_DESFAVORAVEL: 'parecerDesfavoravel',
	EXISTE_GRAVAME: 'existeGravame',
	PROC_ENCONTRADO: 'procEncontrado',
	DIGITALIZACAO_0500: 'digitalizacao0500',
	DIGITALIZACAO_0600: 'digitalizacao0600',
	DIGITALIZACAO_ENCERRAMENTO: 'digitalizacaoEncerramento',
	CONSULTAR_REGISTROS_UAP: 'consultarRegistrosUAP',
	CONSULTA_SECRETARIAS: 'consultaSecretarias',
	CONSULTAR_COORD_EDIF: 'consultaCoordenador',
	CONSULTAR_DIRECAO: 'consultarDirecao',

	// CONSTANTES SMXXXX - Devem ser iguais em ANDAMENTOS_AP e ANDAMENTOS_VS
	CONSULTA_CHEFE_ILHA: 'consultaChefeIlha',
	DEVOLVER_CHEFE_ILHA: 'devolverChefeIlha',
	ANALISE_DISTRIBUICAO_EAPIS: 'eapis',
	ANALISE_DISTRIBUICAO_EUOS: 'euos',
	ANALISE_MEIO_FISICO: 'fisico',
	ANALISE_MEIO_BIOTICO: 'biotico',
	ANALISE_AMBOS_MEIOS: 'ambos',
	RETORNAR_CHEFIA_CLA: 'retornaChefiaCla',
	CONSULTA_CLA: 'consultaCla',
	CONSULTA_DIRECAO: 'consultaDirecao',
	RETORNAR_CHEFIA: 'retornarChefia',
	ANALISE_COMISSAO: 'analiseComissao',
	CONSULTAR_ASSES_JUR: 'consultarAssesJur',
	CONSULTA_CHEFE_ULE: 'consultaChefeULE',
	CONSULTA_SETOR_NUMERACAO: 'consultaSetorNumeracao',
	CONSULTA_SMF: 'consultaSmf',
	CONSULTA_GEO: 'consultaGeo',

	RETORNAR_LEV_TOP: 'retornarLevantamentoTop',

	VS0100: {
		concluida: 'Recebimento do requerimento (VS0100) concluído, encaminhado para Registro da etapa (VS0200)',
		documentosInvalidos: 'Recebimento do requerimento (VS0100) concluído, requerimento rejeitado e concluído'
	},
	VS0200: {
		concluida:
			'Registro da etapa (VS0200) concluído, encaminhado para Distribuição da Análise Documental e Condicionantes (VS0500)',
		retornoVerificacaoDocs:
			'Registro da etapa (VS0200) concluído, processo retornado para Recebimento do requerimento (VS0100)',
		digitalizar:
			'Registro da etapa (VS0200) aguardando, encaminhado para Localização da pasta do Expediente Único (VS0300) para posterior encaminhamento para Digitalização do Expediente Único (VS0400)',
		tramitandoEU:
			'Registro da etapa (VS0200) aguardando, processo aguardando tramitação de outra etapa do Expediente Único'
	},
	VS0300: {
		concluida:
			'Localização da pasta do Expediente Único (VS0300) concluído, encaminhado para Digitalização do Expediente Único (VS0400)',
		procEncontrado:
			'Localização da pasta do Expediente Único (VS0300) concluído, não localizado e processo B criado. Encaminhado para Registro da etapa (VS0200)'
	},
	VS0400: {
		concluida: 'Digitalização do Expediente Único (VS0400) concluída, encaminhado para Registro da etapa (VS0200)',
		digitalizacaoEncerramento: 'Digitalização do Expediente Único (VS0400) concluída, etapa encerrada',
		digitalizacao0500:
			'Digitalização do Expediente Único (VS0400) concluída, encaminhado para Distribuição da Análise Documental e Condicionantes (VS0500)',
		digitalizacao0600:
			'Digitalização do Expediente Único (VS0400) concluída, encaminhado para Análise Documental e Condicionantes (VS0600)'
	},
	VS0500: {
		concluida:
			'Distribuição da Análise Documental e Condicionantes (VS0500) concluída, encaminhado para Análise Documental e Condicionantes (VS0600)',
		consultaChefeVistoria:
			'Distribuição da Análise Documental e Condicionantes (VS0500) aguardando, encaminhado para Consulta à Chefia Unidade de Vistoria Predial (VS0510)',
		redigitalizacao:
			'Distribuição da Análise Documental e Condicionantes (VS0500) aguardando, encaminhado para Localização da pasta do Expediente Único (VS0300) para posterior encaminhamento para Digitalização do Expediente Único (VS0400)',
		retornoAtendimento:
			'Distribuição da Análise Documental e Condicionantes (VS0500) concluída, processo retornado para Registro da etapa (VS0200)'
	},
	VS0510: {
		concluida:
			'Consulta à Chefia Unidade de Vistoria Predial (VS0510) concluída, encaminhado para Distribuição da Análise Documental e Condicionantes (VS0500)',
		consultaCoordenador:
			'Consulta à Chefia Unidade de Vistoria Predial (VS0510) concluída, encaminhado para Consulta à Coordenação de Edificações (VS0700)',
		consultarAssesJur:
			'Consulta à Chefia Unidade de Vistoria Predial (VS0510) concluída, encaminhado para Consulta à Assessoria Jurídica (VS0850)'
	},
	VS0600: {
		concluida10:
			'Análise Documental e Condicionantes (VS0600) concluída, encaminhado para Distribuição da Equipe de Cartas (VS0675)',
		concluida:
			'Análise Documental e Condicionantes (VS0600) concluída, encaminhado para Conferência e registro da Carta de Habitação (VS0680)',
		duvidasEtapa:
			'Análise Documental e Condicionantes (VS0600) concluída, processo retornado para Registro da etapa (VS0200)',
		redistribuicao:
			'Análise Documental e Condicionantes (VS0600) concluída, processo retornado para Distribuição da Análise Documental e Condicionantes (VS0500)',
		redigitalizacao:
			'Análise Documental e Condicionantes (VS0600) aguardando, encaminhado para Localização da pasta do Expediente Único (VS0300) para posterior encaminhamento para Digitalização do Expediente Único (VS0400)',
		consultaChefeVistoria:
			'Análise Documental e Condicionantes (VS0600) concluída, encaminhado para Consulta à Chefia Unidade de Vistoria Predial (VS0610)',
		indeferirEU:
			'Análise Documental e Condicionantes (VS0600) concluída, encaminhado para Homologação da Análise Documental e Condicionantes (VS0690)',
		complementar:
			'Análise Documental e Condicionantes (VS0600) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (VS0650)',
		analiseOutrasSecretarias:
			'Análise Documental e Condicionantes (VS0600) aguardando, encaminhado para Análise de outras secretarias',
		paralelas:
			'Análise Documental e Condicionantes (VS0600) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (VS0650) e para Análise de outras secretarias'
	},
	VS0610: {
		concluida:
			' Consulta à Chefia Unidade de Vistoria Predial (VS0610) concluída, encaminhado para Análise Documental e Condicionantes (VS0600)',
		consultaCoordenador:
			'Consulta à Chefia Unidade de Vistoria Predial (VS0610) concluída, encaminhado para Consulta à Coordenação de Edificações (VS0700)',
		consultarAssesJur:
			'Consulta à Chefia Unidade de Vistoria Predial (VS0510) concluída, encaminhado para Consulta à Assessoria Jurídica (VS0850)'
	},
	VS0620: {
		concluida:
			'Consulta ao Setor de Numeração (VS0620) concluída, encaminhado para Conferência e registro da Carta de Habitação (VS0680)',
		consultaSmf: 'Consulta ao Setor de Numeração (VS0620) concluída, encaminhado para Consulta à SMF (VS0630)',
		consultaGeo: 'Consulta ao Setor de Numeração (VS0620) concluída, encaminhado para Consulta ao GEO (VS0640)',
		complementar:
			'Consulta ao Setor de Numeração (VS0620) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (VS0625)'
	},
	VS0625: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (VS0625) concluída, encaminhado para Consulta ao Setor de Numeração (VS0620)'
	},
	VS0630: {
		concluida: 'Consulta à SMF (VS0630) concluída, encaminhado para Consulta ao Setor de Numeração (VS0620)'
	},
	VS0640: {
		concluida: 'Consulta ao GEO (VS0640) concluída, encaminhado para Consulta ao Setor de Numeração (VS0620)'
	},
	VS0675: {
		concluida:
			' Distribuição da Equipe de Cartas (VS0675) concluída, encaminhado para Conferência e registro da Carta de Habitação (VS0680)'
	},
	VS0650: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (VS0650) concluída, encaminhado para Análise Documental e Condicionantes (VS0600)'
	},
	VS0680: {
		concluidav10:
			'Conferência e registro da Carta de Habitação (VS0680) concluída, encaminhado para Homologação da Carta de Habitação (VS0800)',
		concluida:
			'Conferência e registro da Carta de Habitação (VS0680) concluída, Carta de Habitação concedida, etapa deferida e encerrada',
		retornarTriagem:
			'Conferência e registro da Carta de Habitação (VS0680) concluída, processo retornado para Análise Documental e Condicionantes (VS0600)',
		consultarRegistrosUAP:
			'Conferência e registro da Carta de Habitação (VS0680) aguardando, encaminhado para Consulta à Unidade de Aprovação de Projetos (VS0685)',
		complementar:
			'Conferência e registro da Carta de Habitação (VS0680) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (VS0850)',
		consultaSecretarias:
			'Conferência e registro da Carta de Habitação (VS0680) aguardando, encaminhado para Análise de outras secretarias',
		consultaChefeVistoria:
			'Conferência e registro da Carta de Habitação (VS0680) aguardando, encaminhado para Consulta à Chefia Unidade de Vistoria Predial (VS0688)',
		consultaChefeULE:
			'Conferência e registro da Carta de Habitação (VS0680) aguardando, encaminhado para Consulta à Chefia da Unidade de Licenciamento Expresso (VS0687)',
		consultaSetorNumeracao:
			'Conferência e registro da Carta de Habitação (VS0680) aguardando, encaminhado para Consulta ao Setor de Numeração (VS0620)'
	},
	VS0685: {
		concluida:
			'Consulta à Unidade de Aprovação de Projetos (VS0685) concluída, encaminhado para Conferência e registro da Carta de Habitação (VS0680)'
	},
	VS0687: {
		concluida:
			'Consulta à Chefia da Unidade de Licenciamento Expresso (VS0687) concluída, encaminhado para Conferência e registro da Carta de Habitação (VS0680)'
	},
	VS0688: {
		concluida:
			' Consulta à Chefia Unidade de Vistoria Predial (VS0688) concluída, encaminhado para Conferência e registro da Carta de Habitação (VS0680)',
		consultaCoordenador:
			'Consulta à Chefia Unidade de Vistoria Predial (VS0688) concluída, encaminhado para Consulta à Coordenação de Edificações (VS0700)'
	},
	VS0690: {
		concluida: 'Homologação da Análise Documental e Condicionantes (VS0690) concluída, encaminhado para indeferimento',
		consultaChefeVistoria:
			'Homologação da Análise Documental e Condicionantes (VS0690) concluída, encaminhado para Consulta à Chefia Unidade de Vistoria Predial (VS0695)',
		retornarTriagem:
			'Homologação da Análise Documental e Condicionantes (VS0690) concluída, encaminhado para Análise Documental e Condicionantes (VS0600)',
		consultarDirecao:
			'Homologação da Análise Documental e Condicionantes (VS0690) concluída, encaminhado para Consulta à Direção EL (VS0696)'
	},
	VS0695: {
		concluida:
			'Consulta à Chefia Unidade de Vistoria Predial (VS0695) concluída, encaminhado para Homologação da Análise Documental e Condicionantes (VS0690)',
		consultaCoordenador:
			'Consulta à Chefia Unidade de Vistoria Predial (VS0695) concluída, encaminhado para Consulta à Coordenação de Edificações (VS0700)'
	},
	VS0696: {
		concluida:
			'Consulta Direção do Escritório de Licenciamento (VS0696) concluída, encaminhado para Homologação da Análise Documental e Condicionantes (VS0690)'
	},
	VS0700: {
		// o concluída é complementado no saga com a origem da consulta entre parenteses: (VS0510), (VS0610), (VS0688), (VS0695)
		concluida:
			'Consulta a Coordenação de Edificações (VS0700) concluída, retornando para Consulta à Chefia Unidade de Vistoria Predial',
		consultarDirecao:
			'Consulta a Coordenação de Edificações (VS0700) concluída, encaminhado para Consulta à Direção do Escritório de Licenciamento (VS0750)'
	},
	VS0750: {
		concluida:
			'Consulta à Direção do Escritório de Licenciamento (VS0750) concluida, retornando para Consulta a Coordenação de Edificações (VS0700)'
	},
	VS0800: {
		concluida:
			'Homologação da Carta de Habitação (VS0800) concluída, Carta de Habitação emitida e etapa deferida e encerrada',
		complementar:
			'Homologação da Carta de Habitação (VS0800) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (VS0850)',
		retornarTriagem:
			'Homologação da Carta de Habitação (VS0800) concluída, processo retornado para Análise Documental e Condicionantes (VS0600)',
		retornarEquipeCartas:
			'Homologação da Carta de Habitação (VS0800) concluída, processo retornado para Conferência e registro da Carta de Habitação (VS0680)',
		consultaSecretarias:
			'Homologação da Carta de Habitação (VS0800) aguardando, encaminhado para Análise de outras secretarias'
	},
	VS0850: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (VS0850) concluída, encaminhado para Homologação da Carta de Habitação (VS0800)'
	},
	VS0900: {
		concluida:
			'Consulta à Assessoria Jurídica (VS0900) concluida, retornando para Consulta à Chefia Unidade de Vistoria Predial'
	},
	EU1100: {
		concluida: 'Consulta à Índices construtivos (EU1100) concluída, encaminhado para a Unidade de Vistoria Predial',
		complementar:
			'Consulta à Índices construtivos (EU1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EU1300)'
	},
	EU1300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EU1300) concluída, encaminhado para Consulta à Índices construtivos (EU1100)'
	},
	EC1100: {
		concluida:
			'Consulta à Urbanização e/ou Obras de Logradouro (EC1100) concluída, encaminhado para a Unidade de Vistoria Predial',
		complementar:
			' Consulta à Urbanização e/ou Obras de Logradouro (EC1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EC1300)'
	},
	EC1300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EC1300) concluída, encaminhado para Consulta à Urbanização e/ou Obras de Logradouro (EC1100) '
	},
	EP1100: {
		concluida: 'Consulta à Diretrizes SMMU (EP1100) concluída, encaminhado para a Unidade de Vistoria Predial',
		complementar:
			'Consulta à Diretrizes SMMU (EP1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EP1300)'
	},
	EP1300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EP1300) concluída, encaminhado para Consulta à Diretrizes SMMU (EP1100)'
	},
	ES1100: {
		concluida:
			'Consulta ao Cadastramento de Logradouro (ES1100) concluída, encaminhado para a Unidade de Vistoria Predial',
		complementar:
			'Consulta ao Cadastramento de Logradouro (ES1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (ES1300)'
	},
	ES1300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (ES1300) concluída, encaminhado para Consulta ao Cadastramento de Logradouro (ES1100) '
	},
	UA1100: {
		concluida:
			'Consulta à Unidade de Aprovação de Projetos (UA1100) concluída, encaminhado para a Unidade de Vistoria Predial',
		complementar:
			'Consulta à Unidade de Aprovação de Projetos (UA1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (UA1300)'
	},
	UA1300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (UA1300) concluída, encaminhado para Consulta à Unidade de Aprovação de Projetos (UA1100)'
	},
	CA1100: {
		concluida: 'Consulta à CAADHAP (CA1100) concluída, encaminhado para a Unidade de Vistoria Predial',
		complementar:
			'Consulta à CAADHAP (CA1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (CA1300)'
	},
	CA1300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (CA1300) concluída, encaminhado para Consulta à Unidade de Aprovação de Projetos (CA1100)'
	},
	PH1100: {
		concluida:
			'Consulta ao Patrimônio Histórico e Cultural (PH1100) concluída, encaminhado para a Unidade de Vistoria Predial',
		complementar:
			'Consulta ao Patrimônio Histórico e Cultural (PH1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (PH1300)'
	},
	PH1300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PH1300) concluída, encaminhado para Consulta ao Patrimônio Histórico e Cultural (PH1100)'
	},
	DM1000: {
		concluida:
			'Distribuição Departamento Municipal de Água e Esgoto (DM1000) concluída, encaminhado para Análise(s) técnica Departamento Municipal de Água e Esgoto'
	},
	DM2000: {
		concluida:
			'Validação de pareceres Departamento Municipal de Água e Esgoto (DM2000) concluída, encaminhado para a Unidade de Vistoria',
		parecerDesfavoravel:
			'Validação de pareceres Departamento Municipal de Água e Esgoto (DM2000) concluída, encaminhado para Distribuição Departamento Municipal de Água e Esgoto (DM1000)'
	},
	DM1100: {
		concluida:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1100) concluída, encaminhado para Consolidação de pareceres Departamento Municipal de Água e Esgoto e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1100) concluída, encaminhado para Validação de pareceres Departamento Municipal de Água e Esgoto (DM2000)',
		complementar:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DM1200: {
		concluida:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1200) concluída, encaminhado para Consolidação de pareceres Departamento Municipal de Água e Esgoto e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1200) concluída, encaminhado para Validação de pareceres Departamento Municipal de Água e Esgoto (DM2000)',
		complementar:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1200) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DM1300: {
		concluida:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1300) concluída, encaminhado para Consolidação de pareceres Departamento Municipal de Água e Esgoto e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1300) concluída, encaminhado para Validação de pareceres Departamento Municipal de Água e Esgoto (DM2000)',
		complementar:
			'Análise técnica Departamento Municipal de Água e Esgoto (DM1300) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	DM3000: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DM3000) concluída, encaminhado para Análise técnica Departamento Municipal de Água e Esgoto (DM1100)'
	},
	DM3100: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DM3100) concluída, encaminhado para Análise técnica Departamento Municipal de Água e Esgoto (DM1200)'
	},
	DM3200: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (DM3200) concluída, encaminhado para Análise técnica Departamento Municipal de Água e Esgoto (DM1300)'
	},
	PV1000: {
		concluida: 'Distribuição Projetos Viários (PV1000) concluída, encaminhado para Análise(s) técnica Projetos Viários'
	},
	PV2000: {
		concluida:
			'Validação de pareceres Projetos Viários (PV2000) concluída, encaminhado para a Unidade de Vistoria Predial',
		parecerDesfavoravel:
			'Validação de pareceres Projetos Viários (PV2000) concluída, encaminhado para Distribuição Projetos Viários (PV1000)'
	},
	PV1100: {
		concluida:
			'Análise técnica Projetos Viários (PV1100) concluída, encaminhado para Consolidação de pareceres Projetos Viários e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Projetos Viários (PV1100) concluída, encaminhado para Validação de pareceres Projetos Viários (PV2000)',
		complementar:
			'Análise técnica Projetos Viários (PV1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PV1200: {
		concluida:
			'Análise técnica Projetos Viários (PV1200) concluída, encaminhado para Consolidação de pareceres Projetos Viários e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Projetos Viários (PV1200) concluída, encaminhado para Validação de pareceres Projetos Viários (PV2000)',
		complementar:
			'Análise técnica Projetos Viários (PV1200) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PV1300: {
		concluida:
			'Análise técnica Projetos Viários (PV1300) concluída, encaminhado para Consolidação de pareceres Projetos Viários e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Projetos Viários (PV1300) concluída, encaminhado para Validação de pareceres Projetos Viários (PV2000)',
		complementar:
			'Análise técnica Projetos Viários (PV1300) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PV3000: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PV3000) concluída, encaminhado para Análise técnica Projetos Viários (PV1100)'
	},
	PV3100: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PV3100) concluída, encaminhado para Análise técnica Projetos Viários (PV1200)'
	},
	PV3200: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PV3200) concluída, encaminhado para Análise técnica Projetos Viários (PV1300)'
	},
	QP1000: {
		concluida:
			'Distribuição Questões Pluviais (QP1000) concluída, encaminhado para Análise(s) técnica Questões Pluviais'
	},
	QP2000: {
		concluida:
			'Validação de pareceres Questões Pluviais (QP2000) concluída, encaminhado para a Unidade de Vistoria Predial',
		parecerDesfavoravel:
			'Validação de pareceres Questões Pluviais (QP2000) concluída, encaminhado para Distribuição Questões Pluviais (QP1000)'
	},
	QP1100: {
		concluida:
			'Análise técnica Questões Pluviais (QP1100) concluída, encaminhado para Consolidação de pareceres Questões Pluviais e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Questões Pluviais (QP1100) concluída, encaminhado para Validação de pareceres Questões Pluviais (QP2000)',
		complementar:
			'Análise técnica Questões Pluviais (QP1100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	QP1200: {
		concluida:
			'Análise técnica Questões Pluviais (QP1200) concluída, encaminhado para Consolidação de pareceres Questões Pluviais e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Questões Pluviais (QP1200) concluída, encaminhado para Validação de pareceres Questões Pluviais (QP2000)',
		complementar:
			'Análise técnica Questões Pluviais (QP1200) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	QP1300: {
		concluida:
			'Análise técnica Questões Pluviais (QP1300) concluída, encaminhado para Consolidação de pareceres Questões Pluviais e retorno para a Unidade de Vistoria Predial',
		vistoChefia:
			'Análise técnica Questões Pluviais (QP1300) concluída, encaminhado para Validação de pareceres Questões Pluviais (QP2000)',
		complementar:
			'Análise técnica Questões Pluviais (QP1300) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	QP3000: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (QP3000) concluída, encaminhado para Análise técnica Questões Pluviais (QP1100)'
	},
	QP3100: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (QP3100) concluída, encaminhado para Análise técnica Questões Pluviais (QP1200)'
	},
	QP3200: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (QP3200) concluída, encaminhado para Análise técnica Questões Pluviais (QP1300)'
	},
	PG0100: {
		concluida:
			'Distribuição Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0100) concluída, encaminhado para Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200)'
	},
	PG0200: {
		concluida:
			'Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200) concluída, encaminhado para Distribuir processo (PG0500)',
		consultar:
			'Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200) concluída, encaminhado para Consulta à Procuradoria Setorial/PGM (PG0210)',
		aguardar:
			'Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200) aguardando, encaminhado para Aguardando Respostas Termo de Compromisso (PG0400)',
		retornar:
			'Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200) concluída, retornado para Distribuição Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0100)',
		complementar:
			'Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (PG0300)'
	},
	PG0210: {
		concluida:
			'Consulta à Procuradoria Setorial/PGM (PG0210) concluída, encaminhado para Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200)'
	},
	PG0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico vegetação EUOS (PG0300) concluída, encaminhado para Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200)'
	},
	PG0310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico vegetação EUOS (PG0310) concluída, encaminhado para Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600)'
	},
	PG0400: {
		concluida:
			'Aguardando Respostas Termo de Compromisso (PG0400) concluída, encaminhado para Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200)'
	},
	PG0500: {
		concluida:
			'Distribuição Procuradoria Setorial/PGM (PG0500) concluída, encaminhado para Análise termo de compromisso pela Procuradoria Setorial/PGM (PG0600)'
	},
	PG0600: {
		concluida:
			'Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600) concluída, encaminhado para Unidade de Vistoria Predial',
		aguardar:
			'Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600) aguardando, encaminhado para Aguardando Análise Jurídica (PG0700)',
		retornar:
			'Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600) concluída, retornado para Análise Termo de Compromisso da Procuradoria de Urbanismo e Meio-Ambiente/PGM (PG0200)',
		adjunto:
			'Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600) concluída, encaminhado para Consulta à Procuradoria Adjunta (PG0800)',
		complementar:
			'Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (PG0310)'
	},
	PG0700: {
		concluída:
			' Aguardando Análise Jurídica (PG0700) concluída, encaminhado para Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600)'
	},
	PG0800: {
		concluida:
			'Consulta à Procuradoria Adjunta (PG0800) concluída, retornando para Análise Termo de Compromisso pela Procuradoria Setorial/PGM (PG0600)'
	},
	EA1000: {
		concluida:
			'Recebimento e Análise do Processo (EA1000) concluída, encaminhado para Verificação do Gravame e Elaboração do Parecer (EA1200)',
		existeGravame:
			'Recebimento e Análise do Processo (EA1000) concluída, encaminhado para Realização do Levantamento Topográfico (EA1100)',
		retornarTriagem:
			'Recebimento e análise do processo (EA1000) concluída, processo retornado Unidade de Vistoria Predial'
	},
	EA1100: {
		concluida:
			'Realização do Levantamento Topográfico (EA1100) concluída, encaminhado para Análise da Documentação e Elaboração do Croqui (EA1300)'
	},
	EA1200: {
		existeGravame:
			'Verificação do Gravame e Elaboração do Parecer (EA1200) concluída, encaminhado para Recebimento e Análise do Processo (EA1000) ',
		retornarTriagem:
			'Verificação do Gravame e Elaboração do Parecer (EA1200) concluída, encaminhado para Unidade de Vistoria Predial'
	},
	EA1300: {
		concluida:
			'Análise da Documentação e Elaboração do Croqui (EA1300) concluída, encaminhado para Unidade de Vistoria Predial',
		retornarLevantamentoTop:
			'Análise da Documentação e Elaboração do Croqui (EA1300) concluída, processo retornado para Realização do Levantamento Topográfico (EA1100)'
	},

	...getAndamentosSM('Unidade de Vistoria Predial'),

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_VS[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_LE = {
	LE0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'LE0100',
	LE0200_VERIFICACAO_EU: 'LE0200',
	LE0300_EM_DISTRIBUICAO: 'LE0300',
	LE0400_EM_ANALISE_LICENCA_EXPRESSA: 'LE0400',
	LE0500_EM_COMPLEMENTACAO: 'LE0500',
	LE0600_EM_CONSULTA_CHEFIA_LICENCAS: 'LE0600',
	LE0700_EM_CONSULTA_COORDENACAO_EDIFICACOES: 'LE0700',
	LE0750_EM_CONSULTA_DIRECAO_EL: 'LE0750',
	LE0800_EM_LOCALIZACAO_PASTA_EU: 'LE0800',
	LE0900_EM_DIGITALIZACAO: 'LE0900',
	LE0950_EM_UNIFICACAO_PRANCHA: 'LE0950',
	LE0970_EM_ASSINATURA_PRANCHA: 'LE0970',

	properties: {
		LE0100: { key: 'LE0100', value: 'Recebimento do requerimento (LE0100)' },
		LE0200: { key: 'LE0200', value: 'Registro da etapa (LE0200)' },
		LE0300: { key: 'LE0300', value: 'Distribuição da Análise Licenciamento Expresso (LE0300)' },
		LE0400: { key: 'LE0400', value: 'Análise do Licenciamento Expresso (LE0400)' },
		LE0500: { key: 'LE0500', value: 'Complementação Análise do Licenciamento Expresso (LE0500)' },
		LE0600: { key: 'LE0600', value: 'Consulta à Chefia da Unidade de Licenciamento Expresso (LE0600)' },
		LE0700: { key: 'LE0700', value: 'Consulta à Coordenação de Edificações (LE0700)' },
		LE0750: { key: 'LE0750', value: 'Consulta à Diretoria do Escritório de Licenciamento (LE0750)' },
		LE0800: { key: 'LE0800', value: 'Localização do Expediente Único no Arquivo (LE0800)' },
		LE0900: { key: 'LE0900', value: 'Digitalização do Expediente Único (LE0900)' },
		LE0950: {
			key: 'LE0950',
			value: 'Consolidação Documento Final e Assinatura do Licenciamento Expresso (LE0950)'
		},
		LE0970: { key: 'LE0970', value: 'Assinatura do Licenciamento Expresso pelo Responsável Técnico (LE0970)' }
	},

	value: s => (SITUACOES_LE.properties[s] || {}).value
};

export const ANDAMENTOS_LE = {
	CONCLUIDA: 'concluida',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	CONFLITO_ETAPAS: 'conflitoEtapas',
	TRAMITANDO_EU: 'tramitandoEU',
	CRIACAO_EU: 'criacaoEu',
	ATUALIZACAO_EU: 'atualizacaoEu',
	RETORNAR_ATENDIMENTO: 'retornarAtendimento',
	RETORNAR_ANALISE: 'retornarAnalise',
	PROC_NAO_ENCONTRADO: 'procNaoEncontrado',
	NAO_DIGITALIZADO: 'naoDigitalizado',
	CONSULTAR_COORDENACAO: 'consultarCoordenacao',
	RETORNAR_DISTRIBUICAO: 'retornarDistribuicao',
	DIGITALIZAR: 'digitalizar',
	COMPLEMENTAR: 'complementar',
	CONSULTAR_CHEFIA: 'consultarChefia',
	CRIAR_EU: 'criarEU',
	TRANSFERIR_EU: 'transferirEU',
	INDEFERIR: 'indeferir',
	ASSINATURA_PRANCHA: 'assinaturaPrancha',
	CONSULTAR_DIRECAO: 'consultarDirecao',

	LE0100: {
		concluida: 'Recebimento do requerimento (LE0100) concluído, encaminhado para Registro da etapa (LE0200)',
		conflitoEtapas: 'Recebimento do requerimento (LE0100) concluído, requerimento rejeitado e concluído',
		documentosInvalidos: 'Recebimento do requerimento (LE0100) concluído, requerimento rejeitado e concluído'
	},
	LE0200: {
		concluida:
			'Registro da etapa (LE0200) concluída, encaminhado para Distribuição da Análise do Licenciamento Expresso (LE0300)',
		tramitandoEU:
			'Registro da etapa (LE0200) aguardando, processo aguardando tramitação de outra etapa do Expediente Único',
		criacaoEu:
			'Registro da etapa (LE0200) concluída, encaminhado para criação do expediente único e Distribuição da Análise do Licenciamento Expresso (LE0300)',
		atualizacaoEu:
			'Registro da etapa (LE0200) concluída, encaminhado para atualização do expediente único e Distribuição da Análise do Licenciamento Expresso (LE0300)'
	},
	LE0300: {
		concluida:
			'Distribuição da Análise Licenciamento Expresso (LE0300) concluída, encaminhado para Análise do Licenciamento Expresso (LE0400)',
		retornarAtendimento:
			'Distribuição da Análise Licenciamento Expresso (LE0300) concluída, encaminhado para Recebimento do requerimento (LE0100)'
	},
	LE0400: {
		concluida:
			'Análise do Licenciamento Expresso (LE0400) concluída, encaminhado para Consolidação licenciamento expresso para assinatura e conferência da taxa (LE0950)',
		retornarDistribuicao:
			'Análise do Licenciamento Expresso (LE0400) concluída, retornado para Distribuição da Análise Licenciamento Expresso (LE0300)',
		retornarAtendimento:
			'Análise do Licenciamento Expresso (LE0400) concluída, retornado para Registro da etapa (LE0200)',
		digitalizar:
			'Análise do Licenciamento Expresso (LE0400) concluída, encaminhado para Localização do Expediente Único no Arquivo (LE0800) e Digitalização do Expediente Único (LE0900)',
		complementar:
			'Análise do Licenciamento Expresso (LE0400) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (LE0500)',
		consultarChefia:
			'Análise do Licenciamento Expresso (LE0400) aguardando, encaminhado para Consulta à Chefia da Unidade de Licenciamento Expresso (LE0600)',
		criarEU:
			'Análise do Licenciamento Expresso (LE0400) aguardando, encaminhado para Criação de Expediente Único e retorna para a Análise do Licenciamento Expresso (LE0400)',
		transferirEU:
			'Análise do Licenciamento Expresso (LE0400) aguardando, encaminhado para Transferência de etapa no Expediente Único e retorna para a Análise do Licenciamento Expresso (LE0400)',
		indeferir: 'Análise do Licenciamento Expresso (LE0400) concluída, requerimento indeferido e concluído'
	},
	LE0500: {
		concluida:
			'Complementação Análise do Licenciamento Expresso (LE0500) concluída, encaminhado para Análise do Licenciamento Expresso (LE0400)'
	},
	LE0600: {
		concluida:
			'Consulta à Chefia da Unidade de Licenciamento Expresso (LE0600), encaminhado para Análise do Licenciamento Expresso (LE0400)',
		consultarCoordenacao:
			'Consulta à Chefia da Unidade de Licenciamento Expresso (LE0600) concluído, encaminhado para Consulta à Coordenação de Edificações (LE0700)'
	},
	LE0700: {
		concluida:
			'Consulta à Coordenação de Edificações (LE0700) concluída, encaminhado para Consulta à Chefia da Unidade de Licenciamento Expresso (LE0600)',
		consultarDirecao:
			'Consulta à Coordenação de Edificações (LE0700) concluída, encaminhado para Consulta à Diretoria do Escritório de Licenciamento (LE0750)'
	},
	LE0750: {
		concluida:
			'Consulta à Diretoria do Escritório de Licenciamento (LE0750) concluída, processo retornado para Consulta à Coordenação de Edificações (LE0700)'
	},
	LE0800: {
		concluida:
			'Localização do Expediente Único no Arquivo (LE0800) concluída, encaminhado para Digitalização do Expediente Único (LE0900)',
		procNaoEncontrado:
			'Localização do Expediente Único no Arquivo (LE0800) concluída, processo não localizado e processo B criado. Encaminhado para Análise do Licenciamento Expresso (LE0400)'
	},
	LE0900: {
		concluida:
			'Digitalização do Expediente Único (LE0900) concluída, encaminhado para Análise do Licenciamento Expresso (LE0400)',
		naoDigitalizado:
			'Digitalização do Expediente Único (LE0900) não concluída, encaminhado para Análise do Licenciamento Expresso (LE0400)'
	},
	LE0950: {
		concluida:
			'Consolidação Documento Final e Assinatura do Licenciamento Expresso (LE0950) concluída, processo Deferido e etapa encerrada',
		assinaturaPrancha:
			'Consolidação Documento Final e Assinatura do Licenciamento Expresso (LE0950) aguardando, encaminhado para Assinatura do Licenciamento Expresso pelo Responsável Técnico (LE0970)',
		retornarAnalise:
			'Consolidação Documento Final e Assinatura do Licenciamento Expresso (LE0950) concluída, retornado para Análise do Licenciamento Expresso (LE0400)'
	},
	LE0970: {
		concluida:
			'Assinatura do Licenciamento Expresso pelo Responsável Técnico (LE0970) concluída, encaminhado para Consolidação Documento Final e Assinatura do Licenciamento Expresso (LE0950)'
	},
	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_LE[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_ET = {
	ET0100_EM_ANALISE: 'ET0100',
	ET0300_EM_COMPLEMENTACAO: 'ET0300',

	properties: {
		ET0100: { key: 'ET0100', value: 'Análise do requerimento (ET0100)' },
		ET0300: { key: 'ET0300', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (ET0300)' }
	},

	value: s => (SITUACOES_ET.properties[s] || {}).value
};

export const ANDAMENTOS_ET = {
	CONCLUIDA: 'concluida',
	COMPLEMENTAR: 'complementar',
	INDEFERIDO: 'indeferido',

	ET0100: {
		concluida: 'Análise do requerimento (ET0100) concluído, requerimento aprovado e concluído',
		indeferido: 'Análise do requerimento (ET0100) concluído, requerimento rejeitado e concluído',
		complementar:
			'Análise do requerimento (ET0100) concluído, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	ET0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (ET0300) concluída, encaminhado para Análise do requerimento (ET0100)'
	},
	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_ET[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_CE = {
	CE0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'CE0100',
	CE0200_EM_VERIFICACAO_EXPEDIENTE_UNICO: 'CE0200',
	CE0300_LOCALIZA_EU: 'CE0300',
	CE0310_EM_DIGITALIZACAO: 'CE0310',
	CE0400_DISTRIBUIR: 'CE0400',
	CE0500_EM_ANALISE_CERTIDAO: 'CE0500',
	CE0600_CONSULTA_COORD_EDIF: 'CE0600',
	CE0800_CONSULTA_DIRECAO_EL: 'CE0800',
	CE0700_CONSULTA_ASSESJUR: 'CE0700',
	CE0920_INFORMAR_MOTIVO_INDEFERIMENTO: 'CE0920',

	CI0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'CI0100',
	CI0200_DISTRIBUIR_CHEFE_EQUIPE: 'CI0200',
	CI0300_EM_ANALISE: 'CI0300',
	CI0400_CONSULTA_ASSESJUR: 'CI0400',
	CI0500_CONSULTA_PGM: 'CI0500',
	CI0700_CONSULTA_DIRECAO_EL: 'CI0700',
	CI0800_GERAR_CERTIDAO: 'CI0800',
	CI0810_HOMOLOGAR_INDEFERIMENTO: 'CI0810',

	properties: {
		CE0100: { key: 'CE0100', value: 'Recebimento do requerimento (CE0100)' },
		CE0200: { key: 'CE0200', value: 'Registro da etapa (CE0200)' },
		CE0210: { key: 'CE0210', value: 'Em complementação (CE0210)' },
		CE0300: { key: 'CE0300', value: 'Localização do Expediente Único no arquivo (CE0300)' },
		CE0310: { key: 'CE0310', value: 'Digitalização do Expediente Único (CE0310)' },
		CE0400: { key: 'CE0400', value: 'Distribuição para Análise da documentação para a certidão (CE0400)' },
		CE0410: { key: 'CE0410', value: 'Em complementação (CE0410)' },
		CE0420: { key: 'CE0420', value: 'Consulta à Chefia Equipe Certidões (CE0420)' },
		CE0500: { key: 'CE0500', value: 'Análise da documentação para a certidão (CE0500)' },
		CE0510: { key: 'CE0510', value: 'Em complementação (CE0210)' },
		CE0600: { key: 'CE0600', value: 'Consulta à Coordenação de Edificações (CE0600)' },
		CE0700: { key: 'CE0700', value: 'Consulta à Assessoria Jurídica (CE0700)' },
		CE0800: { key: 'CE0800', value: 'Consulta a direção do Escritório de Licenciamento (CE0800)' },
		CE0900: { key: 'CE0900', value: 'Redigir Certidão (CE0900)' },
		CE0920: { key: 'CE0920', value: 'Informar motivo do Indeferimento (CE0920)' },

		CI0100: { key: 'CI0100', value: 'Atendimento CDRI (CI0100)' },
		CI0200: { key: 'CI0200', value: 'Chefe de equipe - Distribuição (CI0200)' },
		CI0300: { key: 'CI0300', value: 'Análise da documentação (CI0300)' },
		CI0310: { key: 'CI0310', value: 'Análise da documentação (CI0310)' },
		CI0400: { key: 'CI0400', value: 'Consulta à Assessoria Jurídica (CI0400)' },
		CI0500: { key: 'CI0500', value: 'Consulta à Procuradoria Geral do Município (CI0500)' },
		CI0700: { key: 'CI0700', value: 'Consulta à Direção do Escritório de Licenciamento (CI0700)' },
		CI0800: { key: 'CI0800', value: 'Redigir Certidão (CI0800)' },
		CI0810: { key: 'CI0810', value: 'Homologação de indeferimento (CI0810)' }
	},
	value: s => (SITUACOES_CE.properties[s] || {}).value
};

export const ANDAMENTOS_CE = {
	CONCLUIDA: 'concluida',
	CRIACAO_EU: 'criacaoEu',
	ATUALIZACAO_EU: 'atualizacaoEu',
	RETORNAR: 'retornar',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	TRAMITANDO_EU: 'tramitandoEU',
	DIGITALIZAR: 'digitalizar',
	CONFLITO_ETAPAS: 'conflitoEtapas',
	RETORNO_RECEBIMENTO: 'retornoRecebimento',
	PROC_ENCONTRADO: 'procEncontrado',
	DIGITALIZADO: 'digitalizado',
	RETORNO_DISTRIBUICAO: 'retornarDistribuicao',
	DIGITALIZACAO: 'digitalizar',
	COMPLEMENTAR: 'complementar',
	CONSULTAR_COORD_EDIF: 'consultarCoordEdif',
	INDEFERIMENTO: 'indeferir',
	CONSULTA_DIRECAO: 'consultarDirecao',
	CONSULTA_ASSESJUR: 'consultarAssesJur',
	CONSULTA_PGM: 'consultarPgm',
	INDEFERIR_CERTIDAO: 'enviarParaIndeferimento',
	RETORNAR_ANALISE: 'retornarAnalise',

	CE0100: {
		concluida: 'Recebimento do requerimento (CE0100) concluído, encaminhado para Registro da etapa (CE0200)',
		documentosInvalidos: 'Recebimento do requerimento (CE0100) concluído, requerimento rejeitado e concluído',
		conflitoEtapas: 'Recebimento do requerimento (CE0100) concluído, requerimento rejeitado e concluído'
	},
	CE0200: {
		concluida:
			'Verificação do Expediente Único e registro de etapa (CE0200) concluída, encaminhado para geração da etapa no expediente único e Distribuição para Análise da documentação para a certidão (CE0400)',
		criacaoEu:
			'Verificação do Expediente Único e registro de etapa (CE0200) concluída, encaminhado para criação do expediente único e Distribuição para Análise da documentação para a certidão (CE0400)',
		atualizacaoEu:
			'Verificação do Expediente Único e registro de etapa (CE0200) concluída, encaminhado para atualização do expediente único e Distribuição para Análise da documentação para a certidão (CE0400)',
		retornar:
			'Verificação do Expediente Único e registro de etapa (CE0200) concluída, processo retornado para Recebimento do requerimento (CE0100) '
	},
	CE0300: {
		concluida:
			'Localização do Expediente Único no Arquivo (CE0300) concluída, encaminhado para Digitalização do Expediente Único (CE0310)',
		procEncontrado:
			'Localização do Expediente Único no Arquivo (CE0300) concluída, processo não localizado e processo B criado. Encaminhado para XXXXXXXX (a definir)'
	},
	CE0310: {
		concluida: 'Digitalização do Expediente Único (CE0310) concluída, encaminhado para xxxxxxxxxxxxxx (a definir)',
		digitalizado: 'Digitalização do Expediente Único (CE0310) não concluída, encaminhado para xxxxxxxxxxx (a definir)'
	},
	CE0400: {
		concluida:
			'Distribuição para Análise da documentação para a certidão (CE0400) concluída, encaminhado para Análise da documentação para a certidão (CE0500)'
	},
	CE0420: {
		concluida:
			'Consulta Chefe Equipe Certidões (CE0420) concluída, retornado para Análise da documentação para a certidão (CE0500)',
		consultarCoordEdif:
			'Consulta Chefe Equipe Certidões (CE0420) concluída, encaminhado para Consulta Coordenação Edificações (CE0600)',
		complementar:
			'Análise da documentação para a certidão (CE0420), encaminhado para Complementação de informações pelo responsável técnico (CE0410)'
	},
	CE0500: {
		concluida: 'Análise da documentação para a certidão (CE0500), encaminhado para Geração da Certidão (CE0900)',
		retornarDistribuicao:
			'Análise da documentação para a certidão (CE0500), retornado para Distribuição para Análise da documentação para a certidão (CE0400)',
		digitalizar:
			'Análise da documentação para a certidão (CE0500), encaminhado para Arquivo para localizar pasta do Expediente Único (CE0300) e Digitalização do Expediente Único (CE0310)',
		complementar:
			'Análise da documentação da Certidão (CE0500) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (CE0510)',
		consultarChefia:
			'Análise da documentação para a certidão (CE0500), encaminhado para Consulta à Chefia de Certidões (CE0420)',
		indeferir: 'Análise da documentação para a certidão (CE0500), processo indeferido',
		transfereEtapa: 'Realizada verificação de Expediente Único resultando em transferência de etapa'
	},
	CE0510: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (CE0510) concluída, encaminhado para Análise da documentação da Certidão (CE0500)'
	},
	CE0600: {
		concluida:
			'Consulta Coordenação Edificações (CE0600) concluída, retornado para Análise da documentação para a certidão (CE0500)',
		consultarDirecao:
			'Consulta Coordenação Edificações (CE0600) concluída, encaminhado para Consulta à Direção do Escritório de Licenciamento (CE0800)',
		consultarAssesJur:
			'Consulta Coordenação Edificações (CE0600) concluída, encaminhado para Consulta à Assessoria Jurídica (CE0700)'
	},
	CE0700: {
		concluida:
			'Consulta à Assessoria Jurídica (CE0700) concluída, processo retornado para Consulta Coordenação Edificações (CE0600)'
	},
	CE0800: {
		concluida:
			'Consulta à Direção do Escritório de Licenciamento (CE0800) concluída, processo retornado para Consulta Coordenação Edificações (CE0600)',
		consultarAssesJur:
			'Consulta à Direção do Escritório de Licenciamento (CE0800) concluída, encaminhado para Consulta à Assessoria Jurídica (CE0700)'
	},
	CE0900: {
		concluida: 'Redigir Certidão (CE0900) concluída, certidão gerada.',
		retornar:
			'Não foi possível redigir a certidão (CE0900), retornada para a Análise da documentação para a certidão (CE0500).'
	},
	CE0920: {
		concluida:
			'Informar motivo do Indeferimento (CE0920) concluído, encaminhando para geração do documento de indeferimento.',
		retornar:
			'Informar motivo do Indeferimento (CE0920) concluído, retornando para a Análise da documentação para a certidão (CE0500).'
	},
	CI0100: {
		concluida: 'Recebimento do requerimento (CI0100) concluído, encaminhado para Distribuição do processo (CI0200)',
		documentosInvalidos: 'Recebimento do requerimento (CI0100) concluído, requerimento rejeitado e concluído'
	},
	CI0200: {
		concluida:
			'Distribuição do processo (CI0200) concluída, encaminhado para Análise da documentação para a certidão (CI0300)',
		consultarDirecao:
			'Distribuição do processo (CI0200) concluída, encaminhado para consulta à Direção do Escritório de Licenciamento (CI0700)'
	},
	CI0300: {
		concluida: 'Análise da documentação para a certidão (CI0300) concluída, encaminhado para Redigir certidão (CI0800)',
		consultarAssesJur:
			'Análise da documentação para a certidão (CI0300) concluída, encaminhado para Consulta à Assessoria Jurídica (CI0400)',
		complementar:
			'Análise da documentação para a certidão (CI0300) concluída, encaminhado para Complementação de informações pelo responsável técnico (CI0310)',
		retornarDistribuicao:
			'Análise da documentação para a certidão (CI0300) concluída, retornado para Distribuição do processo (CI0200)',
		enviarParaIndeferimento:
			'Análise da documentação para a certidão (CI0300) concluída, encaminhado para Redigir parecer do indeferimento (CI0810)'
	},
	CI0310: {
		concluida: 'Complementação (CI0310) concluída, encaminhado para Análise da documentação da certidão (CI0300)'
	},
	CI0400: {
		concluida:
			'Consulta à Assessoria Jurídica (CI0400) concluída, retornado para Análise da documentação da certidão (CI0300)',
		consultarPgm:
			'Consulta à Assessoria Jurídica (CI0400) concluída, encaminhado para Consulta à Procuradoria Geral do Município (CI0500)'
	},
	CI0500: {
		concluida:
			'Consulta à Procuradoria Geral do Município (CI0500) concluída, retornado para Consulta à Assessoria Jurídica (CI0400)'
	},
	CI0700: {
		concluida:
			'Consulta à Direção do Escritório de Licenciamento (CI0700) concluída, retornado para Distribuição do processo (CI0200)'
	},
	CI0800: {
		concluida: 'Redigir Certidão (CI0800) concluída, certidão gerada.',
		retornar: 'Não foi possível redigir a certidão (CI0800), retornada para a análise de documentos (CI0300).'
	},
	CI0810: {
		aprovado: 'Redigir Parecer do Indeferimento (CI0810) concluído, indeferimento homologado.',
		reprovado: 'Redigir Parecer do Indeferimento (CI0810) concluído, indeferimento não homologado'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_CE[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_LI = {
	LI0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'LI0100',
	LI0200: 'LI0200',
	LI0250: 'LI0250',
	LI0300: 'LI0300',
	LI0350: 'LI0350',
	LI0400: 'LI0400',
	LI0450: 'LI0450',
	LI0500: 'LI0500',
	LI0600: 'LI0600',
	LI0700: 'LI0700',
	LI0800: 'LI0800',
	LI0900: 'LI0900',
	PH2100: 'PH2100',
	PH2110: 'PH2110',
	PH2120: 'PH2120',
	PH2130: 'PH2130',
	PH2200: 'PH2200',
	PH2300: 'PH2300',
	PH2310: 'PH2310',
	PH2320: 'PH2320',
	properties: {
		LI0100: { key: 'LI0100', value: 'Recebimento do requerimento (LI0100)' },
		LI0200: { key: 'LI0200', value: 'Verificação do Expediente Único e distribuição (LI0200)' },
		LI0250: { key: 'LI0250', value: 'Consulta Chefia Licenças (LI0250)' },
		LI0300: { key: 'LI0300', value: 'Analisar Licenças (LI0300)' },
		LI0350: { key: 'LI0350', value: 'Complementação Análise do Licenciamento Expresso (LI0350)' },
		LI0400: { key: 'LI0400', value: 'Consulta 4º Distrito (LI0400)' },
		LI0450: { key: 'LI0450', value: 'Complementação Análise do Licenciamento Expresso (LI0450)' },
		LI0500: { key: 'LI0500', value: 'Consulta Coordenação de Edificações (LI0500)' },
		LI0600: { key: 'LI0600', value: 'Consulta Direção do Escritório de Licenciamento (LI0600)' },
		LI0700: { key: 'LI0700', value: 'Consulta Assessoria Jurídica (LI0700)' },
		LI0800: { key: 'LI0800', value: 'Gerar Licenças (LI0800)' },
		LI0840: { key: 'LI0840', value: 'Conferir assinatura do RT e assinar Licença (LI0840)' },
		LI0900: { key: 'LI0900', value: 'Gerar Licenças sem croqui (LI0900)' },
		PH2100: { key: 'PH2100', value: 'Distribuição Patrimônio Histórico e Cultural (PH2100)' },
		PH2110: { key: 'PH2110', value: 'Análise técnica Patrimônio Histórico e Cultural (PH2110))' },
		PH2120: { key: 'PH2120', value: 'Análise técnica Patrimônio Histórico e Cultural (PH2120)' },
		PH2130: { key: 'PH2130', value: 'Análise técnica Patrimônio Histórico e Cultural (PH2130)' },
		PH2200: { key: 'PH2200', value: 'Validação de pareceres Patrimônio Histórico e Cultural (PH2200)' },
		PH2300: {
			key: 'PH2300',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Patrimônio Histórico e Cultural (PH2300)'
		},
		PH2310: {
			key: 'PH2310',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Patrimônio Histórico e Cultural (PH2310)'
		},
		PH2320: {
			key: 'PH2320',
			value:
				'Em complementação de documentos e/ou informações pelo Responsável Técnico Patrimônio Histórico e Cultural (PH2320)'
		}
	},

	value: s => (SITUACOES_LI.properties[s] || {}).value
};

export const ANDAMENTOS_LI = {
	CONCLUIDA: 'concluida',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	TRAMITANDO_EU: 'tramitandoEU',
	CRIACAO_EU: 'criacaoEu',
	ATUALIZACAO_EU: 'atualizacaoEu',
	RETORNAR: 'retornar',
	CONSULTAR_CHEFIA: 'consultarChefe',
	CONSULTAR_COORDENACAO: 'consultarCoordenacao',
	CONSULTAR_DIRECAO: 'consultarDirecao',
	CONSULTAR_JURIDICA: 'consultarJuridica',
	COMPLEMENTAR: 'complementar',
	VISTO_CHEFIA: 'vistoChefia',
	PARECER_DESFAVORAVEL: 'parecerDesfavoravel',

	LI0100: {
		concluida:
			'Recebimento do requerimento (LI0100) concluído, encaminhado para Verificação do Expediente Único e registro de etapa (LI0200)',
		documentosInvalidos: 'Recebimento do requerimento (LI0100) concluído, requerimento rejeitado e concluído'
	},
	LI0200: {
		concluida:
			'Verificação do Expediente Único e registro de etapa (LI0200) concluída, encaminhado para Análise da licença (LI0300)',
		tramitandoEU:
			'Verificação do Expediente Único e registro de etapa (LI0200) aguardando, processo aguardando tramitação de outra etapa do Expediente Único',
		criacaoEu:
			'Verificação do Expediente Único e registro de etapa (LI0200) concluída, encaminhado para criação do expediente único e Análise da licença (LI0300)',
		atualizacaoEu:
			'Verificação do Expediente Único e registro de etapa (LI0200) concluída, encaminhado para atualização do expediente único e Análise da licença (LI0300)',
		retornar:
			'Verificação do Expediente Único e registro de etapa (LI0200) concluída, processo retornado para Recebimento do requerimento (LI0100)'
	},
	LI0250: {
		concluida: 'Consulta Chefia Licenças (LI0250) concluída, encaminhado para Análise da licença (LI0300)',
		consultarCoordenacao:
			'Consulta Chefia Licenças (LI0250) concluída, encaminhado para Consulta à Coordenação de Edificações (LI0500)'
	},
	LI0300: {
		concluida: 'Analisar Licenças (LI0300) concluída, encaminhado para Geração da Licença e deferimento.',
		retornar: 'Analisar Licenças (LI0300) concluída, processo retornado para Distribuição (LI0200).',
		digitalizar: 'Analisar Licenças (LI0300) concluída, encaminhado para Digitalização.',
		complementar: 'Analisar Licenças (LI0300) aguardando Complementação pelo requerente (LI0350).',
		trocarEu: 'Analisar Licenças (LI0300) troca de Expediente Único realizado.',
		consultarEpahc: 'Analisar Licenças (LI0300) concluída, encaminhado à Consulta EPAHC (PH2100).',
		consultarChefe: 'Analisar Licenças (LI0300) concluída, encaminhado à Consulta Chefia Licenças (LI0250).',
		consultar4Distrito: 'Analisar Licenças (LI0300) concluída, encaminhado à Consulta 4º Distrito (LI0400).',
		indeferir: 'Analisar Licenças (LI0300) concluída, processo indeferido.'
	},
	LI0350: {
		concluida:
			'Complementação Análise do Licenciamento Expresso (LI0350) concluída, encaminhado para Analisar Licenças (LI0300)'
	},
	LI0400: {
		complementar:
			'Consulta 4º Distrito (LI0400) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico',
		concluida: 'Consulta 4º Distrito (LI0400) concluída, encaminhado para Analisar Licenças (LI0300)'
	},
	LI0450: {
		concluida:
			'Complementação Análise do Licenciamento Expresso (LI0450) concluída, encaminhado para Consulta 4º Distrito (LI0400)'
	},
	LI0500: {
		concluida:
			'Consulta à Coordenação de Edificações (LI0500) concluída, encaminhado para Consulta Chefia Licenças (LI0250)',
		consultarDirecao:
			'Consulta à Coordenação de Edificações (LI0500) concluída, encaminhado para Consulta à Direção do Escritório de Licenciamento (LI0600)',
		consultarJuridica:
			'Consulta à Coordenação de Edificações (LI0500) concluída, encaminhado para Consulta à Assessoria Jurídica (LI0700)'
	},
	LI0600: {
		concluida:
			'Consulta à Direção do Escritório de Licenciamento (LI0600) concluída, encaminhado para Consulta à Coordenação de Edificações (LI0500)',
		consultarJuridica:
			'Consulta à Direção do Escritório de Licenciamento (LI0600) concluída, encaminhado para Consulta à Assessoria Jurídica (LI0700)'
	},
	LI0700: {
		concluidaLI0500:
			'Consulta à Assessoria Jurídica (LI0700) concluída, encaminhado para Consulta à Coordenação de Edificações (LI0500)',
		concluidaLI0600:
			'Consulta à Assessoria Jurídica (LI0700) concluída, encaminhado para Consulta à Direção do Escritório de Licenciamento (LI0600)'
	},
	LI0800: {
		concluida: 'Gerar Sumário e Licenças (LI0800) concluída.',
		retornar: 'Gerar Sumário e Licenças (LI0800) concluída, processo retornado para Analise (LI0300).'
	},
	LI0840: {
		concluida: 'Conferir assinatura do RT e assinar Licença (LI0840) concluída.',
		retornar:
			'Conferir assinatura do RT e assinar Licença (LI0840) concluída, processo retornado para Gerar Sumário e Licenças (LI0800).'
	},
	LI0900: {
		concluida: 'Gerar Licenças (LI0900) concluída.',
		retornar: 'Gerar Licenças (LI0900) concluída, processo retornado para Analise (LI0300).'
	},
	PH2100: {
		concluida:
			'Distribuição Patrimônio Histórico e Cultural (PH2100) concluída, encaminhado para Análise(s) técnica Patrimônio Histórico e Cultural'
	},
	PH2200: {
		concluida:
			'Validação de pareceres Patrimônio Histórico e Cultural (PH2200) concluída, encaminhado para a Unidade de Aprovação de Projetos',
		parecerDesfavoravel:
			'Validação de pareceres Patrimônio Histórico e Cultural (PH2200) concluída, encaminhado para Distribuição Patrimônio Histórico e Cultural (PH2100)'
	},
	PH2110: {
		concluida:
			'Análise técnica Patrimônio Histórico e Cultural (PH2110) concluída, encaminhado para Consolidação de pareceres Patrimônio Histórico e Cultural e retorno para a Unidade de Aprovação de Projetos',
		vistoChefia:
			'Análise técnica Patrimônio Histórico e Cultural (PH2110) concluída, encaminhado para Validação de pareceres Patrimônio Histórico e Cultural (PH2200)',
		complementar:
			'Análise técnica Patrimônio Histórico e Cultural (PH2110) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PH2120: {
		concluida:
			'Análise técnica Patrimônio Histórico e Cultural (PH2120) concluída, encaminhado para Consolidação de pareceres Patrimônio Histórico e Cultural e retorno para a Unidade de Aprovação de Projetos',
		vistoChefia:
			'Análise técnica Patrimônio Histórico e Cultural (PH2120) concluída, encaminhado para Validação de pareceres Patrimônio Histórico e Cultural (PH2200)',
		complementar:
			'Análise técnica Patrimônio Histórico e Cultural (PH2120) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PH2130: {
		concluida:
			'Análise técnica Patrimônio Histórico e Cultural (PH2130) concluída, encaminhado para Consolidação de pareceres Patrimônio Histórico e Cultural e retorno para a Unidade de Aprovação de Projetos',
		vistoChefia:
			'Análise técnica Patrimônio Histórico e Cultural (PH2130) concluída, encaminhado para Validação de pareceres Patrimônio Histórico e Cultural (PH2200)',
		complementar:
			'Análise técnica Patrimônio Histórico e Cultural (PH2130) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	PH2300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PH2300) concluída, encaminhado para Análise técnica Patrimônio Histórico e Cultural (PH2110)'
	},
	PH2310: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PH2310) concluída, encaminhado para Análise técnica Patrimônio Histórico e Cultural (PH0120)'
	},
	PH2320: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (PH2320) concluída, encaminhado para Análise técnica Patrimônio Histórico e Cultural (PH0130)'
	},
	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_LI[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_PR = {
	PR0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'PR0100',
	PR0200: 'PR0200',
	PR0300_EM_DISTRIBUICAO: 'PR0300',
	PR0400_EM_ANALISE: 'PR0400',
	PR0450_EM_COMPLEMENTACAO: 'PR0450',
	PR0460_HOMOLOGAR_INDEFERIMENTO: 'PR0460',
	PR0500_EM_CONSULTA_CHEFIA_LICENCAS: 'PR0500',
	PR0550_EM_COMPLEMENTACAO: 'PR0550',
	properties: {
		PR0100: { key: 'PR0100', value: 'Recebimento do requerimento (PR0100)' },
		PR0200: { key: 'PR0200', value: 'Verificação do Expediente Único (PR0200)' },
		PR0300: { key: 'PR0300', value: 'Distribuição (PR0300)' },
		PR0400: { key: 'PR0400', value: 'Analisar Documentação (PR0400)' },
		PR0450: { key: 'PR0450', value: 'Complementação (PR0450)' },
		PR0460: { key: 'PR0460', value: 'Homologar Indeferimento (PR0460)' },
		PR0500: { key: 'PR0500', value: 'Consulta à Chefia PRCHPA (PR0500)' },
		PR0550: { key: 'PR0550', value: 'Complementação (PR0550)' }
	},

	value: s => (SITUACOES_PR.properties[s] || {}).value
};

export const ANDAMENTOS_PR = {
	CONCLUIDA: 'concluida',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	CRIACAO_EU: 'criacaoEu',
	ATUALIZACAO_EU: 'atualizacaoEu',
	RETORNAR: 'retornar',
	CONSULTAR_CHEFIA: 'consultarChefia',
	CONSULTAR_COORDENACAO: 'consultarCoordenacao',
	COMPLEMENTAR: 'complementar',
	VISTO_CHEFIA: 'vistoChefia',
	PARECER_DESFAVORAVEL: 'parecerDesfavoravel',
	INDEFERIR: 'indeferir',

	PR0100: {
		concluida:
			'Recebimento do requerimento (PR0100) concluído, encaminhado para Verificação do Expediente Único e registro de etapa (PR0200)',
		documentosInvalidos: 'Recebimento do requerimento (PR0100) concluído, requerimento rejeitado e concluído'
	},
	PR0200: {
		concluida:
			'Verificação do Expediente Único e registro de etapa (PR0200) concluída, encaminhado para Distribuição do Processo (PR0300)',
		criacaoEu:
			'Verificação do Expediente Único e registro de etapa (PR0200) concluída, encaminhado para criação do expediente único e Distribuição (PR0300)',
		atualizacaoEu:
			'Verificação do Expediente Único e registro de etapa (PR0200) concluída, encaminhado para atualização do expediente único e Distribuição (PR0300)'
	},
	PR0300: {
		concluida: 'Distribuição do Processo (PR0300) concluída, encaminhado para Analisar Documentação (PR0400).'
	},
	PR0400: {
		concluida: 'Analisar Documentação (PR0400) concluída, requerimento DEFERIDO e concluído',
		retornar: 'Analisar Documentação (PR0400) concluída, retornado para Distribuição do Processo (PR0300)',
		complementar:
			'Analisar Documentação (PR0400) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (PR0450)',
		consultarChefia: 'Analisar Documentação (PR0400) aguardando, encaminhado para Consulta à Chefia (PR0500)',
		consultarCoordenacao: 'Analisar Documentação (PR0400) finalizada, encaminhado para Consulta à Coordenação (PR0600)',
		indeferir: 'Analisar Documentação (PR0400) concluída, requerimento INDEFERIDO e concluído'
	},
	PR0450: {
		concluida: 'Complementação Análise do PRCHPA (PR0450) concluída, encaminhado para Analisar Documentação (PR0400)'
	},
	PR0460: {
		retornar:
			'Homologação de Indeferimento do PRCHPA (PR0460) concluída, retornado para Avaliação do Processo (PR0400)',
		concluida: 'Homologação de Indeferimento do PRCHPA (PR0460) concluída, requerimento INDEFERIDO e concluído'
	},
	PR0500: {
		concluida: 'Consulta à Chefia PRCHPA (PR0500) concluída, encaminhado para Analisar Documentação (LO0400)',
		complementar:
			'Analisar Documentação (PR0500) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (PR0550)'
	},
	PR0550: {
		concluida: 'Complementação Chefia do PRCHPA (PR0550) concluída, encaminhado para Consulta à Chefia (PR0500)'
	},
	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_PR[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_CA = {
	CA0100: 'CA0100',
	properties: {
		CA0100: { key: 'CA0100', value: 'Verificação do Expediente Único (CA0100)' }
	},

	value: s => (SITUACOES_PR.properties[s] || {}).value
};

export const ANDAMENTOS_CA = {
	CONCLUIDA: 'concluida',
	CRIACAO_EU: 'criacaoEu',
	ATUALIZACAO_EU: 'atualizacaoEu',

	CA0100: {
		concluida: 'Verificação do Expediente Único e registro de etapa (CA0100) concluída.',
		criacaoEu: 'Verificação do Expediente Único e registro de etapa (CA0100) concluída.',
		atualizacaoEu: 'Verificação do Expediente Único e registro de etapa (CA0100) concluída.'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_CA[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_RC = {
	RC0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'RC0100',
	RC0200_EM_RETIFICACAO_CARTA: 'RC0200',
	RC0210_EM_CONSULTA_UAP: 'RC0210',
	RC0220_EM_CONSULTA_ULE: 'RC0220',
	RC0230_EM_CONSULTA_SETOR_NUMERACAO: 'RC0230',
	RC0240_EM_CONSULTA_CHEFE_VISTORIA: 'RC0240',
	RC0250_EM_COMPLEMENTACAO_DOCUMENTOS: 'RC0250',
	RC0260_EM_CONSULTA_COORDENACAO_EDIFICACOES: 'RC0260',
	RC0270_EM_CONSULTA_ASSESSORIA_JURIDICA: 'RC0270',
	RC0300_EM_LOCALIZAO_PROCESSO_ARQUIVO: 'RC0300',
	RC0400_EM_DIGITALIZACAO: 'RC0400',
	properties: {
		RC0100: { key: 'RC0100', value: 'Verificação de documentos e registro de etapa (RC0100)' },
		RC0200: { key: 'RC0200', value: 'Retificação da carta (RC0200)' },
		RC0210: { key: 'RC0210', value: 'Consulta à Unidade de Aprovação de Projetos (RC0210)' },
		RC0220: { key: 'RC0220', value: 'Consulta à Unidade de Licenciamento Expresso (RC0220)' },
		RC0230: { key: 'RC0230', value: 'Consulta ao Setor de Numeração (RC0230)' },
		RC0240: { key: 'RC0240', value: 'Consulta ao Chefe de Vistoria (RC0240)' },
		RC0250: { key: 'RC0250', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (RC0250)' },
		RC0260: { key: 'RC0260', value: 'Consulta à Coordenação de Edificações (RC0260)' },
		RC0270: { key: 'RC0270', value: 'Consulta à Assessoria Jurídica (RC0270)' },
		RC0300: { key: 'RC0300', value: 'Localização do Expediente Único no Arquivo (RC0300)' },
		RC0400: { key: 'RC0400', value: 'Digitalização do Expediente Único (RC0400)' }
	},

	value: s => (SITUACOES_RC.properties[s] || {}).value
};

export const ANDAMENTOS_RC = {
	CONCLUIDA: 'concluida',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	COMPLEMENTAR: 'complementar',
	CONSULTAS_EXTERNAS: 'consultasExternas',
	DIGITALIZACAO: 'digitalizacao',
	INDEFERIR: 'indeferir',
	PROCESSO_NAO_ENCONTRADO: 'procNaoEncontrado',
	NAO_DIGITALIZADO: 'naoDigitalizado',
	CONSULTA_ASSESSORIA_JURIDICA: 'consultaAssJur',
	CONSULTA_COORD_EDIF: 'consultaCoordEdif',

	RC0100: {
		concluida:
			'Verificação de documentos e registro de etapa (RC0100) concluída, encaminhado para geração de etapa no expediente único e Geração da carta (RC0200)',
		documentosInvalidos:
			'Verificação de documentos e registro de etapa (RC0100) concluída, requerimento rejeitado e concluído'
	},
	RC0200: {
		concluida: 'Retificação da carta (RC0200) concluído, encaminhado para geração da carta e registro no expedientes',
		complementar:
			'Retificação da carta (RC0200) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (RC0250)',
		consultasExternas: 'Retificação da carta (RC0200) concluído, encaminhado para ',
		digitalizacao:
			'Retificação da carta (RC0200) concluído, encaminhado para Localização do Expediente Único no Arquivo (RC0300)',
		indeferir: 'Retificação da carta (RC0200) concluído, requerimento rejeitado e concluído'
	},
	RC0210: {
		concluida:
			'Consulta à Unidade de Aprovação de Projetos (RC0210) concluída, encaminhado para Retificação da carta (RC0200)'
	},
	RC0220: {
		concluida:
			'Consulta à Unidade de Licenciamento Expresso (RC0220) concluída, encaminhado para Retificação da carta (RC0200)'
	},
	RC0230: {
		concluida: 'Consulta ao Setor de Numeração (RC0230) concluída, encaminhado para Retificação da carta (RC0200)'
	},
	RC0240: {
		concluida: 'Consulta ao Chefe de Vistoria (RC0240) concluída, encaminhado para Retificação da carta (RC0200)',
		consultaCoordEdif:
			'Consulta ao Chefe de Vistoria (RC0240) concluída, encaminhado para Consulta à Coordenação de Edificações (RC0260)',
		consultaAssJur:
			'Consulta ao Chefe de Vistoria (RC0240) concluída, encaminhado para Consulta à Assessoria Jurídica (RC0270)'
	},
	RC0250: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (RC0250) concluída, encaminhado para Retificação da carta (RC0200)'
	},
	RC0260: {
		concluida:
			'Consulta à Coordenação de Edificações (RC0260) concluída, encaminhado para Consulta ao Chefe de Vistoria (RC0240)',
		consultaAssJur:
			'Consulta à Coordenação de Edificações (RC0260) concluída, encaminhado para Consulta à Assessoria Jurídica (RC0270)'
	},
	RC0270: {
		concluidaRC0240:
			'Consulta Assessoria Jurídica (RC0270) concluída, encaminhado para Consulta ao Chefe de Vistoria (RC0240)',
		concluidaRC0260:
			'Consulta Assessoria Jurídica (RC0270) concluída, encaminhado para Consulta à Coordenação de Edificações (RC0260)'
	},
	RC0300: {
		concluida:
			'Localização do Expediente Único no Arquivo (RC0300) concluída, encaminhado para Digitalização do Expediente Único (RC0400)',
		procNaoEncontrado:
			'Localização do Expediente Único no Arquivo (RC0300) concluída, processo não localizado e processo B criado. Encaminhado para Retificação da carta (RC0200)'
	},
	RC0400: {
		concluida: 'Digitalização do Expediente Único (RC0400) concluída, encaminhado para Retificação da carta (RC0200)',
		naoDigitalizado:
			'Digitalização do Expediente Único (RC0400) não concluída, encaminhado para Retificação da carta (RC0200)'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_RC[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_BL = {
	BL0050_EM_TENTAR_CRIAR_ETAPA_NOVAMENTE: 'BL0050',
	BL0100_EM_VALIDACAO_ALVARA: 'BL0100',
	BL0150_EM_COMPLEMENTACAO_DOCUMENTOS: 'BL0150',
	properties: {
		BL0050: { key: 'BL0050', value: 'Tentativa de criar a etapa do Expedientes novamente (BL0050)' },
		BL0100: { key: 'BL0100', value: 'Verificar Prazos dos Boletos (BL0100)' },
		BL0150: { key: 'BL0150', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (BL0150)' }
	},

	value: s => (SITUACOES_BL.properties[s] || {}).value
};

export const ANDAMENTOS_BL = {
	CONCLUIDA: 'concluida',
	ANULAR: 'anular',
	COMPLEMENTAR: 'complementar',

	BL0050: {
		concluida: 'Efetuando nova tentativa de criar a etapa do Expedientes (BL0050)'
	},
	BL0100: {
		concluida:
			'Verificar Prazos dos Boletos (BL0100) concluída, encaminhado para Notificação do RT sobre Emissão da Certidão',
		anular: 'Verificar Prazos dos Boletos (BL0100) concluída, encaminhado para Invalidação do Alvará',
		complementar:
			'Verificar Prazos dos Boletos (BL0100) concluída, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (BL0150)'
	},
	BL0150: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (BL0150) concluída, encaminhado para Validação do Alvará (RC0100)'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_RC[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_CS = {
	CS0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'CS0100',
	CS0200_EM_VERIFICA_EU: 'CS0200',
	CS0300_ANALISE: 'CS0300',

	properties: {
		CS0100: { key: 'CS0100', value: 'Receber solicitação e verificar docs (CS0100)' },
		CS0200: { key: 'CS0200', value: 'Verificar EU para a criação de etapa (CS0200)' },
		CS0300: { key: 'CS0300', value: 'Análise da Certificação Sustentável (CS0300)' },
		CS0350: { key: 'CS0350', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (CS0350)' }
	},

	value: s => (SITUACOES_CS.properties[s] || {}).value
};

export const ANDAMENTOS_CS = {
	CONCLUIDA: 'concluida',
	INDEFERIR: 'indeferir',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	CONFLITO_ETAPAS: 'conflitoEtapas',
	COMPLEMENTAR: 'complementar',
	CRIAR_EU: 'criarEU',
	ATUALIZACAO_EU: 'atualizacaoEu',
	TRANSFERIR_EU: 'transferirEU',
	TRAMITANDO_EU: 'tramitandoEU',

	CS0100: {
		concluida:
			'Receber solicitação e verificar docs (CS0100) concluído, encaminhado para Verificar EU para a criação de etapa (CS0200)',
		conflitoEtapas: 'Receber solicitação e verificar docs (CS0100) concluído, requerimento rejeitado e concluído',
		documentosInvalidos: 'Receber solicitação e verificar docs (CS0100) concluído, requerimento rejeitado e concluído'
	},
	CS0200: {
		concluida:
			'Verificar EU para a criação de etapa (CS0200) concluído, encaminhado para Análise da Certificação Sustentável (CS0300)'
	},
	CS0300: {
		concluida:
			'Análise da Certificação Sustentável (CS0300) concluído, encaminhado para emissão do certificado sustentável',
		indeferir:
			'Análise da Certificação Sustentável (CS0300) concluído, encaminhado para indeferimento da solicitação de certificação sustentável',
		complementar:
			'Análise da Certificação Sustentável (CS0300) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (CS0350)',
		criarEU:
			'Análise da Certificação Sustentável (CS0300) aguardando, encaminhado para Criação de Expediente Único e retorna para a Análise da Certificação Sustentável (CS0300) ',
		transferirEU:
			'Análise da Certificação Sustentável (CS0300) aguardando, encaminhado para Transferência de etapa no Expediente Único e retorna para a Análise da Certificação Sustentável (CS0300) '
	},
	CS0350: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (CS0350) concluída, encaminhado para Análise da Certificação Sustentável (CS0300)'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_CS[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_GE = {
	GE0100_EM_GEOREFERENCIAMENTO: 'GE0100',
	GE0110_EM_COMPLEMENTACAO: 'GE0110',

	properties: {
		GE0100: { key: 'GE0100', value: 'Georreferenciamento dos Expedientes Únicos (GE0100)' },
		GE0110: { key: 'GE0110', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (GE0110)' },
		GE0120: { key: 'GE0120', value: 'Consultar Chefe da UAP (GE0120)' },
		GE0130: { key: 'GE0130', value: 'Consultar Chefe das Licenças (LE) (GE0130)' },
		GE0140: { key: 'GE0140', value: 'Consultar Chefe da Equipe de Certidões (GE0140)' },
		GE0150: { key: 'GE0150', value: 'Consultar Analista da Certificação Sustentável (GE0150)' },
		GE0160: { key: 'GE0160', value: 'Consultar Chefe da Equipe de Licenças (LI) (GE0160)' },
		GE0170: { key: 'GE0170', value: 'Consultar Atendimento (GE0170)' }
	},

	value: s => (SITUACOES_GE.properties[s] || {}).value
};

export const ANDAMENTOS_GE = {
	CONCLUIDA: 'concluida',
	CONCLUIDA_NOK: 'concluidaNok',
	COMPLEMENTAR: 'complementar',
	CONSULTAR_UAP: 'consultarUap',
	CONSULTAR_CHEFE_LICENCAS_LE: 'consultarChefeLicencasLE',
	CONSULTAR_CHEFE_EQUIPE_CERTIDOES: 'consultarChefeEquipeCertidoes',
	CONSULTAR_ANALISTA_CERTIFICACAO_SUSTENTAVEL: 'consultarAnalistaCertificacaoSustentavel',
	CONSULTAR_CHEFE_EQUIPE_LICENCAS_LI: 'consultarChefeEquipeLicencasLI',
	CONSULTAR_ATENDIMENTO: 'consultarAtendimento',

	GE0100: {
		concluida: 'Georreferenciamento dos Expedientes Únicos (GE0100) concluída com expediente georeferenciado',
		concluidaNok: 'Georreferenciamento dos Expedientes Únicos (GE0100) concluída sem expediente georeferenciado',
		complementar:
			'Georreferenciamento dos Expedientes Únicos (GE0100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (GE0110)',
		consultarUap:
			'Georreferenciamento dos Expedientes Únicos (GE0100) aguardando, encaminhado para Consultar Chefe da UAP (GE0120)',
		consultarChefeLicencasLE:
			'Georreferenciamento dos Expedientes Únicos (GE0100) aguardando, encaminhado para Consultar Chefe das Licenças (LE) (GE0130)',
		consultarChefeEquipeCertidoes:
			'Georreferenciamento dos Expedientes Únicos (GE0100) aguardando, encaminhado para Consultar Chefe da Equipe de Certidões (GE0140)',
		consultarAnalistaCS:
			'Georreferenciamento dos Expedientes Únicos (GE0100) aguardando, encaminhado para Consultar Analista da Certificação Sustentável (GE0150)',
		consultarChefeEquipeLicencasLI:
			'Georreferenciamento dos Expedientes Únicos (GE0100) aguardando, encaminhado para Consultar Chefe da Equipe de Licenças (LI) (GE0160)',
		consultarAtendimento:
			'Georreferenciamento dos Expedientes Únicos (GE0100) aguardando, encaminhado para Consultar Atendimento (GE0170)'
	},
	GE0110: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (GE0110) concluída, ' +
			'encaminhado para Georreferenciamento dos Expedientes Únicos (GE0100)'
	},
	GE0120: {
		concluida:
			'Consultar Chefe da UAP (GE0120) concluída, encaminhado para Georreferenciamento dos Expedientes Únicos (GE0100)'
	},
	GE0130: {
		concluida:
			'Consultar Chefe das Licenças (LE) (GE0130) concluída, encaminhado para Georreferenciamento dos Expedientes Únicos (GE0100)'
	},
	GE0140: {
		concluida:
			'Consultar Chefe da Equipe de Certidões (GE0140) concluída, encaminhado para Georreferenciamento dos Expedientes Únicos (GE0100)'
	},
	GE0150: {
		concluida:
			'Consultar Analista da Certificação Sustentável (GE0150) concluída, encaminhado para Georreferenciamento dos Expedientes Únicos (GE0100)'
	},
	GE0160: {
		concluida:
			'Consultar Chefe da Equipe de Licenças (LI) (GE0160) concluída, encaminhado para Georreferenciamento dos Expedientes Únicos (GE0100)'
	},
	GE0170: {
		concluida:
			'Consultar Atendimento (GE0170) concluída, encaminhado para Georreferenciamento dos Expedientes Únicos (GE0100)'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_GE[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_PE = {
	PE0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'PE0100',
	PE0200_VERIFICACAO_EU: 'PE0200',
	PE0300_EM_DISTRIBUICAO: 'PE0300',
	PE0400_EM_ANALISE_PROJETOS_ESPECIAIS: 'PE0400',
	PE0420_EM_COMPLEMENTACAO: 'PE0420',
	PE0500_EM_ANALISE_TECNICA_EVU: 'PE0500',
	PE0505_RESPONDER_CONSULTA: 'PE0505',
	PE0515_RESPONDER_CONSULTA: 'PE0515',
	PE0520_EM_COMPLEMENTACAO: 'PE0520',
	PE0525_RESPONDER_CONSULTA: 'PE0525',
	PE0535_RESPONDER_CONSULTA: 'PE0535',
	PE0545_RESPONDER_CONSULTA: 'PE0545',
	PE0555_RESPONDER_CONSULTA: 'PE0555',
	PE0565_RESPONDER_CONSULTA: 'PE0565',
	PE0310_EM_CONSULTA_CHEFIA_LICENCAS: 'PE0310',
	PE0700_EM_CONSULTA_COORDENACAO_EDIFICACOES: 'PE0700',
	PE0750_EM_CONSULTA_DIRECAO_EL: 'PE0750',
	PE0800_EM_LOCALIZACAO_PASTA_EU: 'PE0800',
	PE0900_EM_DIGITALIZACAO: 'PE0900',
	...SITUACOES_SM.situacoes,
	...SITUACOES_EV.situacoes,

	properties: {
		PE0100: { key: 'PE0100', value: 'Recebimento do requerimento (PE0100)' },
		PE0200: { key: 'PE0200', value: 'Registro da etapa (PE0200)' },
		PE0300: { key: 'PE0300', value: 'Distribuição da Análise Projetos Especiais (PE0300)' },
		PE0400: { key: 'PE0400', value: 'Validação do Expediente Único e Documentos EVU 1 grau (PE0400)' },
		PE0420: { key: 'PE0420', value: 'Complementação da Análise de Projetos Especiais (PE0420)' },
		PE0500: { key: 'PE0500', value: 'Triagem técnica EVU 1º Grau (PE0500)' },
		PE0505: { key: 'PE0505', value: 'Consulta UAIU/SMAMUS (PE0505)' },
		PE0515: { key: 'PE0515', value: 'Consulta ASSETEC/SMAMUS (PE0515)' },
		PE0520: { key: 'PE0520', value: 'Complementação (PE0520)' },
		PE0525: { key: 'PE0525', value: 'Consulta UAC/SMAMUS (PE0525)' },
		PE0535: { key: 'PE0535', value: 'Consulta CAUGE (PE0535)' },
		PE0545: { key: 'PE0545', value: 'Consulta Chefia de Projetos Especiais (PE0545)' },
		PE0555: { key: 'PE0555', value: 'Consulta UEU/SMAMUS (PE0555)' },
		PE0565: { key: 'PE0565', value: 'Consulta CPU/SMAMUS (PE0565)' },
		PE0310: { key: 'PE0310', value: 'Consulta à Chefia da Unidade de Projetos Especiais (PE0310)' },
		PE0700: { key: 'PE0700', value: 'Consulta à Coordenação de Edificações (PE0700)' },
		PE0750: { key: 'PE0750', value: 'Consulta à Diretoria do Escritório de Licenciamento (PE0750)' },
		PE0800: { key: 'PE0800', value: 'Localização do Expediente Único no Arquivo (PE0800)' },
		PE0900: { key: 'PE0900', value: 'Digitalização do Expediente Único (PE0900)' },

		...SITUACOES_SM.properties
	},

	value: s => (SITUACOES_PE.properties[s] || {}).value
};

export const ANDAMENTOS_PE = {
	CONCLUIDA: 'concluida',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	CONFLITO_ETAPAS: 'conflitoEtapas',
	TRAMITANDO_EU: 'tramitandoEU',
	CRIACAO_EU: 'criacaoEu',
	RETORNAR_ATENDIMENTO: 'retornarAtendimento',
	RETORNAR_ANALISE: 'retornarAnalise',
	PROC_NAO_ENCONTRADO: 'procNaoEncontrado',
	NAO_DIGITALIZADO: 'naoDigitalizado',
	CONSULTAR_COORDENACAO: 'consultarCoordenacao',
	RETORNAR_DISTRIBUICAO: 'retornarDistribuicao',
	DIGITALIZAR: 'digitalizar',
	COMPLEMENTAR: 'complementar',
	CONSULTAR_CHEFIA: 'consultarChefia',
	CRIAR_EU: 'criarEU',
	TRANSFERIR_EU: 'transferirEU',
	INDEFERIR: 'indeferir',
	ASSINATURA_PRANCHA: 'assinaturaPrancha',
	CONSULTAR_DIRECAO: 'consultarDirecao',
	CONSULTAR_SECRETARIAS: 'consultarSecretarias',

	PE0100: {
		concluida: 'Recebimento do requerimento (PE0100) concluído, encaminhado para Registro da etapa (PE0200)',
		conflitoEtapas: 'Recebimento do requerimento (PE0100) concluído, requerimento rejeitado e concluído',
		documentosInvalidos: 'Recebimento do requerimento (PE0100) concluído, requerimento rejeitado e concluído'
	},
	PE0200: {
		concluida:
			'Registro da etapa (PE0200) concluída, encaminhado para Distribuição da Análise de Projetos Especiais (PE0300)',
		tramitandoEU:
			'Registro da etapa (PE0200) aguardando, processo aguardando tramitação de outra etapa do Expediente Único',
		criacaoEu:
			'Registro da etapa (PE0200) concluída, encaminhado para Distribuição da Análise de Projetos Especiais (PE0300)',
		retornarAtendimento: 'Registro da etapa (PE0200) concluído, retornado para Distribuição da Análise (PE0100)'
	},
	PE0300: {
		concluida:
			'Distribuição da Análise Projetos Especiais (PE0300) concluída, encaminhado para Análise de Projetos Especiais (PE0400)',
		retornarAtendimento:
			'Distribuição da Análise Projetos Especiais (PE0300) concluída, encaminhado para Recebimento do requerimento (PE0100)'
	},
	PE0400: {
		concluida:
			'Análise de Projetos Especiais (PE0400) concluída, encaminhado para Consolidação Projetos Especiais para assinatura e conferência da taxa (PE0500)',
		retornarDistribuicao:
			'Análise de Projetos Especiais (PE0400) concluída, retornado para Distribuição da Análise Projetos Especiais (PE0300)',
		retornarAtendimento: 'Análise de Projetos Especiais (PE0400) concluída, retornado para Registro da etapa (PE0200)',
		digitalizar:
			'Análise de Projetos Especiais (PE0400) concluída, encaminhado para Localização do Expediente Único no Arquivo (PE0800) e Digitalização do Expediente Único (PE0900)',
		complementar:
			'Análise de Projetos Especiais (PE0400) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (PE0420)',
		consultarChefia:
			'Validação do Expediente Único e Documentos EVU 1 grau (PE0400) aguardando, encaminhado para Consulta à Chefia da Unidade de Projetos Especiais (PE0310)',
		criarEU:
			'Validação do Expediente Único e Documentos EVU 1 grau (PE0400) aguardando, encaminhado para Criação de Expediente Único e retorna para a Validação do Expediente Único e Documentos EVU 1 grau (PE0400)',
		transferirEU:
			'Validação do Expediente Único e Documentos EVU 1 grau (PE0400) aguardando, encaminhado para Transferência de etapa no Expediente Único e retorna para a Validação do Expediente Único e Documentos EVU 1 grau (PE0400)',
		indeferir:
			'Validação do Expediente Único e Documentos EVU 1 grau (PE0400) concluída, requerimento indeferido e concluído'
	},
	PE0420: {
		concluida:
			'Complementação da Validação do Expediente Único e Documentos EVU 1 grau (PE0420) concluída, encaminhado para Validação do Expediente Único e Documentos EVU 1 grau (PE0400)'
	},
	PE0500: {
		concluida:
			'Triagem técnica EVU 1º Grau (PE0500) concluída, encaminhado à Análise de condicionantes e distribuição do Estudo de Viabilidade Urbanística de 1º Grau (EV0100)',
		complementar: 'Triagem técnica EVU 1º Grau (PE0500) aguardando, encaminhado para Complementação (PE0520)',
		consultarSecretarias: 'Triagem técnica EVU 1º Grau (PE0500) aguardando, encaminhado para ',
		retornarAnalise:
			'Triagem técnica EVU 1º Grau (PE0500) concluída, retornado para Validação do Expediente Único e Documentos EVU 1 grau (PE0400)',
		indeferir: 'Triagem técnica EVU 1º Grau (PE0500) concluída, requerimento indeferido e concluído'
	},
	PE0505: {
		concluida: 'Consulta UAIU/SMAMUS (PE0505) concluída, encaminhado para Triagem técnica EVU 1º Grau (PE0500)'
	},
	PE0515: {
		concluida: 'Consulta ASSETEC/SMAMUS (PE0515) concluída, encaminhado para Triagem técnica EVU 1º Grau (PE0500)'
	},
	PE0520: {
		concluida: 'Complementação (PE0520) concluída, encaminhado para Triagem técnica EVU 1º Grau (PE0500)'
	},
	PE0525: {
		concluida: 'Consulta UAC/SMAMUS (PE0525) concluída, encaminhado para Triagem técnica EVU 1º Grau (PE0500)'
	},
	PE0535: { concluida: 'Consulta CAUGE (PE0535) concluída, encaminhado para Triagem técnica EVU 1º Grau (PE0500)' },
	PE0545: {
		concluida:
			'Consulta Chefia de Projetos Especiais (PE0545) concluída, encaminhado para Triagem técnica EVU 1º Grau (PE0500)'
	},
	PE0555: {
		concluida: 'Consulta UEU/SMAMUS (PE0555) concluída, encaminhado para Triagem técnica EVU 1º Grau (PE0500)'
	},
	PE0565: {
		concluida: 'Consulta CPU/SMAMUS (PE0565) concluída, encaminhado para Triagem técnica EVU 1º Grau (PE0500)'
	},
	PE0310: {
		concluida:
			'Consulta à Chefia da Unidade de Projetos Especiais (PE0310), encaminhado para Validação do Expediente Único e Documentos EVU 1 grau (PE0400)',
		consultarCoordenacao:
			'Consulta à Chefia da Unidade de Projetos Especiais (PE0310) concluído, encaminhado para Consulta à Coordenação de Edificações (PE0700)'
	},
	PE0700: {
		concluida:
			'Consulta à Coordenação de Edificações (PE0700) concluída, encaminhado para Consulta à Chefia da Unidade de Projetos Especiais (PE0310)',
		consultarDirecao:
			'Consulta à Coordenação de Edificações (PE0700) concluída, encaminhado para Consulta à Diretoria do Escritório de Licenciamento (PE0750)'
	},
	PE0750: {
		concluida:
			'Consulta à Diretoria do Escritório de Licenciamento (PE0750) concluída, processo retornado para Consulta à Coordenação de Edificações (PE0700)'
	},
	PE0800: {
		concluida:
			'Localização do Expediente Único no Arquivo (PE0800) concluída, encaminhado para Digitalização do Expediente Único (PE0900)',
		procNaoEncontrado:
			'Localização do Expediente Único no Arquivo (PE0800) concluída, processo não localizado e processo B criado. Encaminhado para Validação do Expediente Único e Documentos EVU 1 grau (PE0400)'
	},
	PE0900: {
		concluida:
			'Digitalização do Expediente Único (PE0900) concluída, encaminhado para Validação do Expediente Único e Documentos EVU 1 grau (PE0400)',
		naoDigitalizado:
			'Digitalização do Expediente Único (PE0900) não concluída, encaminhado para Validação do Expediente Único e Documentos EVU 1 grau (PE0400)'
	},

	...getAndamentosSM('Triagem técnica EVU 1º Grau (PE0500)'),
	...getAndamentosEV('Conclusão do requerimento de Projetos Especiais'),
	...getAndamentosFluxosPadrao('Triagem técnica EVU 1º Grau (PE0500)'),

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_PE[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_DI = {
	EM_LOCALIZACAO_EU_DI0100: 'DI0100',
	EM_DIGITALIZACAO_DI0200: 'DI0200',

	properties: {
		DI0100: { key: 'DI0100', value: 'Localização do Expediente Único no Arquivo (DI0100)' },
		DI0200: { key: 'DI0200', value: 'Digitalização do Expediente Único (DI0200)' }
	},

	value: s => (SITUACOES_DI.properties[s] || {}).value
};

export const ANDAMENTOS_DI = {
	CONCLUIDA: 'concluida',
	CONCLUIDA_NOK: 'concluidaNok',
	NAO_LOCALIZADO: 'procNaoEncontradoFinal',
	NAO_DIGITALIZADO: 'naoDigitalizadoFinal',

	DI0100: {
		concluida:
			'Localização do Expediente Único no Arquivo (DI0100) concluída, encaminhado para Digitalização do Expediente Único (DI0200)',
		procNaoEncontradoFinal:
			'Localização do Expediente Único no Arquivo (DI0100) concluída, processo não localizado e processo B criado, encaminhado para encerramento de fluxo'
	},
	DI0200: {
		concluida: 'Digitalização do Expediente Único (DI0200) concluída, encaminhado para encerramento de fluxo',
		naoDigitalizadoFinal:
			'Digitalização do Expediente Único (DI0200) não concluída, encaminhado para encerramento de fluxo'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_DI[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_CH = {
	CH0100_EM_ANALISE_COMPAHC: 'CH0100',
	properties: {
		CH0100: { key: 'CH0100', value: 'Em análise do Conselho do Patrimônio Histórico Cultural (CH0100)' }
	},
	value: s => (SITUACOES_CH.properties[s] || {}).value
};

export const ANDAMENTOS_CH = {
	CONCLUIDA: 'concluida',
	COMPLEMENTAR: 'complementar',

	CH0100: {
		concluida:
			'Em análise do Conselho do Patrimônio Histórico Cultural (CH0100) concluída, encaminhado para encerramento do processo.'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_CH[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_LO = {
	LO0100_EM_RECEBIMENTO_VERIFICACAO_DOCS: 'LO0100',
	LO0200_VERIFICACAO_EU: 'LO0200',
	LO0300_EM_DISTRIBUICAO: 'LO0300',
	LO0350_EM_DISTRIBUICAO: 'LO0350',
	LO0400_EM_ANALISE: 'LO0400',
	LO0410_EM_COMPLEMENTACAO: 'LO0410',
	LO0420_EM_CONSULTA_DPU: 'LO0420',
	LO0430_EM_COMPLEMENTACAO: 'LO0430',
	LO0450_EM_REVISAO_PENDENCIAS: 'LO0450',
	LO0460_EM_COMPLEMENTACAO: 'LO0460',
	LO0470_EM_CONSULTA_CHEFIA_LICENCAS: 'LO0470',
	LO0480_EM_CONSULTA_COORDENACAO_EDIFICACOES: 'LO0480',
	LO0490_EM_CONSULTA_DIRECAO_EL: 'LO0490',

	EI0100_DISTRIBUIR_PROCESSO_EAPIS: 'EI0100',
	EI0200_ANALISE_PARECER_TECNICO: 'EI0200',
	EI0310_ANALISE_VEGETACAO: 'EI0310',
	EI0320_ANALISE_POLUICAO_SONORA: 'EI0320',
	EI0330_ANALISE_POLUICAO_HIDRICA: 'EI0330',
	EI0340_ANALISE_MEIO_FISICO: 'EI0340',
	EI0350_ANALISE_MOVIMENTACAO_TERRA: 'EI0350',
	EI0360_ANALISE_FAUNA: 'EI0360',
	EI0370_ANALISE_POLUICAO_VISUAL: 'EI0370',

	properties: {
		LO0100: { key: 'LO0100', value: 'Recebimento do requerimento (LO0100)' },
		LO0200: { key: 'LO0200', value: 'Registro da etapa (LO0200)' },
		LO0300: { key: 'LO0300', value: 'Distribuição do processo (LO0300)' },
		LO0350: { key: 'LO0350', value: 'Distribuição do processo (LO0350)' },
		LO0400: { key: 'LO0400', value: 'Analisar Documentação (LO0400)' },
		LO0410: { key: 'LO0410', value: 'Complementação (LO0410)' },
		LO0420: { key: 'LO0420', value: 'Consulta à DPU (LO0420)' },
		LO0430: { key: 'LO0430', value: 'Complementação (LO0430)' },
		LO0450: { key: 'LO0450', value: 'Revisar Pendências (LO0450)' },
		LO0460: { key: 'LO0460', value: 'Complementação (LO0460)' },
		LO0470: { key: 'LO0470', value: 'Consulta à Chefia ULA (LO0470)' },
		LO0480: { key: 'LO0480', value: 'Consulta à Coordenação de Edificações (LO0480)' },
		LO0490: { key: 'LO0490', value: 'Consulta à Diretoria do Escritório de Licenciamento (LO0490)' },
		EI0100: { key: 'EI0100', value: 'Distribuir processo EAPIS (EI0100)' },
		EI0130: { key: 'EI0130', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130)' },
		EI0200: { key: 'EI0200', value: 'Análise do Parecer Técnico (EI0200)' },
		EI0210: { key: 'EI0210', value: 'Consulta ao Chefe da ULA (EI0210)' },
		EI0230: { key: 'EI0230', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (EI0230)' },
		EI0310: { key: 'EI0310', value: 'Análise Vegetação (EI0310)' },
		EI0320: { key: 'EI0320', value: 'Análise Poluição Sonora (EI0320)' },
		EI0330: { key: 'EI0330', value: 'Análise Poluição Hídrica (EI0330)' },
		EI0340: { key: 'EI0340', value: 'Análise Meio Físico (EI0340)' },
		EI0350: { key: 'EI0350', value: 'Análise Movimentação de Terra (EI0350)' },
		EI0360: { key: 'EI0360', value: 'Análise Fauna (EI0360)' },
		EI0370: { key: 'EI0370', value: 'Análise Poluição Visual (EI0370)' }
	},

	value: s => (SITUACOES_LO.properties[s] || {}).value
};

export const ANDAMENTOS_LO = {
	CONCLUIDA: 'concluida',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',
	CONFLITO_ETAPAS: 'conflitoEtapas',
	TRAMITANDO_EU: 'tramitandoEU',
	CRIACAO_EU: 'criacaoEu',
	ATUALIZACAO_EU: 'atualizacaoEu',
	RETORNAR_ATENDIMENTO: 'retornarAtendimento',
	RETORNAR_DISTRIBUICAO: 'retornarDistribuicao',
	DIGITALIZAR: 'digitalizar',
	COMPLEMENTAR: 'complementar',
	CONSULTAR_CHEFIA: 'consultarChefia',
	CONSULTAR_DPU: 'consultarDpu',
	TRANSFERIR_EU: 'transferirEU',
	CRIAR_EU: 'criarEU',
	INDEFERIR: 'indeferir',
	RETORNAR: 'retornar',
	CONSULTAR_COORDENACAO: 'consultarCoordenacao',
	CONSULTAR_DIRECAO: 'consultarDirecao',
	CONSULTAR_TECNICOS: 'consultarTecnicos',
	RETORNAR_ULA: 'retornarUla',
	CONSULTAR_CHEFIA_ULA: 'consultarChefiaUla',
	RETORNAR_ANALISE: 'retornarAnalise',

	LO0100: {
		concluida: 'Recebimento do requerimento (LO0100) concluído, encaminhado para Registro da etapa (LO0200)',
		conflitoEtapas: 'Recebimento do requerimento (LO0100) concluído, requerimento rejeitado e concluído',
		documentosInvalidos: 'Recebimento do requerimento (LO0100) concluído, requerimento rejeitado e concluído'
	},
	LO0200: {
		concluida: 'Registro da etapa (LO0200) concluída, encaminhado para Distribuição do processo (LO0300)',
		tramitandoEU:
			'Registro da etapa (LO0200) aguardando, processo aguardando tramitação de outra etapa do Expediente Único',
		criacaoEu:
			'Registro da etapa (LO0200) concluída, encaminhado para criação do expediente único e Distribuição do processo (LO0300)',
		atualizacaoEu:
			'Registro da etapa (LO0200) concluída, encaminhado para atualização do expediente único e Distribuição do processo (LO0300)'
	},
	LO0300: {
		concluida: 'Distribuição do processo (LO0300) concluída, encaminhado para Analisar Documentação (LO0400)',
		retornarAtendimento:
			'Distribuição do processo (LO0300) concluída, encaminhado para Recebimento do requerimento (LO0100)'
	},
	LO0350: {
		concluida: 'Distribuição do processo (LO0350) concluída, encaminhado para Revisar Pendências (LO0450)'
	},
	LO0400: {
		concluida: 'Analisar Documentação (LO0400) concluída, encaminhado para Distribuir processo EAPIS (EI0100)',
		retornarDistribuicao: 'Analisar Documentação (LO0400) concluída, retornado para Distribuição do processo (LO0300)',
		retornarAtendimento: 'Analisar Documentação (LO0400) concluída, retornado para Registro da etapa (LO0200)',
		digitalizar:
			'Analisar Documentação (LO0400) concluída, encaminhado para Localização do Expediente Único no Arquivo (DI0100)',
		complementar:
			'Analisar Documentação (LO0400) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (LO0410)',
		consultarChefia: 'Analisar Documentação (LO0400) aguardando, encaminhado para Consulta à Chefia ULA (LO0470)',
		consultarDpu: 'Analisar Documentação (LO0400) aguardando, encaminhado para Consulta à DPU (LO0420)',
		criarEU:
			'Analisar Documentação (LO0400) aguardando, encaminhado para Criação de Expediente Único e retorna para a Analisar Documentação (LO0400)',
		transferirEU:
			'Analisar Documentação (LO0400) aguardando, encaminhado para Transferência de etapa no Expediente Único e retorna para a Analisar Documentação (LO0400)',
		indeferir: 'Analisar Documentação (LO0400) concluída, requerimento indeferido e concluído'
	},
	LO0410: {
		concluida:
			'Complementação Análise do Licenciamento Expresso (LO0410) concluída, encaminhado para Analisar Documentação (LO0400)'
	},
	LO0420: {
		concluida: 'Consulta à DPU (LO0420), encaminhado para Analisar Documentação (LO0400)',
		complementar:
			'Consulta à DPU (LO0420) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (LO0430)'
	},
	LO0430: {
		concluida:
			'Complementação Análise do Licenciamento Expresso (LO0430) concluída, encaminhado para Consulta à DPU (LO0420)'
	},
	LO0450: {
		concluida: 'Revisar Pendências (LO0450) concluída, encaminhado para Emissão da Licença e deferimento do processo.',
		retornarAnalise: 'Revisar Pendências (LO0450) concluída, encaminhado para Analisar Documentação (LO0400)',
		complementar:
			'Revisar Pendências (LO0450) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (LO0460)',
		indeferir: 'Revisar Pendências (LO0450) concluída, processo indeferido e concluído.'
	},
	LO0460: {
		concluida:
			'Complementação Análise do Licenciamento Expresso (LO0460) concluída, encaminhado para Consulta à DPU (LO0420)'
	},
	LO0470: {
		concluida: 'Consulta à Chefia ULA (LO0470), encaminhado para Analisar Documentação (LO0400)',
		consultarCoordenacao:
			'Consulta à Chefia ULA (LO0470) concluído, encaminhado para Consulta à Coordenação de Edificações (LO0480)'
	},
	LO0480: {
		concluida:
			'Consulta à Coordenação de Edificações (LO0480) concluída, encaminhado para Consulta à Chefia ULA (LO0470)',
		consultarDirecao:
			'Consulta à Coordenação de Edificações (LO0480) concluída, encaminhado para Consulta à Diretoria do Escritório de Licenciamento (LE0490)'
	},
	LO0490: {
		concluida:
			'Consulta à Diretoria do Escritório de Licenciamento (LO0490) concluída, processo retornado para Consulta à Coordenação de Edificações (LO0480)'
	},
	EI0100: {
		concluida: 'Distribuir processo EAPIS (EI0100) concluída, encaminhado para ',
		retornarUla: 'Distribuir processo EAPIS (EI0100) concluída, retornando para Analisar Documentação (LO0400)'
	},
	EI0130: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130) concluída, encaminhado para Análise Técnica'
	},
	EI0200: {
		concluida: 'Análise do Parecer Técnico (EI0200) concluída, encaminhado para Distribuição do Processo (LO0350)',
		indeferir: 'Análise do Parecer Técnico (EI0200) concluída, encaminhado para Indeferimento do Requerimento',
		retornarDistribuicao: 'Análise do Parecer Técnico (EI0200) concluída, retornado Distribuir processo EAPIS (EI0100)',
		consultarChefiaUla:
			'Análise do Parecer Técnico (EI0200) aguardando, encaminhado para Consulta à Chefia ULA (EI0210)',
		complementar:
			'Análise do Parecer Técnico (EI0200) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EI0230)'
	},
	EI0210: {
		concluida: 'Consulta ao Chefe da ULA (EI0210) concluída, encaminhado para Análise do Parecer Técnico (EI0200)'
	},
	EI0230: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (EI0230) concluída, encaminhado para Análise do Parecer Técnico (EI0200)'
	},
	EI0310: {
		concluida: 'Análise Vegetação (EI0310) concluída, encaminhado para Análise do Parecer Técnico (EI0200)',
		complementar:
			'Análise Vegetação (EI0310) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130)'
	},
	EI0320: {
		concluida: 'Análise Poluição Sonora (EI0320) concluída, encaminhado para Análise do Parecer Técnico (EI0200)',
		complementar:
			'Análise Poluição Sonora (EI0320) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130)'
	},
	EI0330: {
		concluida: 'Análise Hídrica (EI0330) concluída, encaminhado para Análise do Parecer Técnico (EI0200)',
		complementar:
			'Análise Hídrica (EI0330) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130)'
	},
	EI0340: {
		concluida: 'Análise Meio Físico (EI0340) concluída, encaminhado para Análise do Parecer Técnico (EI0200)',
		complementar:
			'Análise Meio Físico (EI0340) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130)'
	},
	EI0350: {
		concluida: 'Análise Terra (EI0350) concluída, encaminhado para Análise do Parecer Técnico (EI0200)',
		complementar:
			'Análise Terra (EI0350) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130)'
	},
	EI0360: {
		concluida: 'Análise Fauna (EI0360) concluída, encaminhado para Análise do Parecer Técnico (EI0200)',
		complementar:
			'Análise Fauna (EI0360) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130)'
	},
	EI0370: {
		concluida: 'Análise Poluição Visual (EI0370) concluída, encaminhado para Análise do Parecer Técnico (EI0200)',
		complementar:
			'Análise Poluição Visual (EI0370) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico (EI0130)'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_LO[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_CV = {
	CV0100_EM_ANALISE: 'CV0100',
	CV0150_EM_COMPLEMENTACAO: 'CV0150',
	CV0200_EM_AVALIACAO: 'CV0200',
	CV0250_EM_COMPLEMENTACAO: 'CV0250',

	properties: {
		CV0100: { key: 'CV0100', value: 'Em análise (CV0100)' },
		CV0150: { key: 'CV0150', value: 'Complementação de documentos e/ou informações pelo Responsável (CV0150)' },
		CV0200: { key: 'CV0200', value: 'Em avaliação (CV0200)' },
		CV0250: { key: 'CV0250', value: 'Complementação de documentos e/ou informações pelo Responsável (CV0250)' }
	},

	value: s => (SITUACOES_CV.properties[s] || {}).value
};

export const ANDAMENTOS_CV = {
	CONCLUIDA: 'concluida',
	COMPLEMENTAR: 'complementar',
	INDEFERIR: 'indeferir',
	REAGENDAR: 'reagendar',
	DISPENSADO: 'dispensado',

	CV0100: {
		concluida: 'Em análise (CV0100) concluída, aguardando realização do evento',
		indeferir: 'Em análise (CV0100) concluída, requerimento rejeitado e concluído',
		dispensado: 'Em análise (CV0100) concluída, requerimento dispensado de licenciamento',
		complementar:
			'Em análise (CV0100) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável (CV0150)'
	},
	CV0150: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável (CV0150) concluída, encaminhado para Em análise (CV0100)'
	},
	CV0200: {
		concluida: 'Em avaliação (CV0200) concluída, encaminhado para conclusão do requerimento',
		indeferir: 'Em avaliação (CV0200) concluída, requerimento marcado como não ocorrido e concluído',
		reagendar: 'Em avaliação (CV0200) concluída, encaminhado para reagendamento (CV0100)',
		complementar:
			'Em avaliação (CV0200) aguardando, encaminhado para Complementação de documentos e/ou informações pelo Responsável (CV0250)'
	},
	CV0250: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável (CV0250) concluída, encaminhado para Em avaliação (CV0200)'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_CV[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_GA = {
	GA0100_EM_CONSULTA_COORDENACAO_EDIF: 'GA0100',
	GA0200_EM_CONSULTA_DIREACAO_EL: 'GA0200',

	properties: {
		GA0100: { key: 'GA0100', value: 'Em consulta à Coordenação de Edificações (GA0100)' },
		GA0200: { key: 'GA0200', value: 'Em consulta à Direção do EL (GA0200)' }
	},

	value: s => (SITUACOES_GA.properties[s] || {}).value
};

export const ANDAMENTOS_GA = {
	CONCLUIDA: 'concluida',
	CONSULTA_DIRECAO_EL: 'consultaDirecaoEl',

	GA0100: {
		concluida: 'Em consulta à Coordenação de Edificações (GA0100) concluída, aguardando realização do evento',
		consultaDirecaoEl:
			'Em consulta à Coordenação de Edificações (GA0100) concluída, encaminhado para Em consulta à Direção do EL (GA0200)'
	},
	GA0200: {
		concluida:
			'Em consulta à Direção do EL (GA0200) concluída, retornando para Em consulta à Coordenação de Edificações (GA0100)'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_GA[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_AT = {
	AT0100_EM_PRE_TRIAGEM: 'AT0100',

	properties: {
		AT0100: { key: 'AT0100', value: 'Recebimento do requerimento (AT0100)' }
	},

	value: s => (SITUACOES_GA.properties[s] || {}).value
};

export const ANDAMENTOS_AT = {
	CONCLUIDA: 'concluida',
	DOCUMENTOS_INVALIDOS: 'documentosInvalidos',

	AT0100: {
		concluida:
			'Recebimento do requerimento (AT0100) concluído, encaminhado à Pré-distribuição de Ajustes de Projetos (AP1101)',
		documentosInvalidos: 'Recebimento do requerimento (AT0100) concluído, etapa rejeitada e concluída.'
	},

	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_AT[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const SITUACOES_LT = {
	LT0100_EM_ANALISE: 'LT0100',
	LT0300_EM_COMPLEMENTACAO: 'LT0300',

	properties: {
		LT0100: { key: 'LT0100', value: 'Análise do requerimento (LT0100)' },
		LT0300: { key: 'LT0300', value: 'Complementação de documentos e/ou informações pelo Responsável Técnico (LT0300)' }
	},

	value: s => (SITUACOES_LT.properties[s] || {}).value
};

export const ANDAMENTOS_LT = {
	CONCLUIDA: 'concluida',
	COMPLEMENTAR: 'complementar',
	INDEFERIDO: 'indeferido',

	LT0100: {
		concluida: 'Análise do requerimento (LT0100) concluído, requerimento aprovado e concluído',
		indeferido: 'Análise do requerimento (LT0100) concluído, requerimento rejeitado e concluído',
		complementar:
			'Análise do requerimento (LT0100) concluído, encaminhado para Complementação de documentos e/ou informações pelo Responsável Técnico'
	},
	LT0300: {
		concluida:
			'Complementação de documentos e/ou informações pelo Responsável Técnico (LT0300) concluída, encaminhado para Análise do requerimento (LT0100)'
	},
	getAndamento: (taskname, andamento = 'concluida') =>
		(ANDAMENTOS_LT[taskname] || {})[andamento] || `Andamento ${andamento} não encontrado para task ${taskname}`
};

export const DATA_CONTAINERS = {
	AP0200: 'dadosAP0200',
	AP0400: 'dadosAP0400',
	AP0500: 'dadosTriagem',
	AP0600: 'dadosConsultaEdiSmams',
	AP0610: 'dadosConsultaAtendimento',
	AP0620: 'dadosConsultaCauge',
	AP0630: 'dadosConsultaEpe1',
	AP0640: 'dadosConsultaChefiaTriagem',
	AP0650: 'dadosConsultaCoordEdif',
	AP0660: 'dadosConsultaDirecao',
	AP0670: 'dadosAP0670',
	AP0680: 'dadosAP0680',
	AP0700: 'dadosConsultaAssessoriaJuridica',
	AP0800: 'dadosLocalizacaoArquivo',
	AP0900: 'dadosDigitalizacao',
	AP0905: 'dadosHomologacaoIndeferimento',
	AP1100: 'dadosAP1100',
	AP1101: 'dadosAP1101',
	AP1200: 'dadosAnaliseCondicionantes',
	AP1205: 'dadosAP1205',
	AP1209: 'dadosAP1209',
	AP1210: 'dadosAP1210',
	AP1215: 'dadosAP1215',
	AP1220: 'dadosAP1220',
	AP1230: 'dadosAP1230',
	AP1240: 'dadosAP1240',
	AP1250: 'dadosAP1250',
	AP1255: 'dadosAP1255',
	AP1260: 'dadosAP1260',
	AP1265: 'dadosAP1265',
	AP1270: 'dadosAP1270',
	AP1280: 'dadosAP1280',
	AP1281: 'dadosAP1281',
	AP1290: 'dadosAP1290',
	AP1299: 'dadosAP1299',
	AP1430: 'dadosLocalizacaoArquivo1430',
	AP1460: 'dadosDigitalizacao1460',
	AP1500: 'dadosAnaliseProjetoArquitetonico',
	AP1510: 'dadosReanaliseCondicionantes',
	AP1600: 'dadosCondicionantesAprovacao',
	AP1700: 'dadosAnaliseParecerFinal',
	AP1750: 'dadosAP1750',
	AP1800: 'dadosRevisaoCompraDeIndices',
	AP1820: 'dadosAP1820',
	AP1822: 'dadosAP1820', // usa o mesmo container da AP1820 para centralizar os dados
	AP1850: 'dadosAP1850',
	AP1890: 'dadosValidacaoIndeferimento',
	AP1900: 'dadosRevisaoTaxaDam',
	AP1920: 'dadosAP1920',
	AP1950: 'dadosAP1950',
	AP1990: 'dadosAP1990',

	SM0100: 'dadosDistribuicaoChefeElicCla',
	SM0200: 'dadosDistribuicaoChefeEuos',
	SM0250: 'dadosConsultaChefiaEuos',
	SM0260: 'dadosConsultaChefiaEapis',
	SM0300: 'dadosAnaliseMeioBioticoEuos',
	SM0320: 'dadosConsultaMeioBioticoChefiaEuos',
	SM0400: 'dadosAnaliseMeioFisicoEuos',
	SM0420: 'dadosConsultaMeioFisicoChefiaEuos',
	SM0450: 'dadosAnaliseFaunaEuos',
	SM0470: 'dadosConsultaFaunaChefiaEuos',
	SM0500: 'dadosDistribuicaoChefeEapis',
	SM0600: 'dadosAnaliseMeioBioticoEapis',
	SM0620: 'dadosConsultaMeioBioticoChefiaEapis',
	SM0700: 'dadosAnaliseMeioFisicoEapis',
	SM0720: 'dadosConsultaMeioFisicoChefiaEapis',
	SM0820: 'dadosSM0820',
	SM0850: 'dadosSM0850',

	UD0100: 'dadosEncaminhamentoAnaliseUdri',
	UD0200: 'dadosAnaliseViabilidadeUdri',
	UD0300: 'dadosConsultaAssessoriaJuridicaUdri',
	UD0400: 'dadosConsultaPgmUdri',
	UD0600: 'dadosConsultaChefiaUdri',
	UD0700: 'dadosConsultaDirecaoUdri',

	EV0100: 'dadosEV0100',
	EV0110: 'dadosEV0110',
	EV0120: 'dadosEV0120',
	EV0130: 'dadosEV0130',
	EV0140: 'dadosEV0140',
	EV0150: 'dadosEV0150',
	EV0155: 'dadosEV0155',
	EV0160: 'dadosEV0160',
	EV0191: 'dadosEV0191',
	EV0192: 'dadosEV0192',
	EV0193: 'dadosEV0193',
	EV0194: 'dadosEV0194',
	EV0200: 'dadosAnaliseProcessoEve',
	EV0250: 'dadosEV0250',
	EV0291: 'dadosEV0291',
	EV0292: 'dadosEV0292',
	EV0293: 'dadosEV0293',
	EV0294: 'dadosEV0294',
	EV0350: 'dadosRevisaoParecerEvu',
	EV0400: 'dadosElaboracaoPautaCevea',
	EV0500: 'dadosDisponibilizacaoProcEve',
	EV0510: 'dadosEV0510',
	EV0520: 'dadosEV0520',
	EV0530: 'dadosEV0530',
	EV0540: 'dadosEV0540',
	EV0550: 'dadosEV0550',
	EV0560: 'dadosEV0560',
	EV0600: 'dadosFinalizacaoParecerAssinaturasEve',
	EV0800: 'dadosEV0800',
	EV0900: 'dadosEV0900',

	CP0100: 'dadosDistribuicaoCpodSmim',
	CP0110: 'dadosAnaliseTecnicosCpodSmim',
	CP0120: 'dadosAnaliseTecnicosCpodSmim',
	CP0130: 'dadosAnaliseTecnicosCpodSmim',
	CP0200: 'dadosValidacaoParecerChefiaCpodSmim',

	DM0100: 'dadosDistribuicaoDmae',
	DM0200: 'dadosValidacaoParecerChefiaDmae',
	DM0110: 'dadosAnaliseTecnicosDmae',
	DM0120: 'dadosAnaliseTecnicosDmae',
	DM0130: 'dadosAnaliseTecnicosDmae',
	DM1000: 'dadosDistribuicaoDmae',
	DM2000: 'dadosValidacaoParecerChefiaDmae',
	DM1100: 'dadosAnaliseTecnicosDmae',
	DM1200: 'dadosAnaliseTecnicosDmae',
	DM1300: 'dadosAnaliseTecnicosDmae',

	DP0100: 'dadosDistribuicaoDpvSmim',
	DP0200: 'dadosValidacaoParecerChefiaDpvSmim',
	DP0110: 'dadosAnaliseTecnicosDpvSmim',
	DP0120: 'dadosAnaliseTecnicosDpvSmim',
	DP0130: 'dadosAnaliseTecnicosDpvSmim',

	EA0100: 'dadosDistribuicaoEapSmams',
	EA0200: 'dadosValidacaoParecerChefiaEapSmams',
	EA0110: 'dadosAnaliseTecnicosEapSmams',
	EA0120: 'dadosAnaliseTecnicosEapSmams',
	EA0130: 'dadosAnaliseTecnicosEapSmams',

	ES0100: 'dadosDistribuicaoEdiSmams',
	ES0200: 'dadosValidacaoParecerChefiaEdiSmams',
	ES0110: 'dadosAnaliseTecnicosEdiSmams',
	ES0120: 'dadosAnaliseTecnicosEdiSmams',
	ES0130: 'dadosAnaliseTecnicosEdiSmams',

	PH0100: 'dadosDistribuicaoEpahc',
	PH0200: 'dadosValidacaoParecerChefiaEpahc',
	PH0110: 'dadosAnaliseTecnicosEpahc',
	PH0120: 'dadosAnaliseTecnicosEpahc',
	PH0130: 'dadosAnaliseTecnicosEpahc',
	// para o fluxo de licencas (li0300)
	PH2100: 'dadosDistribuicaoEpahc',
	PH2110: 'dadosAnaliseTecnicosEpahc',
	PH2120: 'dadosAnaliseTecnicosEpahc',
	PH2130: 'dadosAnaliseTecnicosEpahc',
	PH2200: 'dadosValidacaoParecerChefiaEpahc',

	EP0100: 'dadosDistribuicaoEptc',
	EP0200: 'dadosValidacaoParecerChefiaEptc',
	EP0110: 'dadosAnaliseTecnicosEptc',
	EP0120: 'dadosAnaliseTecnicosEptc',
	EP0130: 'dadosAnaliseTecnicosEptc',

	ER0100: 'dadosDistribuicaoErfDgel',
	ER0200: 'dadosValidacaoParecerChefiaErfDgel',
	ER0110: 'dadosAnaliseTecnicosErfDgel',
	ER0120: 'dadosAnaliseTecnicosErfDgel',
	ER0130: 'dadosAnaliseTecnicosErfDgel',

	VS0100: 'dadosVerificacaoDocsVistoria',
	VS0200: 'dadosVS0200',
	VS0300: 'dadosVS0300',
	VS0400: 'dadosVS0400',
	VS0500: 'dadosVS0500',
	VS0510: 'dadosVS0510',
	VS0600: 'dadosVS0600',
	VS0610: 'dadosVS0610',
	VS0620: 'dadosVS0620',
	VS0630: 'dadosVS0630',
	VS0640: 'dadosVS0640',
	VS0675: 'dadosVS0675',
	VS0680: 'dadosVS0680',
	VS0685: 'dadosVS0685',
	VS0687: 'dadosVS0687',
	VS0688: 'dadosVS0688',
	VS0690: 'dadosVS0690',
	VS0695: 'dadosVS0695',
	VS0696: 'dadosVS0696',
	VS0700: 'dadosVS0700',
	VS0750: 'dadosVS0750',

	VS0800: 'dadosVS0800',
	VS0900: 'dadosVS0900',
	EU1100: 'dadosEU1100',
	EC1100: 'dadosEC1100',
	EP1100: 'dadosEP1100',
	ES1100: 'dadosES1100',
	UA1100: 'dadosUA1100',
	CA1100: 'dadosCA1100',
	PH1100: 'dadosPH1100',

	PV1000: 'dadosDistribuicaoCpv',
	PV2000: 'dadosValidacaoParecerChefiaCpv',
	PV1100: 'dadosAnaliseTecnicosCpv',
	PV1200: 'dadosAnaliseTecnicosCpv',
	PV1300: 'dadosAnaliseTecnicosCpv',

	QP1000: 'dadosDistribuicaoGplaDmae',
	QP2000: 'dadosValidacaoParecerChefiaGplaDmae',
	QP1100: 'dadosAnaliseTecnicosGplaDmae',
	QP1200: 'dadosAnaliseTecnicosGplaDmae',
	QP1300: 'dadosAnaliseTecnicosGplaDmae',

	PG0100: 'dadosPG0100',
	PG0200: 'dadosPG0200',
	PG0210: 'dadosPG0210',
	PG0400: 'dadosPG0400',
	PG0500: 'dadosPG0500',
	PG0600: 'dadosPG0600',
	PG0700: 'dadosPG0700',
	PG0800: 'dadosPG0800',

	EA1000: 'dadosEA1000',
	EA1100: 'dadosEA1100',
	EA1200: 'dadosEA1200',
	EA1300: 'dadosEA1300',

	AJ1000: 'dadosAJ1000',

	LE0100: 'dadosLE0100',
	LE0200: 'dadosLE0200',
	LE0300: 'dadosLE0300',
	LE0400: 'dadosLE0400',
	LE0600: 'dadosLE0600',
	LE0700: 'dadosLE0700',
	LE0750: 'dadosLE0750',
	LE0800: 'dadosLE0800',
	LE0900: 'dadosLE0900',
	LE0950: 'dadosLE0950',
	LE0970: 'dadosLE0970',

	ET0100: 'dadosET0100',

	DI0100: 'dadosDI0100',
	DI0200: 'dadosDI0200',

	CE0100: 'dadosVerificacaoDocsVistoria',
	CE0200: 'dadosCE0200',
	CE0300: 'dadosCE0300',
	CE0310: 'dadosCE0310',
	CE0400: 'dadosCE0400',
	CE0420: 'dadosCE0420',
	CE0500: 'dadosCE0500',
	CE0600: 'dadosCE0600',
	CE0700: 'dadosCE0700',
	CE0800: 'dadosCE0800',
	CE0900: 'dadosCE0900',
	CE0920: 'dadosCE0920',

	CI0100: 'dadosVerificacaoDocs',
	CI0200: 'dadosCI0200',
	CI0300: 'dadosCI0300',
	CI0400: 'dadosCI0400',
	CI0500: 'dadosCI0500',
	CI0800: 'dadosCI0800',
	CI0810: 'dadosCI0810',

	LI0100: 'dadosLI0100',
	LI0200: 'dadosLI0200',
	LI0250: 'dadosLI0250',
	LI0300: 'dadosLI0300',
	LI0400: 'dadosLI0400',
	LI0500: 'dadosLI0500',
	LI0600: 'dadosLI0600',
	LI0700: 'dadosLI0700',
	LI0800: 'dadosLI0800',
	LI0840: 'dadosLI0840',
	LI0900: 'dadosLI0900',

	RC0100: 'dadosRC0100',
	RC0200: 'dadosRC0200',
	RC0210: 'dadosRC0210',
	RC0220: 'dadosRC0220',
	RC0240: 'dadosRC0240',
	RC0260: 'dadosRC0260',
	RC0270: 'dadosRC0270',
	RC0300: 'dadosRC0300',
	RC0400: 'dadosRC0400',

	BL0050: 'dadosBL0050',
	BL0100: 'dadosBL0100',

	CS0100: 'dadosCS0100',
	CS0200: 'dadosCS0200',
	CS0300: 'dadosCS0300',

	GE0100: 'dadosGE0100',

	PE0100: 'dadosPE0100',
	PE0200: 'dadosPE0200',
	PE0300: 'dadosPE0300',
	PE0400: 'dadosPE0400',
	PE0500: 'dadosPE0500',
	PE0505: 'dadosPE0505',
	PE0515: 'dadosPE0515',
	PE0520: 'dadosPE0520',
	PE0525: 'dadosPE0525',
	PE0535: 'dadosPE0535',
	PE0545: 'dadosPE0545',
	PE0555: 'dadosPE0555',
	PE0565: 'dadosPE0565',
	PE0310: 'dadosPE0310',
	PE0700: 'dadosPE0700',
	PE0750: 'dadosPE0750',
	PE0800: 'dadosPE0800',
	PE0900: 'dadosPE0900',

	CH0100: 'dadosCH0100',

	LO0100: 'dadosLO0100',
	LO0200: 'dadosLO0200',
	LO0300: 'dadosLO0300',
	LO0350: 'dadosLO0350',
	LO0400: 'dadosLO0400',
	LO0420: 'dadosLO0420',
	LO0450: 'dadosLO0450',
	LO0470: 'dadosLO0470',
	LO0480: 'dadosLO0480',
	LO0490: 'dadosLO0490',

	EI0100: 'dadosEI0100',
	EI0200: 'dadosEI0200',
	EI0210: 'dadosEI0210',
	EI0310: 'dadosEI0310',
	EI0320: 'dadosEI0320',
	EI0330: 'dadosEI0330',
	EI0340: 'dadosEI0340',
	EI0350: 'dadosEI0350',
	EI0360: 'dadosEI0360',
	EI0370: 'dadosEI0370',

	CV0100: 'dadosCV0100',
	CV0200: 'dadosCV0200',

	PR0100: 'dadosPR0100',
	PR0200: 'dadosPR0200',
	PR0300: 'dadosPR0300',
	PR0400: 'dadosPR0400',
	PR0460: 'dadosPR0460',
	PR0500: 'dadosPR0500',

	CA0100: 'dadosCA0100',

	GA0100: 'dadosGA0100',
	GA0200: 'dadosGA0200',

	AT0100: 'dadosAT0100',

	LT0100: 'dadosLT0100'
};

export const ROLES = {
	CADASTRAR_TIPOS: 1,
	DISTRIBUIR: 2,
	REVISAR_PROCESSO: 3,
	CHEFE_CERTIDAO: 4,
	CHEFE_LICENCA: 5,
	PRE_TRIAGEM: 6,
	TRIAGEM: 7,
	DIGITALIZACAO: 8,
	ARQUIVO: 9,
	CHEFE_UAP: 10,
	CHEFE_ILHA: 11,
	REVISOR_UAP: 12,
	UDRI: 13,
	ASSISTENTE_ADMINISTRATIVO: 14,
	EPS: 15,
	CPU_SMAMS: 16,
	CADASTRO_CHECKLISTS: 19,
	CHEFE_TRIAGEM: 20,
	EDI_SMAMS: 21,
	EDITOR_ENQUADRAMENTO: 22,
	ASSESSORIA_JURIDICA: 23,
	TERCEIROS: 24,
	TECNICO_MEIO_BIOTICO_EUOS: 25,
	TECNICO_MEIO_FISICO_EUOS: 26,
	TECNICO_FAUNA_EUOS: 40,
	TECNICO_MEIO_BIOTICO_EAPIS: 27,
	TECNICO_MEIO_FISICO_EAPIS: 28,
	ANALISTA_UDRI: 29,
	CHEFE_EVE: 30,
	REVISOR_EVE: 31,
	TRIAGEM_VISTORIA: 32,
	CRIAR_MARCADOR_PUBLICO: 33,
	EDITAR_MARCADOR_PUBLICO: 34,
	EXCLUIR_MARCADOR_PUBLICO: 35,
	ADMIN_MARCADOR_PUBLICO: 36,
	EQUIPE_CARTAS_HABITACAO: 37,
	EQUIPE_NUMERACAO: 38,
	TECNICO_ERDU_SMAMUS: 39,
	ANALISTA_TERMOS_PGM: 41,
	PROCURADOR_PMS06: 42,
	CHEFE_LICENCAS: 43,
	EQUIPE_LICENCAS: 44,
	CADASTRO_NOVIDADES: 45,
	CADASTRO_PROCESSO_ORQUESTRA: 46,
	PROMOVER_ENQUADRAMENTO: 47,
	PROMOVER_RESTRICOES_ADMINISTRATIVAS: 48,
	MIGRAR_BPM: 49,
	EDITOR_RESTRICOES: 50,

	TECNICO_CPOD: 51,
	TECNICO_DMAE: 52,
	TECNICO_DPV: 53,
	TECNICO_EAP: 54,
	TECNICO_EDI: 55,
	TECNICO_EPAHC: 56,
	TECNICO_EPTC: 57,
	TECNICO_ERF: 58,
	TECNICO_PROJVIARIOS: 59,
	TECNICO_QUESTPLUVIAIS: 60,
	TECNICO_DMAE_5PONTOS: 61,
	DIRETOR_SECRETARIO: 62,
	REVISOR_CERTIDOES: 63,
	CHEFE_UDRI: 64,
	ANALISTA_GE: 65,
	EQUIPE_PROJETOS_ESPECIAIS: 66,

	EQUIPE_ULA: 67,
	EDICAO_FAQ: 70,

	TECNICO_POLUICAO_SONORA_EAPIS: 68,
	TECNICO_POLUICAO_VISUAL_EAPIS: 69,
	TECNICO_FAUNA_EAPIS: 71,
	TECNICO_POLUICAO_HIDRICA_EAPIS: 72,
	TECNICO_MOVIMENTACAO_TERRA_EAPIS: 73,

	TECNICO_CEVEN: 74,

	REGISTROS_UAP: 75,

	ADMINISTRADOR_SUBSTITUTOS: 76,

	EQUIPE_PRCHPA: 77,
	CHEFE_PRCHPA: 78,
	ATENDIMENTO_PRCHPA: 79,
	CORDENACAO_PRCHPA: 80,

	EQUIPE_CA: 81,
	PREVIEW: 82,
	CONSULTA_INTEIRO_TEOR: 83,
	EDITOR_AJUDA: 84,
	EDITOR_PARECERES: 85,
	TROCAR_CRIADOR: 86,

	ANULAR_CERTIDAO_BPM: 87,

	LOGADO: 99,
	USUARIO_LICENCIAMENTO: 100,
	ADMINISTRADOR: 999,
	NINGUEM_TEM: 9999,

	properties: {
		1: { key: '1', value: 'urbanismo-licenciamento-cadastrar-tipos' },
		2: {
			key: '2',
			value: 'urbanismo-licenciamento-distribuir',
			bpmRole: 'distribuidor'
		},
		3: {
			key: '3',
			value: 'urbanismo-licenciamento-revisar-processo',
			bpmRole: 'revisor'
		},
		4: { key: '4', value: 'urbanismo-licenciamento-chefe-certidao' },
		5: { key: '5', value: 'urbanismo-licenciamento-chefe-licenca' },
		6: {
			key: '6',
			value: 'urbanismo-licenciamento-pre-triagem',
			bpmRole: 'preTriagem'
		},
		7: {
			key: '7',
			value: 'urbanismo-licenciamento-triagem',
			bpmRole: 'triagem'
		},
		8: {
			key: '8',
			value: 'urbanismo-licenciamento-digitalizacao',
			bpmRole: 'digitalizacao'
		},
		9: {
			key: '9',
			value: 'urbanismo-licenciamento-arquivo',
			bpmRole: 'arquivo'
		},
		10: {
			key: '10',
			value: 'urbanismo-licenciamento-chefe-uap',
			bpmRole: 'chefeUap'
		},
		11: {
			key: '11',
			value: 'urbanismo-licenciamento-chefe-ilha',
			bpmRole: 'chefeIlha'
		},
		12: {
			key: '12',
			value: 'urbanismo-licenciamento-revisor-uap',
			bpmRole: 'revisorUap'
		},
		13: { key: '13', value: 'urbanismo-licenciamento-udri', bpmRole: 'udri' },
		14: {
			key: '14',
			value: 'urbanismo-licenciamento-assistente-administrativo',
			bpmRole: 'assistAdministrativo'
		},
		15: { key: '15', value: 'urbanismo-licenciamento-eps', bpmRole: 'eps' },
		16: {
			key: '16',
			value: 'urbanismo-licenciamento-cpu-smams',
			bpmRole: 'cpuSmams'
		},
		19: { key: '19', value: 'urbanismo-licenciamento-checklist-admin' },
		20: {
			key: '20',
			value: 'urbanismo-licenciamento-chefe-triagem',
			bpmRole: 'chefeTriagem'
		},
		21: {
			key: '21',
			value: 'urbanismo-licenciamento-edi-smams',
			bpmRole: 'ediSmams'
		},
		22: {
			key: '22',
			value: 'urbanismo-licenciamento-editar-enquadramento',
			bpmRole: 'editorEnquadramento'
		},
		23: {
			key: '23',
			value: 'urbanismo-licenciamento-assessoria-juridica',
			bpmRole: 'assessJur'
		},
		24: {
			key: '24',
			value: 'urbanismo-licenciamento-terceiros',
			bpmRole: 'terceiros'
		},
		25: {
			key: '25',
			value: 'urbanismo-licenciamento-tecnico-biotico-euos',
			bpmRole: 'tecnicoBioticoEuos'
		},
		26: {
			key: '26',
			value: 'urbanismo-licenciamento-tecnico-biotico-euos',
			bpmRole: 'tecnicoFisicoEuos'
		},
		40: {
			key: '40',
			value: 'urbanismo-licenciamento-tecnico-fauna-euos',
			bpmRole: 'tecnicoFaunaEuos'
		},
		27: {
			key: '27',
			value: 'urbanismo-licenciamento-tecnico-biotico-eapis',
			bpmRole: 'tecnicoBioticoEapis'
		},
		28: {
			key: '28',
			value: 'urbanismo-licenciamento-tecnico-biotico-eapis',
			bpmRole: 'tecnicoFisicoEapis'
		},
		29: {
			key: '29',
			value: 'urbanismo-licenciamento-analista-udri',
			bpmRole: 'analistaUdri'
		},
		30: {
			key: '30',
			value: 'urbanismo-licenciamento-chefe-eve',
			bpmRole: 'chefeEve'
		},
		31: {
			key: '31',
			value: 'urbanismo-licenciamento-revisor-eve',
			bpmRole: 'revisorEve'
		},
		32: {
			key: '32',
			value: 'urbanismo-licenciamento-triagem-vistoria',
			bpmRole: 'triagemVistoria'
		},
		33: {
			key: '33',
			value: 'urbanismo-licenciamento-criar-marcador-publico'
		},
		34: {
			key: '34',
			value: 'urbanismo-licenciamento-editar-marcador-publico'
		},
		35: {
			key: '35',
			value: 'urbanismo-licenciamento-excluir-marcador-publico'
		},
		36: {
			key: '36',
			value: 'adminMarcadorPublico'
		},
		37: {
			key: '37',
			value: 'urbanismo-licenciamento-equipe-cartas-habitacao'
		},
		38: {
			key: '38',
			value: 'urbanismo-licenciamento-setor-numeracao'
		},
		39: {
			key: '39',
			value: 'urbanismo-licenciamento-tecnico-erdu-smamus'
		},
		41: {
			key: '41',
			value: 'urbanismo-licenciamento-termos-pgm'
		},
		42: {
			key: '42',
			value: 'urbanismo-licenciamento-procurador-pms06'
		},
		43: {
			key: '43',
			value: 'urbanismo-licenciamento-chefe-licencas'
		},
		44: {
			key: '44',
			value: 'urbanismo-licenciamento-equipe-licencas'
		},
		45: { key: '45', value: 'urbanismo-licenciamento-novidades-admin' },
		46: { key: '46', value: 'urbanismo-licenciamento-processo-orquestra-admin' },
		47: { key: '47', value: 'urbanismo-licenciamento-promover-enquadramento' },
		48: { key: '48', value: 'urbanismo-licenciamento-promover-restricoes-administrativas' },
		49: { key: '49', value: 'urbanismo-licenciamento-migrar-bpm' },
		50: { key: '50', value: 'urbanismo-licenciamento-editor-restricoes' },
		51: {
			key: '51',
			value: 'urbanismo-licenciamento-tecnico-cpod',
			bpmRole: 'tecnicoCpod'
		},
		52: {
			key: '52',
			value: 'urbanismo-licenciamento-tecnico-dmae',
			bpmRole: 'tecnicoDmae'
		},
		53: {
			key: '53',
			value: 'urbanismo-licenciamento-tecnico-dpv',
			bpmRole: 'tecnicoDpv'
		},
		54: {
			key: '54',
			value: 'urbanismo-licenciamento-tecnico-eap',
			bpmRole: 'tecnicoEap'
		},
		55: {
			key: '55',
			value: 'urbanismo-licenciamento-tecnico-edi',
			bpmRole: 'tecnicoEdi'
		},
		56: {
			key: '56',
			value: 'urbanismo-licenciamento-tecnico-epahc',
			bpmRole: 'tecnicoEpahc'
		},
		57: {
			key: '57',
			value: 'urbanismo-licenciamento-tecnico-eptc',
			bpmRole: 'tecnicoEptc'
		},
		58: {
			key: '58',
			value: 'urbanismo-licenciamento-tecnico-erf',
			bpmRole: 'tecnicoErf'
		},
		59: {
			key: '59',
			value: 'urbanismo-licenciamento-tecnico-cpv',
			bpmRole: 'tecnicoCpv'
		},
		60: { key: '60', value: 'urbanismo-licenciamento-tecnico-cpd', bpmRole: 'tecnicoCpd' },
		61: { key: '61', value: 'urbanismo-licenciamento-tecnico-5pontos-dmae', bpmRole: 'tecnicoDmae' },
		62: { key: '62', value: 'urbanismo-licenciamento-diretor-secretario' },
		63: { key: '63', value: 'urbanismo-licenciamento-revisor-certidoes', bpmRole: 'revisorCertidao' },
		64: { key: '64', value: 'urbanismo-licenciamento-chefe-udri' },
		65: {
			key: '65',
			value: 'analistaCS',
			bpmRole: 'analistaCS'
		},
		66: {
			key: '66',
			value: 'urbanismo-licenciamento-equipe-projetos-especiais',
			bpmRole: 'equipeProjetosEspeciais'
		},
		67: { key: '67', value: 'urbanismo-licenciamento-equipe-ula' },
		70: { key: '70', value: 'urbanismo-licenciamento-edicao-faq' },

		68: {
			key: '68',
			value: 'urbanismo-licenciamento-tecnico-poluicao-sonora-eapis',
			bpmRole: 'tecnicoPoluicaoSonoraEapis'
		},
		69: {
			key: '69',
			value: 'urbanismo-licenciamento-tecnico-poluicao-visual-eapis',
			bpmRole: 'tecnicoPoluicaoVisualEapis'
		},
		71: {
			key: '71',
			value: 'urbanismo-licenciamento-tecnico-fauna-eapis',
			bpmRole: 'tecnicoFaunaEapis'
		},
		72: {
			key: '72',
			value: 'urbanismo-licenciamento-tecnico-poluicao-hidrica-eapis',
			bpmRole: 'tecnicoPoluicaoHidricaEapis'
		},
		73: {
			key: '73',
			value: 'urbanismo-licenciamento-tecnico-movimentacao-terra-eapis',
			bpmRole: 'tecnicoMovimentacaoTerraEapis'
		},

		74: {
			key: '74',
			value: 'urbanismo-licenciamento-tecnico-ceven',
			bpmRole: 'tecnicoCeven'
		},
		75: { key: '75', value: 'urbanismo-licenciamento-registros-uap', bpmRole: 'registrosUAP' },
		76: { key: '76', value: 'urbanismo-licenciamento-administrador-substituto' },

		77: { key: '77', value: 'urbanismo-licenciamento-analista-prchpa	', bpmRole: 'analistaPrchpa' },
		78: { key: '78', value: 'urbanismo-licenciamento-chefe-prchpa	', bpmRole: 'chefePrchpa' },
		79: { key: '79', value: 'urbanismo-licenciamento-atendimento-prchpa	', bpmRole: 'atendimentoPrchpa' },
		80: { key: '80', value: 'urbanismo-licenciamento-coordenacao-prchpa	', bpmRole: 'coordenacaoPrchpa' },

		81: { key: '81', value: 'urbanismo-licenciamento-revisor-averbacao	', bpmRole: 'revisorAverbacao' },

		82: { key: '82', value: 'urbanismo-licenciamento-preview' },

		83: { key: '83', value: 'urbanismo-licenciamento-consulta-inteiro-teor' },
		84: { key: '84', value: 'urbanismo-licenciamento-editor-ajuda' },
		85: { key: '85', value: 'urbanismo-licenciamento-editor-pareceres' },
		86: { key: '86', value: 'urbanismo-licenciamento-trocar-criador' },

		87: { key: '87', value: 'urbanismo-licenciamento-anular-certidao-bpm' },

		99: { key: '99', value: 'USUARIO_LOGADO_NO_SISTEMA' },
		100: { key: '100', value: 'urbanismo-licenciamento-usuario-licenciamento' },

		999: { key: '999', value: 'administrador' },
		9999: { key: '9999', value: 'NINGUEM_TEM' }
	}
};
export const getRole = roleId => ROLES.properties[roleId]?.value || ROLES.properties[9999].value;
export const getRoles = roleId => {
	const array = [ROLES.properties[roleId]?.value || ROLES.properties[9999].value];
	if (ROLES.properties[roleId]?.bpmRole) {
		array.push(ROLES.properties[roleId]?.bpmRole);
	}
	return array;
};

export const FLUXOS_PADRAO = {
	CP: 'CP',
	DM: 'DM',
	DP: 'DP',
	EA: 'EA',
	EP: 'EP',
	ER: 'ER',
	ES: 'ES',
	PH: 'PH',
	properties: {
		CP: {
			key: 'CP',
			value: 'CpodSmim',
			metadadoContainer: 'analiseCpodSmim',
			siglaSecretaria: 'CPOD-SMIM'
		},
		DM: {
			key: 'DM',
			value: 'Dmae',
			metadadoContainer: 'analiseDmae',
			siglaSecretaria: 'DMAE'
		},
		DP: {
			key: 'DP',
			value: 'DpvSmim',
			metadadoContainer: 'analiseDpvSmim',
			siglaSecretaria: 'DPV-SMIM'
		},
		EA: {
			key: 'EA',
			value: 'EapSmams',
			metadadoContainer: 'analiseEapSmams',
			siglaSecretaria: 'EAP-SMAMS'
		},
		EP: {
			key: 'EP',
			value: 'SMMU',
			metadadoContainer: 'analiseCpvEptc',
			siglaSecretaria: 'DMU-SMMU'
		},
		ER: {
			key: 'ER',
			value: 'ErfDgel',
			metadadoContainer: 'analiseErfDgel',
			siglaSecretaria: 'ERF-DGEL'
		},
		ES: {
			key: 'ES',
			value: 'EdiSmams',
			metadadoContainer: 'analiseEdiSmams',
			siglaSecretaria: 'UAIU-SMAMUS'
		},
		PG: {
			key: 'PG',
			value: 'PumaPgm',
			metadadoContainer: 'analisePumaPgm',
			siglaSecretaria: 'PUMA-PGM'
		},
		PH: {
			key: 'PH',
			value: 'EpahcSmc',
			metadadoContainer: 'analiseEpahcSmc',
			siglaSecretaria: 'EPAHC-SMC'
		},
		PV: {
			key: 'PV',
			value: 'DpovSmoi',
			metadadoContainer: 'analiseDpovSmoi',
			siglaSecretaria: 'DPOV-SMOI'
		},
		QP: {
			key: 'QP',
			value: 'GplaDmae',
			metadadoContainer: 'analiseGplaDmae',
			siglaSecretaria: 'GPLA-DMAE'
		}
	},
	key: value => {
		let key = null;
		const props = FLUXOS_PADRAO.properties;
		const sigla = Object.keys(props).find(key => props[key].value === value);
		if (sigla) {
			key = props[sigla]?.key;
		}
		return key;
	},
	value: s => (FLUXOS_PADRAO.properties[s] || {}).value,
	metadadoContainer: s => (FLUXOS_PADRAO.properties[s] || {}).metadadoContainer,
	siglaSecretaria: s => (FLUXOS_PADRAO.properties[s] || {}).siglaSecretaria
};

export const MAP_NOMES_ATUAIS_SECRETARIAS = {
	'DMAE': 'DMAE',
	'CPOD-SMIM': 'Coordenação de Mercado - DMAE',
	'GPLA-DMAE': 'Coordenação de Mercado - DMAE',
	'EUOS-SMAMS': 'CLA-SMAMUS',
	'UDRI-DGEL': 'CDRI-SMAMUS',
	'EDI-SMAMS': 'UAIU-SMAMUS',
	'CPV-EPTC': 'DMU-SMMU',
	'EAP-SMAMS': 'EAP-SMAMUS',
	'ERF-DGEL': 'ERF-DGEL',
	'EPAHC-SMC': 'EPAHC-SMC',
	'DPV-SMIM': 'DPV-SMIM'
};

export function getAndamento(taskname, andamento) {
	return (
		(ANDAMENTOS_AP[taskname] || {})[andamento] ||
		(ANDAMENTOS_VS[taskname] || {})[andamento] ||
		`Andamento ${andamento} não encontrado para task ${taskname}`
	);
}

/**
 * 10% para Certificação Prata; 15% para Certificação Ouro; 20% para Certificação Diamante.
 */
export const CERTIFICADOS_SUSTENTAVEL = {
	bronze: { nivel: 'bronze', valor: 0 },
	prata: { nivel: 'prata', valor: 0.1 },
	ouro: { nivel: 'ouro', valor: 0.15 },
	diamante: { nivel: 'diamante', valor: 0.2 }
};

/**
 * 2234 - bronze
 * 2235 - prata
 * 2236 - ouro
 * 2237 - diamante
 */
export const CODIGOS_CERTIFICADOS_SUSTENTAVEIS = {
	2234: CERTIFICADOS_SUSTENTAVEL.bronze,
	2235: CERTIFICADOS_SUSTENTAVEL.prata,
	2236: CERTIFICADOS_SUSTENTAVEL.ouro,
	2237: CERTIFICADOS_SUSTENTAVEL.diamante
};

/**
 * de-para do código de um radio/check para algo legível
 *
 * sim-Sim, nao-Não, naoSeAplica-Não se Aplica, etc...
 */
export const CODIGO_LABELS = {
	sim: 'Sim',
	nao: 'Não',
	naoSeAplica: 'Não se aplica'
};
