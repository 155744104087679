import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { isImmutable } from 'immutable';
import { get, isNil } from 'lodash';
import moment from 'moment';

import Loader from 'components/Loader';

import { isDebug } from 'utils/tools';

import ComplementacaoBpmTask from './ComplementacaoBpmTask';
import DocumentosSlots from './DocumentosSlots';

const ComplementacaoBpm = ({
	usuarioDonoTask,
	formulario,
	documentos,
	documentosDados,
	errors,
	onDrop,
	removeFile,
	retiraDocumento,
	criarNovoDocumento,
	criarMultiDocumentos,
	complementarProcesso,
	updateResposta,
	emComplementacao
}) => {
	const [bpmTasks, setBpmTasks] = useState(null);
	const bpmTasksImm = useSelector(state => state.getIn(['licenciamento', 'bpmTasks']));
	useEffect(() => {
		if (bpmTasksImm) {
			setBpmTasks(isImmutable(bpmTasksImm) ? bpmTasksImm.toJS() : bpmTasksImm);
		}
	}, [bpmTasksImm]);

	const getMotivos = docsTask => {
		const todos = get(formulario, 'documentos') || [];
		let motivos = {};
		(docsTask || [])
			.filter(d => d.original && !d.idDocumentoSei)
			.forEach(d => {
				const index = todos.findIndex(item => item.id === d.original);
				const motivo = get(todos, `${index}.motivo`);
				motivos[d.id] = [
					{
						msg: motivo ? `Arquivo rejeitado pelo revisor: ${motivo}` : 'Arquivo rejeitado pelo revisor.',
						resolvido: !!d.filename,
						type: 'warning'
					}
				];
			});
		return motivos;
	};

	const emProcessamento = documento => !documento.idDocumentoSei && !!documento.originalName;

	const documentosComplementar = taskName => documentos.filter(doc => (doc ? doc.complementadaEm === taskName : false));
	const perguntasComplementar = taskName =>
		formulario.formData.extraInfo.filter(info =>
			info ? info.naoRespondida && info.complementadaEm === taskName : false
		);
	const motivosComplementar = taskName => getMotivos(documentosComplementar(taskName));

	const demaisDocumentos = documentos.filter(doc =>
		doc ? !doc.complementadaEm && (!doc.invalido || doc.substituido) : false
	);
	const demaisMotivos = getMotivos(demaisDocumentos);

	return isNil(bpmTasks) ? (
		<Loader msg="Carregando complementaçoes" />
	) : (
		<div className="form-group form-docs">
			<legend>Documentos {isDebug && <span className="debug-message">[COMPLEMENTAÇÃO BPM]</span>}</legend>

			<div className="col alert warning">
				<h3>
					Complementação de documentos e/ou informações <i className="fa fa-exclamation-circle" />
				</h3>
				<p>Verifique os documentos invalidados e/ou informações solicitadas referente ao seu processo.</p>
				<p>
					Se um documento foi invalidado, veja os campos com as observações sobre o motivo pelo qual o documento foi
					invalidado e anexe o documento adequado.Em caso de informações solicitadas, verifique o solicitado e esclareça
					a dúvida.
				</p>
				{usuarioDonoTask ? (
					<p>
						Você tem {bpmTasks.length} {bpmTasks.length === 1 ? 'solicitação' : 'solicitações'} para complementar. Você
						pode fazer isso na ordem e no momento que preferir, mas seu processo só poderá ser concluído quando todas as
						solicitações tiverem sido atendidas. Você tem até{' '}
						{`${moment(get(formulario, 'formData.dataComparecimento'))
							.subtract(1, 'days') // remove 1 dia da exibicao do prazo, OP #58863
							.format('DD/MM/YYYY')} às 23:59`}{' '}
						para concluir todas as complementações, ou seu processo irá expirar.
					</p>
				) : (
					<p>Este requerimento está sendo analisado pela Prefeitura neste momento e não pode ser modificado.</p>
				)}
			</div>
			<>
				{(bpmTasks || []).map(bpmTask => {
					const taskName = bpmTask.displayName.split(' ')[0];
					const extraInfo = get(formulario, 'formData.extraInfo');
					const complAtual = extraInfo.find(info => info.complementadaEm === taskName);
					const dataCompl = complAtual
						? moment(complAtual.data)
						: moment(get(formulario, 'formData.dataComplementacao'));

					return (
						<ComplementacaoBpmTask
							key={bpmTask.id}
							taskName={taskName}
							formulario={formulario}
							documentosComplementar={documentosComplementar(taskName)}
							perguntasComplementar={perguntasComplementar(taskName)}
							documentosDados={documentosDados}
							motivos={motivosComplementar(taskName)}
							errors={errors}
							onDrop={onDrop}
							removeFile={removeFile}
							retiraDocumento={retiraDocumento}
							criarNovoDocumento={criarNovoDocumento}
							criarMultiDocumentos={criarMultiDocumentos}
							complementarProcesso={complementarProcesso}
							updateResposta={updateResposta}
							emProcessamento={emProcessamento}
							dataSolicComplementacao={dataCompl}
							emComplementacao={emComplementacao}
						/>
					);
				})}
				{!emComplementacao && (
					<>
						<legend>Demais documentos</legend>
						<DocumentosSlots
							formulario={formulario}
							documentos={demaisDocumentos}
							documentosDados={documentosDados}
							perguntas={[]}
							disponivel={false}
							motivos={demaisMotivos}
							errors={errors}
							usuarioDonoTask={false}
							prontoParaComplementar={false}
							criarMultiDocumentos={criarMultiDocumentos}
							emProcessamento={emProcessamento}
							onDrop={() => () => false}
							removeFile={() => false}
							retiraDocumento={() => false}
							criarNovoDocumento={() => false}
							updateResposta={() => false}
							complementarProcesso={() => false}
						/>
					</>
				)}
			</>
		</div>
	);
};
ComplementacaoBpm.displayName = 'ComplementacaoBpm';
ComplementacaoBpm.propTypes = {
	usuarioDonoTask: PropTypes.bool,
	formulario: PropTypes.object,
	documentos: PropTypes.arrayOf(PropTypes.object),
	documentosDados: PropTypes.arrayOf(PropTypes.object),
	motivos: PropTypes.object,
	errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
	onDrop: PropTypes.func,
	removeFile: PropTypes.func,
	retiraDocumento: PropTypes.func,
	criarNovoDocumento: PropTypes.func,
	avancar: PropTypes.func,
	criarMultiDocumentos: PropTypes.func,
	complementarProcesso: PropTypes.func,
	updateResposta: PropTypes.func,
	emComplementacao: PropTypes.bool
};

export default ComplementacaoBpm;
