import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { isNil, isEmpty, size } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';

import { NumberField } from 'containers/Form/metadata-template/fields';

import { accessApi } from 'utils/injectApi';

import Loader from './Loader';
import Selecao from './Selecao';

// const CLASS_NAME_FORM_CONTROL = 'form-control';
// const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const AtividadesLicenciaveisHooks = props => {
	const [codram, setCodram] = useState(null);
	const [descricao, setDescricao] = useState(null);
	const [medidaPorte, setMedidaPorte] = useState(null);
	const [unidadeMedidaPorte, setUnidadeMedidaPorte] = useState(null);
	const [permiteProtocolar, setPermiteProtocolar] = useState(undefined);
	const [options, setOptions] = useState([]);
	const [option, setOption] = useState(null);
	const [optionSearchTerm, setOptionSearchTerm] = useState(null);
	const [loading, setLoading] = useState(false);

	const { formData, onChange, schema, errorSchema, disabled } = props;

	useEffect(() => {
		setCodram(formData.codram);
		setDescricao(formData.descricao);
		setMedidaPorte(formData.medidaPorte);
		setUnidadeMedidaPorte(formData.unidadeMedidaPorte);
	}, [formData]);

	const carregaOptions = useCallback(async () => {
		setLoading(true);
		if (size(options) === 0) {
			try {
				const result = await accessApi('/collections/consema-atividades-licenciaveis', true);
				const options = result.data.sort((o1, o2) => {
					const c1 = parseFloat(o1.codram.replace(',', '.'));
					const c2 = parseFloat(o2.codram.replace(',', '.'));
					return c1 > c2 ? 1 : -1;
				});
				let optionAux = option;
				if (formData.codram) {
					optionAux = options.find(o => o.codram === formData.codram);
				}
				setOptions(options);
				setOption(optionAux);
			} catch (e) {
				console.error('Erro no acesso aos dados de Atividades Licenciáveis ', e);
			} finally {
				setLoading(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	useEffect(() => {
		if (size(options) === 0) {
			carregaOptions();
		}
	}, [carregaOptions, options]);

	const onChangeNumberField = obj => {
		if (Array.isArray(obj)) {
			obj.forEach(o => {
				if (o.name !== 'errors') {
					setMedidaPorte(o.value.value);
					const atividadeAux = {
						codram: codram,
						descricao: descricao,
						unidadeMedidaPorte: unidadeMedidaPorte,
						medidaPorte: o.value.value
					};
					onChange(atividadeAux);
					let permiteProtocolar = classificaPorte(atividadeAux, option);
					if (permiteProtocolar !== undefined) {
						setPermiteProtocolar(permiteProtocolar.key);
					}
				}
			});
		}
	};

	const setOptionAux = option => {
		setOption(option);
		if (option) {
			setCodram(option.codram);
			setDescricao(option.descricao);
			setUnidadeMedidaPorte(option.unidadeMedidaPorte);
			const atividadeAux = {
				codram: option.codram,
				descricao: option.descricao,
				unidadeMedidaPorte: option.unidadeMedidaPorte,
				medidaPorte: option.medidaPorte
			};
			onChange(atividadeAux);
			let permiteProtocolar = classificaPorte(atividadeAux, option);
			if (permiteProtocolar !== undefined) {
				setPermiteProtocolar(permiteProtocolar.key);
			}
		} else {
			setPermiteProtocolar(undefined);
			onChange({ codram: null, descricao: null, unidadeMedidaPorte: null, medidaPorte: null });
		}
	};

	const { codram: errorCodram, medidaPorte: errorMedidaPorte } = errorSchema;
	const requireds = schema?.required || [];
	const requiredCodram = requireds.indexOf('codram') > -1;
	const requiredMedidaPorte = requireds.indexOf('medidaPorte') > -1;

	return (
		<>
			{loading && <Loader />}
			<div className="d-flex flex-wrap">
				<div className="form-group col-md-6" style={{ whiteSpace: 'nowrap' }}>
					<label className="control-label hideable" htmlFor="root_nome">
						CODRAM (
						<a
							href="https://www.sema.rs.gov.br/upload/arquivos/202207/07113333-372-2018-atividades-licenciaveis-compilada.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							Resolução CONSEMA
						</a>
						){requiredCodram ? '*' : ''}
					</label>
					<div />
					<Selecao
						className={'form-control'}
						detailInnerClassName={'title-with-button-white-space-normal'}
						multiple={false}
						selected={option || ''}
						label={'CODRAM'}
						detailCodigo={'codram'}
						detailDescricao={'descricao'}
						autoShowList={true}
						searchTerm={optionSearchTerm}
						searchList={(options || [])
							.filter(o => JSON.stringify(o).indexOf(optionSearchTerm) > -1)
							.filter(o => Object.prototype.hasOwnProperty.call(o, 'competenciaMunicipal'))}
						searchTermMinLength={0}
						errorList={false}
						onChangeSearchTerm={e => setOptionSearchTerm(e.target.value)}
						onBlurSearchTerm={() => false}
						onSelectItem={item => () => setOptionAux(item)}
						onUnselect={() => () => setOptionAux(null)}
						loading={false}
						required={true}
						detailModifier={prop => prop}
						placeholder="Selecione a atividade"
						disabled={disabled}
						readOnly={disabled}
					/>
					<ErrorDetail attr={errorCodram} />
				</div>
				<div className="form-group col-md-3">
					<label>Unidade de Medida</label>
					<textarea type="text" readOnly aria-readonly value={unidadeMedidaPorte || ''} className="form-control" />
				</div>
				<div className="form-group col-md-3">
					<NumberField
						name="medidaPorte"
						label="Medida de Porte"
						placeHolder="0,00"
						required={requiredMedidaPorte}
						onChangeHandler={e => onChangeNumberField(e)}
						value={{ value: medidaPorte } || {}}
						decimals={2}
						maxSize="10"
						disabled={disabled}
					/>
					<ErrorDetail attr={errorMedidaPorte} />
				</div>
			</div>
			{permiteProtocolar === 'restringir' && !isNil(medidaPorte) && isEmpty(errorSchema) && (
				<li className={'error-message'} style={{ listStyle: 'none' }}>
					<i className="fa fa-exclamation-circle" style={{ marginRight: '8px' }} aria-hidden="true" />
					<span style={{ whiteSpace: 'pre-wrap' }}>
						Este licenciamento deve ser realizado no governo do Estado do Rio Grande do Sul através do endereço{' '}
						<a href="https://secweb.procergs.com.br/sra">https://secweb.procergs.com.br/sra</a>.
					</span>
				</li>
			)}
			{permiteProtocolar === 'bloquear' && !isNil(medidaPorte) && isEmpty(errorSchema) && (
				<li className={'error-message'} style={{ listStyle: 'none' }}>
					<i className="fa fa-exclamation-circle" style={{ marginRight: '8px' }} aria-hidden="true" />
					<span style={{ whiteSpace: 'pre-wrap' }}>Esta atividade não é incidente de licenciamento.</span>
				</li>
			)}
		</>
	);
};
AtividadesLicenciaveisHooks.displayName = 'AtividadesLicenciaveisHooks';
AtividadesLicenciaveisHooks.propTypes = {
	formData: PropTypes.object,
	onChange: PropTypes.func,
	schema: PropTypes.object,
	errorSchema: PropTypes.object,
	readonly: PropTypes.bool,
	disabled: PropTypes.bool
};

export const mapeiaLimitesCodram = staticParams => {
	let limites = {
		naoIncidencia: undefined,
		porteMinimo: undefined,
		portePequeno: undefined,
		porteMedio: undefined,
		porteGrande: undefined,
		porteExcepcional: undefined
	};

	let optionAux = staticParams !== undefined ? staticParams : null;

	if (optionAux?.naoIncidencia === 'todos os portes') {
		limites.naoIncidencia = 'todos os portes';
		return limites;
	}
	if (optionAux?.porteMinimo === 'Único') {
		limites.porteUnico = true;
		return limites;
	}

	Object.keys(optionAux || {}).forEach(key => {
		let auxString = '';
		switch (key) {
			case 'naoIncidencia':
				if (!isNil(optionAux[key])) {
					if (optionAux[key].startsWith('até ')) {
						limites[key] = ['0,00', optionAux[key].slice(4)];
					} else if (optionAux[key].startsWith('até')) {
						limites[key] = ['0,00', optionAux[key].slice(3)];
					}
				} else {
					limites[key] = null;
				}
				break;
			case 'porteMinimo':
				if (optionAux[key].startsWith('até ')) {
					limites[key] = ['0,00', optionAux[key].slice(4)];
				} else if (optionAux[key].startsWith('até')) {
					limites[key] = ['0,00', optionAux[key].slice(3)];
				} else {
					if (optionAux[key].startsWith('de ')) {
						auxString = optionAux[key].slice(3);
					} else {
						auxString = optionAux[key].slice(2);
					}
					auxString = auxString.split(' ');
					limites[key] = [auxString[0], auxString[2]];
				}
				break;
			case 'portePequeno':
			case 'porteMedio':
			case 'porteGrande':
				if (optionAux[key].startsWith('de ')) {
					auxString = optionAux[key].slice(3);
				} else {
					auxString = optionAux[key].slice(2);
				}
				auxString = auxString.split(' ');
				limites[key] = [auxString[0], auxString[2]];
				break;
			case 'porteExcepcional':
				if (optionAux[key] === 'demais') {
					limites[key] = optionAux[key];
				} else if (!isNil(optionAux[key])) {
					auxString = optionAux[key].slice('acima de '.length);
				}
				break;
			default:
				break;
		}
	});
	return limites;
};

export const classificaPorte = (atividade, option) => {
	let limites;
	let tipoPorte = undefined;
	let medidaPorte = atividade?.medidaPorte;
	limites = mapeiaLimitesCodram(option || null);

	if (limites.naoIncidencia === 'todos os portes') {
		tipoPorte = 'naoIncidencia';
	} else if (limites?.porteUnico) {
		tipoPorte = 'porteMinimo';
	} else if (
		Array.isArray(limites.porteMinimo) &&
		parseFloat(limites.porteMinimo[0]) <= medidaPorte &&
		parseFloat(limites.porteMinimo[1]) >= medidaPorte
	) {
		tipoPorte = 'porteMinimo';
	} else if (
		Array.isArray(limites.portePequeno) &&
		parseFloat(limites.portePequeno[0]) <= medidaPorte &&
		parseFloat(limites.portePequeno[1]) >= medidaPorte
	) {
		tipoPorte = 'portePequeno';
	} else if (
		Array.isArray(limites.porteMedio) &&
		parseFloat(limites.porteMedio[0]) <= medidaPorte &&
		parseFloat(limites.porteMedio[1]) >= medidaPorte
	) {
		tipoPorte = 'porteMedio';
	} else if (
		Array.isArray(limites.porteGrande) &&
		parseFloat(limites.porteGrande[0]) <= medidaPorte &&
		parseFloat(limites.porteGrande[1]) >= medidaPorte
	) {
		tipoPorte = 'porteGrande';
	} else if (
		Array.isArray(limites.naoIncidencia) &&
		parseFloat(limites.naoIncidencia[0]) <= medidaPorte &&
		parseFloat(limites.naoIncidencia[1]) >= medidaPorte
	) {
		tipoPorte = 'naoIncidencia';
	} else {
		tipoPorte = 'porteExcepcional';
	}

	let permiteProtocolar = option?.competenciaMunicipal.includes(tipoPorte)
		? 'protocolar'
		: tipoPorte === 'naoIncidencia'
		? 'bloquear'
		: 'restringir';

	permiteProtocolar = ['3511,1', '3511,2'].includes(atividade?.codram) ? 'restringir' : permiteProtocolar;
	let permiteProtocolarAux =
		permiteProtocolar === 'protocolar'
			? { key: 'protocolar' }
			: permiteProtocolar === 'bloquear'
			? { key: 'bloquear', message: 'Esta atividade não é incidente de licenciamento.' }
			: {
					key: 'restringir',
					message:
						'Este licenciamento deve ser realizado no governo do Estado do Rio Grande do Sul através do endereço https://secweb.procergs.com.br/sra.'
			  };

	return permiteProtocolarAux;
};
export const onValidate = (data, errors) => {
	const { atividade, option } = data;
	const classificaPorteAux = classificaPorte(atividade, option);

	if (classificaPorteAux.key !== 'protocolar') {
		errors.atividadeEmpreendimento.atividade.addError(classificaPorteAux.message);
	}

	return errors;
};

export default AtividadesLicenciaveisHooks;
