import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import { get, isNil, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import TemplateField from './TemplateField';

export function TemplateFieldSet({
	fieldSet = {},
	errorFields = {},
	onErrorHandler: formErrorHandler,
	onChangeHandler: formChangeHandler,
	consultaPublicaExpedientes,
	usuarioInterno,
	obterDadosProjetosLicencasExpediente,
	customErrors
}) {
	const { legend, fields, disabled, readOnly, fieldSetName, required, enumerate } = fieldSet;

	const fsetChangeHandler = useCallback(
		e => {
			if (Array.isArray(e)) {
				formChangeHandler(e.map(item => ({ fieldSetName, key: item.key, value: item.value })));
			} else {
				const { key, value } = e;
				// envia os valores para o handler com dependencia em outro field do form
				if (!isNil(fields[key].onSameAsChangeHandler)) {
					fields[key].onSameAsChangeHandler({ fieldSetName, key, value });
				}
				// caso o field tenha um handler customizado em um form-especializado
				if (!isNil(fields[key].onChangeHandler)) {
					fields[key].onChangeHandler({ fieldSetName, key, value });
				} else {
					// handler padrao do form
					formChangeHandler({ fieldSetName, key, value });
				}
			}
		},
		[formChangeHandler, fieldSetName, fields]
	);

	const rcFieldSets = useMemo(() => {
		// todos os fields do fieldset
		const fieldNames = Object.keys(fields).filter(key => {
			if (usuarioInterno) {
				return !fields[key].isHiddenInAdmin;
			}
			return true;
		});
		// processamentos para opcoes gerais do fieldset
		// e dependencias em outros fields do mesmo fieldset
		let cdlListFieldAux = null;
		fieldNames.forEach(key => {
			const attributes = fields[key];
			//#61399 sempre editavel
			const isCampoIdentificacao = key === 'identificacao';

			if (attributes) {
				// se estiver readOnly/disable/required
				attributes.disabled = isCampoIdentificacao
					? false
					: isNil(attributes.disabled)
					? disabled
					: attributes.disabled;
				attributes.readOnly = isCampoIdentificacao
					? false
					: isNil(attributes.readOnly)
					? readOnly
					: attributes.readOnly;
				attributes.required = isNil(attributes.required) ? required : attributes.required;
				attributes.enumerate = isNil(attributes.enumerate) ? enumerate : attributes.enumerate;
			}

			if (!readOnly) {
				// implementa uma dependencia em cdlListField para torná-lo readOnly se o expediente tiver sido informado
				// o valor do campo readonluSeInformouExpediente é o nome do atributo de expedientes, para pode verificar
				// se este tem valor e desabilitar a edição do cdlList, substituindo seu valor pelo array de endereços do expediente
				if (['cdlListField', 'cdlList'].includes(fields[key].type)) {
					if (attributes.readonlySeInformouExpediente) {
						const expedientes = fields[attributes.readonlySeInformouExpediente];
						if (size(expedientes.value) > 0) {
							if (expedientes.value._id) {
								//significa que o expediente é valido
								attributes.readOnly = true;
								// attributes.value = expedientes.value.enderecos;
							} else {
								//significa que ou o expediente está em branco ou é inválido
								attributes.readOnly = false;
							}
						}
					}
					// criamos uma dependencia do expedienteFieldBox com o cdlListField
					// armazenando as configurações do cdlListField para ser usada dentro do expedienteFieldBox
					if (fieldNames.includes('expediente') && fields?.expediente?.type === 'expedienteEnderecosFieldBox') {
						attributes.readOnly = false;
						cdlListFieldAux = { ...attributes, name: key };
					}
				}
			}

			if (['vistoriasExpediente', 'projetosExpediente'].includes(fields[key].type)) {
				const expedientes = fields[attributes.euFieldName];
				if (size(expedientes?.value) > 0) {
					if (get(expedientes, 'value._id')) {
						//significa que o expediente é valido
						attributes.expedienteValue = expedientes.value;
					} else {
						attributes.expedienteValue = null;
					}
				}
			}
		});

		// criar os fields React Elements
		let lastEnum = 0;
		let lastSubEnum = 0;

		const rcElements = fieldNames.map((name, index) => {
			// resetar o enum ao mudar de fieldset
			if (index === 0 && enumerate) {
				lastEnum = 0;
				lastSubEnum = 0;
			}
			let styleObject = {};
			let cssClasses = '';

			const hasErrorFields = errorFields && size(errorFields?.[name]) > 0;
			const hasCustomErrors = customErrors && size(customErrors?.[name]) > 0;
			const allErrors = Object.assign({}, errorFields, customErrors);

			const isInvalid = hasErrorFields || hasCustomErrors;
			const shouldHide = fields[name].hide === true;
			if (shouldHide) {
				return undefined;
			}

			const isHidden = fields[name].hidden === true;
			const bypassFormGroup = [
				'cdl',
				'cdlList',
				'cdlListField',
				'expedienteEnderecos',
				'expedienteEnderecosField',
				'expedienteEnderecosFieldBox',
				'responsavelTecnico',
				'responsavelTecnicoField',
				'responsavelTecnicoNovo',
				'responsavelTecnicoNovoField'
			].includes(fields[name].type);

			const hasLabel = fields[name].label && fields[name].label.length > 0;
			const isEnumeration = fields[name].enumerate === true;
			const isSubEnum = fields[name].subEnumerate === true;

			// enumerar os labels do fieldset
			if ((isEnumeration || isSubEnum) && !isHidden && !bypassFormGroup && hasLabel) {
				const label = fields[name].label;
				if (isEnumeration) {
					const novoLabel = (
						<>
							<span className="enum-number">{++lastEnum}</span>
							{label}
						</>
					);
					lastSubEnum = 0;
					fields[name].labelEnum = novoLabel;
				} else if (isSubEnum) {
					const novoLabel = (
						<>
							<span className="enum-number">
								{lastEnum}.{++lastSubEnum}
							</span>
							{label}
						</>
					);
					fields[name].labelEnum = novoLabel;
				}
			}
			if (bypassFormGroup) {
				styleObject = { width: '100%' };
				if (['expedienteEnderecosFieldBox'].includes(fields[name].type)) {
					styleObject = {};
					cssClasses = 'form-group col-md-12';
				}
			} else {
				cssClasses = `form-group col-md-${fields[name].columns || 12} ${isEnumeration || isSubEnum ? 'enum' : ''} ${
					isSubEnum ? 'sub-enum' : ''
				}`;
			}

			return bypassFormGroup ? (
				<div style={styleObject} className={cssClasses} key={name}>
					<TemplateField
						controlName={name}
						attributes={fields[name]}
						cdlListFieldAux={cdlListFieldAux}
						onErrorHandler={formErrorHandler}
						onChangeHandler={fsetChangeHandler}
						isInvalid={isInvalid}
						consultaPublicaExpedientes={consultaPublicaExpedientes}
						obterDadosProjetosLicencasExpediente={obterDadosProjetosLicencasExpediente}
						allErrors={allErrors}
					></TemplateField>
					{(hasErrorFields || hasCustomErrors) && (
						<ErrorMessages
							errorList={[...(errorFields?.[name] || []), ...(customErrors?.[name] || [])]}
						></ErrorMessages>
					)}
				</div>
			) : (
				<div style={styleObject} className={cssClasses} key={name} hidden={isHidden}>
					<TemplateField
						controlName={name}
						attributes={fields[name]}
						onErrorHandler={formErrorHandler}
						onChangeHandler={fsetChangeHandler}
						isInvalid={isInvalid}
						consultaPublicaExpedientes={consultaPublicaExpedientes}
						obterDadosProjetosLicencasExpediente={obterDadosProjetosLicencasExpediente}
					></TemplateField>
					{(hasErrorFields || hasCustomErrors) && (
						<ErrorMessages
							errorList={[...(errorFields?.[name] || []), ...(customErrors?.[name] || [])]}
						></ErrorMessages>
					)}
				</div>
			);
		});
		return rcElements;
	}, [
		fields,
		usuarioInterno,
		readOnly,
		disabled,
		required,
		enumerate,
		errorFields,
		customErrors,
		formErrorHandler,
		fsetChangeHandler,
		consultaPublicaExpedientes,
		obterDadosProjetosLicencasExpediente
	]);

	// render
	return (
		<fieldset>
			<legend>{legend}</legend>
			<div className="row row-cols-12">{rcFieldSets}</div>
		</fieldset>
	);
}
TemplateFieldSet.displayName = 'TemplateFieldSet';
TemplateFieldSet.propTypes = {
	fieldSet: PropTypes.object,
	errorFields: PropTypes.object,
	usuarioInterno: PropTypes.bool,
	consultaPublicaExpedientes: PropTypes.bool,
	customErrors: PropTypes.any,
	onErrorHandler: PropTypes.func.isRequired,
	onChangeHandler: PropTypes.func.isRequired,
	obterDadosProjetosLicencasExpediente: PropTypes.bool
};
export default TemplateFieldSet;
