import React, { useState, useCallback, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { get, uniqBy, padStart } from 'lodash';
import uuid from 'uuid/v4';

import Selecao from 'containers/Form/metadata-template/components/Selecao';
import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE';

import useMessages from 'custom-hooks/useMessages';
import useQueryStore from 'custom-hooks/useQueryStore';

import { isDebug } from 'utils/tools';

import 'tippy.js/dist/tippy.css'; // optional

const PlanoDiretorAnexosSelecao = ({
	data: dataProps = {},
	property,
	multiple = false,
	label,
	url,
	tagged,
	required = false,
	containerClass = 'form-group',
	detailCodigo = 'codigo',
	detailDescricao = 'descricao',
	readOnly,
	setDataHandler,
	filter: filterProps,
	showTooltipAutoFill,
	digitavel,
	tooltip = 'Preenchido automaticamente pelo sistema',
	uniqueBy,
	ignoredValues,
	disabled = false
}) => {
	/* CUSTOM HOOKS */
	const { createMessage } = useMessages();
	const { data: listaStore, loading, error } = useQueryStore(url, property);
	// se deu erro na query, manda um toast para tela
	useEffect(() => {
		if (error) {
			createMessage(`Não foi possível recuperar as ${label}. Tente novamente mais tarde.`, 3);
		}
	}, [error, createMessage, label, property]);

	const lista = useMemo(() => {
		let listaAux = listaStore;
		if (uniqueBy) {
			listaAux = uniqBy(listaStore, uniqueBy);
		}
		if (ignoredValues) {
			listaAux = listaAux.filter(item => !ignoredValues.includes(item[detailDescricao]));
			if (detailCodigo) {
				listaAux = listaAux.filter(item => !ignoredValues.includes(item[detailCodigo]));
			}
		}
		return listaAux;
	}, [detailCodigo, detailDescricao, ignoredValues, listaStore, uniqueBy]);

	/* VARIÁVEIS DE ESTADOS */
	const [termoPesquisa, setTermoPesquisa] = useState('');

	const filtrar = useCallback(
		item => {
			const temCodigo = filterProps
				? filterProps(lista, termoPesquisa)(item)
				: JSON.stringify(item)
						.toLowerCase()
						.indexOf((termoPesquisa || '').toLowerCase()) > -1;
			return temCodigo;
		},
		[filterProps, lista, termoPesquisa]
	);

	const listaAux = useMemo(() => {
		const ordenar = (item1, item2) =>
			item1[detailCodigo] > item2[detailCodigo]
				? 1
				: item1[detailCodigo] === item2[detailCodigo]
				? item1[detailDescricao] > item2[detailDescricao]
					? 1
					: -1
				: -1;

		const ordenarNumeros = property => (item1, item2) => {
			const d1 = item1[property].replace(/[^0-9]/g, '');
			const d2 = item2[property].replace(/[^0-9]/g, '');

			const desc1 = parseInt(d1, 10);
			const desc2 = parseInt(d2, 10);

			return desc1 > desc2 ? 1 : -1;
		};

		const ordenarHierarquicos = property => (item1, item2) => {
			const d1 = item1[property]
				.split('.')
				.map(p => padStart(p, 4, '0'))
				.join('');
			const d2 = item2[property]
				.split('.')
				.map(p => padStart(p, 4, '0'))
				.join('');
			return d1 > d2 ? 1 : -1;
		};

		return property === 'implantacaoAnexo53'
			? (lista || []).filter(filtrar).sort(ordenarNumeros(detailCodigo))
			: property === 'atividadeAnexo52'
			? (lista || []).filter(filtrar).sort(ordenarHierarquicos('item'))
			: property === 'quotaRegimeUrbanisticoAnexo' && label === 'Quota Ideal (m²)'
			? (lista || []).filter(filtrar).sort(ordenarNumeros(detailDescricao))
			: (lista || []).filter(filtrar).sort(ordenar);
	}, [detailCodigo, detailDescricao, filtrar, label, lista, property]);

	return digitavel ? (
		<NumberFieldLE
			name={property}
			label={label}
			placeHolder="metragem m²"
			required={true}
			containerClass={containerClass}
			onChangeHandler={props =>
				(props || [])
					.filter(p => p.name === property)
					.forEach(p =>
						setDataHandler(
							{ id: uuid(), codigo: `${p?.value?.value}`, descricao: `${p?.value?.value}`, value: p.value },
							p.name
						)
					)
			}
			value={{ ...(dataProps[property] || {}), value: get(dataProps, `${property}.${detailCodigo}`) || '' }}
			decimals={2}
			maxSize="10"
			disabled={disabled}
		/>
	) : (
		<>
			<div className={containerClass}>
				{loading || !lista ? (
					<h5>{`Obtendo ${label}`}</h5>
				) : (
					<>
						<label className="label-anexos">
							{label}
							{tagged || required ? <span style={{ color: 'red', fontWeight: 'bold' }}>*</span> : null}
							{isDebug && <span className="debug-message">{` (${property})`}</span>}
						</label>
						{
							<>
								<Selecao
									selected={dataProps?.[property]}
									detailCodigo={detailCodigo}
									detailDescricao={detailDescricao}
									autoShowList={true}
									searchTerm={termoPesquisa}
									searchList={listaAux}
									searchTermMinLength={0}
									errorList={false}
									onChangeSearchTerm={event => setTermoPesquisa(event.target.value)}
									onBlurSearchTerm={() => false}
									onSelectItem={item => () => {
										multiple
											? setDataHandler([...(dataProps?.[property] || []), item], property)
											: setDataHandler(item, property);
									}}
									onUnselect={item => () => {
										multiple
											? setDataHandler(
													[...(dataProps?.[property] || [])].filter(a => a.codigo !== item.codigo),
													property
											  )
											: setDataHandler(null, property);
									}}
									loading={loading}
									required={true}
									placeholder={'Selecione'}
									readOnly={disabled || readOnly || tagged}
									multiple={multiple}
									showHyphen={false}
									descriptionSubstringLength={53}
								/>
								{showTooltipAutoFill && (readOnly || tagged) && (
									<Tippy content={tooltip} placement="right" animation="fade" duration={200}>
										<i className="fa fa-info-circle campo-automatico-selecao" aria-hidden="true"></i>
									</Tippy>
								)}
							</>
						}
					</>
				)}
			</div>
		</>
	);
};
PlanoDiretorAnexosSelecao.displayName = 'PlanoDiretorAnexosSelecao';

PlanoDiretorAnexosSelecao.propTypes = {
	data: PropTypes.object,
	property: PropTypes.string,
	multiple: PropTypes.bool,
	label: PropTypes.string,
	url: PropTypes.string,
	tagged: PropTypes.bool,
	required: PropTypes.bool,
	containerClass: PropTypes.string,
	detailCodigo: PropTypes.string,
	detailDescricao: PropTypes.string,
	readOnly: PropTypes.bool,
	setDataHandler: PropTypes.func,
	filter: PropTypes.func,
	showTooltipAutoFill: PropTypes.bool,
	tooltip: PropTypes.string,
	digitavel: PropTypes.bool,
	uniqueBy: PropTypes.string,
	ignoredValues: PropTypes.arrayOf(PropTypes.string),
	disabled: PropTypes.bool
};

export default PlanoDiretorAnexosSelecao;
