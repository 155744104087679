import React from 'react';

import { Switch, Route } from 'react-router';

import { ConnectedRouter } from 'connected-react-router/immutable';

import AccessDenied from 'components/403Page';
import NotFoundPage from 'components/404Page';
import ClientErrorPage from 'components/4xxPage';
import ServerUnavailablePage from 'components/503Page';
import Footer from 'components/Footer';
import Header from 'components/Header';
import ModalNovidades from 'components/ModalNovidades';
import ModalPerfilUsuario from 'components/ModalPerfilUsuario';
import PrivateRoute from 'components/PrivateRoute';
import withGoogleAnalytics from 'components/withGoogleAnalytics';

import ConsultaCartaHabitacao from 'containers/ConsultaCartaHabitacao';
import Faq from 'containers/Faq';
import Lista from 'containers/Form';
import Form from 'containers/Form/Detalhe';
import Home from 'containers/Home';
import Novidades from 'containers/Novidades';
import PerfilUsuarioForm from 'containers/Perfil/PerfilForm';
import PlanilhaEdificacoesGerais from 'containers/PlanilhaEdificacoesGerais';
import TesteMapa from 'containers/TesteMapa';
import TrackerOld from 'containers/Tracker Old';
import ValidacaoDocumentos from 'containers/ValidacaoDocumentos';
import ValidacaoPlantaAssinada from 'containers/ValidacaoPlantaAssinada';

import { SERVER_URL } from 'environments';

import { history } from 'index';

const AppRouter = () => (
	<div className={`app-container ${SERVER_URL.indexOf('-hom.') > -1 ? 'is-hom-wrapper' : ''}`}>
		<div className={`app-body ${SERVER_URL.indexOf('-hom.') > -1 ? 'is-hom' : ''}`}>
			<ConnectedRouter history={history}>
				<Header />
				<ModalNovidades />
				<ModalPerfilUsuario />
				<Switch id="switch">
					<Route path="/testeMapa" component={TesteMapa} />
					<PrivateRoute path="/accessdenied" component={withGoogleAnalytics(AccessDenied)} />
					<Route path="/notfound" component={withGoogleAnalytics(NotFoundPage)} />
					<Route path="/serverUnavailable" component={withGoogleAnalytics(ServerUnavailablePage)} />
					<Route path="/clientError" component={withGoogleAnalytics(ClientErrorPage)} />
					<Route path="/validacaoDocumentos/:codaut?" component={withGoogleAnalytics(ValidacaoDocumentos)} />
					<Route path="/habite-se" component={withGoogleAnalytics(ConsultaCartaHabitacao)} />
					<Route path="/faq/" component={withGoogleAnalytics(Faq)} />
					<Route path="/validaPlanta/:codaut?" component={withGoogleAnalytics(ValidacaoPlantaAssinada)} />
					<Route path="/novidades/" component={withGoogleAnalytics(Novidades)} />
					<PrivateRoute path="/tracker/:idForm/:idFormData?" component={withGoogleAnalytics(TrackerOld)} />
					<Route exact path="/" component={withGoogleAnalytics(Home)} />
					<Route exact path="/consulta-carta-habitacao" component={withGoogleAnalytics(ConsultaCartaHabitacao)} />
					<PrivateRoute exact path="/list" component={withGoogleAnalytics(Lista)} />
					<PrivateRoute exact path="/:idForm/:id" component={withGoogleAnalytics(Form)} />
					<PrivateRoute
						exact
						path="/:idForm/:id/planilhaEdifGerais"
						component={withGoogleAnalytics(PlanilhaEdificacoesGerais)}
					/>
					{/* <PrivateRoute exact path="/gerarboleto/:idform/:id" component={PrintBoleto} /> */}
					<PrivateRoute exact path="/perfil" component={withGoogleAnalytics(PerfilUsuarioForm)} />
					<Route component={withGoogleAnalytics(NotFoundPage)} />
				</Switch>
				<Footer />
			</ConnectedRouter>
		</div>
	</div>
);

AppRouter.displayName = 'AppRouter';

export default AppRouter;
