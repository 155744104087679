import React from 'react';

import PropTypes from 'prop-types';

import { get, defaultTo } from 'lodash';

import { NumberField } from 'containers/Form/metadata-template/fields';

/**
 * componente local
 */
export function CelulaTabelaAreasAP({
	value,
	name,
	placeHolder,
	onChangeHandler,
	readOnly,
	disabled = false,
	classNameAux = ''
}) {
	return (
		<td
			className={`cell-input ${disabled ? 'cell-disabled' : ''}${classNameAux}${
				parseFloat(get(value, 'value')) > 0 ? ' cell-input-value' : ''
			}`}
		>
			<div className="cell-input-wrapper">
				<NumberField
					placeHolder={placeHolder}
					value={defaultTo(value, {})}
					title={get(value, 'value')}
					decimals={2}
					maxSize="10"
					name={name}
					onChangeHandler={onChangeHandler}
					readOnly={readOnly}
					disabled={disabled}
				/>
			</div>
		</td>
	);
}
CelulaTabelaAreasAP.displayName = 'CelulaTabelaAreasAP';
CelulaTabelaAreasAP.propTypes = {
	value: PropTypes.object,
	name: PropTypes.string,
	placeHolder: PropTypes.string,
	onChangeHandler: PropTypes.func,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	classNameAux: PropTypes.string
};
