import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useAxios from 'axios-hooks';
import { get, pick, set, size, snakeCase, sortBy } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Selecao from 'components/Selecao';
import WarningMessage from 'components/WarningMessage';

import { TextField } from 'containers/Form/metadata-template/fields';
import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE.js';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE.js';
import PlanoDiretorAnexosSelecao from 'containers/PlanoDiretor/PlanoDiretorAnexosSelecao';

import useMutableState from 'custom-hooks/useMutableState';

import { EXPEDIENTES_API_URL, PLANO_DIRETOR_API_URL } from 'environments';

import { filterList, maiorQue, multiplica, soma } from 'utils/tools';

import { PLANODIRETOR_CONTROLNAME } from './InfosPlanoDiretorLE';
import { InfosProjetoSubUnidades } from './InfosProjetoSubUnidades';
import { ITEM_HABITACAO_UNIFAMILIAR, ITEM_HABITACAO_COLETIVA, LE_Enquadramentos } from './MetadataLE';
import UltimoEvuValido from './UltimoEvuValido';

const OPT_SIM_NAO = [
	{ codigo: 'sim', descricao: 'Sim' },
	{ codigo: 'nao', descricao: 'Não' }
];

const LABEL_CIENCIA_RESTRICOES =
	'A(s) atividade(s) não residencial(is) (conforme ANEXO 5.2 do PDDUA) atende(m) a RESTRIÇÃO QUANTO À IMPLANTAÇÃO DE ATIVIDADES NA ÁREA DE OCUPAÇÃO INTENSIVA (ANEXO 5.3) e/ou RESTRIÇÃO QUANTO AOS LIMITES DE PORTE NA ÁREA DE OCUPAÇÃO INTENSIVA (ANEXO 5.4)?';

const LABEL_IMPACTO_URBANO =
	'A(s) atividade(s) não residencial(ais), conforme Anexo 5.2 do PDDUA, referem-se a Projetos Especiais de Impacto Urbano de 1° ou 2º grau, listadas no Anexo 11 do PDDUA?';

const LABEL_VIABILIDADE_URBANISTICO =
	'O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido referente a atividade conforme Art. 159 do PDDUA?';

const AVISO_RESTRICOES =
	'O atendimento aos Anexos 5.3 e 5.4 do Plano Diretor de Desenvolvimento Urbano Ambiental (PDDUA) requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"';

const AVISO_ESTUDO =
	'Devido a atividade estar listada no ANEXO 11 e NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá solicitar Estudo Viabilidade Urbanística (EVU) no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).';

const ERRO_RESTRICOES =
	'A(s) atividade(s) do projeto deve(m) estar de acordo com os Anexos 5.3 e 5.4 do Plano Diretor de Desenvolvimento Urbano Ambiental (PDDUA)';

const NUM_ITEM_HABITACAO_COLETIVA = '1.2.';

/*
const OPT_ATIVIDADE = [
	{ codigo: 'habitacao', descricao: 'Habitação Unifamiliar' },
	{
		codigo: 'habitacao_coletiva',
		descricao:
			'Habitação Coletiva (pensionatos, internatos, conventos, mosteiros, seminários e residenciais geriátricos)'
	}
];
*/

export const INFOSPROJETO_CONTROLNAME = 'infosProjeto';

/**
 * valida e limpa os dados do Form baseado no METAFORMLE.INFOS_PROJETO
 *
 * @returns objeto com erros
 * */
const validarInfosProjetoLE = (infosProjeto, docDados, tipoForm) => {
	const dadosForm = infosProjeto;
	const isUnifamiliarENaoResidencial = LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES === tipoForm;
	const isNaoResidenciais = [
		LE_Enquadramentos.NAO_RESIDENCIAL,
		LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES
	].includes(tipoForm);

	let erros = {};
	const isProjetoPossuiBaseCorpo =
		dadosForm.projetoPossuiBaseCorpo === 'sim' ? true : dadosForm.projetoPossuiBaseCorpo === 'nao' ? false : undefined;

	if (isProjetoPossuiBaseCorpo === true) {
		delete erros.taxaOcupacaoTotal;

		let valInformado = get(dadosForm, ['totalBaseProjeto', 'value']);
		let valLimite = get(dadosForm, ['totalPermitidoBase', 'value']);
		if (maiorQue(valInformado, valLimite)) {
			set(erros, 'totalBaseProjeto', [
				'Taxa de Ocupação (TO) total da base do projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido para a base (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
			]);
		}
		valInformado = get(dadosForm, ['totalCorpoProjeto', 'value']);
		valLimite = get(dadosForm, ['totalPermitidoCorpo', 'value']);
		if (maiorQue(valInformado, valLimite)) {
			set(erros, 'totalCorpoProjeto', [
				'Taxa de Ocupação (TO) total do corpo do projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido para o corpo (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
			]);
		}
	}

	const valInformado = get(dadosForm, ['taxaOcupacaoTotal', 'value']);
	const valLimite = get(dadosForm, ['taxaOcupacaoTotalPermitido', 'value']);
	if (maiorQue(valInformado, valLimite)) {
		set(erros, 'taxaOcupacaoTotal', [
			'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
		]);
	}

	const valLimiteLote = get(docDados, ['areaTotalMatriculaLote', 'value']);
	if (maiorQue(valInformado, valLimiteLote)) {
		set(erros, 'taxaOcupacaoTotal', [
			'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser menor ou igual a Área total da(s) matrícula(s) do(s) lote(s) (m²).'
		]);
	}

	const alturaMaximaAeroportoAerodromo = get(dadosForm, 'alturaMaximaAeroportoAerodromo.value');
	const alturaTotal = get(dadosForm, 'alturaTotalProjeto.value');

	if (
		maiorQue(alturaTotal, alturaMaximaAeroportoAerodromo) &&
		get(docDados, 'dmiRestricaoAlturaProximidadeAeroporto') === 'nao'
	) {
		set(erros, 'alturaTotalProjeto', [
			'Há divergência nas respostas do formulário. Verificar a restrição relativa à ALTURA FACE PROXIMIDADE AEROPORTUÁRIA. A altura total do projeto é maior do que a Altura máxima definida pela guia Aeroporto informada na DMWEB, anexe o documento AUTORIZAÇÃO do DECEA (DECLARAÇÃO DE INEXIGIBILIDADE) no botão"Adicionar documento" da aba DOCUMENTOS após concluir este formulário.'
		]);
	}
	const taxaOcupacaoProjeto = get(dadosForm, 'taxaOcupacaoProjeto.value');
	const quotaTaxaOcupacao = get(docDados, 'quotaTaxaOcupacao.value');
	if (maiorQue(taxaOcupacaoProjeto, quotaTaxaOcupacao)) {
		set(erros, 'taxaOcupacaoProjeto', [
			'A Taxa de Ocupação do Projeto (m²) deve ser menor ou igual a Taxa de Ocupação - TO (m²) conforme a aprovação do condomínio.'
		]);
	}
	if (
		maiorQue(alturaTotal, alturaMaximaAeroportoAerodromo) &&
		get(docDados, 'dmiRestricaoAlturaProximidadeAeroporto') === 'sim' &&
		get(docDados, 'edificacaoUltrapassaAlturaDECEA') === 'nao'
	) {
		set(erros, 'alturaTotalProjeto', [
			'Há divergência nas respostas do formulário. Verificar a restrição relativa à ALTURA FACE PROXIMIDADE AEROPORTUÁRIA. A altura total do projeto é maior do que a Altura máxima definida pela guia Aeroporto informada na DMWEB, anexe o documento AUTORIZAÇÃO do DECEA (DECLARAÇÃO DE INEXIGIBILIDADE) no botão"Adicionar documento" da aba DOCUMENTOS após concluir este formulário.'
		]);
	}

	const numeroEconomiasResidencial = get(dadosForm, 'numeroEconomiasResidencial.value');
	if (
		isUnifamiliarENaoResidencial &&
		(maiorQue(numeroEconomiasResidencial, 2) || numeroEconomiasResidencial === 0 || numeroEconomiasResidencial === '0')
	) {
		set(erros, 'numeroEconomiasTotal', [
			'Só é permitido informar 1 ou 2 economias residenciais (habitação unifamiliar até 2 economias). Em caso de mais de 2 economias residenciais, este projeto não se enquadra na modalidade de Aprovação de Projeto Licenciamento Expresso. Deverá ser solicitada a Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).'
		]);
	}

	const numeroEconomiasNaoResidencial = get(dadosForm, 'numeroEconomiasNaoResidencial.value');
	if (isNaoResidenciais && (numeroEconomiasNaoResidencial === 0 || numeroEconomiasNaoResidencial === '0')) {
		set(erros, 'numeroEconomiasTotal', ['Deve ser informada ao menos 1 economia não residencial.']);
	}

	return erros;
};

export function InfosProjetoLE({ data: localData, tipoForm, onChangeHandler, errosSubmissao, semExpedienteUnico }) {
	const infosProjetoData = get(localData, [INFOSPROJETO_CONTROLNAME], {});
	const projetoAtividade = get(infosProjetoData, 'projetoAtividade');
	const subunidades = get(localData, 'planoDiretor.dadosSubunidades', []);
	const subunidadesLength = size(subunidades);
	// const certificado = get(localData, 'certificadoSustentavel');

	const [erros, setErros] = useState({});
	const [searchMaterial, setSearchMaterial] = useState();
	const [outroMaterial, setOutroMaterial] = useState();
	const [hasOutroMaterial, setHasOutroMaterial] = useState(false);
	// informa que algo mudou aqui e a subunidade precisa mudar
	const [mudarSubunidades, setMudarSubunidades] = useState([]);
	const [atividadeSelecionada, setAtividadeSelecionada] = useState(projetoAtividade);
	const [qtdSubunidades, setQtdSubunidades] = useState(subunidadesLength);
	const atividadesAnexo52 = useMutableState(['licenciamento', 'atividadeAnexo52']);

	const {
		UNIFAMILIAR_01_ECONO,
		UNIFAMILIAR_02_ECONO,
		UNIFAMILIAR_AUTONOMA,
		NAO_RESIDENCIAL,
		UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES,
		MULTIFAMILIAR
	} = LE_Enquadramentos;

	const isUnifamiliarUmaEconomia = UNIFAMILIAR_01_ECONO === tipoForm;
	const isUniFamiliarDuasEconomias = UNIFAMILIAR_02_ECONO === tipoForm;
	const isNaoResidenciais = [NAO_RESIDENCIAL, UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES].includes(tipoForm);
	const isUnifamiliarENaoResidencial = UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES === tipoForm;
	const isUnidadeAutonoma = tipoForm === UNIFAMILIAR_AUTONOMA;
	const isHabitacaoColetiva = infosProjetoData?.projetoAtividade?.item === ITEM_HABITACAO_COLETIVA;
	const isNaoResidencial = NAO_RESIDENCIAL === tipoForm;
	const isMultifamiliar = tipoForm === MULTIFAMILIAR;

	const isProjetoPossuiBaseCorpo =
		infosProjetoData.projetoPossuiBaseCorpo === 'sim'
			? true
			: infosProjetoData.projetoPossuiBaseCorpo === 'nao'
			? false
			: undefined;

	/** alguns cálculos automáticos */
	const calculos = useCallback(
		localData => {
			const infosProjetoData = get(localData, [INFOSPROJETO_CONTROLNAME], {});
			const subunidades = get(localData, 'planoDiretor.dadosSubunidades', []);
			if (!isUnidadeAutonoma) {
				let sumIA = subunidades.reduce((acc, subUnidade) => {
					const val = get(subUnidade, 'indiceAproveitamento.value');
					return soma(acc, val);
				}, 0);

				if (
					isNaoResidencial &&
					parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value) === 1 &&
					infosProjetoData?.trintaPorcentoIa === 'sim'
				) {
					sumIA = multiplica(sumIA, 1.3);
				}

				if (infosProjetoData?.projetoAtividade?.item === NUM_ITEM_HABITACAO_COLETIVA) {
					sumIA = multiplica(sumIA, 1.3);
				}

				infosProjetoData.indiceAproveitamentoTotalPermitido = { value: sumIA };

				const sumTO = subunidades.reduce((p, c) => {
					const val = get(c, 'resultadoTOSubunidade.value');
					return soma(p, val);
				}, 0);
				infosProjetoData.taxaOcupacaoTotalPermitido = { value: sumTO };

				const sumTBase = subunidades.reduce((p, c) => {
					const val = get(c, 'resultadoTOBase.value');
					return soma(p, val);
				}, 0);
				const sumTCorpo = subunidades.reduce((p, c) => {
					const val = get(c, 'resultadoTOCorpo.value');
					return soma(p, val);
				}, 0);
				infosProjetoData.totalPermitidoBase = { value: sumTBase };
				infosProjetoData.totalPermitidoCorpo = { value: sumTCorpo };
			}
			if (isNaoResidenciais || isMultifamiliar) {
				const bs = get(infosProjetoData, 'qtdBoxesPrivativosSimples.value');
				const bd = get(infosProjetoData, 'qtdBoxesPrivativosDuplos.value');
				infosProjetoData.totalQtdBoxesPrivativos = { value: soma(bs, bd, { decimais: 0 }) };

				// vagas = Boxes privativos simples + 2x (boxes privativos duplos) + Vagas condominiais
				const vc = get(infosProjetoData, 'qtdVagasCondominiais.value');
				const bd2 = multiplica(bd, 2, { decimais: 0 });
				const bd2s = soma(bd2, bs, { decimais: 0 });
				infosProjetoData.totalQtdVagasCondominiais = { value: soma(bd2s, vc, { decimais: 0 }) };
			}
		},
		[isMultifamiliar, isNaoResidenciais, isNaoResidencial, isUnidadeAutonoma]
	);

	// manipular os tipos de construção
	const [tiposConstrucao, setTiposConstrucao] = useState([]);
	const [{ data: tiposconstrucao }] = useAxios(`${EXPEDIENTES_API_URL}/cadastros/tipoconstrucao`);

	const handleSelect = ({ name, value: item, operacao }) => {
		//localData.tipoConstrucao
		const tiposConstru = infosProjetoData[name] || {};
		if (item.codigo === 'outro') {
			setHasOutroMaterial(true);
			setSearchMaterial(undefined);
		} else if (operacao === 'add-outro') {
			tiposConstru[item.codigo] = { ...item };
			setHasOutroMaterial(false);
			setOutroMaterial(undefined);
		} else if (operacao === 'add') {
			tiposConstru[item.codigo] = { ...item };
		}
		if (operacao === 'remove') {
			delete tiposConstru[item.codigo];
		}
		localChangeHandler && localChangeHandler({ name, value: { ...tiposConstru } });
	};

	const dadosSubunidades = localData?.planoDiretor?.dadosSubunidades;
	useEffect(() => {
		// este effect é necessário para que os cálculos sejam refeitos quando se
		// adiciona ou remove subunidades do Plano Diretor
		calculos(localData);
		onChangeHandler && onChangeHandler({ name: INFOSPROJETO_CONTROLNAME, value: { ...infosProjetoData } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dadosSubunidades]);

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			const { name, value } = changes;

			// set dos dados do projeto
			if (name === 'planoDiretor') {
				set(localData, ['planoDiretor'], value);
			} else {
				set(localData, [INFOSPROJETO_CONTROLNAME, name], value);
			}

			// na unifamiliar de uma economia todas as subunidades possuem a mesma atividade (habitacao ou coletiva)
			// na unifamiliar de mais de uma economias todos sao habitacao, outro effect faz isso
			if (name === 'projetoAtividade') {
				const subunidades = get(localData, [PLANODIRETOR_CONTROLNAME, 'dadosSubunidades'], []);
				subunidades.forEach(sub => {
					set(sub, 'dadosAtividades.0.atividadeAnexo52', value);
				});
			}

			calculos(localData);

			const erros = validarInfosProjetoLE(infosProjetoData, localData, tipoForm);
			setErros(erros);
			onChangeHandler && onChangeHandler({ name: INFOSPROJETO_CONTROLNAME, value: { ...infosProjetoData } });
		},
		[calculos, infosProjetoData, localData, onChangeHandler, tipoForm]
	);

	useEffect(() => {
		if (infosProjetoData) {
			if (infosProjetoData && !infosProjetoData.projetoPossuiEvuValido) {
				localChangeHandler({ name: 'projetoPossuiEvuValido', value: localData.ultimoEvuValido ? 'sim' : 'nao' });
			}
		}
	}, [infosProjetoData, localChangeHandler, localData.ultimoEvuValido]);

	useEffect(() => {
		if (tiposconstrucao) {
			let tipos = tiposconstrucao.map(tp => pick(tp, ['_id', 'codigo', 'descricao']));
			tipos = sortBy(tipos, 'descricao');
			tipos.push({ _id: '000', codigo: 'outro', descricao: 'Informar outro' });
			setTiposConstrucao(tipos);
		}
	}, [tiposconstrucao]);

	const atividadeChangeHandler = useCallback(
		atividade => {
			if (isUniFamiliarDuasEconomias || isUnifamiliarUmaEconomia) {
				// cada subunidade deve atualizar se mudou a atividade
				setMudarSubunidades(new Array(subunidadesLength).fill(true, 0, subunidadesLength));
				// setAtividadeSelecionada(atividade);
				localChangeHandler && localChangeHandler({ name: 'projetoAtividade', value: atividade });
			}
		},
		[isUniFamiliarDuasEconomias, isUnifamiliarUmaEconomia, localChangeHandler, subunidadesLength]
	);

	// preset da atividade habitacao para unifamiliar duas economias
	useEffect(() => {
		if (
			isUniFamiliarDuasEconomias &&
			atividadesAnexo52 &&
			infosProjetoData?.projetoAtividade?.item !== ITEM_HABITACAO_UNIFAMILIAR
		) {
			const atv = atividadesAnexo52.find(ativ => ativ.item === ITEM_HABITACAO_UNIFAMILIAR);
			atividadeChangeHandler(atv);
		}
	}, [atividadeChangeHandler, atividadesAnexo52, infosProjetoData, isUniFamiliarDuasEconomias]);

	// se mudar a quantidade de subunidades preenche a atividade da subunidade
	useEffect(() => {
		if (qtdSubunidades !== subunidadesLength && atividadeSelecionada) {
			setQtdSubunidades(subunidadesLength);
			atividadeChangeHandler && atividadeChangeHandler(atividadeSelecionada);
		}
	}, [atividadeChangeHandler, atividadeSelecionada, qtdSubunidades, subunidadesLength]);

	// salva a atividade do projeto para usar em outros hooks
	useEffect(() => {
		setAtividadeSelecionada(projetoAtividade);
	}, [projetoAtividade]);

	useEffect(() => {
		let soma = 0;
		if (isNaoResidencial) {
			soma = parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value || '0');
		} else {
			soma =
				parseInt(infosProjetoData?.numeroEconomiasResidencial?.value || '0') +
				parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value || '0');
		}
		if (soma !== parseInt(infosProjetoData?.numeroEconomiasTotal?.value)) {
			localChangeHandler && localChangeHandler({ name: 'numeroEconomiasTotal', value: { value: `${soma}` } });
		}
	}, [infosProjetoData, isNaoResidencial, localChangeHandler]);

	const atividadeCallbackHandle = useCallback(
		i => () => {
			mudarSubunidades[i] = false;
			setMudarSubunidades([...mudarSubunidades]);
		},
		[mudarSubunidades]
	);

	// 1,3 ids de habitacao e coletiva
	const filtro = useCallback(
		() => item => [ITEM_HABITACAO_UNIFAMILIAR, ITEM_HABITACAO_COLETIVA].includes(item?.item),
		[]
	);

	if (!isUnidadeAutonoma) {
		return (
			<fieldset>
				<legend>4. Projeto</legend>
				<legend className="legenda-titulo-secundario dados-iniciais">4.1 Dados iniciais do projeto</legend>
				<div className="projeto-le">
					{(isUnifamiliarUmaEconomia || isUniFamiliarDuasEconomias) && (
						<>
							<PlanoDiretorAnexosSelecao
								data={{ atividadeAnexo52: get(localData, [INFOSPROJETO_CONTROLNAME, 'projetoAtividade']) }}
								property="atividadeAnexo52"
								multiple={false}
								label="Atividade"
								url={`${PLANO_DIRETOR_API_URL}/anexo52`}
								containerClass="pergunta selecao2"
								detailCodigo=""
								detailDescricao="atv"
								readOnly={isUniFamiliarDuasEconomias}
								setDataHandler={value => atividadeChangeHandler(value)}
								filter={filtro}
								required={true}
							></PlanoDiretorAnexosSelecao>
							<ErrorMessages
								errorList={
									get(erros, ['atividadeAnexo52']) || (errosSubmissao || []).filter(e => e.campo === 'atividadeAnexo52')
								}
							/>
						</>
					)}
					<div className="pergunta metragem">
						<NumberFieldLE
							label="Altura máxima pela guia Aeroporto (DECEA) informada na DMWEB (m)"
							name="alturaMaximaAeroportoAerodromo"
							value={infosProjetoData.alturaMaximaAeroportoAerodromo}
							readOnly={false}
							onChangeHandler={localChangeHandler}
							maxSize="10"
							decimals={2}
							placeHolder="0,00"
							required={true}
						/>
						<ErrorMessages
							errorList={
								get(erros, ['alturaMaximaAeroportoAerodromo']) ||
								(errosSubmissao || []).filter(e => e.campo === 'alturaMaximaAeroportoAerodromo')
							}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							label="Altura do elemento mais alto do projeto (m)"
							name="alturaTotalProjeto"
							value={infosProjetoData.alturaTotalProjeto}
							readOnly={false}
							onChangeHandler={localChangeHandler}
							maxSize="10"
							decimals={2}
							placeHolder="0,00"
							required={true}
						/>
						<ErrorMessages
							errorList={
								get(erros, ['alturaTotalProjeto']) ||
								(errosSubmissao || []).filter(e => e.campo === 'alturaTotalProjeto')
							}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="numeroPavimentos"
							label="Número de pavimentos"
							placeHolder="0"
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.numeroPavimentos}
							maxSize="10"
						/>
						<ErrorMessages
							errorList={
								get(erros, ['numeroPavimentos']) || (errosSubmissao || []).filter(e => e.campo === 'numeroPavimentos')
							}
						/>
					</div>

					<div className="pergunta">
						<RadioFieldLE
							name="alpIsenta"
							label="Conforme PDDUA, há isenção da Área Livre Permeável?"
							options={OPT_SIM_NAO}
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.alpIsenta}
						/>
						<ErrorMessages
							errorList={get(erros, ['alpIsenta']) || (errosSubmissao || []).filter(e => e.campo === 'alpIsenta')}
						/>
					</div>
					<div className="pergunta">
						<RadioFieldLE
							name="recuoJardimIsento"
							label="Conforme PDDUA, há isenção do Recuo de Jardim?"
							options={OPT_SIM_NAO}
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.recuoJardimIsento}
						/>
						<ErrorMessages
							errorList={
								get(erros, ['recuoJardimIsento']) || (errosSubmissao || []).filter(e => e.campo === 'recuoJardimIsento')
							}
						/>
					</div>

					{!isNaoResidencial && (
						<div className="pergunta metragem">
							<NumberFieldLE
								name="numeroDormitorios"
								label="Número de dormitórios"
								placeHolder="0"
								required={false}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.numeroDormitorios}
								maxSize="10"
							/>
							<ErrorMessages
								errorList={
									get(erros, ['numeroDormitorios']) ||
									(errosSubmissao || []).filter(e => e.campo === 'numeroDormitorios')
								}
							/>
						</div>
					)}
					<div className="pergunta">
						<label>
							Tipo de material<span className="required">*</span>
						</label>
						<Selecao
							className={'form-control'}
							multiple={true}
							selected={Object.values(infosProjetoData?.tipoConstrucao || {})}
							label={'Tipo de construção'}
							detailCodigo={''}
							detailDescricao={'descricao'}
							autoShowList={true}
							searchTerm={searchMaterial}
							searchList={filterList(tiposConstrucao, searchMaterial)}
							searchTermMinLength={1}
							errorList={false}
							onChangeSearchTerm={e => setSearchMaterial(e.target.value)}
							onBlurSearchTerm={() => false}
							onSelectItem={item => () => handleSelect({ name: 'tipoConstrucao', value: item, operacao: 'add' })}
							onUnselect={item => () => handleSelect({ name: 'tipoConstrucao', value: item, operacao: 'remove' })}
							loading={false}
							required={true}
							placeholder="tipos de material"
						/>
						<ErrorMessages
							errorList={
								get(erros, ['tipoConstrucao']) || (errosSubmissao || []).filter(e => e.campo === 'tipoConstrucao')
							}
						/>
						{hasOutroMaterial && (
							<div style={{ marginTop: '8px' }}>
								<TextField
									name="outroMaterial"
									value={outroMaterial || ''}
									label="Descrever Outro Material"
									placeHolder="Informar o nome do outro material..."
									// eslint-disable-next-line no-unused-vars
									onChangeHandler={([{ name, value }]) => setOutroMaterial(value)}
								/>
								<button
									type="button"
									onClick={() => {
										let item = { descricao: outroMaterial, codigo: snakeCase(outroMaterial) };
										size(outroMaterial) &&
											handleSelect({
												name: 'tipoConstrucao',
												value: item,
												operacao: 'add-outro'
											});
									}}
								>
									Adicionar
								</button>
							</div>
						)}
					</div>

					{isUnifamiliarENaoResidencial && (
						<div className="pergunta metragem">
							<NumberFieldLE
								name="numeroEconomiasResidencial"
								label="Número de Economias - Residencial"
								placeHolder="0"
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.numeroEconomiasResidencial}
								maxSize="10"
							/>
							<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'numeroEconomiasResidencial')} />
						</div>
					)}
					{isNaoResidenciais && (
						<div className="pergunta metragem">
							<NumberFieldLE
								name="numeroEconomiasNaoResidencial"
								label="Número de Economias - Não Residencial"
								placeHolder="0"
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.numeroEconomiasNaoResidencial}
								maxSize="10"
							/>
							<ErrorMessages
								errorList={(errosSubmissao || []).filter(e => e.campo === 'numeroEconomiasNaoResidencial')}
							/>
						</div>
					)}

					{(isUnifamiliarENaoResidencial || isNaoResidenciais) && (
						<div className="pergunta metragem">
							<NumberFieldLE
								name="numeroEconomiasTotal"
								label="Número de Economias - Total"
								placeHolder="0"
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.numeroEconomiasTotal}
								maxSize="10"
								readOnly={true}
							/>
							<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'numeroEconomiasTotal')} />
							<ErrorMessages errorList={get(erros, ['numeroEconomiasTotal'])} />
						</div>
					)}
					{isNaoResidencial && parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value) === 1 && (
						<div className="pergunta">
							<RadioFieldLE
								name="trintaPorcentoIa"
								label="O projeto utilizará acréscimo de 30% de índice de aproveitamento conforme Art. 107, §5º do PDDUA?"
								options={OPT_SIM_NAO}
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.trintaPorcentoIa}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['trintaPorcentoIa']) || (errosSubmissao || []).filter(e => e.campo === 'trintaPorcentoIa')
								}
							/>
						</div>
					)}
					{isUnifamiliarUmaEconomia && isHabitacaoColetiva && (
						<div className="pergunta">
							<RadioFieldLE
								name="trintaPorcentoIa"
								label="O projeto utilizará acréscimo de 30% de índice de aproveitamento conforme Art. 107, §5º do PDDUA?"
								options={OPT_SIM_NAO}
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.trintaPorcentoIa}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['trintaPorcentoIa']) || (errosSubmissao || []).filter(e => e.campo === 'trintaPorcentoIa')
								}
							/>
						</div>
					)}
					{/* subunidades */}
					{size(subunidades) > 0 && <legend className="legenda-titulo-secundario">4.2 Dados das subunidades</legend>}
					{subunidades.map((sub, i) => (
						<InfosProjetoSubUnidades
							key={sub.id}
							data={localData}
							tipoForm={tipoForm}
							onChangeHandler={localChangeHandler}
							dadosSubunidade={sub}
							atividadeModificada={mudarSubunidades[i]}
							atividadeModificadaCallback={atividadeCallbackHandle(i)}
							errosSubmissao={errosSubmissao}
						/>
					))}
					{/* fim das subunidades */}
					<legend className="legenda-titulo-secundario">4.3 Dados gerais</legend>
					{isNaoResidenciais && (
						<div className="pergunta">
							<RadioFieldLE
								name="projetoAtendeRestricoesAtividades"
								label={LABEL_CIENCIA_RESTRICOES}
								options={OPT_SIM_NAO}
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.projetoAtendeRestricoesAtividades}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['projetoAtendeRestricoesAtividades']) ||
									(errosSubmissao || []).filter(e => e.campo === 'projetoAtendeRestricoesAtividades')
								}
							/>
							{infosProjetoData?.projetoAtendeRestricoesAtividades === 'sim' ? (
								<WarningMessage>{AVISO_RESTRICOES}</WarningMessage>
							) : infosProjetoData?.projetoAtendeRestricoesAtividades === 'nao' ? (
								<ErrorMessages errorList={[ERRO_RESTRICOES]} />
							) : null}
						</div>
					)}
					{/* INICIO */}
					{isNaoResidenciais && (
						<div className="pergunta">
							<RadioFieldLE
								name="projetoImpactoUrbano"
								label={LABEL_IMPACTO_URBANO}
								options={OPT_SIM_NAO}
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.projetoImpactoUrbano}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['projetoImpactoUrbano']) ||
									(errosSubmissao || []).filter(e => e.campo === 'projetoImpactoUrbano')
								}
							/>
							{infosProjetoData?.projetoImpactoUrbano === 'sim' && (
								<div className="pergunta">
									<RadioFieldLE
										name="projetoViabilidaUrbanistico"
										label={LABEL_VIABILIDADE_URBANISTICO}
										options={OPT_SIM_NAO}
										required={true}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.projetoViabilidaUrbanistico}
									/>
									<ErrorMessages
										errorList={
											get(erros, ['projetoViabilidaUrbanistico']) ||
											(errosSubmissao || []).filter(e => e.campo === 'projetoViabilidaUrbanistico')
										}
									/>
									{infosProjetoData?.projetoViabilidaUrbanistico ===
									'sim' ? null : infosProjetoData?.projetoViabilidaUrbanistico === 'nao' ? (
										<ErrorMessages errorList={[AVISO_ESTUDO]} />
									) : null}
								</div>
							)}
						</div>
					)}
					{/* FIM */}
					{(isNaoResidenciais || (isUnifamiliarUmaEconomia && isHabitacaoColetiva)) && (
						<div className="pergunta metragem">
							<NumberFieldLE
								name="indiceAproveitamentoTotalPermitido"
								label="Índice de Aproveitamento (IA) total permitido (m²)"
								value={infosProjetoData.indiceAproveitamentoTotalPermitido}
								decimals={2}
								maxSize="10"
								readOnly={true}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['indiceAproveitamentoTotalPermitido']) ||
									(errosSubmissao || []).filter(e => e.campo === 'indiceAproveitamentoTotalPermitido')
								}
							/>
						</div>
					)}
					<div className="pergunta">
						<RadioFieldLE
							name="projetoPossuiBaseCorpo"
							label="O projeto possui base e corpo?"
							options={OPT_SIM_NAO}
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.projetoPossuiBaseCorpo}
						/>
						<ErrorMessages
							errorList={
								get(erros, ['projetoPossuiBaseCorpo']) ||
								(errosSubmissao || []).filter(e => e.campo === 'projetoPossuiBaseCorpo')
							}
						/>
					</div>
					{isProjetoPossuiBaseCorpo && (
						<>
							<div className="pergunta metragem">
								<NumberFieldLE
									name="totalPermitidoBase"
									label="Taxa de Ocupação (TO) total permitido para a base (m²)"
									value={infosProjetoData.totalPermitidoBase}
									decimals={2}
									maxSize="10"
									readOnly={true}
								/>
							</div>
							<div className="pergunta metragem">
								<NumberFieldLE
									name="totalBaseProjeto"
									label="Taxa de Ocupação (TO) total da base do projeto (m²)"
									required={true}
									value={infosProjetoData.totalBaseProjeto}
									decimals={2}
									maxSize="10"
									onChangeHandler={localChangeHandler}
								/>
								<ErrorMessages
									errorList={
										get(erros, ['totalBaseProjeto']) ||
										(errosSubmissao || []).filter(e => e.campo === 'totalBaseProjeto')
									}
								/>
							</div>
							<div className="pergunta metragem">
								<NumberFieldLE
									name="totalPermitidoCorpo"
									label="Taxa de Ocupação (TO) total permitido para o corpo (m²)"
									value={infosProjetoData.totalPermitidoCorpo}
									decimals={2}
									maxSize="10"
									readOnly={true}
								/>
							</div>
							<div className="pergunta metragem">
								<NumberFieldLE
									name="totalCorpoProjeto"
									label="Taxa de Ocupação (TO) total do corpo do projeto (m²)"
									required={true}
									value={infosProjetoData.totalCorpoProjeto}
									decimals={2}
									maxSize="10"
									onChangeHandler={localChangeHandler}
								/>
								<ErrorMessages
									errorList={
										get(erros, ['totalCorpoProjeto']) ||
										(errosSubmissao || []).filter(e => e.campo === 'totalCorpoProjeto')
									}
								/>
							</div>
						</>
					)}
					<div className="pergunta metragem">
						<NumberFieldLE
							name="taxaOcupacaoTotalPermitido"
							label="Taxa de Ocupação (TO) total permitido (m²)"
							value={infosProjetoData.taxaOcupacaoTotalPermitido}
							decimals={2}
							maxSize="10"
							readOnly={true}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="taxaOcupacaoTotal"
							label="Taxa de Ocupação (TO) total do projeto (m²)"
							required={true}
							value={infosProjetoData.taxaOcupacaoTotal}
							decimals={2}
							maxSize="10"
							onChangeHandler={localChangeHandler}
						/>
						<ErrorMessages
							errorList={
								get(erros, ['taxaOcupacaoTotal']) || (errosSubmissao || []).filter(e => e.campo === 'taxaOcupacaoTotal')
							}
						/>
					</div>
					<div className="pergunta">
						<RadioFieldLE
							name="projetoPossuiEvuValido"
							label="O projeto possui Estudo de Viabilidade Urbanística aprovado e válido?"
							options={OPT_SIM_NAO}
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.projetoPossuiEvuValido}
						/>
						<ErrorMessages
							errorList={
								get(erros, ['projetoPossuiEvuValido']) ||
								(errosSubmissao || []).filter(e => e.campo === 'projetoPossuiEvuValido')
							}
						/>
						<UltimoEvuValido evu={localData?.ultimoEvuValido} semExpedienteUnico={semExpedienteUnico} />
					</div>
					{isNaoResidenciais && (
						<>
							<legend className="legenda-titulo-secundario">4.4 Vagas de estacionamento</legend>
							<div style={{ border: '1px solid #c5c5c9', marginTop: '40px', background: '#f2f2f2' }} className="">
								{/* <label
									style={{
										marginTop: '10px',
										fontSize: '18px',
										marginBottom: '14px',
										fontWeight: 'bold',
										marginLeft: '10px'
									}}
								>
									Vagas de estacionamento
								</label> */}
								<div className="pergunta metragem">
									<NumberFieldLE
										name="qtdBoxesPrivativosSimples"
										label="Boxes privativos simples"
										placeHolder="0"
										required={false}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.qtdBoxesPrivativosSimples}
										maxSize="10"
									/>
									<ErrorMessages
										errorList={
											get(erros, ['qtdBoxesPrivativosSimples']) ||
											(errosSubmissao || []).filter(e => e.campo === 'qtdBoxesPrivativosSimples')
										}
									/>
								</div>
								<div className="pergunta metragem">
									<NumberFieldLE
										name="qtdBoxesPrivativosDuplos"
										label="Boxes privativos duplos"
										placeHolder="0"
										required={false}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.qtdBoxesPrivativosDuplos}
										maxSize="10"
									/>
									<ErrorMessages
										errorList={
											get(erros, ['qtdBoxesPrivativosDuplos']) ||
											(errosSubmissao || []).filter(e => e.campo === 'qtdBoxesPrivativosDuplos')
										}
									/>
								</div>
								<div className="pergunta metragem">
									<NumberFieldLE
										name="totalQtdBoxesPrivativos"
										label="Boxes totais"
										placeHolder="0"
										readOnly={true}
										value={infosProjetoData.totalQtdBoxesPrivativos}
										maxSize="10"
									/>
								</div>
								<div className="pergunta metragem">
									<NumberFieldLE
										name="qtdVagasCondominiais"
										label="Vagas condominiais"
										placeHolder="0"
										required={false}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.qtdVagasCondominiais}
										maxSize="10"
									/>
									<ErrorMessages
										errorList={
											get(erros, ['qtdVagasCondominiais']) ||
											(errosSubmissao || []).filter(e => e.campo === 'qtdVagasCondominiais')
										}
									/>
								</div>
								<div className="pergunta metragem">
									<NumberFieldLE
										name="totalQtdVagasCondominiais"
										label="Vagas totais"
										placeHolder="0"
										readOnly={true}
										value={infosProjetoData.totalQtdVagasCondominiais}
										maxSize="10"
									/>
								</div>
								<div className="pergunta">
									<RadioFieldLE
										name="possuiVagaCargaDescarga"
										label="Vagas de carga e descarga?"
										options={OPT_SIM_NAO}
										required={false}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.possuiVagaCargaDescarga}
									/>
									<ErrorMessages
										errorList={
											get(erros, ['possuiVagaCargaDescarga']) ||
											(errosSubmissao || []).filter(e => e.campo === 'possuiVagaCargaDescarga')
										}
									/>
								</div>
								{infosProjetoData?.possuiVagaCargaDescarga === 'sim' && (
									<div className="pergunta metragem">
										<>
											<NumberFieldLE
												name="qtdVagasCargaDescarga"
												label="Quantas vagas de carga e descarga?"
												placeHolder="0"
												required={false}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.qtdVagasCargaDescarga}
												maxSize="10"
											/>
											<ErrorMessages
												errorList={
													get(erros, ['qtdVagasCargaDescarga']) ||
													(errosSubmissao || []).filter(e => e.campo === 'qtdVagasCargaDescarga')
												}
											/>
										</>
									</div>
								)}

								<div className="pergunta">
									<RadioFieldLE
										name="possuiBicicletario"
										label="Bicicletário?"
										options={OPT_SIM_NAO}
										required={false}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.possuiBicicletario}
									/>
									<ErrorMessages
										errorList={
											get(erros, ['possuiBicicletario']) ||
											(errosSubmissao || []).filter(e => e.campo === 'possuiBicicletario')
										}
									/>
								</div>
								<div className="pergunta metragem">
									<NumberFieldLE
										name="qtdVagasDeficiencia"
										label="Vagas para PcD (Art. 124, §4º do PDDUA)?"
										placeHolder="0"
										required={false}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.qtdVagasDeficiencia}
										maxSize="10"
									/>
									<ErrorMessages
										errorList={
											get(erros, ['qtdVagasDeficiencia']) ||
											(errosSubmissao || []).filter(e => e.campo === 'qtdVagasDeficiencia')
										}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</fieldset>
		);
	} else if (isUnidadeAutonoma) {
		return (
			<fieldset>
				<legend>4. Projeto</legend>
				<legend className="legenda-titulo-secundario dados-iniciais">4.1 Dados iniciais do projeto</legend>
				<div className="projeto-le">
					<div className="pergunta metragem">
						<NumberFieldLE
							name="taxaOcupacaoProjeto"
							label="Taxa de Ocupação do Projeto (m²)"
							placeHolder="metragem m²"
							required={true}
							onChangeHandler={e => localChangeHandler(e, localData.quotaTaxaOcupacao, 'quotaTaxaOcupacao')}
							value={infosProjetoData.taxaOcupacaoProjeto}
							decimals={2}
							maxSize="10"
						/>
						<ErrorMessages
							errorList={
								get(erros, ['taxaOcupacaoProjeto']) ||
								(errosSubmissao || []).filter(e => e.campo === 'taxaOcupacaoProjeto')
							}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="numeroPavimentos"
							label="Número de pavimentos"
							placeHolder="0"
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.numeroPavimentos}
							maxSize="10"
						/>
						<ErrorMessages
							errorList={
								get(erros, ['numeroPavimentos']) || (errosSubmissao || []).filter(e => e.campo === 'numeroPavimentos')
							}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="numeroDormitorios"
							label="Número de dormitórios"
							placeHolder="0"
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.numeroDormitorios}
							maxSize="10"
						/>
						<ErrorMessages
							errorList={
								get(erros, ['numeroDormitorios']) || (errosSubmissao || []).filter(e => e.campo === 'numeroDormitorios')
							}
						/>
					</div>
					<div className="pergunta">
						<label>Tipo de material</label>
						<Selecao
							className={'form-control'}
							multiple={true}
							selected={Object.values(infosProjetoData?.tipoConstrucao || {})}
							label={'Tipo de construção'}
							detailCodigo={''}
							detailDescricao={'descricao'}
							autoShowList={true}
							searchTerm={searchMaterial}
							searchList={filterList(tiposConstrucao, searchMaterial)}
							searchTermMinLength={1}
							errorList={false}
							onChangeSearchTerm={e => setSearchMaterial(e.target.value)}
							onBlurSearchTerm={() => false}
							onSelectItem={item => () => handleSelect({ name: 'tipoConstrucao', value: item, operacao: 'add' })}
							onUnselect={item => () => handleSelect({ name: 'tipoConstrucao', value: item, operacao: 'remove' })}
							loading={false}
							required={true}
							placeholder="tipos de material"
						/>
						{hasOutroMaterial && (
							<div style={{ marginTop: '8px' }}>
								<TextField
									name="outroMaterial"
									value={outroMaterial || ''}
									label="Descrever Outro Material"
									placeHolder="Informar o nome do outro material..."
									// eslint-disable-next-line no-unused-vars
									onChangeHandler={([{ name, value }]) => setOutroMaterial(value)}
								/>
								<button
									type="button"
									onClick={() => {
										let item = { descricao: outroMaterial, codigo: snakeCase(outroMaterial) };
										size(outroMaterial) &&
											handleSelect({
												name: 'tipoConstrucao',
												value: item,
												operacao: 'add-outro'
											});
									}}
								>
									Adicionar
								</button>
							</div>
						)}
						<ErrorMessages
							errorList={
								get(erros, ['tipoConstrucao']) || (errosSubmissao || []).filter(e => e.campo === 'tipoConstrucao')
							}
						/>
					</div>
					<div className="pergunta">
						<RadioFieldLE
							name="recuoJardimIsento"
							label="Conforme PDDUA, há isenção do Recuo de Jardim?"
							options={OPT_SIM_NAO}
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.recuoJardimIsento}
						/>
						<ErrorMessages
							errorList={
								get(erros, ['recuoJardimIsento']) || (errosSubmissao || []).filter(e => e.campo === 'recuoJardimIsento')
							}
						/>
					</div>
				</div>
			</fieldset>
		);
	}
}

InfosProjetoLE.displayName = 'InfosProjetoLE';
InfosProjetoLE.propTypes = {
	data: PropTypes.object,
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	errosSubmissao: PropTypes.array,
	semExpedienteUnico: PropTypes.bool
};
