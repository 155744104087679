import AtividadesLicenciaveisHooks from 'components/AtividadesLicenciaveisHooks';
import EnderecoCDL from 'components/EnderecoCDL';
import EnderecosCDL from 'components/EnderecosCDL';
import Expediente from 'components/Expediente';
import RegistroProfissional from 'components/RegistroProfissional';
import TipoAutorizacao from 'components/TipoAutorizacao';
import TipoLicencaExpressa from 'components/TipoLicencaExpressa';
import TipoProprietario from 'components/TipoProprietario';
import TipoRequerente from 'components/TipoRequerente';

export default {
	atividadesLicenciaveis: AtividadesLicenciaveisHooks,
	enderecoCDL: EnderecoCDL,
	expediente: Expediente,
	enderecosCDL: EnderecosCDL,
	registroProfissional: RegistroProfissional,
	tipoAutorizacao: TipoAutorizacao,
	tipoLicencaExpressa: TipoLicencaExpressa,
	tipoProprietario: TipoProprietario,
	tipoRequerente: TipoRequerente
};
